import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AvailabilityService from 'services/availability.service';
import { pendingReducer, rejectionReducer } from 'Util';

export const fetchAvailabilities = createAsyncThunk(
  'availability/fetchAvailabilities',
  async (payload, { rejectWithValue }) => {
    try {
      return await AvailabilityService.getAvailabilities();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchAvailability = createAsyncThunk(
  'availability/fetchAvailability',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await AvailabilityService.getAvailability(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateAvailability = createAsyncThunk(
  'availability/updateAvailability',
  async (payload, { rejectWithValue }) => {
    const {
      id, name, userId, timezoneId,
    } = payload;
    try {
      return await AvailabilityService.updateAvailability(
        id,
        name,
        userId,
        timezoneId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createAvailability = createAsyncThunk(
  'availability/createAvailability',
  async (payload, { rejectWithValue }) => {
    const { name, userId, timezoneId } = payload;
    try {
      return await AvailabilityService.createAvailability(
        name,
        userId,
        timezoneId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteAvailability = createAsyncThunk(
  'availability/deleteAvailability',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await AvailabilityService.deleteUserProfile(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

const fulfilledReducer = (state, { payload }) => {
  // eslint-disable-next-line no-param-reassign
  state.message = payload.message !== undefined ? payload.message : '';
  // eslint-disable-next-line no-param-reassign
  state.status = 'succeeded';
  // eslint-disable-next-line no-param-reassign
  state.succeeded = true;
  // eslint-disable-next-line no-param-reassign
  state.failed = false;
};

const generateDropboxProperties = ({ payload }) => payload.map((item) => ({
  ...item,
  value: item.uuid,
  label: `${item.first_name} ${item.last_name}`,
}));

/* eslint-disable no-param-reassign */
export const availabilitySlice = createSlice({
  name: 'availability',
  initialState: {
    availabilities: [],
    availability: {},
    editing: false,
    succeeded: false,
    created: false,
    updated: false,
    deleted: false,
    failed: false,
    message: '',
  },
  reducers: {
    setAvailability: (state, action) => {
      state.availability = action.payload;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setCreated: (state, action) => {
      state.created = action.payload;
    },
    setUpdated: (state, action) => {
      state.updated = action.payload;
    },
    setDeleted: (state, action) => {
      state.deleted = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setEditing: (state, action) => {
      state.editing = action.payload;
    },
  },
  extraReducers: (builder) => builder
    .addMatcher(
      (action) => action.type.endsWith('/rejected'),
      rejectionReducer,
    )
    .addMatcher((action) => action.type.endsWith('/pending'), pendingReducer)
    .addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, action) => {
        const performedAction = action.type.split('/');

        if (performedAction[0] === 'availability') {
          switch (performedAction[1]) {
            case 'fetchAvailabilities':
              fulfilledReducer(state, action);
              state.availabilities = generateDropboxProperties(action);
              break;
            case 'fetchAvailability':
              fulfilledReducer(state, action);
              state.availability = action.payload;
              break;
            case 'updateAvailability':
              fulfilledReducer(state, action);
              state.availability = action.payload;
              state.updated = true;
              break;
            case 'createAvailability':
              fulfilledReducer(state, action);
              state.availability = action.payload;
              state.created = true;
              break;
            case 'deleteAvailability':
              fulfilledReducer(state, action);
              state.deleted = true;
              break;
            default:
              fulfilledReducer(state, action);
              state.message = action.payload;
              break;
          }
        }
      },
    ),
});

export const {
  setAvailability,
  setSucceeded,
  setCreated,
  setUpdated,
  setDeleted,
  setFailed,
} = availabilitySlice.actions;

export default availabilitySlice.reducer;
