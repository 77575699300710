import {
  createCandidatePersonal,
  setErrors,
  setFailed,
  updateCandidatePersonal,
} from 'reducers/candidatesSlice';

// eslint-disable-next-line import/prefer-default-export
export const savePersonalInfo = (t, dispatch, personal, candidateId) => {
  if (personal.changed) {
    const isValid = (personal.weight !== '' && personal.weight !== undefined)
      && (personal.height !== '' && personal.height !== undefined && personal.height !== 0);

    if (isValid) {
      const data = {
        candidateId,
        weight: personal.weight,
        height: personal.height,
        hairColor: personal.hair_color,
        eyeColor: personal.eye_color,
        raceId: personal.race_id,
        willingCovidVaccine: personal.willing_covid_vaccine,
        willingInfluenzaVaccine: personal.willing_influenza_vaccine,
        immigrationIssues: personal.immigration_issues,
        immigrationIssuesDetail: personal.immigration_issues_detail,
        countryId: personal.country_id,
        passportDateIssue: personal.passport_date_issue,
        passportDateExpiration: personal.passport_date_expiration,
        passportNumber: personal.passport_number,
        currentlyInUs: personal.currently_in_us,
        currentImmigrationStatus: personal.current_immigration_status,
        visaCategory: personal.visa_category,
        asylumApproved: personal.asylum_approved,
        issuedEad: personal.issued_ead,
        issuedSsn: personal.issued_ssn,
        legallyMarried: personal.legally_married,
      };

      if (typeof personal.id !== 'undefined') {
        data.id = personal.id;
        dispatch(updateCandidatePersonal(data));
      } else {
        dispatch(createCandidatePersonal(data));
      }
    } else {
      dispatch(setErrors(t('entry-is-required-or-has-an-invalid-value', { keyPrefix: 'common' })));
      dispatch(setFailed(true));
    }
  } else {
    dispatch(setErrors(t('no-changes-to-save', { keyPrefix: 'common' })));
    dispatch(setFailed(true));
  }
};
