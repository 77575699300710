import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { setCurrentSubView } from 'reducers/candidatesSlice';

// Functions
import { checkPermission, moveSubView, setTitle } from 'Util';

// @mui material components
import Grid from '@mui/material/Grid';

// Components
import TabBar from 'layouts/components/TabBar';
import RecruitmentPreferences from '../RecruitmentPreferences';
import JobOpportunities from '../JobOpportunities';
import JobApplications from '../JobApplications';

const selector = (state) => ({
  currentUser: state.auth.user,
  currentSubView: state.candidate.currentSubView,
  goToSubView: state.candidate.goToSubView,
});

function Recruitment() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.recruitment' });
  const {
    currentUser,
    currentSubView,
    goToSubView,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [defaultTab, setDefaultTab] = useState(0);

  setTitle('Candidate Recruitment');

  const tabs = [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const views = [];

  const handleSetRecruitmentTabValue = (event, newValue) => {
    switch (tabs[newValue]) {
      case t('job-opportunities'):
        dispatch(setCurrentSubView('job-opportunities'));
        break;
      case t('job-applications'):
        dispatch(setCurrentSubView('job-applications'));
        break;
      case t('immigration'):
        dispatch(setCurrentSubView('immigration'));
        break;
      case t('preferences'):
        dispatch(setCurrentSubView('preferences'));
        break;
      default:
        dispatch(setCurrentSubView('job-opportunities'));
        break;
    }
  };

  if (checkPermission('CAJO', currentUser)) {
    tabs.push(t('job-opportunities'));
    views.push('job-opportunities');
  }

  if (checkPermission('CAJA', currentUser)) {
    tabs.push(t('job-applications'));
    views.push('job-applications');
  }

  if (checkPermission('CARO', currentUser)) {
    tabs.push(t('preferences'));
    views.push('preferences');
  }

  useEffect(() => {
    moveSubView(views, goToSubView, setDefaultTab, dispatch, currentSubView);
  }, [dispatch, views, currentSubView, goToSubView]);

  useEffect(() => {
    setDefaultTab(views.indexOf(currentSubView) >= 0 ? views.indexOf(currentSubView) : 0);
    if (views.indexOf(currentSubView) < 0) {
      dispatch(setCurrentSubView(views[0]));
    }
  }, [currentSubView, dispatch, views]);

  return (
    <div id="recruitment">
      <TabBar
        tabs={tabs}
        setTabValueFunction={handleSetRecruitmentTabValue}
        defaultTabValue={defaultTab}
      />
      <div id="view">
        <Grid item md={12}>
          {currentSubView === 'job-opportunities' && (
            <JobOpportunities />
          )}
          {currentSubView === 'job-applications' && (
            <JobApplications />
          )}
          {currentSubView === 'preferences' && (
            <RecruitmentPreferences />
          )}
        </Grid>
      </div>
    </div>
  );
}

export default Recruitment;
