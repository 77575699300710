import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Reducers
import { setOrderEditing, setOrderInfo } from 'reducers/ordersSlice';
import { fetchServices } from 'reducers/servicesSlice';
import { fetchCandidatesOutlook } from 'reducers/candidatesSlice';
import { fetchPaymentPlans } from 'reducers/paymentPlansSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import OrderInfo from '../components/OrderInfo';
import OrderDetails from '../components/OrderDetails';
import SavePanel from '../components/SavePanel';
import { fetchOrderTemplates } from '../../../reducers/orderTemplatesSlice';

function NewOrder() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setOrderEditing(false),
    );
    dispatch(
      setOrderInfo({
        candidate_id: '',
        issue: new Date().toISOString().slice(0, 10),
        installment_plan_id: '',
        installment_plan_invoices: [],
        status: '',
        order_id: '',
        order_items: [
          {
            group: 0,
            cost: 0,
            quantity: 0,
            service_id: '',
            total: 0,
          },
        ],
        candidate_name: '',
        first_name: '',
        last_name: '',
        total: '',
      }),
    );
    dispatch(fetchServices());
    dispatch(fetchPaymentPlans());
    dispatch(fetchCandidatesOutlook({ statuses: '*' }));
    dispatch(fetchOrderTemplates());
  }, [dispatch]);

  return (
    <BaseLayout>
      <SuiBox mt={4}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <OrderInfo />
                  <Divider />
                  <OrderDetails />
                  <SavePanel />
                </Grid>
              </Grid>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </BaseLayout>
  );
}

export default NewOrder;
