// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// @material-ui core components
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

function FormAutocomplete({ label, ...rest }) {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
      <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </SuiTypography>
      </SuiBox>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Autocomplete
        {...rest}
        renderInput={(params) => (
          <TextField {...params} />
        )}
      />
    </SuiBox>
  );
}

// Setting admin values for the props of FormField
FormAutocomplete.defaultProps = {
  label: ' ',
};

// Typechecking props for FormField
FormAutocomplete.propTypes = {
  label: PropTypes.string,
};

export default FormAutocomplete;
