import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { fetchCandidateActivity } from 'reducers/candidatesSlice';

// @mui material components
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { Pagination } from '@mui/lab';

// Functions
import { getCandidateUUID, setTitle } from 'Util';
import ActivityTimeline from './components/ActivityTimeline';

const selector = (state) => ({
  editing: state.candidate.editing,
  activity: state.candidate.activity,
});

function Activity() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.activity' });
  const {
    editing,
    activity,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  const [page, setPage] = useState(1);

  setTitle('Candidate Activity Log');

  useEffect(() => {
    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateActivity({ uuid, page }));
    }
  }, [dispatch, editing, page, uuid]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <Card id="activity-log" sx={{ overflow: 'visible' }}>
      <CardHeader
        title={t('activity-log')}
      />
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item md={2} />
          <Grid item md={9}>
            <ActivityTimeline activity={activity.data} />
          </Grid>
          <Grid item md={1} />
          <Grid item md={12}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Pagination
                count={activity.meta.pages}
                showFirstButton
                showLastButton
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Activity;
