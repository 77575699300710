// eslint-disable-next-line object-curly-newline
import { useEffect, useMemo, useRef, useState } from 'react';
// eslint-disable-next-line object-curly-newline
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

// Reducers
import { fetchInvoice } from 'reducers/invoicesSlice';
import { createPaypalPayment, createStripePayment } from 'reducers/paymentsSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Modal from '@mui/material/Modal';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import SuiTypography from 'components/SuiTypography';

// Images
import check from 'assets/images/check-mark.png';

// @fontawesome components
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Invoice page components
import BaseLayout from 'layouts/components/BaseLayout';
import { useReactToPrint } from 'react-to-print';
import InvoiceHeader from '../components/Header';
import InvoiceFooter from '../components/Footer';
import InvoiceDetail from '../components/Detail';
import InvoicePayment from '../components/Payment';
import { InvoicePrint } from '../components/InvoicePrint';
import PaymentLink from '../components/PaymentLink';

const cardStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const boxStyle = {
  display: 'flex',
  justifyContent: 'center',
  p: 1,
  m: 1,
  borderRadius: 1,
};

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const selector = (state) => ({
  invoiceInfo: state.invoice.invoiceInfo,
});

function Invoice() {
  const { invoiceInfo } = useSelector(selector, shallowEqual);

  const componentRef = useRef();
  const dispatch = useDispatch();
  const { id } = useParams();

  const query = useQuery();

  const [showPayment, setShowPayment] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    dispatch(
      fetchInvoice({
        id,
      }),
    );
  }, [dispatch, id]);

  const handleOpenPayment = () => setShowPayment(!showPayment);
  const handleOpenConfirmation = () => setShowConfirmation(true);
  const handleCloseConfirmation = () => setShowConfirmation(true);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  if (query.get('provider') !== undefined) {
    if (query.get('provider') === 'stripe' && !showConfirmation) {
      handleOpenConfirmation();

      dispatch(
        createStripePayment({
          paymentIntent: query.get('payment_intent'),
        }),
      );
    }

    if (query.get('provider') === 'paypal' && !showConfirmation) {
      handleOpenConfirmation();

      dispatch(
        createPaypalPayment({
          id: query.get('payment'),
        }),
      );
    }
  }

  return (
    <BaseLayout stickyNavbar>
      <SuiBox mb={2}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={10} md={10}>
            <Card>
              <InvoiceHeader />
              <InvoiceDetail />
              <InvoiceFooter />
              <SuiBox
                p={3}
                mt={1}
              >
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  flexDirection="row-reverse"
                >
                  <Grid item xs={2} lg={2}>
                    <SuiBox>
                      <div style={{ display: 'none' }}>
                        <InvoicePrint ref={componentRef} />
                      </div>
                      <SuiButton
                        variant="gradient"
                        color="secondary"
                        onClick={handlePrint}
                        p={3}
                        mt={2}
                      >
                        print
                      </SuiButton>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={2} lg={2}>
                    {invoiceInfo.status !== 'draft' && invoiceInfo.status !== 'paid' && invoiceInfo.status !== 'void' && (
                      <SuiBox>
                        <SuiButton
                          variant="contained"
                          color="info"
                          onClick={handleOpenPayment}
                        >
                          pay invoice
                        </SuiButton>
                      </SuiBox>
                    )}
                  </Grid>
                  <Grid item xs={8} lg={8}>
                    {showPayment && (
                      <SuiBox>
                        <InvoicePayment isExternal={false} />
                      </SuiBox>
                    )}
                  </Grid>
                  <Grid item xs={4} lg={4}>
                    {invoiceInfo.status !== 'draft' && invoiceInfo.status !== 'paid' && invoiceInfo.status !== 'void' && (
                      <SuiBox mt={2}>
                        <PaymentLink link={`${process.env.REACT_APP_APP_URL}payment/${id}`} />
                      </SuiBox>
                    )}
                  </Grid>
                  <Grid item xs={8} lg={8} />
                </Grid>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
      <Modal
        open={showConfirmation}
        aria-labelledby="confirmation"
        aria-describedby="payment confirmation"
      >
        <Card sx={cardStyle}>
          <SuiBox mt={2} mx={2} sx={boxStyle}>
            <SuiBox component="img" src={check} width="50%" borderRadius="lg" />
          </SuiBox>
          <SuiBox pt={2} pb={3} px={3} sx={boxStyle}>
            <SuiTypography
              id="modal-modal-description"
              display="inline"
              variant="h5"
              textTransform="capitalize"
              fontWeight="bold"
            >
              Invoice Paid!
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={2} mb={3} sx={boxStyle}>
            <SuiTypography variant="body2" component="p" color="text">
              Transaction was successful
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={2} mb={3} sx={boxStyle}>
            <SuiButton
              color="secondary"
              size="large"
              sx={{
                width: '30%',
              }}
              onClick={handleCloseConfirmation}
            >
              <FontAwesomeIcon icon={faAngleRight} />
            </SuiButton>
          </SuiBox>
        </Card>
      </Modal>
    </BaseLayout>
  );
}

export default Invoice;
