import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Soft UI Dashboard PRO React components
import SuiAlert from 'components/SuiAlert';
import SuiTypography from 'components/SuiTypography';

const style = {
  margin: 0,
  top: 'auto',
  right: 20,
  bottom: 150,
  left: 'auto',
  position: 'fixed',
};

const selector = (state) => ({
  similar: state.candidate.similar,
});

function SimilarCandidatesWidget() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.similar' });
  const {
    similar,
  } = useSelector(selector, shallowEqual);

  return (
    <div
      aria-label="note"
      style={style}
    >
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Card id="similar-candidates" sx={{ overflow: 'visible' }}>
            <Grid container p={3}>
              <Grid item md={12}>
                <SuiTypography variant="h5">{t('similar-candidates')}</SuiTypography>
              </Grid>
              <Grid item md={12}>
                {similar.map((c) => (
                  <SuiAlert
                    color="warning"
                  >
                    <Link to={`/candidates/edit/${c.uuid}`} style={{ color: '#FFF' }}>
                      {`${c.first_name} ${c.last_name} - ${c.default_email} - ${c.default_phone}`}
                    </Link>
                  </SuiAlert>
                ))}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default SimilarCandidatesWidget;
