import Service from './service';

class ReportService extends Service {
  // eslint-disable-next-line class-methods-use-this
  async getCandidateExpensesStats(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`reports/candidates/expenses/${uuid}`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateStatusesStats() {
    const response = await this.api
      .get(
        this.localizeURL('reports/candidates/stats/statuses'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateStatusesStatsByAdvisor(id) {
    const response = await this.api
      .get(
        this.localizeURL(`reports/candidates/stats/statuses/${id}`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidatesTotalsStats() {
    const response = await this.api
      .get(
        this.localizeURL('reports/candidates/stats/totals'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidatesTotalsStatsByAdvisor(id) {
    const response = await this.api
      .get(
        this.localizeURL(`reports/candidates/stats/totals/${id}`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidatesMonthStats() {
    const response = await this.api
      .get(
        this.localizeURL('reports/candidates/stats/month'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidatesMonthStatsByAdvisor(id) {
    const response = await this.api
      .get(
        this.localizeURL(`reports/candidates/stats/month/${id}`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidatesOverview() {
    const response = await this.api
      .get(
        this.localizeURL('reports/candidates/overview'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidatesContracts() {
    const response = await this.api
      .get(
        this.localizeURL('reports/candidates/contracts'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidatesProgress(statuses) {
    const response = await this.api
      .get(
        this.localizeURL(`reports/candidates/progress/${statuses}`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateSummary(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`reports/candidates/summary/${uuid}`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateSummaries() {
    const response = await this.api
      .get(
        this.localizeURL('reports/candidates/summary/all'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateRecruitmentSummary(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`reports/candidates/summary/recruitment/${uuid}`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateDataview(section) {
    let url = '';

    switch (section) {
      case 'english':
        url = 'reports/candidates/data-view/english';
        break;
      case 'profile':
        url = 'reports/candidates/data-view/profile';
        break;
      case 'education':
        url = 'reports/candidates/data-view/education';
        break;
      case 'personal':
        url = 'reports/candidates/data-view/personal';
        break;
      default:
        url = 'reports/candidates/data-view/english';
        break;
    }

    const response = await this.api
      .get(
        this.localizeURL(url),
        { headers: this.getHeaders() },
      );
    return {
      section,
      data: response.data,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidatePaymentPlans() {
    const response = await this.api
      .get(
        this.localizeURL('reports/financial/payment-plans'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async matchBsrCandidates(candidates) {
    const data = {
      bsr: {
        candidates,
      },
    };
    const response = await this.api
      .post(
        this.localizeURL('reports/candidates/bsr/match'),
        data,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidatesLatestStatuses() {
    const response = await this.api
      .get(
        this.localizeURL('reports/candidates/statuses/latest'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getDueInvoices(date) {
    const response = await this.api
      .get(
        this.localizeURL(`reports/financial/invoices/due/${date}`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getEnglishEnrollmentDays(date, statuses) {
    const data = {
      enrollment: {
        date,
        statuses,
      },
    };
    const response = await this.api
      .post(
        this.localizeURL('reports/analytics/english/enrollment-days'),
        data,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getLeadConversion(from, to) {
    const data = {
      conversion: {
        from,
        to,
      },
    };
    const response = await this.api
      .post(
        this.localizeURL('reports/analytics/conversion/rate'),
        data,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCommitmentMeetings(from, to) {
    const data = {
      meeting: {
        from,
        to,
      },
    };
    const response = await this.api
      .post(
        this.localizeURL('reports/analytics/meetings/commitment'),
        data,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getEnglishLearningSpans(statuses) {
    const response = await this.api
      .get(
        this.localizeURL(`reports/analytics/english/learning-span/${statuses}`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }
}

export default new ReportService();
