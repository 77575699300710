import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import AttorneyService from 'services/attorney.service';

// Functions
import {
  fulfilledCreatedReducer,
  fulfilledDeletedReducer,
  fulfilledReducer,
  fulfilledSavedReducer,
  generateDropboxProperties,
  pendingReducer,
  rejectionReducer,
} from 'Util';

export const fetchAttorneys = createAsyncThunk(
  'attorney/fetchAttorneys',
  async (payload, { rejectWithValue }) => {
    try {
      return await AttorneyService.getAttorneys();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchAttorney = createAsyncThunk(
  'attorney/fetchAttorney',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await AttorneyService.getAttorney(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createAttorney = createAsyncThunk(
  'attorney/createAttorney',
  async (payload, { rejectWithValue }) => {
    const {
      name,
      description,
    } = payload;
    try {
      return await AttorneyService.createAttorney(name, description);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateAttorney = createAsyncThunk(
  'attorney/updateAttorney',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      name,
      description,
    } = payload;
    try {
      return await AttorneyService.updateAttorney(id, name, description);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteAttorney = createAsyncThunk(
  'attorney/deleteAttorney',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await AttorneyService.deleteAttorney(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

/* eslint-disable no-param-reassign */
export const attorneySlice = createSlice({
  name: 'attorney',
  initialState: {
    attorneyInfo: {},
    attorneys: [],
    message: '',
    errors: [],
    editing: false,
    created: false,
    saved: false,
    deleted: false,
    failed: false,
    succeeded: false,
  },
  reducers: {
    clearAttorney: (state) => {
      state.attorneyInfo = {
        name: '',
        description: '',
      };
      state.attorneys = [];
      state.message = '';
      state.errors = [];
      state.editing = false;
      state.created = false;
      state.saved = false;
      state.deleted = false;
      state.failed = false;
      state.succeeded = false;
    },
    setAttorneyEditing: (state, action) => {
      state.editing = action.payload;
    },
    setCreated: (state, action) => {
      state.created = action.payload;
    },
    setSaved: (state, action) => {
      state.saved = action.payload;
    },
    setDeleted: (state, action) => {
      state.deleted = action.payload;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setAttorneyInfo: (state, action) => {
      state.attorneyInfo = action.payload;
    },
  },
  extraReducers: (builder) => builder
    .addMatcher(
      (action) => action.type.endsWith('/rejected'),
      rejectionReducer,
    )
    .addMatcher((action) => action.type.endsWith('/pending'), pendingReducer)
    .addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, action) => {
        const performedAction = action.type.split('/');
        if (performedAction[0] === 'attorney') {
          switch (performedAction[1]) {
            case 'fetchAttorneys':
              fulfilledReducer(state, action);
              state.attorneys = generateDropboxProperties(action);
              break;
            case 'fetchAttorney':
              fulfilledReducer(state, action);
              state.attorneyInfo = action.payload;
              break;
            case 'createAttorney':
              fulfilledCreatedReducer(state, action);
              break;
            case 'updateAttorney':
              fulfilledSavedReducer(state, action);
              state.attorneyInfo = action.payload.data;
              break;
            case 'deleteAttorney':
              fulfilledDeletedReducer(state, action);
              state.attorneyInfo = action.payload.data;
              break;
            default:
              fulfilledReducer(state, action);
              state.message = action.payload;
              break;
          }
        }
      },
    ),
});

/* eslint-disable no-param-reassign */
export const {
  clearAttorney,
  setAttorneyEditing,
  setCreated,
  setSaved,
  setFailed,
  setDeleted,
  setSucceeded,
  setAttorneyInfo,
} = attorneySlice.actions;

export default attorneySlice.reducer;
