// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Soft UI Dashboard PRO React base styles
import colors from 'assets/theme/base/colors';

function BillingIcon({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
      viewBox="0 0 63.23 58.99"
    >
      <title>billing</title>
      <g fill={colors[color] ? colors[color].main : colors.dark.main}>
        <path
          d="M51,39.4c-0.6,0-1.5-0.3-2-0.6c-1.2-0.6-2-2-2-3.4c0-0.8,0.3-1.4,1.2-1.7c0.9,0,1.5,0.6,1.5,1.1s0.3,0.8,0.6,1.1
                c0.3,0.3,0.6,0.3,1.2,0c0.3,0,0.6-0.3,0.9-0.6c0.3-0.8,0-1.7-0.6-2L49,31.9c-2-1.1-2.6-3.6-1.5-5.9c1.2-2,3.5-2.8,5.5-1.7
                c1.2,0.6,2,2,2,3.4c0,0.8-0.3,1.4-1.2,1.7c-0.6,0-1.5-0.6-1.5-1.1c0-0.6-0.3-0.8-0.6-1.1c-0.6-0.3-1.5,0-1.7,0.6
                c-0.3,0.8,0,1.7,0.6,2l2.6,1.7c2,1.1,2.6,3.6,1.5,5.9c-0.6,1.1-1.5,1.7-2.3,2C51.9,39.4,51.6,39.4,51,39.4z"
        />
        <path
          d="M51,27.1c-0.9,0-1.5-0.6-1.5-1.4v-2c0-0.8,0.6-1.4,1.5-1.4c0.9,0,1.5,0.6,1.5,1.4v2C52.5,26.6,51.9,27.1,51,27.1z"
        />
        <path
          d="M51,41.4c-0.9,0-1.5-0.6-1.5-1.4v-2c0-0.8,0.6-1.4,1.5-1.4c0.9,0,1.5,0.6,1.5,1.4v2C52.5,40.8,51.9,41.4,51,41.4z"
        />
        <path
          d="M45.2,64H14.6c-4.1,0-7.3-3.4-7.3-7.5V7.5c0-4.2,3.2-7.5,7.3-7.5h30.6c4.1,0,7.3,3.4,7.3,7.5v10.3c0,0.8-0.6,1.4-1.5,1.4
                c-0.9,0-1.5-0.6-1.5-1.4V7.5c0-2.5-2-4.8-4.4-4.8H14.6c-2.6,0-4.4,2.2-4.4,4.8v48.6c0,2.5,2,4.8,4.4,4.8h30.6
                c2.6,0,4.4-2.2,4.4-4.8V45.8c0-0.8,0.6-1.4,1.5-1.4c0.9,0,1.5,0.6,1.5,1.4v10.3C52.5,60.6,49.3,64,45.2,64z"
        />
        <path
          d="M41.7,47H23.3c-0.9,0-1.5-0.6-1.5-1.4s0.6-1.4,1.5-1.4h18.4c0.9,0,1.5,0.6,1.5,1.4C42.9,46.4,42.3,47,41.7,47z"
        />
        <path
          d="M41.7,38.6H23.3c-0.9,0-1.5-0.6-1.5-1.4s0.6-1.7,1.5-1.7h18.4c0.9,0,1.5,0.6,1.5,1.4S42.3,38.6,41.7,38.6z"
        />
        <path
          d="M41.7,29.9H23.3c-0.9,0-1.5-0.6-1.5-1.4s0.6-1.4,1.5-1.4h18.4c0.9,0,1.5,0.6,1.5,1.4C42.9,29.3,42.3,29.9,41.7,29.9z"
        />
        <path
          d="M41.7,21.5H23.3c-0.9,0-1.5-0.6-1.5-1.4s0.6-1.4,1.5-1.4h18.4c0.9,0,1.5,0.6,1.5,1.4S42.3,21.5,41.7,21.5z"
        />
        <path
          d="M19.2,47h-1.2c-0.9,0-1.5-0.6-1.5-1.4s0.6-1.4,1.5-1.4H19c0.9,0,1.5,0.6,1.5,1.4C20.4,46.4,19.8,47,19.2,47z"
        />
        <path
          d="M19.2,38.6h-1.2c-0.9,0-1.5-0.6-1.5-1.4s0.9-1.7,1.5-1.7H19c0.9,0,1.5,0.8,1.5,1.4C20.4,37.4,19.8,38.6,19.2,38.6z"
        />
        <path
          d="M19.2,29.9h-1.2c-0.6,0-1.2-0.6-1.2-1.4s0.6-1.4,1.2-1.4H19c0.9,0,1.5,0.6,1.5,1.4S19.8,29.9,19.2,29.9z"
        />
        <path
          d="M19.2,21.5h-1.2c-0.6,0-1.2-0.6-1.2-1.4s0.6-1.4,1.2-1.4H19c0.9,0,1.5,0.6,1.5,1.4S19.8,21.5,19.2,21.5z"
        />
      </g>
    </svg>
  );
}

// Setting admin values for the props of BillingIcon
BillingIcon.defaultProps = {
  color: 'dark',
  size: '16px',
};

// Typechecking props for the BillingIcon
BillingIcon.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
    'light',
    'white',
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default BillingIcon;
