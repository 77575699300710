import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Reducer functions
// eslint-disable-next-line object-curly-newline
import {
  createFingerprint,
  deleteFingerprint,
  setCreated,
  setFailed,
  setSaved,
  updateFingerprint,
} from 'reducers/fingerprintsSlice';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import Swal from 'sweetalert2';

const selector = (state) => ({
  fingerprintInfo: state.fingerprint.fingerprintInfo,
  editing: state.fingerprint.editing,
  created: state.fingerprint.created,
  saved: state.fingerprint.saved,
  failed: state.fingerprint.failed,
  errors: state.fingerprint.errors,
});

function SavePanel() {
  const { t } = useTranslation('translation', { keyPrefix: 'fingerprints' });

  const {
    fingerprintInfo,
    editing,
    created,
    saved,
    failed,
    errors,
  } = useSelector(
    selector,
    shallowEqual,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  if (failed === true) {
    dispatch(setFailed(false));
    const errMsg = errors.join('; ');

    Swal.fire({
      title: 'Error',
      text: `${errMsg}`,
      icon: 'error',
      confirmButtonText: 'Close',
    });
  }

  if (created === true || saved === true) {
    dispatch(setCreated(false));
    dispatch(setSaved(false));

    Swal.fire({
      text: saved ? t('modified-successfully') : t('created-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      navigate('/process/fingerprints');
    });
  }

  const callUpdateFingerprint = async (data) => {
    await dispatch(updateFingerprint(data));
  };

  const callCreateFingerprint = async (data) => {
    await dispatch(createFingerprint(data));
  };

  const callDeleteFingerprint = async (sId) => {
    await dispatch(
      deleteFingerprint({
        id: sId,
      }),
    );
  };

  const handleDelete = () => {
    const alert = Swal.mixin({
      customClass: {
        confirmButton: 'button button-success',
        cancelButton: 'button button-error',
      },
      buttonsStyling: false,
    });

    alert
      .fire({
        title: 'Are you sure?',
        text: `Are you sure you want to delete "${fingerprintInfo.name}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
      })
      .then((result) => {
        if (result.isConfirmed) {
          callDeleteFingerprint(id).then(() => {
            Swal.fire('Deleted!', '', 'success').then(() => {
              navigate('/process/fingerprints', { replace: true });
            });
          });
        }
      });
  };

  const handleSave = () => {
    // const docs = [];

    if (fingerprintInfo.changed === true) {
      const data = {
        name: fingerprintInfo.name,
        documents: fingerprintInfo.documents,
      };

      if (editing === true) {
        data.id = id;

        callUpdateFingerprint(data);
      } else {
        callCreateFingerprint(data);
      }
    }
  };

  return (
    <SuiBox mt={5} mb={3}>
      <SuiBox display="flex" justifyContent="flex-end" mt={6}>
        {editing && (
          <SuiBox mr={1}>
            <SuiButton
              variant="gradient"
              color="error"
              size="small"
              onClick={handleDelete}
            >
              delete
            </SuiButton>
          </SuiBox>
        )}
        <SuiBox mr={1}>
          <SuiButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleSave}
          >
            save
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default SavePanel;
