// react-quill components
import ReactQuill from 'react-quill';

// react-quill styles
import 'react-quill/dist/quill.snow.css';
import 'assets/styles/quill-editor.css';

// Custom styles for the SuiEditor
import SuiEditorRoot from 'components/SuiEditor/SuiEditorRoot';

function SuiEditor(props) {
  return (
    <SuiEditorRoot sx={{ height: '100%' }}>
      <ReactQuill theme="snow" {...props} className="quill-editor" />
    </SuiEditorRoot>
  );
}

export default SuiEditor;
