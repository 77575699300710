import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// @mui material components
import Paper from '@mui/material/Paper';

// DevExtreme components
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  HeaderFilter,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Sorting,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.material.blue.light.css';

// Functions

const selector = (state) => ({
  importData: state.candidate.importData,
});

function Preview() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.import' });
  const { importData } = useSelector(selector, shallowEqual);

  const allowedPageSizes = [10, 30, 50, 'all'];

  return (
    <Paper>
      <DataGrid
        id="import-candidates"
        dataSource={importData.data}
        allowColumnReordering
        allowColumnResizing
        columnAutoWidth
      >
        <ColumnChooser enabled />
        <ColumnFixing enabled />
        <HeaderFilter visible />
        <Scrolling rowRenderingMode="virtual" />
        <Sorting mode="multiple" />
        <Paging defaultPageSize={30} />
        <Pager
          visible
          allowedPageSizes={allowedPageSizes}
          displayMode="full"
          showPageSizeSelector
          showInfo
          showNavigationButtons
        />
        <SearchPanel
          visible
          width={240}
          placeholder={t('search', { keyPrefix: 'common' })}
        />
        <Column
          dataField="first_name"
          caption={t('first-name', { keyPrefix: 'common' })}
        />
        <Column
          dataField="middle_name"
          caption={t('middle-name', { keyPrefix: 'common' })}
        />
        <Column
          dataField="last_name"
          caption={t('last-name', { keyPrefix: 'common' })}
        />
        <Column
          dataField="preferred_name"
          caption="PreferredName"
        />
        <Column
          dataField="date_of_birth"
          caption="DateOfBirth"
        />
        <Column
          dataField="social_security"
          caption="SocialSecurity"
        />
        <Column
          dataField="native_language"
          caption="NativeLanguage"
        />
        <Column
          dataField="country_of_origin"
          caption="CountryOfOrigin"
        />
        <Column
          dataField="country_of_residence"
          caption="CountryOfResidence"
        />
        <Column
          dataField="citizenship"
          caption="Citizenship"
        />
        <Column
          dataField="contact_method"
          caption="ContactMethod"
        />
        <Column
          dataField="email1"
          caption="Email1"
        />
        <Column
          dataField="email2"
          caption="Email2"
        />
        <Column
          dataField="email3"
          caption="Email3"
        />
        <Column
          dataField="phone1"
          caption="Phone1"
        />
        <Column
          dataField="phone2"
          caption="Phone2"
        />
        <Column
          dataField="phone3"
          caption="Phone3"
        />
        <Column
          dataField="address"
          caption="Address"
        />
        <Column
          dataField="city"
          caption="City"
        />
        <Column
          dataField="state"
          caption="State"
        />
        <Column
          dataField="country"
          caption="Country"
        />
        <Column
          dataField="postal_code"
          caption="PostalCode"
        />
        <Column
          dataField="weight"
          caption="Weight"
        />
        <Column
          dataField="height"
          caption="Height"
        />
        <Column
          dataField="hair_color"
          caption="HairColor"
        />
        <Column
          dataField="eye_color"
          caption="EyeColor"
        />
        <Column
          dataField="race"
          caption="Race"
        />
        <Column
          dataField="covid_vaccine"
          caption="CovidVaccine"
        />
        <Column
          dataField="university_name1"
          caption="UniversityName1"
        />
        <Column
          dataField="university_address1"
          caption="UniversityAddress1"
        />
        <Column
          dataField="university_city1"
          caption="UniversityCity1"
        />
        <Column
          dataField="university_state1"
          caption="UniversityState1"
        />
        <Column
          dataField="university_country1"
          caption="UniversityCountry1"
        />
        <Column
          dataField="university_postal_code1"
          caption="UniversityPostalCode1"
        />
        <Column
          dataField="university_entered_date1"
          caption="UniversityEnteredDate1"
        />
        <Column
          dataField="university_graduation_date1"
          caption="UniversityGraduationDate1"
        />
        <Column
          dataField="university_name2"
          caption="UniversityName2"
        />
        <Column
          dataField="university_address2"
          caption="UniversityAddress2"
        />
        <Column
          dataField="university_city2"
          caption="UniversityCity2"
        />
        <Column
          dataField="university_state2"
          caption="UniversityState2"
        />
        <Column
          dataField="university_country2"
          caption="UniversityCountry2"
        />
        <Column
          dataField="university_postal_code2"
          caption="UniversityPostalCode2"
        />
        <Column
          dataField="university_entered_date2"
          caption="UniversityEnteredDate2"
        />
        <Column
          dataField="university_graduation_date2"
          caption="UniversityGraduationDate2"
        />
        <Column
          dataField="university_name3"
          caption="UniversityName3"
        />
        <Column
          dataField="university_address3"
          caption="UniversityAddress3"
        />
        <Column
          dataField="university_city3"
          caption="UniversityCity3"
        />
        <Column
          dataField="university_state3"
          caption="UniversityState3"
        />
        <Column
          dataField="university_country3"
          caption="UniversityCountry3"
        />
        <Column
          dataField="university_postal_code3"
          caption="UniversityPostalCode3"
        />
        <Column
          dataField="university_entered_date3"
          caption="UniversityEnteredDate3"
        />
        <Column
          dataField="university_graduation_date3"
          caption="UniversityGraduationDate3"
        />
        <Column
          dataField="university_name4"
          caption="UniversityName4"
        />
        <Column
          dataField="university_address4"
          caption="UniversityAddress4"
        />
        <Column
          dataField="university_city4"
          caption="UniversityCity4"
        />
        <Column
          dataField="university_state4"
          caption="UniversityState4"
        />
        <Column
          dataField="university_country4"
          caption="UniversityCountry4"
        />
        <Column
          dataField="university_postal_code4"
          caption="UniversityPostalCode4"
        />
        <Column
          dataField="university_entered_date4"
          caption="UniversityEnteredDate4"
        />
        <Column
          dataField="university_graduation_date4"
          caption="UniversityGraduationDate4"
        />
        <Column
          dataField="elementary_name1"
          caption="ElementaryName1"
        />
        <Column
          dataField="elementary_address1"
          caption="ElementaryAddress1"
        />
        <Column
          dataField="elementary_city1"
          caption="ElementaryCity1"
        />
        <Column
          dataField="elementary_state1"
          caption="ElementaryState1"
        />
        <Column
          dataField="elementary_country1"
          caption="ElementaryCountry1"
        />
        <Column
          dataField="elementary_postal_code1"
          caption="ElementaryPostalCode1"
        />
        <Column
          dataField="elementary_entered_date1"
          caption="ElementaryEnteredDate1"
        />
        <Column
          dataField="elementary_graduation_date1"
          caption="ElementaryGraduationDate1"
        />
        <Column
          dataField="elementary_name2"
          caption="ElementaryName2"
        />
        <Column
          dataField="elementary_address2"
          caption="ElementaryAddress2"
        />
        <Column
          dataField="elementary_city2"
          caption="ElementaryCity2"
        />
        <Column
          dataField="elementary_state2"
          caption="ElementaryState2"
        />
        <Column
          dataField="elementary_country2"
          caption="ElementaryCountry2"
        />
        <Column
          dataField="elementary_postal_code2"
          caption="ElementaryPostalCode2"
        />
        <Column
          dataField="elementary_entered_date2"
          caption="ElementaryEnteredDate2"
        />
        <Column
          dataField="elementary_graduation_date2"
          caption="ElementaryGraduationDate2"
        />
        <Column
          dataField="elementary_name3"
          caption="ElementaryName3"
        />
        <Column
          dataField="elementary_address3"
          caption="ElementaryAddress3"
        />
        <Column
          dataField="elementary_city3"
          caption="ElementaryCity3"
        />
        <Column
          dataField="elementary_state3"
          caption="ElementaryState3"
        />
        <Column
          dataField="elementary_country3"
          caption="ElementaryCountry3"
        />
        <Column
          dataField="elementary_postal_code3"
          caption="ElementaryPostalCode3"
        />
        <Column
          dataField="elementary_entered_date3"
          caption="ElementaryEnteredDate3"
        />
        <Column
          dataField="elementary_graduation_date3"
          caption="ElementaryGraduationDate3"
        />
        <Column
          dataField="elementary_name4"
          caption="ElementaryName4"
        />
        <Column
          dataField="elementary_address4"
          caption="ElementaryAddress4"
        />
        <Column
          dataField="elementary_city4"
          caption="ElementaryCity4"
        />
        <Column
          dataField="elementary_state4"
          caption="ElementaryState4"
        />
        <Column
          dataField="elementary_country4"
          caption="ElementaryCountry4"
        />
        <Column
          dataField="elementary_postal_code4"
          caption="ElementaryPostalCode4"
        />
        <Column
          dataField="elementary_entered_date4"
          caption="ElementaryEnteredDate4"
        />
        <Column
          dataField="elementary_graduation_date4"
          caption="ElementaryGraduationDate4"
        />
        <Column
          dataField="middle_school_name1"
          caption="MiddleSchoolName1"
        />
        <Column
          dataField="middle_school_address1"
          caption="MiddleSchoolAddress1"
        />
        <Column
          dataField="middle_school_city1"
          caption="MiddleSchoolCity1"
        />
        <Column
          dataField="middle_school_state1"
          caption="MiddleSchoolState1"
        />
        <Column
          dataField="middle_school_country1"
          caption="MiddleSchoolCountry1"
        />
        <Column
          dataField="middle_school_postal_code1"
          caption="MiddleSchoolPostalCode1"
        />
        <Column
          dataField="middle_school_entered_date1"
          caption="MiddleSchoolEnteredDate1"
        />
        <Column
          dataField="middle_school_graduation_date1"
          caption="MiddleSchoolGraduationDate1"
        />
        <Column
          dataField="middle_school_name2"
          caption="MiddleSchoolName2"
        />
        <Column
          dataField="middle_school_address2"
          caption="MiddleSchoolAddress2"
        />
        <Column
          dataField="middle_school_city2"
          caption="MiddleSchoolCity2"
        />
        <Column
          dataField="middle_school_state2"
          caption="MiddleSchoolState2"
        />
        <Column
          dataField="middle_school_country2"
          caption="MiddleSchoolCountry2"
        />
        <Column
          dataField="middle_school_postal_code2"
          caption="MiddleSchoolPostalCode2"
        />
        <Column
          dataField="middle_school_entered_date2"
          caption="MiddleSchoolEnteredDate2"
        />
        <Column
          dataField="middle_school_graduation_date2"
          caption="MiddleSchoolGraduationDate2"
        />
        <Column
          dataField="middle_school_name3"
          caption="MiddleSchoolName3"
        />
        <Column
          dataField="middle_school_address3"
          caption="MiddleSchoolAddress3"
        />
        <Column
          dataField="middle_school_city3"
          caption="MiddleSchoolCity3"
        />
        <Column
          dataField="middle_school_state3"
          caption="MiddleSchoolState3"
        />
        <Column
          dataField="middle_school_country3"
          caption="MiddleSchoolCountry3"
        />
        <Column
          dataField="middle_school_postal_code3"
          caption="MiddleSchoolPostalCode3"
        />
        <Column
          dataField="middle_school_entered_date3"
          caption="MiddleSchoolEnteredDate3"
        />
        <Column
          dataField="middle_school_graduation_date3"
          caption="MiddleSchoolGraduationDate3"
        />
        <Column
          dataField="middle_school_name4"
          caption="MiddleSchoolName4"
        />
        <Column
          dataField="middle_school_address4"
          caption="MiddleSchoolAddress4"
        />
        <Column
          dataField="middle_school_city4"
          caption="MiddleSchoolCity4"
        />
        <Column
          dataField="middle_school_state4"
          caption="MiddleSchoolState4"
        />
        <Column
          dataField="middle_school_country4"
          caption="MiddleSchoolCountry4"
        />
        <Column
          dataField="middle_school_postal_code4"
          caption="MiddleSchoolPostalCode4"
        />
        <Column
          dataField="middle_school_entered_date4"
          caption="MiddleSchoolEnteredDate4"
        />
        <Column
          dataField="middle_school_graduation_date4"
          caption="MiddleSchoolGraduationDate4"
        />
        <Column
          dataField="middle_school_diploma_obtained"
          caption="MiddleSchoolDiplomaObtained"
        />
        <Column
          dataField="high_school_name1"
          caption="HighSchoolName1"
        />
        <Column
          dataField="high_school_address1"
          caption="HighSchoolAddress1"
        />
        <Column
          dataField="high_school_city1"
          caption="HighSchoolCity1"
        />
        <Column
          dataField="high_school_state1"
          caption="HighSchoolState1"
        />
        <Column
          dataField="high_school_country1"
          caption="HighSchoolCountry1"
        />
        <Column
          dataField="high_school_postal_code1"
          caption="HighSchoolPostalCode1"
        />
        <Column
          dataField="high_school_entered_date1"
          caption="HighSchoolEnteredDate1"
        />
        <Column
          dataField="high_school_graduation_date1"
          caption="HighSchoolGraduationDate1"
        />
        <Column
          dataField="high_school_name2"
          caption="HighSchoolName2"
        />
        <Column
          dataField="high_school_address2"
          caption="HighSchoolAddress2"
        />
        <Column
          dataField="high_school_city2"
          caption="HighSchoolCity2"
        />
        <Column
          dataField="high_school_state2"
          caption="HighSchoolState2"
        />
        <Column
          dataField="high_school_country2"
          caption="HighSchoolCountry2"
        />
        <Column
          dataField="high_school_postal_code2"
          caption="HighSchoolPostalCode2"
        />
        <Column
          dataField="high_school_entered_date2"
          caption="HighSchoolEnteredDate2"
        />
        <Column
          dataField="high_school_graduation_date2"
          caption="HighSchoolGraduationDate2"
        />
        <Column
          dataField="high_school_name3"
          caption="HighSchoolName3"
        />
        <Column
          dataField="high_school_address3"
          caption="HighSchoolAddress3"
        />
        <Column
          dataField="high_school_city3"
          caption="HighSchoolCity3"
        />
        <Column
          dataField="high_school_state3"
          caption="HighSchoolState3"
        />
        <Column
          dataField="high_school_country3"
          caption="HighSchoolCountry3"
        />
        <Column
          dataField="high_school_postal_code3"
          caption="HighSchoolPostalCode3"
        />
        <Column
          dataField="high_school_entered_date3"
          caption="HighSchoolEnteredDate3"
        />
        <Column
          dataField="high_school_graduation_date3"
          caption="HighSchoolGraduationDate3"
        />
        <Column
          dataField="high_school_name4"
          caption="HighSchoolName4"
        />
        <Column
          dataField="high_school_address4"
          caption="HighSchoolAddress4"
        />
        <Column
          dataField="high_school_city4"
          caption="HighSchoolCity4"
        />
        <Column
          dataField="high_school_state4"
          caption="HighSchoolState4"
        />
        <Column
          dataField="high_school_country4"
          caption="HighSchoolCountry4"
        />
        <Column
          dataField="high_school_postal_code4"
          caption="HighSchoolPostalCode4"
        />
        <Column
          dataField="high_school_entered_date4"
          caption="HighSchoolEnteredDate4"
        />
        <Column
          dataField="high_school_graduation_date4"
          caption="HighSchoolGraduationDate4"
        />
        <Column
          dataField="high_school_diploma_obtained"
          caption="HighSchoolDiplomaObtained"
        />
        <Column
          dataField="clinical_experience"
          caption="ClinicalExperience"
        />
        <Column
          dataField="clinical_detail"
          caption="ClinicalDetail"
        />
        <Column
          dataField="english_academy"
          caption="EnglishAcademy"
        />
        <Column
          dataField="english_last_designation_date"
          caption="EnglishLastDesignationDate"
        />
        <Column
          dataField="english_last_designation"
          caption="EnglishLastDesignation"
        />
        <Column
          dataField="english_test"
          caption="EnglishTest"
        />
        <Column
          dataField="english_test_date"
          caption="EnglishTestDate"
        />
        <Column
          dataField="english_test_result"
          caption="EnglishTestResult"
        />
        <Column
          dataField="license_received"
          caption="LicenseReceived"
        />
        <Column
          dataField="license_number"
          caption="LicenseNumber"
        />
        <Column
          dataField="license_issue_date"
          caption="LicenseIssueDate"
        />
        <Column
          dataField="license_expiration_date"
          caption="LicenseExpirationDate"
        />
        <Column
          dataField="evaluation_pathway_selected"
          caption="EvaluationPathwaySelected"
        />
        <Column
          dataField="evaluation_pathway_state"
          caption="EvaluationPathwayState"
        />
        <Column
          dataField="evaluation_submission_status"
          caption="EvaluationSubmissionStatus"
        />
        <Column
          dataField="evaluation_date_submitted"
          caption="EvaluationDateSubmitted"
        />
        <Column
          dataField="evaluation_university_docs_date1"
          caption="EvaluationUniversityDocsDate1"
        />
        <Column
          dataField="evaluation_university_docs_date2"
          caption="EvaluationUniversityDocsDate2"
        />
        <Column
          dataField="evaluation_license_date"
          caption="EvaluationLicenseDate"
        />
        <Column
          dataField="evaluation_translation_date"
          caption="EvaluationTraslationDate"
        />
        <Column
          dataField="evaluation_university_verification"
          caption="EvaluationUniversityVerification"
        />
        <Column
          dataField="evaluation_university_docs_currier"
          caption="EvaluationUniversityDocsCurrier"
        />
        <Column
          dataField="evaluation_university_docs_tracking"
          caption="EvaluationUniversityDocsTracking"
        />
        <Column
          dataField="evaluation_result"
          caption="EvaluationResult"
        />
        <Column
          dataField="evaluation_target_date"
          caption="EvaluationTargetDate"
        />
        <Column
          dataField="bon_state"
          caption="BONState"
        />
        <Column
          dataField="bon_date"
          caption="BONDate"
        />
        <Column
          dataField="bon_username"
          caption="BONUsername"
        />
        <Column
          dataField="bon_password"
          caption="BONPassword"
        />
        <Column
          dataField="bon_applicant_id"
          caption="BONApplicantId"
        />
        <Column
          dataField="bon_fingerprint_candidate"
          caption="BONFingerprintCandidate"
        />
        <Column
          dataField="bon_fingerprint_currier"
          caption="BONFingerPrintCurrier"
        />
        <Column
          dataField="bon_fingerprint_tracking"
          caption="BONFingerPrintTracking"
        />
        <Column
          dataField="pearson_registration_date"
          caption="PearsonRegistrationDate"
        />
        <Column
          dataField="pearson_user"
          caption="PearsonUser"
        />
        <Column
          dataField="pearson_password"
          caption="PearsonPassword"
        />
        <Column
          dataField="ncsbn_id"
          caption="NCSBNId"
        />
        <Column
          dataField="att"
          caption="Att"
        />
        <Column
          dataField="att_received"
          caption="AttReceived"
        />
        <Column
          dataField="nclex_completed_pretests"
          caption="NclexCompletedPretests"
        />
        <Column
          dataField="nclex_test_status"
          caption="NclexTestStatus"
        />
        <Column
          dataField="nclex_test_date"
          caption="NclexTestDate"
        />
        <Column
          dataField="deferral_payment_candidate"
          caption="DeferralPaymentCandidate"
        />
        <Column
          dataField="deferral_payment_candidate_amount"
          caption="DeferralPaymentCandidateAmount"
        />
        <Column
          dataField="first_payment_date"
          caption="FirstPaymentDate"
        />
        <Column
          dataField="payment_plan"
          caption="PaymentPlan"
        />
        <Column
          dataField="deferral_payment_plan"
          caption="DeferralPaymentPlan"
        />
        <Column
          dataField="deferral_payment_plan_agreement"
          caption="DeferralPaymentPlanAgreement"
        />
        <Column
          dataField="deferral_payment_plan_email_sent"
          caption="DeferralPaymentPlanEmailSent"
        />
        <Column
          dataField="deferral_payment_plan_first_appointment"
          caption="DeferralPaymentPlanFirstAppointment"
        />
        <Column
          dataField="first_appointment_date"
          caption="FirstAppointmentDate"
        />
        <Column
          dataField="first_appointment_status"
          caption="FirstAppointmentStatus"
        />
        <Column
          dataField="second_appointment_date"
          caption="SecondAppointmentDate"
        />
        <Column
          dataField="second_appointment_status"
          caption="SecondAppointmentStatus"
        />
        <Column
          dataField="last_contact_date"
          caption="LastContactDate"
        />
        <Column
          dataField="nas_agreement_sent"
          caption="NASAgreementSent"
        />
        <Column
          dataField="nas_agreement_date"
          caption="NASAgreementDate"
        />
        <Column
          dataField="nash_agreement_sent"
          caption="NashAgreementSent"
        />
        <Column
          dataField="nash_agreement_date"
          caption="NashAgreementDate"
        />
        <Column
          dataField="general_info_form_sent"
          caption="GeneralInfoFormSent"
        />
        <Column
          dataField="general_info_form_date"
          caption="GeneralInfoFormDate"
        />
        <Column
          dataField="interview_email_sent"
          caption="InterviewEmailSent"
        />
        <Column
          dataField="interviewPrep_email_sent"
          caption="InterviewPrepEmailSent"
        />
        <Column
          dataField="interviewPrep_date"
          caption="InterviewPrepDate"
        />
        <Column
          dataField="hospital_info_email_sent"
          caption="HospitalInfoEmailSent"
        />
        <Column
          dataField="option_returned"
          caption="OptionReturned"
        />
        <Column
          dataField="hospital_interviewing"
          caption="HospitalInterviewing"
        />
        <Column
          dataField="interview_expectation_email_sent"
          caption="InterviewExpectationEmailSent"
        />
        <Column
          dataField="hospital_interview_date"
          caption="HospitalInterviewDate"
        />
        <Column
          dataField="confirm_interview_date"
          caption="ConfirmInterviewDate"
        />
        <Column
          dataField="hospital_offer_made"
          caption="HospitalOfferMade"
        />
        <Column
          dataField="previous_interviews"
          caption="PreviousInterviews"
        />
        <Column
          dataField="sendgrid"
          caption="Sendgrid"
        />
        <Column
          dataField="university_diploma_received"
          caption="UniversityDiplomaReceived"
        />
        <Column
          dataField="resume"
          caption="Resume"
        />
        <Column
          dataField="resume_reviewed"
          caption="ResumeReviewed"
        />
        <Column
          dataField="passport"
          caption="Passport"
        />
        <Column
          dataField="immigration"
          caption="Immigration"
        />
        <Column
          dataField="recruit"
          caption="Recruit"
        />
        <Column
          dataField="commit_assigned"
          caption="CommitAssigned"
        />
        <Column
          dataField="notes"
          caption="Notes"
        />
      </DataGrid>
    </Paper>
  );
}

export default Preview;
