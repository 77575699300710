import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AccordionActions } from '@mui/material';

// Soft UI Dashboard PRO React components
import SuiButton from 'components/SuiButton';
import SuiTypography from 'components/SuiTypography';

// Functions
import { checkPermission, setTitle } from 'Util';

// Components
import FormSelect from 'layouts/components/FormSelect';
import BaseLayout from 'layouts/components/BaseLayout';
import PaymentPlans from './components/PaymentPlans';
import DueInvoices from './components/DueInvoices';
import DueInvoicesParameters from './components/Parameters/DueInvoicesParameters';

const selector = (state) => ({
  currentUser: state.auth.user,
});

function FinancialReports() {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { currentUser } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [selected, setSelected] = useState({ value: '' });
  const [parameters, setParameters] = useState({});
  const [preRunParams, setPreRunParams] = useState({});
  const [showParameters, setShowParameters] = useState(false);
  const [openParameters, setOpenParameters] = useState(false);

  useEffect(() => {
    const today = new Date();
    switch (selected.value) {
      case 'due-invoices':
        setParameters({
          date: `${today
            .getFullYear()}-${(today
            .getMonth() + 1).toString().padStart(2, '0')}-${today
            .getDate().toString().padStart(2, '0')}`,
        });
        setShowParameters(true);
        break;
      default:
        setShowParameters(false);
    }
  }, [selected, dispatch]);

  setTitle('Financial Reports');

  const reports = useMemo(() => {
    const r = [];

    if (checkPermission('R101', currentUser)) {
      r.push({
        value: 'payment-plans',
        label: t('payment-plans'),
      });
    }

    if (checkPermission('R102', currentUser)) {
      r.push({
        value: 'due-invoices',
        label: t('due-invoices'),
      });
    }

    return r;
  }, [currentUser, t]);

  const handleSaveParameters = () => {
    setOpenParameters(false);
    setParameters(preRunParams);
  };

  const handleSetParameters = (params) => {
    setPreRunParams(params);
  };

  const handleSelectReport = (rep) => {
    if (reports.length > 0) {
      setSelected(rep);
    }
  };

  return (
    <BaseLayout>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item md={3}>
          <FormSelect
            label={t('report')}
            value={selected}
            options={reports}
            onChange={handleSelectReport}
          />
        </Grid>
        <Grid item md={9}>
          {showParameters && (
            <Accordion
              expanded={openParameters}
              onChange={(e, expanded) => setOpenParameters(expanded)}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="parameters"
                id="parameters"
              >
                <SuiTypography>{t('parameters')}</SuiTypography>
              </AccordionSummary>
              <AccordionDetails>
                {selected.value === 'due-invoices' && (
                  <DueInvoicesParameters
                    defaultParameters={parameters}
                    setParametersFunc={handleSetParameters}
                  />
                )}
              </AccordionDetails>
              <AccordionActions>
                <SuiButton
                  variant="text"
                  color="info"
                  onClick={handleSaveParameters}
                >
                  {t('save', { keyPrefix: 'common' })}
                </SuiButton>
              </AccordionActions>
            </Accordion>
          )}
        </Grid>
        <Grid item md={6} />
        <Grid item md={12} mt={3}>
          {selected.value === 'payment-plans' && <PaymentPlans />}
          {selected.value === 'due-invoices' && <DueInvoices parameters={parameters} />}
        </Grid>
      </Grid>
    </BaseLayout>
  );
}

export default FinancialReports;
