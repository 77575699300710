import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

// Reducers
import { fetchInvoiceLimited } from 'reducers/invoicesSlice';

// Payment form page components
import BaseLayoutNoBars from 'layouts/components/BaseLayoutNoBars';
import InvoicePayment from 'layouts/invoices/components/Payment';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// @mui material components
import Grid from '@mui/material/Grid';

// Functions
import { currencyFormatter } from 'Util';

// Components
// Images
import OverdueIcon from 'layouts/components/Icons/OverdueIcon';
import Error404 from 'layouts/error/404';

const selector = (state) => ({
  invoiceInfo: state.invoice.invoiceInfo,
});

function PaymentForm() {
  const { invoiceInfo } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(
      fetchInvoiceLimited({
        id,
      }),
    );
  }, [dispatch, id]);

  return (
    <>
      {invoiceInfo.status !== 'draft'
        && invoiceInfo.status !== 'paid'
        && invoiceInfo.status !== 'void'
        && (
          <BaseLayoutNoBars stickyNavbar>
            <SuiTypography variant="h1">Invoice Payment</SuiTypography>
            <Grid container spacing={2}>
              <Grid item xs={3} mr={5}>
                <SuiBox
                  width="100%"
                  textAlign={{
                    xs: 'left',
                    md: 'right',
                  }}
                  mt={6}
                >
                  <SuiBox mb={1}>
                    <SuiTypography variant="body2" color="secondary">
                      INVOICE #
                      {' '}
                      {invoiceInfo.id}
                      <br />
                      DATE:
                      {' '}
                      {invoiceInfo.date}
                    </SuiTypography>
                    <SuiTypography variant="h4" fontWeight="medium">
                      Due
                      {' '}
                      {invoiceInfo.due}
                    </SuiTypography>
                    <SuiTypography variant="h1" fontWeight="medium">
                      Total
                      {' '}
                      {currencyFormatter.format(invoiceInfo.total)}
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox mt={1}>
                    <SuiTypography variant="h2" fontWeight="medium">
                      {invoiceInfo.status === 'overdue' && (
                        <OverdueIcon size="80" color="error" />
                      )}
                    </SuiTypography>
                  </SuiBox>
                </SuiBox>
              </Grid>
              <Grid item xs={6}>
                <SuiBox>
                  <InvoicePayment isExternal />
                </SuiBox>
              </Grid>
            </Grid>
          </BaseLayoutNoBars>
        )}
      {(invoiceInfo.status === 'draft'
        || invoiceInfo.status === 'paid'
        || invoiceInfo.status === 'void') && (
        <Error404 />
      )}
    </>
  );
}

export default PaymentForm;
