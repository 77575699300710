import { useTranslation } from 'react-i18next';

// @mui material components
import Grid from '@mui/material/Grid';

// Components
import ReactLoading from 'react-loading';

function Loading() {
  const { t } = useTranslation('translation', { keyPrefix: 'links' });
  return (
    <Grid
      container
      direction="row"
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid item>
        {`${t('loading')}...`}
        <ReactLoading
          type="bars"
          color="#03fc4e"
          height={100}
          width={100}
        />
      </Grid>
    </Grid>
  );
}

export default Loading;
