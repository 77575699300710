import { shallowEqual, useSelector } from 'react-redux';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Layout components
import BaseLayout from 'layouts/components/BaseLayout';
import DefaultCandidate from '../candidate';
import DefaultAdmin from '../admin';
import DefaultAdviser from '../adviser';
import DefaultRecruiter from '../recruitment';

const selector = (state) => ({
  currentUser: state.auth.user,
});

function DefaultDashboard() {
  const { currentUser } = useSelector(selector, shallowEqual);

  return (
    <BaseLayout>
      <SuiBox>
        {currentUser.role.dashboard_id === 1 && <DefaultAdmin />}
        {currentUser.role.dashboard_id === 2 && <DefaultCandidate />}
        {currentUser.role.dashboard_id === 3 && <DefaultAdviser />}
        {currentUser.role.dashboard_id === 4 && <DefaultRecruiter />}
      </SuiBox>
    </BaseLayout>
  );
}

export default DefaultDashboard;
