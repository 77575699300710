import { useEffect } from 'react';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// Components
import ReactGA from 'react-ga4';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import LanguageSelector from '../../LanguageSelector';

function PageLayout(
  {
    background,
    children,
  },
) {
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  return (
    <SuiBox
      width="100vw"
      height="100%"
      minHeight="100vh"
      bgColor={background}
      sx={{ overflowX: 'hidden' }}
    >
      <LanguageSelector languages={['en', 'es', 'pt']} />
      {children}
    </SuiBox>
  );
}

// Setting admin values for the props for PageLayout
PageLayout.defaultProps = {
  background: 'default',
};

// Typechecking props for the PageLayout
PageLayout.propTypes = {
  background: PropTypes.oneOf(['white', 'light', 'default']),
  children: PropTypes.node.isRequired,
};

export default PageLayout;
