import { Link } from 'react-router-dom';

// html parser
import parse from 'html-react-parser';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Icon from '@mui/material/Icon';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiBadge from 'components/SuiBadge';

// Timeline context
import { useTimeline } from '../context';

// Custom styles for the TimelineItem
import { timelineItem, timelineItemIcon } from './styles';

function NoteTimelineItem({
  color, icon, title, dateTime, description, outcome, badges, lastItem, linkHandler,
}) {
  const isDark = useTimeline();

  const renderBadges = badges.length > 0
    ? badges.map((badge, key) => {
      const badgeKey = `badge-${key}`;

      return (
        <SuiBox key={badgeKey} mr={key === badges.length - 1 ? 0 : 0.5}>
          <SuiBadge color={color} size="xs" badgeContent={badge} container />
        </SuiBox>
      );
    })
    : null;

  return (
    <SuiBox position="relative" sx={(theme) => timelineItem(theme, { lastItem })}>
      <SuiBox
        bgColor={isDark ? 'dark' : 'white'}
        width="1.625rem"
        height="1.625rem"
        borderRadius="50%"
        position="absolute"
        top="3.25%"
        left="2px"
        zIndex={2}
      >
        <Icon sx={(theme) => timelineItemIcon(theme, { color })}>{icon}</Icon>
      </SuiBox>
      <SuiBox ml={5.75} pt={description ? 0.7 : 0.5} lineHeight={0}>
        <SuiBox
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link to="#" onClick={linkHandler}>
            <SuiTypography variant="body1" fontWeight="medium" color={isDark ? 'white' : 'dark'}>
              {title}
            </SuiTypography>
          </Link>
          <SuiTypography variant="button" fontWeight="medium" color={isDark ? 'white' : 'dark'}>
            {outcome}
          </SuiTypography>
        </SuiBox>
        <SuiBox mt={0.5}>
          <SuiTypography
            variant="caption"
            fontWeight="medium"
            color={isDark ? 'secondary' : 'text'}
          >
            {dateTime}
          </SuiTypography>
        </SuiBox>
        <SuiBox mt={2} mb={1.5}>
          {description ? (
            <SuiTypography variant="body2" fontWeight="regular" color="text">
              {parse(description)}
            </SuiTypography>
          ) : null}
        </SuiBox>
        {badges.length > 0 ? (
          <SuiBox display="flex" pb={lastItem ? 1 : 2}>
            {renderBadges}
          </SuiBox>
        ) : null}
      </SuiBox>
    </SuiBox>
  );
}

// Setting admin values for the props of NoteTimelineItem
NoteTimelineItem.defaultProps = {
  color: 'info',
  badges: [],
  lastItem: false,
  description: '',
  outcome: '',
  linkHandler: undefined,
};

// Typechecking props for the NoteTimelineItem
NoteTimelineItem.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
    'light',
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  description: PropTypes.string,
  outcome: PropTypes.string,
  badges: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  lastItem: PropTypes.bool,
  linkHandler: PropTypes.func,
};

export default NoteTimelineItem;
