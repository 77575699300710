import { useEffect, useState } from 'react';

// react-router-dom components
import { NavLink, useLocation } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Icon from '@mui/material/Icon';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Soft UI Dashboard PRO React example components
import SidenavRoot from 'layouts/components/Sidenav/SidenavRoot';
import sidenavLogoLabel from 'layouts/components/Sidenav/styles/sidenav';
import { setMiniSidenav, useSoftUIController } from 'context';
import SidenavCollapse from './SidenavCollapse';
import SidenavList from './SidenavList';
import SidenavItem from './SidenavItem';

// Custom styles for the Sidenav

// Soft UI Dashboard PRO React context

function Sidenav({
  color, brand, brandName, routes, ...rest
}) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split('/').slice(1)[0];
  const itemName = pathname.split('/').slice(1)[1];

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      // eslint-disable-next-line no-undef
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
     */
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes
  const renderNestedCollapse = (collapse) => {
    const sidebar = collapse.filter((item) => item.sidebar === true);
    const template = sidebar.map(({
      name, route, key, href,
    }) => (href ? (
      <Link
        key={key}
        href={href}
        target="_blank"
        rel="noreferrer"
        sx={{ textDecoration: 'none' }}
      >
        <SidenavItem name={name} nested />
      </Link>
    ) : (
      <NavLink to={route} key={key} sx={{ textDecoration: 'none' }}>
        <SidenavItem name={name} active={route === pathname} nested />
      </NavLink>
    )));

    return template;
  };

  // Render the all the collpases from the routes
  const renderCollapse = (collapses) => collapses.map(({
    name, collapse, route, href, key, sidebar,
  }) => {
    if (sidebar) {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            name={name}
            active={key === itemName}
            open={openNestedCollapse === name}
            onClick={() => (openNestedCollapse === name
              ? setOpenNestedCollapse(false)
              : setOpenNestedCollapse(name))}
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: 'none' }}
          >
            <SidenavItem name={name} active={key === itemName} />
          </Link>
        ) : (
          <NavLink to={route} key={key} sx={{ textDecoration: 'none' }}>
            <SidenavItem name={name} active={key === itemName} />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    }
    return undefined;
  });

  // Render all the routes from the routes (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({
      type, name, icon, title, collapse, noCollapse, key, href, sidebar,
    }) => {
      if (sidebar) {
        let returnValue;

        if (type === 'collapse') {
          returnValue = href ? (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: 'none' }}
            >
              <SidenavCollapse
                color={color}
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          ) : (
            <SidenavCollapse
              color={color}
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === name}
              onClick={() => (
                openCollapse === name ? setOpenCollapse(false) : setOpenCollapse(name)
              )}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        } else if (type === 'title') {
          returnValue = (
            <SuiTypography
              key={key}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              opacity={0.6}
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </SuiTypography>
          );
        } else if (type === 'divider') {
          returnValue = <Divider key={key} />;
        }

        return returnValue;
      }
      return undefined;
    },
  );

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
      <SuiBox pt={3} pb={1} px={4} textAlign="center">
        <SuiBox
          display={{ xs: 'block', xl: 'none' }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: 'pointer' }}
        >
          <SuiTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: 'bold' }}>close</Icon>
          </SuiTypography>
        </SuiBox>
        <SuiBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <SuiBox component="img" src={brand} alt="Soft UI Logo" width="2rem" />}
          <SuiBox
            width={!brandName && '100%'}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <SuiTypography component="h6" variant="button" fontWeight="medium">
              {brandName}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      </SuiBox>
      <Divider />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Setting admin values for the props of Sidenav
Sidenav.defaultProps = {
  color: 'info',
  brand: '',
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
