import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

// Reducers
import { setEditing, setEmailListInfo, setSucceeded } from 'reducers/emailListsSlice';

// Functions
import { setTitle } from 'Util';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import Base from '../components/Base';

function NewEmailList() {
  const dispatch = useDispatch();

  setTitle('New Email List');

  useEffect(() => {
    dispatch(
      setSucceeded(false),
    );
    dispatch(
      setEditing(false),
    );
    dispatch(
      setEmailListInfo({
        name: '',
      }),
    );
  }, [dispatch]);

  return (
    <BaseLayout>
      <Base />
    </BaseLayout>
  );
}

export default NewEmailList;
