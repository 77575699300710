import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';

// Reducer functions
import {
  importFinancialCandidate,
  setFailed,
  setGoToViewSubView,
  setSaved,
} from 'reducers/candidatesSlice';
import { fetchPaymentMethods } from 'reducers/paymentMethodSlice';
import { fetchVendors } from 'reducers/vendorSlice';
import { fetchOrderTemplates } from 'reducers/orderTemplatesSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';

// Components
import FormSelect from 'layouts/components/FormSelect';
import BaseLayout from 'layouts/components/BaseLayout';

// DevExtreme components
import DataGrid, {
  Column,
  Editing,
  Lookup,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.material.blue.light.css';

import { LoadIndicator } from 'devextreme-react/load-indicator';

// Functions
import {
  failedCandidateMessage,
  findSelectValue,
  getCandidateUUID, isNumber,
} from 'Util';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const selector = (state) => ({
  orderTemplates: state.orderTemplate.orderTemplates,
  paymentMethods: state.paymentMethod.paymentMethods,
  vendors: state.vendor.vendors,
  importData: state.account.importData,
  saved: state.candidate.saved,
  failed: state.candidate.failed,
  errors: state.candidate.errors,
});
function ImportFinancial() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.billing' });
  const {
    orderTemplates,
    paymentMethods,
    vendors,
    importData,
    saved,
    failed,
    errors,
  } = useSelector(
    selector,
    shallowEqual,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uuid = getCandidateUUID();

  const [expenses, setExpenses] = useState([]);
  const [payments, setPayments] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [paymentPlan, setPaymentPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const expensesGrid = useRef(null);
  const paymentsGrid = useRef(null);
  const invoicesGrid = useRef(null);

  useEffect(() => {
    dispatch(fetchOrderTemplates());
    dispatch(fetchPaymentMethods());
    dispatch(fetchVendors());
  }, [dispatch]);

  useEffect(() => {
    if (importData !== undefined) {
      const found = orderTemplates.find(
        (p) => (importData.paymentPlan !== null
          ? p.name.toLowerCase().startsWith(importData.paymentPlan.toString().toLowerCase())
          : null),
      );
      const plan = found ? found.id : null;
      setPaymentPlan(plan);
    }

    if (importData.expenses !== undefined) {
      const data = [];
      importData.expenses.forEach((e, i) => {
        const found = vendors.find(
          (v) => v.name.toLowerCase().startsWith(e.vendor.toLowerCase()),
        );
        const vendor = found ? found.id : null;
        data.push({
          id: i + 1,
          date: e.date,
          vendor,
          amount: isNumber(e.amount) ? e.amount : null,
        });
      });
      setExpenses(data);
    }

    if (importData.payments !== undefined) {
      const data = [];
      importData.payments.forEach((p, i) => {
        const found = paymentMethods.find(
          (m) => m.name.toLowerCase().startsWith(p.item.toLowerCase()),
        );
        const method = found ? found.id : null;
        data.push({
          id: i + 1,
          date: p.date,
          item: `${p.item} payment`,
          method,
          value: isNumber(p.value) ? p.value : null,
        });
      });
      setPayments(data);
    }

    if (importData.invoices !== undefined) {
      const data = [];
      importData.invoices.forEach((i, n) => {
        const item = i.item.split('#');
        data.push({
          id: n + 1,
          date: i.date,
          item: item[0].trim(),
          number: item[1].trim(),
          value: isNumber(i.value) ? i.value : null,
        });
      });
      setInvoices(data);
    }
  }, [importData, paymentMethods, orderTemplates, vendors]);

  const handleAddInvoice = (e) => {
    e.cancel = true;
    setInvoices(invoices.concat({
      ...e.data,
      id: Math.max(...invoices.map((o) => o.id)) + 1,
    }));
    invoicesGrid.current.instance.cancelEditData();
  };
  const handleEditInvoice = (e) => {
    e.cancel = true;
    const idx = invoices.findIndex((i) => i.id === e.key.id);
    let data = invoices[idx];
    Object.keys(e.newData).forEach((i) => {
      data = {
        ...data,
        [i]: e.newData[i],
      };
    });
    setInvoices(invoices.map((obj, i) => {
      if (i === idx) {
        return data;
      }
      return obj;
    }));

    invoicesGrid.current.instance.cancelEditData();
  };
  const handleRemoveInvoice = (e) => {
    e.cancel = true;
    const idx = invoices.findIndex((i) => i.id === e.data.id);
    setInvoices([
      ...invoices.slice(0, idx),
      ...invoices.slice(idx + 1),
    ]);
    invoicesGrid.current.instance.cancelEditData();
  };

  const handleAddExpense = (e) => {
    e.cancel = true;
    setExpenses(expenses.concat({
      ...e.data,
      id: Math.max(...expenses.map((o) => o.id)) + 1,
    }));
    expensesGrid.current.instance.cancelEditData();
  };
  const handleEditExpense = (e) => {
    e.cancel = true;
    const idx = expenses.findIndex((i) => i.id === e.key);
    let data = expenses[idx];
    Object.keys(e.newData).forEach((i) => {
      data = {
        ...data,
        [i]: e.newData[i],
      };
    });
    setExpenses(expenses.map((obj, i) => {
      if (i === idx) {
        return data;
      }
      return obj;
    }));

    expensesGrid.current.instance.cancelEditData();
  };
  const handleRemoveExpense = (e) => {
    e.cancel = true;
    const idx = expenses.findIndex((i) => i.id === e.data.id);
    setExpenses([
      ...expenses.slice(0, idx),
      ...expenses.slice(idx + 1),
    ]);
    expensesGrid.current.instance.cancelEditData();
  };

  const handleAddPayment = (e) => {
    e.cancel = true;
    setPayments(payments.concat({
      ...e.data,
      id: Math.max(...payments.map((o) => o.id)) + 1,
    }));
    paymentsGrid.current.instance.cancelEditData();
  };
  const handleEditPayment = (e) => {
    e.cancel = true;
    const idx = payments.findIndex((i) => i.id === e.key.id);
    let data = payments[idx];
    Object.keys(e.newData).forEach((i) => {
      data = {
        ...data,
        [i]: e.newData[i],
      };
    });
    setPayments(payments.map((obj, i) => {
      if (i === idx) {
        return data;
      }
      return obj;
    }));

    paymentsGrid.current.instance.cancelEditData();
  };
  const handleRemovePayment = (e) => {
    e.cancel = true;
    const idx = payments.findIndex((i) => i.id === e.data.id);
    setPayments([
      ...payments.slice(0, idx),
      ...payments.slice(idx + 1),
    ]);
    paymentsGrid.current.instance.cancelEditData();
  };

  const handleImport = () => {
    setIsLoading(true);
    if (paymentPlan !== null && paymentPlan !== undefined) {
      if (expenses
        .filter((e) => (e.date === null || e.date === undefined)
          || (e.vendor === null || e.vendor === undefined)
          || e.amount <= 0).length < 1) {
        if (payments
          .filter((e) => (e.date === null || e.date === undefined)
            || (e.item === null || e.item === undefined)
            || (e.method === null || e.method === undefined)
            || e.value <= 0).length < 1) {
          if (invoices
            .filter((e) => (e.date === null || e.date === undefined)
              || (e.item === null || e.item === undefined)
              || e.value <= 0).length < 1) {
            dispatch(importFinancialCandidate({
              candidateId: uuid,
              importData: {
                status: importData.status,
                first_payment: importData.firstPayment,
                payment_plan: importData.paymentPlan,
                deferred_plan: importData.deferredPlan,
                pay_nas: importData.payNas,
                pay_bsr: importData.payBsr,
                deferred_payment: importData.deferredPayment,
                down_payment: importData.downPayment,
                monthly_amount: importData.monthlyAmount,
                monthly_payments: importData.monthlyPayments,
                english_academy: importData.englishAcademy,
                english_level: importData.englishLevel,
                services: importData.services,
                selected_payment_plan: paymentPlan,
                expenses,
                payments,
                invoices,
              },
            }));
          } else {
            Swal.fire({
              title: `${t('error', { keyPrefix: 'candidates.save' })}!`,
              text: t('invoices-error'),
              icon: 'error',
              confirmButtonText: t('close', { keyPrefix: 'candidates.save' }),
            });
          }
        } else {
          Swal.fire({
            title: `${t('error', { keyPrefix: 'candidates.save' })}!`,
            text: t('payments-error'),
            icon: 'error',
            confirmButtonText: t('close', { keyPrefix: 'candidates.save' }),
          });
        }
      } else {
        Swal.fire({
          title: `${t('error', { keyPrefix: 'candidates.save' })}!`,
          text: t('expenses-error'),
          icon: 'error',
          confirmButtonText: t('close', { keyPrefix: 'candidates.save' }),
        });
      }
    } else {
      Swal.fire({
        title: `${t('error', { keyPrefix: 'candidates.save' })}!`,
        text: t('invalid-payment-plan'),
        icon: 'error',
        confirmButtonText: t('close', { keyPrefix: 'candidates.save' }),
      });
    }
  };

  if (saved) {
    dispatch(setSaved(false));
    Swal.fire({
      title: `${t('success', { keyPrefix: 'candidates.save' })}!`,
      text: t('candidate-saved-successfully', { keyPrefix: 'candidates.save' }),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'candidates.save' }),
    }).then(() => {
      dispatch(setGoToViewSubView({
        view: 'billing',
        subview: '',
      }));
      navigate(`/candidates/edit/${uuid}`, { replace: true });
    });
  }
  if (failed) {
    setIsLoading(false);
    dispatch(setFailed(false));
    failedCandidateMessage(t, errors);
  }

  return (
    <BaseLayout>
      <Card id="import-financial" sx={{ overflow: 'visible' }}>
        <Grid container spacing={1} mt={1} mb={2}>
          <Grid item md={12}>
            <SuiBox component="form" pb={3} px={3}>
              <FormSelect
                label={t('payment-plan')}
                options={orderTemplates}
                value={findSelectValue(orderTemplates, paymentPlan)}
                onChange={(e) => setPaymentPlan(e.value)}
              />
            </SuiBox>
          </Grid>
          <Grid item md={12}>
            <SuiBox component="form" pb={3} px={3}>
              {t('invoices')}
            </SuiBox>
          </Grid>
          <Grid item md={12}>
            <SuiBox component="form" pb={3} px={3}>
              <DataGrid
                id="invoices"
                ref={invoicesGrid}
                dataSource={invoices}
                allowColumnResizing
                columnAutoWidth
                onRowUpdating={handleEditInvoice}
                onRowInserting={handleAddInvoice}
                onRowRemoving={handleRemoveInvoice}
              >
                <Editing
                  mode="row"
                  allowUpdating
                  allowDeleting
                  allowAdding
                />
                <Column
                  dataField="date"
                  caption={t('date')}
                  dataType="date"
                  defaultSortOrder="asc"
                  width={150}
                />
                <Column
                  dataField="item"
                  caption={t('details')}
                />
                <Column
                  dataField="number"
                  caption={t('number')}
                  width={150}
                />
                <Column
                  dataField="value"
                  caption={t('amount')}
                  format="currency"
                />
                <Summary>
                  <TotalItem
                    column="date"
                    summaryType="count"
                  />
                  <TotalItem
                    column="value"
                    summaryType="sum"
                    valueFormat="currency"
                  />
                </Summary>
              </DataGrid>
            </SuiBox>
          </Grid>
          <Grid item md={12} />
          <Grid item md={5}>
            <SuiBox component="form" pb={3} px={3}>
              {t('expenses')}
            </SuiBox>
          </Grid>
          <Grid item md={7}>
            <SuiBox component="form" pb={3} px={3}>
              {t('payments')}
            </SuiBox>
          </Grid>
          <Grid item md={5}>
            <SuiBox component="form" pb={2} px={2}>
              <DataGrid
                id="expenses"
                ref={expensesGrid}
                dataSource={expenses}
                keyExpr="id"
                allowColumnResizing
                columnAutoWidth
                onRowUpdating={handleEditExpense}
                onRowInserting={handleAddExpense}
                onRowRemoving={handleRemoveExpense}
              >
                <Editing
                  mode="row"
                  allowUpdating
                  allowDeleting
                  allowAdding
                />
                <Column
                  dataField="date"
                  caption={t('date')}
                  dataType="date"
                  defaultSortOrder="asc"
                  width={150}
                />
                <Column
                  dataField="vendor"
                  caption={t('vendor')}
                >
                  <Lookup dataSource={vendors} displayExpr="name" valueExpr="id" />
                </Column>
                <Column
                  dataField="amount"
                  caption={t('amount')}
                  format="currency"
                />
                <Summary>
                  <TotalItem
                    column="date"
                    summaryType="count"
                  />
                  <TotalItem
                    column="amount"
                    summaryType="sum"
                    valueFormat="currency"
                  />
                </Summary>
              </DataGrid>
            </SuiBox>
          </Grid>
          <Grid item md={7}>
            <SuiBox component="form" pb={2} px={2}>
              <DataGrid
                id="payments"
                ref={paymentsGrid}
                dataSource={payments}
                allowColumnResizing
                columnAutoWidth
                onRowUpdating={handleEditPayment}
                onRowInserting={handleAddPayment}
                onRowRemoving={handleRemovePayment}
              >
                <Editing
                  mode="row"
                  allowUpdating
                  allowDeleting
                  allowAdding
                />
                <Column
                  dataField="date"
                  caption={t('date')}
                  dataType="date"
                  defaultSortOrder="asc"
                  width={150}
                />
                <Column
                  dataField="item"
                  caption={t('item')}
                />
                <Column
                  dataField="method"
                  caption={t('payment-method')}
                >
                  <Lookup dataSource={paymentMethods} displayExpr="name" valueExpr="id" />
                </Column>
                <Column
                  dataField="value"
                  caption={t('amount')}
                  format="currency"
                />
                <Summary>
                  <TotalItem
                    column="date"
                    summaryType="count"
                  />
                  <TotalItem
                    column="value"
                    summaryType="sum"
                    valueFormat="currency"
                  />
                </Summary>
              </DataGrid>
            </SuiBox>
          </Grid>
        </Grid>
      </Card>
      <SuiBox display="flex" justifyContent="flex-end" mt={6} p={1}>
        <SuiBox mr={1}>
          {!isLoading && (
            <SuiButton
              variant="gradient"
              color="info"
              size="small"
              onClick={handleImport}
            >
              {t('import')}
            </SuiButton>
          )}
          {isLoading && (
            <LoadIndicator
              visible
              height={40}
              width={40}
            />
          )}
        </SuiBox>
      </SuiBox>
    </BaseLayout>
  );
}

export default ImportFinancial;
