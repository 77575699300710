import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Reducers
import { setOrderInfo } from 'reducers/ordersSlice';

// Components
import DefaultOrderDetails from 'layouts/components/OrderDetails/DefaultOrderDetails';
import { useEffect, useState } from 'react';

// Functions

const selector = (state) => ({
  services: state.service.services,
  orderInfo: state.order.orderInfo,
  editing: state.order.editing,
});

function OrderDetails() {
  const {
    services,
    orderInfo,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [orderItems, setOrderItems] = useState([]);

  useEffect(() => {
    if (orderInfo.order_items !== undefined) {
      setOrderItems(orderInfo.order_items
        // eslint-disable-next-line no-underscore-dangle
        .filter((i) => i._destroy !== true));
    }
  }, [orderInfo]);

  const setItems = (value) => {
    dispatch(
      setOrderInfo(
        {
          ...orderInfo,
          order_items: value,
          changed: true,
        },
      ),
    );
  };

  const handleRemoveItem = (i) => {
    if (orderInfo.order_items[i].id !== null) {
      setItems(orderInfo.order_items.map((obj, index) => {
        if (index === i) {
          return {
            ...obj,
            _destroy: true,
          };
        }
        return obj;
      }));
    } else {
      setItems([
        ...orderInfo.order_items.slice(0, i),
        ...orderInfo.order_items.slice(i + 1),
      ]);
    }
  };

  return (
    <DefaultOrderDetails
      orderDetails={orderItems}
      setValue={setItems}
      services={services}
      removeFunc={handleRemoveItem}
    />
  );
}

export default OrderDetails;
