import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

// @mui material components
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';

// Layout components
import LoadingSpinner from 'layouts/components/LoadingSpinner';
import SimpleInfoCard from 'layouts/components/Cards/InfoCards/SimpleInfoCard';

// Functions
import { currencyFormatter } from 'Util';

const selector = (state) => ({
  balance: state.account.balance,
  status: state.account.status,
});

function AccountBalance() {
  const { t } = useTranslation('translation', { keyPrefix: 'widgets' });
  const {
    balance,
    status,
  } = useSelector(selector, shallowEqual);

  const [isLoading, setIsLoading] = useState(false);
  const [accountBalance, setAccountBalance] = useState(0);

  useEffect(() => {
    if (balance.invoices !== undefined) {
      setAccountBalance(balance.balance);
    } else {
      setAccountBalance(0);
    }

    if (status === 'loading') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [balance, status]);

  return (
    <div>
      {isLoading && (<LoadingSpinner />)}
      {!isLoading && (
        <SimpleInfoCard
          title={t('account-balance')}
          value={currencyFormatter.format(Math.round(accountBalance))}
          icon={<AccountBalanceTwoToneIcon fontSize="large" />}
        />
      )}
    </div>
  );
}

export default AccountBalance;
