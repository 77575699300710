import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

// Reducer functions
import { setDocumentInfo } from 'reducers/documentsSlice';
import { fetchDocumentTypes } from 'reducers/commonSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';

// Components
import FormField from 'layouts/components/FormField';
import FormTextarea from 'layouts/components/FormTextarea';
import FormSwitch from 'layouts/components/FormSwitch';
import FormSelect from 'layouts/components/FormSelect';

// Util functions
import { findSelectValue } from 'Util';
import CardContent from '@mui/material/CardContent';

const selector = (state) => ({
  documentInfo: state.document.documentInfo,
  documentTypes: state.common.documentTypes,
});

function DocumentInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'documents' });
  const {
    documentInfo,
    documentTypes,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDocumentTypes());
  }, [dispatch]);

  const size = [
    {
      value: 15,
      label: '15 MB',
    },
    {
      value: 16,
      label: '16 MB',
    },
    {
      value: 17,
      label: '17 MB',
    },
    {
      value: 18,
      label: '18 MB',
    },
    {
      value: 19,
      label: '19 MB',
    },
    {
      value: 20,
      label: '20 MB',
    },
    {
      value: 21,
      label: '21 MB',
    },
    {
      value: 22,
      label: '22 MB',
    },
    {
      value: 23,
      label: '23 MB',
    },
    {
      value: 24,
      label: '24 MB',
    },
    {
      value: 25,
      label: '25 MB',
    },
    {
      value: 26,
      label: '26 MB',
    },
    {
      value: 27,
      label: '27 MB',
    },
    {
      value: 28,
      label: '28 MB',
    },
    {
      value: 29,
      label: '29 MB',
    },
    {
      value: 30,
      label: '30 MB',
    },
    {
      value: 31,
      label: '31 MB',
    },
    {
      value: 32,
      label: '32 MB',
    },
    {
      value: 33,
      label: '33 MB',
    },
    {
      value: 34,
      label: '34 MB',
    },
    {
      value: 35,
      label: '35 MB',
    },
    {
      value: 36,
      label: '36 MB',
    },
    {
      value: 37,
      label: '37 MB',
    },
    {
      value: 38,
      label: '38 MB',
    },
    {
      value: 39,
      label: '39 MB',
    },
    {
      value: 40,
      label: '40 MB',
    },
    {
      value: 41,
      label: '41 MB',
    },
    {
      value: 42,
      label: '42 MB',
    },
    {
      value: 43,
      label: '43 MB',
    },
    {
      value: 44,
      label: '44 MB',
    },
    {
      value: 45,
      label: '45 MB',
    },
    {
      value: 46,
      label: '46 MB',
    },
    {
      value: 47,
      label: '47 MB',
    },
    {
      value: 48,
      label: '48 MB',
    },
    {
      value: 49,
      label: '49 MB',
    },
    {
      value: 50,
      label: '50 MB',
    },
  ];

  const setValue = (key, value) => {
    dispatch(
      setDocumentInfo(
        {
          ...documentInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  const handleRequired = () => {
    dispatch(
      setDocumentInfo(
        {
          ...documentInfo,
          is_required: !documentInfo.is_required,
          changed: true,
        },
      ),
    );
  };

  const handleAutoAssigned = () => {
    dispatch(
      setDocumentInfo(
        {
          ...documentInfo,
          auto_assigned: !documentInfo.auto_assigned,
          changed: true,
        },
      ),
    );
  };

  const handleMaxSize = (value) => {
    setValue('max_size', value * 1048576);
  };

  return (
    <Card id="document-info" sx={{ overflow: 'visible' }}>
      <CardHeader
        title={t('document-info')}
      />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <FormField
              label={t('name')}
              value={documentInfo.name !== undefined ? documentInfo.name : ''}
              onChange={(e) => {
                setValue('name', e.target.value);
              }}
            />
          </Grid>
          <Grid item md={3}>
            <FormSelect
              label={t('document-type')}
              textTransform="none"
              options={documentTypes}
              value={findSelectValue(documentTypes, documentInfo.document_type_id)}
              onChange={(e) => {
                setValue('document_type_id', e.value);
              }}
            />
          </Grid>
          <Grid item md={2}>
            <FormField
              label={t('uploads-limit')}
              value={documentInfo.uploads_number !== undefined ? documentInfo.uploads_number : ''}
              onChange={(e) => {
                setValue('uploads_number', e.target.value);
              }}
            />
          </Grid>
          <Grid item md={2}>
            <FormSelect
              label={t('max-size')}
              textTransform="none"
              options={size}
              value={findSelectValue(size, documentInfo.max_size / 1048576)}
              onChange={(e) => handleMaxSize(e.value)}
            />
          </Grid>
          <Grid item md={2}>
            <FormSwitch
              label={`${t('required')}: ${documentInfo.is_required
                ? t('yes', { keyPrefix: 'common' }) : t('no', { keyPrefix: 'common' })}`}
              checked={!!documentInfo.is_required}
              onChange={handleRequired}
            />
          </Grid>
          <Grid item md={3}>
            <FormSwitch
              label={`${t('automatically-assigned')}: ${documentInfo.auto_assigned
                ? t('yes', { keyPrefix: 'common' }) : t('no', { keyPrefix: 'common' })}`}
              checked={!!documentInfo.auto_assigned}
              onChange={handleAutoAssigned}
            />
          </Grid>
          <Grid item md={12}>
            <FormTextarea
              label={t('description')}
              value={documentInfo.description !== undefined ? documentInfo.description : ''}
              onChange={(e) => {
                setValue('description', e.target.value);
              }}
              minRows={10}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default DocumentInfo;
