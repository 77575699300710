import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { fetchEnglishLearningSpans } from 'reducers/reportsSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// DevExtreme components
import PivotGrid, { FieldChooser, Export, Scrolling } from 'devextreme-react/pivot-grid';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { exportPivotGrid } from 'devextreme/excel_exporter';
import {
  DataGrid, Column, Paging, Pager,
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';

// Other components
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import useWindowDimensions from 'layouts/components/Hooks/WindowDimensions';
import PropTypes from 'prop-types';

const selector = (state) => ({
  englishLearningSpans: state.reports.englishLearningSpans,
});

function EnglishLearningSpan({ parameters }) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { englishLearningSpans } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const pivotGrid = useRef(null);
  const dataGridRef = useRef(null);

  const [parentHeight, setParentHeight] = useState(500);
  const [reportData, setReportData] = useState([]);
  const [popupTitle, setPopupTitle] = useState('');
  const [drillDownDataSource, setDrillDownDataSource] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);

  const { height } = useWindowDimensions();

  useEffect(() => {
    setParentHeight(height - 150);
  }, [height]);

  useEffect(() => {
    dispatch(fetchEnglishLearningSpans({ statuses: parameters.statuses }));
  }, [dispatch, parameters.statuses]);

  useEffect(() => {
    const dataSource = new PivotGridDataSource({
      fields: [
        {
          caption: t('candidate'),
          dataField: 'candidate_name',
          width: 300,
          area: 'row',
          sortBySummaryField: 'Total',
        },
        {
          caption: t('advisor'),
          dataField: 'advisor_name',
          width: 200,
        },
        {
          dataField: 'uuid',
          area: 'row',
          visible: false,
        },
        {
          dataField: 'advisor_uuid',
          area: 'row',
          visible: false,
        },
        {
          dataField: 'started',
          area: 'row',
          visible: false,
        },
        {
          dataField: 'latest',
          area: 'row',
          visible: false,
        },
        {
          caption: t('level'),
          dataField: 'level',
          area: 'column',
        },
        {
          caption: t('total'),
          dataField: 'duration',
          summaryType: 'sum',
          area: 'data',
          dataType: 'number',
        },
      ],
      store: englishLearningSpans,
    });

    setReportData(dataSource);
  }, [englishLearningSpans, t]);

  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('EnglishLearningSpans');

    exportPivotGrid({
      component: e.component,
      worksheet,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'EnglishLearningSpans.xlsx');
      });
    });
  };

  const onCellClick = (e) => {
    if (e.area === 'data') {
      const pivotGridDataSource = e.component.getDataSource();
      const rowPathLength = e.cell.rowPath.length;
      const rowPathName = e.cell.rowPath[rowPathLength - 1];

      setPopupTitle(rowPathName);
      setDrillDownDataSource(pivotGridDataSource.createDrillDownDataSource(e.cell));
      setPopupVisible(true);
    }
  };

  const isDataCell = (cell) => (cell.area === 'data' && cell.rowType === 'D' && cell.columnType === 'D');

  const isTotalCell = (cell) => (cell.type === 'T' || cell.type === 'GT' || cell.rowType === 'T' || cell.rowType === 'GT' || cell.columnType === 'T' || cell.columnType === 'GT');

  const getCssStyles = ({ fill, font, bold }) => (
    {
      'background-color': `#${fill}`,
      color: `#${font}`,
      'font-weight': bold ? 'bold' : undefined,
    });

  const getConditionalAppearance = (cell) => {
    if (isTotalCell(cell)) {
      return { fill: 'F2F2F2', font: '3F3F3F', bold: true };
    }
    const { value } = cell;

    if (value === null) {
      return { font: '000000', fill: 'ffffff' };
    }
    if (value < 60) {
      return { font: '006100', fill: 'c6efce' };
    }
    if (value >= 60 && value < 90) {
      return { font: '9c6500', fill: 'ffeb9c' };
    }
    if (value >= 90) {
      return { font: 'b22222', fill: 'f08080' };
    }
    return { font: '000000', fill: 'ffffff' };
  };

  const onCellPrepared = ({ cell, area, cellElement }) => {
    // eslint-disable-next-line no-param-reassign
    cell.area = area;

    if (isDataCell(cell) || isTotalCell(cell)) {
      const appearance = getConditionalAppearance(cell);
      Object.assign(cellElement.style, getCssStyles(appearance));
    }
  };

  return (
    <Grid
      container
      direction="row"
      spacing={3}
    >
      <Grid item xs={12} md={12}>
        <Card>
          <Grid item m={3} md={12}>
            <PivotGrid
              id="learning-span"
              dataSource={reportData}
              allowSortingBySummary
              allowSorting
              allowFiltering
              showBorders={false}
              ref={pivotGrid}
              onExporting={onExporting}
              onCellClick={onCellClick}
              onCellPrepared={onCellPrepared}
              height={parentHeight}
            >
              <FieldChooser enabled height={400} />
              <Export enabled />
              <Scrolling mode="virtual" />
            </PivotGrid>
            <Popup
              visible={popupVisible}
              title={popupTitle}
              onHiding={() => setPopupVisible(false)}
              onShown={() => dataGridRef.current.instance.updateDimensions()}
              showCloseButton
            >
              <DataGrid
                dataSource={drillDownDataSource}
                ref={dataGridRef}
              >
                <Paging defaultPageSize={30} />
                <Pager
                  visible
                  allowedPageSizes={[10, 30, 50, 'all']}
                  displayMode="full"
                  showPageSizeSelector
                  showInfo
                  showNavigationButtons
                />
                <Column dataField="candidate_name" caption={t('candidate-name')} />
                <Column dataField="level" caption={t('level')} />
                <Column dataField="started" caption={t('started')} />
                <Column dataField="latest" caption={t('latest')} />
              </DataGrid>
            </Popup>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

EnglishLearningSpan.propTypes = {
  parameters: PropTypes.instanceOf({
    statuses: PropTypes.arrayOf(PropTypes.number.isRequired),
  }).isRequired,
};

export default EnglishLearningSpan;
