import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { fetchLeadConversionRate } from 'reducers/reportsSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// DevExtreme components
import PivotGrid, { FieldChooser, Export, Scrolling } from 'devextreme-react/pivot-grid';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { exportPivotGrid } from 'devextreme/excel_exporter';
import {
  DataGrid, Paging, Pager,
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';

// Other components
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import { renderColumns } from '../../../../../Util';

const selector = (state) => ({
  leadConversionRate: state.reports.leadConversionRate,
});

function LeadConversion({ parameters }) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { leadConversionRate } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const pivotGrid = useRef(null);
  const dataGridRef = useRef(null);

  const [reportData, setReportData] = useState([]);
  const [popupTitle, setPopupTitle] = useState('');
  const [drillDownDataSource, setDrillDownDataSource] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);

  useEffect(() => {
    if (parameters !== undefined) {
      dispatch(fetchLeadConversionRate({
        from: parameters.from,
        to: parameters.to,
      }));
    }
  }, [parameters, dispatch]);

  useEffect(() => {
    const dataSource = new PivotGridDataSource({
      fields: [
        {
          caption: t('advisor'),
          dataField: 'advisor_name',
          area: 'column',
        },
        {
          caption: t('country'),
          dataField: 'country_name',
          width: 200,
          area: 'row',
        },
        {
          caption: t('candidate'),
          dataField: 'candidate_name',
          width: 200,
          area: 'row',
        },
        {
          dataField: 'id',
          area: 'row',
          visible: false,
        },
        {
          dataField: 'uuid',
          area: 'row',
          visible: false,
        },
        {
          dataField: 'country_id',
          area: 'row',
          visible: false,
        },
        {
          dataField: 'advisor_uuid',
          area: 'row',
          visible: false,
        },
        {
          dataField: 'candidate_uuid',
          area: 'column',
          visible: false,
        },
        {
          dataField: 'enrollment_status_date',
          area: 'row',
          visible: false,
        },
        {
          dataField: 'meeting_date',
          area: 'row',
          visible: false,
        },
        {
          caption: t('total'),
          dataField: 'days',
          summaryType: 'avg',
          area: 'data',
          summaryDisplayMode: 'none',
          dataType: 'number',
          format: '#0.0',
        },
      ],
      store: leadConversionRate,
    });

    setReportData(dataSource);
  }, [leadConversionRate, t]);

  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('LeadConversion');

    exportPivotGrid({
      component: e.component,
      worksheet,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'LeadConversion.xlsx');
      });
    });
  };

  const onCellClick = (e) => {
    if (e.area === 'data') {
      const pivotGridDataSource = e.component.getDataSource();
      const rowPathLength = e.cell.rowPath.length;
      const rowPathName = e.cell.rowPath[rowPathLength - 1];

      setPopupTitle(rowPathName);
      setDrillDownDataSource(pivotGridDataSource.createDrillDownDataSource(e.cell));
      setPopupVisible(true);
    }
  };

  const columns = [
    {
      caption: t('candidate'),
      field: 'candidate_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('meeting-date'),
      field: 'meeting_date',
      visible: true,
      allowFixing: true,
      dataType: 'date',
    },
    {
      caption: t('enrollment-date'),
      field: 'enrollment_status_date',
      visible: true,
      allowFixing: true,
      dataType: 'date',
    },
    {
      caption: t('conversion-days'),
      field: 'days',
      visible: true,
      allowFixing: true,
      defaultSortOrder: 'asc',
    },
  ];

  return (
    <Grid
      container
      direction="row"
      spacing={3}
    >
      <Grid item xs={12} md={12}>
        <Card>
          <Grid item m={3} md={12}>
            <PivotGrid
              id="enrollment-days"
              dataSource={reportData}
              allowSortingBySummary
              allowSorting
              allowFiltering
              showBorders={false}
              ref={pivotGrid}
              onExporting={onExporting}
              onCellClick={onCellClick}
            >
              <FieldChooser enabled height={400} />
              <Export enabled />
              <Scrolling mode="virtual" />
            </PivotGrid>
            <Popup
              visible={popupVisible}
              title={popupTitle}
              onHiding={() => setPopupVisible(false)}
              onShown={() => dataGridRef.current.instance.updateDimensions()}
              showCloseButton
            >
              <DataGrid
                dataSource={drillDownDataSource}
                ref={dataGridRef}
              >
                <Paging defaultPageSize={30} />
                <Pager
                  visible
                  allowedPageSizes={[10, 30, 50, 'all']}
                  displayMode="full"
                  showPageSizeSelector
                  showInfo
                  showNavigationButtons
                />
                <Export enabled />
                {renderColumns(columns)}
              </DataGrid>
            </Popup>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

LeadConversion.propTypes = {
  parameters: PropTypes.instanceOf({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
};

export default LeadConversion;
