import PropTypes from 'prop-types';

function ErrorFallback(
  {
    error,
    resetErrorBoundary,
  },
) {
  return (
    <div role="alert">
      <h2>Something went wrong.</h2>
      <pre>{error.message}</pre>
      <details style={{ whiteSpace: 'pre-wrap' }}>
        <br />
        {error.stack}
      </details>
      <br />
      <button type="button" onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

ErrorFallback.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]).isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
};

export default ErrorFallback;
