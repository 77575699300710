import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Reducers functions
import {
  cancelPayment,
  clearPayment,
  createPayment,
  setCreated,
  setDeleted,
  setFailed,
  setSaved,
  updatePayment,
} from 'reducers/paymentsSlice';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';

// Functions
import { errorsToString, MissingDataWarning, NoChangesWarning } from 'Util';
import Swal from 'sweetalert2';

const selector = (state) => ({
  paymentInfo: state.payment.paymentInfo,
  editing: state.payment.editing,
  deleted: state.payment.deleted,
  created: state.payment.created,
  saved: state.payment.saved,
  updated: state.payment.updated,
  failed: state.payment.failed,
  errors: state.payment.errors,
});

function SavePanel() {
  const { t } = useTranslation('translation', { keyPrefix: 'payments' });
  const {
    paymentInfo,
    editing,
    deleted,
    created,
    saved,
    failed,
    errors,
  } = useSelector(
    selector,
    shallowEqual,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  if (created === true) {
    dispatch(setCreated(false));
    dispatch(clearPayment());

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('payment-created-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      navigate('/billing/payments', { replace: true });
    });
  }

  if (saved === true) {
    dispatch(setSaved(false));
    dispatch(clearPayment());

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('payment-saved-successfully'),
      icon: 'success',
    }).then(() => {
      navigate('/billing/payments', { replace: true });
    });
  }

  if (deleted === true) {
    dispatch(setDeleted(false));
    dispatch(clearPayment({}));

    Swal.fire({
      title: `${t('deleted', { keyPrefix: 'common' })}!`,
      text: t('payment-deleted-successfully'),
      icon: 'success',
    }).then(() => {
      navigate('/billing/payments', { replace: true });
    });
  }

  if (failed) {
    dispatch(setFailed(false));

    Swal.fire({
      title: t('error', { keyPrefix: 'common' }),
      text: errorsToString(errors),
      icon: 'error',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    });
  }

  const handleSave = () => {
    if (paymentInfo.changed === true) {
      if (paymentInfo.candidate_id !== undefined && paymentInfo.candidate_id !== ''
        && paymentInfo.amount !== undefined && paymentInfo.amount !== '') {
        const data = {
          date: paymentInfo.date,
          // provider,
          amount: paymentInfo.amount,
          reference: paymentInfo.reference,
          // referenceId,
          // metadata,
          candidateId: paymentInfo.candidate_id,
          paymentMethodId: paymentInfo.payment_method_id,
          notes: paymentInfo.notes,
          invoices: paymentInfo.invoices,
        };

        if (editing === true) {
          data.id = id;

          dispatch(updatePayment(data));
        } else {
          dispatch(createPayment(data));
        }
      } else {
        MissingDataWarning(t);
      }
    } else {
      NoChangesWarning(t);
    }
  };

  const handleDelete = () => {
    Swal.fire({
      text: t('are-you-sure-you-want-to-delete-it', {
        keyPrefix: 'common',
      }),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('delete', { keyPrefix: 'common' }),
    }).then((result) => {
      if (result.isConfirmed) {
        const data = { id, notes: paymentInfo.notes };
        dispatch(cancelPayment(data));
      }
    });
  };

  return (
    <SuiBox mt={5} mb={3}>
      <SuiBox display="flex" justifyContent="flex-end" mt={6}>
        {editing && (
          <SuiBox mr={1}>
            <SuiButton
              variant="gradient"
              color="error"
              size="small"
              onClick={handleDelete}
            >
              delete
            </SuiButton>
          </SuiBox>
        )}
        <SuiBox mr={1}>
          <SuiButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleSave}
          >
            save
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default SavePanel;
