import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Reducer Functions
import { fetchOrders } from 'reducers/ordersSlice';

// @mui material components
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';

// DevExpress components
/* eslint-disable object-curly-newline */
import DataGrid, {
  Column,
  Editing,
  HeaderFilter,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Sorting,
  StateStoring,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.material.blue.light.css';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import TabBar from 'layouts/components/TabBar';

const selector = (state) => ({
  orders: state.order.orders,
});

function Orders() {
  const { t } = useTranslation('translation', { keyPrefix: 'orders' });

  const {
    orders,
  } = useSelector(selector, shallowEqual);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ordersData, setOrdersData] = useState([]);

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  useEffect(() => {
    setOrdersData(orders);
  }, [orders]);

  const dataGrid = useRef(null);
  const allowedPageSizes = [10, 30, 50, 'all'];

  const handleSetTabValue = (event, newValue) => {
    switch (newValue) {
      case 0:
        setOrdersData(orders);
        break;
      case 1:
        setOrdersData(orders.filter((o) => o.status === 'pending'));
        break;
      case 2:
        setOrdersData(orders.filter((o) => o.status === 'billed'));
        break;
      case 3:
        setOrdersData(orders.filter((o) => o.status === 'paid'));
        break;
      case 4:
        setOrdersData(orders.filter((o) => o.status === 'cancelled'));
        break;
      default:
        setOrdersData(orders);
        break;
    }
  };

  const handleNew = () => {
    navigate('new', { replace: true });
  };

  const handleEditing = (e) => {
    navigate(`/billing/orders/edit/${e.key}`);
  };

  const handleStateReset = () => {
    dataGrid.current.instance.state(null);
  };

  return (
    <BaseLayout>
      <SuiBox display="flex" justifyContent="flex-end" mt={6} p={1}>
        <SuiBox mr={1}>
          <SuiButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleNew}
          >
            <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
            &nbsp;
            {t('add-new-order')}
          </SuiButton>
        </SuiBox>
      </SuiBox>
      <TabBar
        tabs={[t('all'), t('pending'), t('billed'), t('paid'), t('cancelled')]}
        setTabValueFunction={handleSetTabValue}
      />
      <SuiBox display="flex" justifyContent="flex-end" p={1}>
        <SuiBox mr={1}>
          <SuiButton variant="text" color="info" onClick={handleStateReset}>
            {t('reset-filters', { keyPrefix: 'common' })}
          </SuiButton>
        </SuiBox>
      </SuiBox>
      <SuiBox>
        <Paper>
          <DataGrid
            id="orders"
            ref={dataGrid}
            dataSource={ordersData}
            keyExpr="id"
            allowColumnReordering
            allowColumnResizing
            columnAutoWidth
            onEditingStart={handleEditing}
          >
            <Editing
              mode="row"
              allowUpdating
              allowDeleting={false}
              allowAdding={false}
            />
            <HeaderFilter visible />
            <Scrolling rowRenderingMode="virtual" />
            <Sorting mode="multiple" />
            <Paging defaultPageSize={30} />
            <Pager
              visible
              allowedPageSizes={allowedPageSizes}
              displayMode="full"
              showPageSizeSelector
              showInfo
              showNavigationButtons
            />
            <SearchPanel
              visible
              width={240}
              placeholder={t('search', { keyPrefix: 'common' })}
            />
            <StateStoring
              enabled
              type="localStorage"
              storageKey="orders-grid"
            />
            <Column
              caption={t('order-num')}
              dataField="order_id"
            />
            <Column
              caption={t('candidate')}
              dataField="candidate_name"
            />
            <Column
              caption={t('issued')}
              dataField="issue"
            />
            <Column
              caption={t('amount')}
              dataField="total"
              format={{
                type: 'currency',
                useCurrencyAccountingStyle: true,
              }}
            />
            <Column
              caption={t('status')}
              dataField="status"
            />
          </DataGrid>
        </Paper>
      </SuiBox>
    </BaseLayout>
  );
}

export default Orders;
