import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// @mui material components
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import parse from 'html-react-parser';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

// Soft UI Dashboard PRO React components
import SuiTypography from 'components/SuiTypography';
import SuiBox from 'components/SuiBox';
import SuiBadge from 'components/SuiBadge';

// Components

const rowStyle = {
  minHeight: '90px',
  maxHeight: '90px',
};

const divStyle = {
  maxHeight: '90px',
  position: 'relative',
  overflow: 'hidden',
};

const pStyle = {
  position: 'absolute',
  bottom: '0',
  left: '0',
  width: '100%',
  textAlign: 'center',
  margin: '0',
  padding: '30px 0',
  backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0), #FFF)',
};

const pStyleSelected = {
  position: 'absolute',
  bottom: '0',
  left: '0',
  width: '100%',
  textAlign: 'center',
  margin: '0',
  padding: '30px 0',
  backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0), #e4fff7)',
};

const selectedStyle = {
  border: '1px solid #31bf95',
  backgroundColor: '#e4fff7',
};

function NoteCard({
  id,
  title,
  icon,
  content,
  dateTime,
  badges,
  selected,
  fade,
  tile,
  editFunc,
  removeFunc,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'common' });

  const renderBadges = badges.length > 0
    ? badges.map((b, k) => {
      const badgeKey = `badge-${k}`;

      return (
        <SuiBox key={badgeKey} mr={k === badges.length - 1 ? 0 : 0.5}>
          <SuiBadge size="xs" color={b.color} badgeContent={b.label} container />
        </SuiBox>
      );
    })
    : null;

  const [menu, setMenu] = useState(null);
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  return (
    <Card sx={selected ? selectedStyle : null}>
      <CardHeader
        title={title}
        action={(
          <IconButton onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
      />
      <Menu
        anchorEl={menu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(menu)}
        onClose={closeMenu}
        keepMounted
      >
        <MenuItem
          onClick={() => {
            editFunc(id);
            closeMenu();
          }}
        >
          {t('edit')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeFunc(id);
            closeMenu();
          }}
        >
          {t('remove')}
        </MenuItem>
      </Menu>
      <CardContent>
        <Grid container direction="row" alignItems="center">
          {icon !== null && (<Grid item md={1} mb={2}>{icon}</Grid>)}
          <Grid item md={12} mb={2} style={tile ? rowStyle : null}>
            <div style={fade ? divStyle : null}>
              <SuiTypography variant="body2" fontWeight="regular" color="text">
                {parse(content)}
              </SuiTypography>
              {fade && (<p style={selected ? pStyleSelected : pStyle} />)}
            </div>
          </Grid>
          <Grid item md={6}>
            <SuiTypography
              variant="caption"
              fontWeight="medium"
            >
              {dateTime !== null ? Date.parse(dateTime).toString('d') : ''}
            </SuiTypography>
          </Grid>
          <Grid item md={6}>
            {badges.length > 0 ? (
              <SuiBox display="flex" justifyContent="flex-end">
                {renderBadges}
              </SuiBox>
            ) : null}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

NoteCard.defaultProps = {
  icon: null,
  content: '',
  badges: [],
  selected: false,
  fade: false,
  tile: false,
  editFunc: null,
  removeFunc: null,
};

NoteCard.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  content: PropTypes.string,
  dateTime: PropTypes.string.isRequired,
  badges: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  selected: PropTypes.bool,
  fade: PropTypes.bool,
  tile: PropTypes.bool,
  editFunc: PropTypes.func,
  removeFunc: PropTypes.func,
};
export default NoteCard;
