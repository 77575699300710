import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Service
import AttorneyService from 'services/attorney.service';

// @material-ui core components
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { Column, Editing, Paging } from 'devextreme-react/data-grid';

function Contacts() {
  const { t } = useTranslation('translation', { keyPrefix: 'attorneys' });
  const dataGrid = useRef(null);
  const { id } = useParams();

  const getItems = () => AttorneyService.getAttorneyContacts(id);

  const addItem = (values) => AttorneyService
    .createAttorneyContact(id, values.name, values.position, values.email, values.phone);

  const modifyItem = (key, values) => {
    const item = dataGrid.current.instance.getDataSource()
      .items()
      .find((cp) => cp.id === key);
    const changes = {
      ...item,
      [Object.keys(values)[0]]: values[Object.keys(values)[0]],
    };
    return AttorneyService
      .updateAttorneyContact(key, id, changes.name, changes.position, changes.email, changes.phone);
  };

  const removeItem = (key) => AttorneyService.deleteAttorneyContact(id, key);

  const [contactsData] = useState(new CustomStore({
    key: 'id',
    load: () => getItems(),
    insert: (values) => addItem(values),
    update: (key, values) => modifyItem(key, values),
    remove: (key) => removeItem(key),
  }));

  return (
    <Card id="contacts" sx={{ overflow: 'visible' }}>
      <CardHeader
        title={t('contacts')}
      />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <DataGrid
              dataSource={contactsData}
              keyExpr="id"
              ref={dataGrid}
              allowColumnReordering
              allowColumnResizing
              columnAutoWidth
            >
              <Paging enabled={false} />
              <Editing
                allowUpdating
                allowAdding
                allowDeleting
              />
              <Column
                caption={t('name', { keyPrefix: 'common' })}
                dataField="name"
              />
              <Column
                caption={t('position')}
                dataField="position"
              />
              <Column
                caption={t('email', { keyPrefix: 'common' })}
                dataField="email"
              />
              <Column
                caption={t('phone')}
                dataField="phone"
              />
            </DataGrid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Contacts;
