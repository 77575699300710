import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Reducers
import { fetchTimezones } from 'reducers/commonSlice';
import { fetchAvailability, setAvailability } from 'reducers/availabilitiesSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Soft UI Dashboard PRO React components
// Soft UI Dashboard PRO React base styles
import breakpoints from 'assets/theme/base/breakpoints';

// Functions
import { findSelectValue } from 'Util';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import FormSelect from 'layouts/components/FormSelect';
import WeeklyHours from '../components/WeeklyHours';
import Overrides from '../components/Overrides';
import FormField from '../../components/FormField';

const selector = (state) => ({
  timezones: state.common.timezones,
  // currentUser: state.auth.user,
  availability: state.availability.availability,
});

function EditAvailability() {
  const {
    timezones,
    // currentUser,
    availability,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const { id } = useParams();

  const [tabsOrientation, setTabsOrientation] = useState('horizontal');
  const [view, setView] = useState(0);

  const [availabilityData, setAvailabilityData] = useState({
    timezone_id: 13,
    weekly: {
      sunday: {
        enable: false,
        availabilities: [],
      },
      monday: {
        enable: true,
        availabilities: [
          {
            id: 1,
            from: '09:00',
            to: '11:00',
          },
        ],
      },
      tuesday: {
        enable: false,
        availabilities: [],
      },
      wednesday: {
        enable: false,
        availabilities: [],
      },
      thursday: {
        enable: false,
        availabilities: [],
      },
      friday: {
        enable: false,
        availabilities: [],
      },
      saturday: {
        enable: false,
        availabilities: [],
      },
    },
    overrides: [
      {
        id: 1,
        date: '11/01/2022',
        from: '',
        to: '',
      },
      {
        id: 2,
        date: '11/02/2022',
        from: '13:00',
        to: '15:00',
      },
    ],
  });

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.md
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal');
    }

    window.addEventListener('resize', handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener('resize', handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetView = (e, newView) => setView(newView);
  const handleAddAvailability = (e, day) => {
    e.preventDefault();

    const week = availability.weekly;
    const weekday = week[day];

    dispatch(
      setAvailability({
        ...availability,
        weekly: {
          ...week,
          [day]: {
            ...weekday,
            availabilities: weekday.availabilities.concat({
              from: '',
              to: '',
            }),
          },
        },
        changed: true,
      }),
    );
  };
  const handleRemoveAvailability = (e, day, i) => {
    e.preventDefault();

    const week = availability.weekly;
    const weekday = week[day];

    dispatch(
      setAvailability({
        ...availability,
        weekly: {
          ...week,
          [day]: {
            ...weekday,
            availabilities: [
              ...weekday.availabilities.slice(0, i),
              ...weekday.availabilities.slice(i + 1),
            ],
          },
        },
        changed: true,
      }),
    );
  };
  const handleEnableDay = (e, day) => {
    e.preventDefault();

    const week = availability.weekly;
    const weekday = week[day];

    dispatch(
      setAvailability({
        ...availability,
        weekly: {
          ...week,
          [day]: {
            ...weekday,
            enable: !weekday.enable,
          },
        },
        changed: true,
      }),
    );
  };
  const handleAddOverride = (e, date) => {
    e.preventDefault();

    dispatch(
      setAvailability({
        ...availability,
        overrides: availability.overrides.concat(
          {
            date,
            from: null,
            to: null,
          },
        ),
        changed: true,
      }),
    );
  };
  const handleRemoveOverride = (e, i) => {
    e.preventDefault();

    const { overrides } = availability;

    if (overrides[i].id === undefined) {
      dispatch(
        setAvailability({
          ...availability,
          overrides: [
            ...overrides.slice(0, i),
            ...overrides.slice(i + 1),
          ],
        }),
      );
    } else {
      dispatch(
        setAvailability({
          ...availability,
          overrides: [
            ...overrides.slice(0, i),
            ...overrides.slice(i + 1),
          ],
          changed: true,
        }),
      );
    }
  };
  const setValue = (key, value) => {
    setAvailabilityData({
      ...availabilityData,
      [key]: value,
      changed: true,
    });
  };

  useEffect(() => {
    dispatch(fetchTimezones());
    dispatch(fetchAvailability({ id }));
  }, [dispatch, id]);

  return (
    <BaseLayout>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item xs={12} md={12}>
          <FormField
            label="Name"
            value={availability.name !== undefined ? availability.name : ''}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormSelect
            label="Time Zone"
            options={timezones}
            value={findSelectValue(timezones, availability.timezone_id)}
            onChange={(e) => {
              setValue('timezone_id', e.value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <AppBar position="static">
            <Tabs
              orientation={tabsOrientation}
              value={view}
              onChange={handleSetView}
            >
              <Tab label="List View" />
              <Tab label="Calendar View" />
            </Tabs>
          </AppBar>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12} md={8}>
          <WeeklyHours
            week={availability.weekly}
            addFunc={handleAddAvailability}
            removeFunc={handleRemoveAvailability}
            enableFunc={handleEnableDay}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container mt={2}>
            <Grid item xs={12} md={12}>
              <Overrides
                overrides={availability.overrides}
                addFunc={handleAddOverride}
                removeFunc={handleRemoveOverride}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BaseLayout>
  );
}

export default EditAvailability;
