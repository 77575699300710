import { useState } from 'react';

// @mui material components
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiInput from 'components/SuiInput';
import SuiButton from 'components/SuiButton';

// Soft UI Dashboard PRO React base styles
import colors from 'assets/theme/base/colors';
import borders from 'assets/theme/base/borders';
import PropTypes from 'prop-types';

function PaymentLink({ link }) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleCopyLink = () => {
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(link);
    setOpen(true);
  };

  const { secondary } = colors;
  const { borderWidth } = borders;

  return (
    <>
      <SuiBox lineHeight={1}>
        <SuiTypography variant="h6" fontWeight="medium">
          Payment Link
        </SuiTypography>
      </SuiBox>
      <SuiBox
        borderRadius="md"
        border={`${borderWidth[1]} dashed ${secondary.main}`}
        pt={2}
        pb={1.5}
        px={2}
        mt={2}
      >
        <SuiBox mb={1} lineHeight={0}>
          <SuiTypography variant="caption" color="text">
            Provide this link to pay this invoice
          </SuiTypography>
        </SuiBox>
        <SuiBox display="flex" alignItems="center" mb={2}>
          <SuiBox width="70%" mr={1}>
            <SuiInput
              size="small"
              defaultValue={link}
              icon={{ component: 'lock', direction: 'right' }}
              disabled
            />
          </SuiBox>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              title="Link copied!"
              placement="top"
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              PopperProps={{
                disablePortal: true,
              }}
            >
              <SuiButton
                variant="outlined"
                color="secondary"
                size="small"
                sx={{ padding: '0.5rem 1rem' }}
                onClick={handleCopyLink}
              >
                copy
              </SuiButton>
            </Tooltip>
          </ClickAwayListener>
        </SuiBox>
      </SuiBox>
    </>
  );
}

PaymentLink.propTypes = {
  link: PropTypes.string.isRequired,
};

export default PaymentLink;
