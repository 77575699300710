import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducers
import { fetchOrderTemplates } from 'reducers/orderTemplatesSlice';
import { fetchAccountBalance } from 'reducers/accountsSlice';
// eslint-disable-next-line object-curly-newline
import { fetchCandidateProfile, setProfile, setSaved, updateCandidateProfile } from 'reducers/candidatesSlice';
import { createOrder, setCreated } from 'reducers/ordersSlice';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { FormControlLabel, FormGroup } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

// Soft UI Dashboard PRO React components
import SuiButton from 'components/SuiButton';

// Components
import FormSelect from 'layouts/components/FormSelect';
import FormField from 'layouts/components/FormField';

// Functions
// eslint-disable-next-line object-curly-newline
import { checkPermission, defaultValue, findSelectValue, getCandidateUUID } from 'Util';
import Swal from 'sweetalert2';

const selector = (state) => ({
  currentUser: state.auth.user,
  editing: state.candidate.editing,
  profile: state.candidate.profile,
  orderTemplates: state.orderTemplate.orderTemplates,
  saved: state.candidate.saved,
  created: state.order.created,
});

function PaymentPlanSelector() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.billing' });
  const {
    currentUser,
    editing,
    profile,
    orderTemplates,
    saved,
    created,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    dispatch(fetchOrderTemplates());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateProfile({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  const [plans, setPlans] = useState([]);
  const [deferredPayment, setDeferredPayment] = useState(false);
  const [deferredAmount, setDeferredAmount] = useState('');

  useEffect(() => {
    if (orderTemplates !== undefined) {
      setPlans(orderTemplates.filter((p) => p.template_type === 1));
    }
  }, [orderTemplates]);

  useEffect(() => {
    if (profile !== undefined) {
      setDeferredPayment(profile.deferred_payment);
    }
  }, [profile]);

  const invoicingActionCreator = (order, candidateProfile) => {
    dispatch(createOrder(order));
    dispatch(updateCandidateProfile(candidateProfile));
  };

  const handleChangePlan = (value) => {
    dispatch(
      setProfile(
        {
          ...profile,
          preferred_payment_plan: value,
          changed: true,
        },
      ),
    );
  };

  const handleSelectPlan = () => {
    if (profile.preferred_payment_plan !== 0) {
      if (profile.changed) {
        const data = {
          uuid,
          preferredPaymentPlan: profile.preferred_payment_plan,
          deferredPayment,
          deferredAmount,
        };

        dispatch(updateCandidateProfile(data));
      }
    }
  };

  const handleInvoicePlan = () => {
    if (profile.preferred_payment_plan !== 0) {
      const template = orderTemplates.find((tpl) => tpl.id === profile.preferred_payment_plan);

      const date = new Date();

      const currentDay = String(date.getDate()).padStart(2, '0');
      const currentMonth = String(date.getMonth() + 1).padStart(2, '0');
      const currentYear = date.getFullYear();
      const currentDate = `${currentDay}-${currentMonth}-${currentYear}`;

      const items = [];

      template.order_template_items.forEach((item, index) => {
        items[index] = {
          service_id: item.service_id,
          quantity: item.quantity,
          cost: item.cost,
          group: item.group,
        };
      });

      invoicingActionCreator(
        {
          candidateId: uuid,
          issue: currentDate,
          paymentPlanId: template.payment_plan_id,
          orderItems: items,
        },
        {
          uuid,
          paymentPlanBilled: true,
        },
      );
    }
  };

  const handleDeferredPayment = () => {
    setDeferredPayment(!deferredPayment);
    dispatch(
      setProfile(
        {
          ...profile,
          deferred_payment: !deferredPayment,
          changed: true,
        },
      ),
    );
  };

  const handleDeferredAmount = (value) => {
    setDeferredAmount(value);
    dispatch(
      setProfile(
        {
          ...profile,
          deferred_amount: value,
          changed: true,
        },
      ),
    );
  };

  if (saved) {
    dispatch(setSaved(false));
    if (editing && uuid !== undefined) {
      dispatch(fetchAccountBalance({ id: uuid }));
    }

    Swal.fire({
      title: `${t('success', { keyPrefix: 'candidates.save' })}!`,
      text: t('candidate-saved-successfully', { keyPrefix: 'candidates.save' }),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'candidates.save' }),
    });
  }

  if (created) {
    if (editing && uuid !== undefined) {
      dispatch(fetchAccountBalance({ id: uuid }));
    }
    dispatch(setCreated(false));
  }

  return (
    <div>
      {(!profile.payment_plan_billed) && (
        <Card id="plan-selector" sx={{ overflow: 'visible' }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            m={2}
          >
            <Grid item xs={12} md={4} pt={3}>
              <FormSelect
                label={t('payment-plan')}
                options={plans}
                value={findSelectValue(plans, profile.preferred_payment_plan)}
                onChange={(e) => {
                  handleChangePlan(e.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={1} />
            <Grid item xs={12} md={1} pt={2}>
              <FormGroup>
                <FormControlLabel
                  control={(
                    <Checkbox
                      onChange={handleDeferredPayment}
                      checked={deferredPayment}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                    />
                  )}
                  label={t('deferred-payment')}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={2} mt={3}>
              <FormField
                label={t('deferred-amount')}
                onChange={(e) => {
                  handleDeferredAmount(e.target.value);
                }}
                value={defaultValue(profile.deferred_amount)}
              />
            </Grid>
            <Grid item xs={12} md={1} />
            <Grid item xs={12} md={1} mt={3}>
              {(!profile.payment_plan_billed) && (
                <SuiButton
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={handleSelectPlan}
                >
                  {t('save', { keyPrefix: 'common' })}
                </SuiButton>
              )}
            </Grid>
            <Grid item xs={12} md={2} mt={3}>
              {(!profile.payment_plan_billed
                  && profile.preferred_payment_plan !== 0
                  && profile.changed === undefined
                  && checkPermission('CBPI', currentUser))
                && (
                  <SuiButton
                    variant="outlined"
                    color="info"
                    size="small"
                    onClick={handleInvoicePlan}
                  >
                    {t('invoice-plan')}
                  </SuiButton>
                )}
            </Grid>
          </Grid>
        </Card>
      )}
    </div>
  );
}

export default PaymentPlanSelector;
