import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Reducer functions
import {
  clearRole,
  createRole,
  deleteRole,
  setCreated,
  setDeleted,
  setFailed,
  setSaved,
  updateRole,
} from 'reducers/roleSlice';

// Soft UI Dashboard PRO React components
import SuiButton from 'components/SuiButton';
import SuiBox from 'components/SuiBox';

// Functions
import Swal from 'sweetalert2';
import { errorsToString } from 'Util';

const selector = (state) => ({
  roleInfo: state.role.roleInfo,
  editing: state.role.editing,
  deleted: state.role.deleted,
  created: state.role.created,
  saved: state.role.saved,
  updated: state.role.updated,
  failed: state.role.failed,
  errors: state.role.errors,
});

function SavePanel() {
  const { t } = useTranslation('translation', { keyPrefix: 'roles' });

  const {
    roleInfo,
    editing,
    deleted,
    created,
    saved,
    failed,
    errors,
  } = useSelector(
    selector,
    shallowEqual,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  if (created === true) {
    dispatch(setCreated(false));
    dispatch(clearRole());

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('role-created-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      navigate('/roles', { replace: true });
    });
  }
  if (saved === true) {
    dispatch(setSaved(false));
    dispatch(clearRole());

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('role-saved-successfully'),
      icon: 'success',
    }).then(() => {
      navigate('/roles', { replace: true });
    });
  }
  if (deleted === true) {
    dispatch(setDeleted(false));
    dispatch(clearRole({}));

    Swal.fire({
      title: `${t('deleted', { keyPrefix: 'common' })}!`,
      text: t('role-deleted-successfully'),
      icon: 'success',
    }).then(() => {
      navigate('/roles', { replace: true });
    });
  }
  if (failed) {
    dispatch(setFailed(false));

    Swal.fire({
      title: t('error', { keyPrefix: 'common' }),
      text: errorsToString(errors),
      icon: 'error',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    });
  }

  const handleSave = () => {
    if (roleInfo.name === undefined) {
      Swal.fire({
        text: t('role-name-can-be-empty'),
        icon: 'error',
        confirmButtonText: t('close', { keyPrefix: 'common' }),
      });
    } else {
      const data = {
        name: roleInfo.name,
        allUsers: roleInfo.all_users,
        dashboardId: roleInfo.dashboard_id,
        roleTypeId: roleInfo.role_type_id,
        rolePermissions: roleInfo.role_permissions.map(({ permission, ...keepAttrs }) => keepAttrs),
        statuses: roleInfo.statuses,
      };

      if (editing === true) {
        data.id = id;
        dispatch(updateRole(data));
      } else {
        dispatch(createRole(data));
      }
    }
  };

  const handleDelete = () => {
    Swal.fire({
      text: t('are-you-sure-you-want-to-delete', {
        name: `${roleInfo.name}`,
        keyPrefix: 'common',
      }),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('delete', { keyPrefix: 'common' }),
    }).then((result) => {
      if (result.isConfirmed) {
        const data = { id };
        dispatch(deleteRole(data));
      }
    });
  };

  return (
    <SuiBox mt={5} mb={3}>
      <SuiBox display="flex" justifyContent="flex-end" mt={6}>
        {editing && (
          <SuiBox mr={1}>
            <SuiButton
              variant="gradient"
              color="error"
              size="small"
              onClick={handleDelete}
            >
              {t('delete', { keyPrefix: 'common' })}
            </SuiButton>
          </SuiBox>
        )}
        <SuiBox mr={1}>
          <SuiButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleSave}
          >
            {t('save', { keyPrefix: 'common' })}
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default SavePanel;
