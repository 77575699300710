import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

function TermsUse() {
  return (
    <SuiBox p={3} mb={1}>
      <SuiBox p={3} mb={1} textAlign="center">
        <SuiTypography variant="h5" fontWeight="medium">
          Terms of Use
        </SuiTypography>
      </SuiBox>
      <SuiBox p={3} mb={1} textAlign="justify">
        <SuiTypography variant="body1">
          <p>
            The Nash Group., And all subsidiaries or affiliated brands and
            {' '}
            <a
              data-auto-recognition="true"
              href="https://www.nursing-advisory.com/"
              target="_blank"
              rel="noreferrer"
            >
              nursing-advisory.com
            </a>
            {' '}
            (hereinafter &quot;Nash&quot;, &quot;We&quot; or &quot;Our&quot;)
            endorse all of our products; Your satisfaction as a customer is very
            important to us.
          </p>

          <p>&nbsp;</p>

          <p>
            All purchases made (including all types of product through our
            websites, sales made by telephone and / or the purchase of physical
            products on the live events site) are covered by the following
            policies.
          </p>

          <p>&nbsp;</p>

          <p>
            <SuiTypography fontWeight="bold">General:</SuiTypography>
          </p>

          <p>
            Offers / coupons cannot be used together unless otherwise stated.
            Coupons are only valid during the advertised promotion period.
            Prices and offers are subject to change without notice.
          </p>

          <p>&nbsp;</p>

          <p>
            <SuiTypography fontWeight="bold">Privacy Policy:</SuiTypography>
          </p>

          <p>
            Please review our Privacy Policy, which also governs your visit to
            our website and any purchases made on our website.
          </p>

          <p>&nbsp;</p>

          <p>
            <SuiTypography fontWeight="bold">
              No guarantee of results:
            </SuiTypography>
          </p>

          <p>
            We do not guarantee results or offer legal advice. Professional
            entrepreneurship involves risk and hard work. Always consult with
            your appropriate professionals before making any decisions. The
            information provided by Nash during conferences, written material,
            or personalized consultations are a reference guide based on
            previous experiences and not a guarantee of success. The information
            varies and depends on governmental and non-governmental tertiary
            entities. Nash and its affiliates make a great effort to be updated
            with the required guidelines in order to offer as much reliable
            information as possible. Any testimonial showing our success or the
            success of our participants should not be construed as common,
            typical or expected. It takes education, determination, and hard
            work to reach goals.
          </p>

          <p>&nbsp;</p>

          <p>
            <br />
            <SuiTypography fontWeight="bold">
              Digital download products / No refund or cancellation.
            </SuiTypography>
          </p>

          <p>
            There are no refunds on any digital product. You will receive access
            to the information for the time indicated in the description of the
            product you purchased. There are no refunds regardless of product
            use. The purchase of any of our digital products does not grant the
            buyer rights to share, reproduce or resell the product in any way.
            All digital and downloadable product sales are final. We do not
            offer refunds for digital products. Once a digital download / access
            has been assigned to you, there is nothing we can retrieve. If you
            have any problems accessing the digital content you have purchased,
            please contact our Customer Service Department immediately at +1 224
            632 1629 from 9 am to 5 pm CST (USA), Monday through Friday or call
            contact us by email at
            {' '}
            <a href="mailto:contacto@nursing-advisory.com" target="_self">
              contacto@nursing-advisory.com
            </a>
            {' '}
            so we can resolve the issue.
          </p>

          <p>&nbsp;</p>

          <p>
            Please note that each and every downloadable / digital product
            agreement offered by The Nash Group is NOT subject to cancellation,
            refund or credit. We do not accept verbal modifications to these
            agreements and the &quot;no refund or cancellation&quot; policy
            established in the agreements is strictly adhered to. After the full
            term of the agreement has expired, you can request to cancel the
            automatic renewal of the agreement. Any cancellation submitted
            before the full term of the agreement will only cancel the automatic
            renewal but your monthly invoices will remain due until the full
            value of the agreement has been paid in full. ALL CONSTRUCTED AND
            ESTABLISHED RATES ARE NON-REFUNDABLE. By signing the Agreement, you
            agree to all terms of use and these terms of purchase and refund
            policy. Including that you agree not to charge, sell, or duplicate
            any of our services provided or digital products that have been
            delivered to you. You also agree and acknowledge that you are paying
            in monthly or annual installments on the full value of your contract
            as a payment plan (if applicable).
          </p>

          <p>&nbsp;</p>

          <p>
            Canceling or unlinking the registered credit card does not cancel
            your subscription and violates the terms of the agreement. If at any
            time you have past due invoices after 60 days, if the account is not
            paid in arrears for two consecutive payments, your account will be
            suspended and you will remain responsible for the full value of the
            contract for the term of the contract. If you have any problems
            accessing the digital content you have purchased, please contact our
            Customer Service Department immediately at +1 224 632 1629 from 9 am
            to 5 pm CST (USA), Monday through Friday, to that we can solve the
            problem. Again, purchases of our physical or digital products do not
            grant the buyer rights to share, reproduce or resell the product in
            any way.
          </p>

          <p>&nbsp;</p>

          <p>
            <SuiTypography fontWeight="bold">
              Gift certificates and coupon codes:
            </SuiTypography>
          </p>

          <p>
            If you have received a coupon code for any of our products through
            The Nash Group or from one of its affiliates, the coupon code is
            valid for a single use and no greater than the value of the
            corresponding code. All physical items purchased are governed by the
            refund policy agreement just like digital products; non-refundable
            and not eligible for exchange or future credit.
          </p>

          <p>&nbsp;</p>

          <p>
            <SuiTypography fontWeight="bold">
              Collection of photos and videos:
            </SuiTypography>
          </p>

          <p>
            Please note that when attending any The Nash Group event there is
            the possibility that videos or photographs may be taken. You grant
            irrevocable permission to The Nash Group and all affiliated
            companies to use your image in a photograph, video, or other digital
            medium (&quot;photo&quot;) in any and all of your postings,
            including web-based postings, without payment or other
            consideration. By accepting these conditions you waive any right to
            inspect or approve the finished product in which your image appears.
            Additionally, you waive any rights to royalties or other
            compensation arising out of or related to the use of such material.
            Your actual testimonial will not be edited. You acknowledge that
            there will be no compensation for your testimony. You understand and
            agree that all photos and videos will become the property of The
            Nash Group and all affiliated companies and will not be returned.
            You hereby release, release and forever release The Nash Group and
            all affiliated companies from all claims, demands and causes of
            action that you, your heirs, representatives, administrators or any
            other person acting on your behalf or on name of your estate has or
            may have by reason of this authorization. If your testimonial is cut
            or not used, you agree that this was at the artistic discretion of
            The Nash Group., And all affiliated companies and that you will not
            take legal action. You also agree that any claim will be arbitrated
            through the American Arbitration Association and that jurisdiction
            for all claims will be in Chicago, Illinois. You hereby RELEASE,
            WAIVE and RELEASE FOREVER any and all claims arising out of, or in
            connection with, such use by The Nash Group and all affiliated
            companies, including, but not limited to, all defamation claims or
            invasion or privacy. You agree that you have read the above
            communication and fully agree with its content. This communication
            contains the entire agreement between the parties hereto and
            supersedes any other agreements that may exist.
          </p>

          <p>&nbsp;</p>

          <p>
            <SuiTypography fontWeight="bold">
              Tickets for live events:
            </SuiTypography>
          </p>

          <p>
            To participate in any live event, you must have your own valid
            ticket. One ticket per participant is required and will only be
            valid for the day (s) of the event and area (s) of the event as
            indicated on the ticket. Entrance to the Event is limited to people
            who meet the minimum age requirements on the day of the event. To
            participate in a live event, you must be eighteen (18) years of age
            or older, unless otherwise specified for a particular event. No one
            is allowed in without a ticket. This means that infants or children
            under eighteen (18) years of age are not allowed to enter the event,
            unless otherwise specified for a particular event or previously
            approved by The Nash Group. Therefore, if you are accompanied by a
            person under 18 years of age, you will be denied access to the
            event, unless otherwise specified and with a valid ticket for each
            person who attends.
          </p>

          <p>&nbsp;</p>

          <p>
            Each person must have a valid form of approved government
            identification to enter the event that proves their age. No
            exceptions will be made, even if accompanied by an adult or person
            with valid identification. Failure to provide valid identification
            will prevent you from participating in the event and you will not be
            entitled to any refund and The Nash Group. Nor will any of its
            affiliates be liable for, any other incidental and / or
            consequential damages. In addition, during the event, you must
            provide valid identification in case a security member requests it.
            If you cannot show valid identification, you may be immediately
            kicked out of the event and will not receive a refund of your
            ticket. The following original forms of identification are
            ACCEPTABLE and your name must appear on the ticket exactly as it
            appears on your identification:
          </p>

          <p>&nbsp;</p>

          <p>&bull; Passport with photo issued by the government.</p>

          <p>&bull; Government driver&#39;s license with photo.</p>

          <p>&bull; Government issued photo identification card.</p>

          <p>&nbsp;</p>

          <p>
            Other forms of identification will not be accepted unless otherwise
            specified.
          </p>

          <p>&nbsp;</p>

          <p>
            During the event, ticket holders must show state or federal
            identification or a passport, and it must match the name of the
            ticket holder.
          </p>

          <p>&nbsp;</p>

          <p>
            If for any reason you are unable to attend an event, within the 7
            days prior to the event, you may request a CONSIDERATION for a
            credit. The credit will be evaluated and if it is approved, it will
            be done within 90 days of the credit approval day. 100% credit or
            refund is not guaranteed. The Nash Group. reserves the right to
            reject any request for credit consideration at any time.
          </p>

          <p>&nbsp;</p>

          <p>
            All credit requests must be sent to
            {' '}
            <a href="mailto:contacto@nursing-advisory.com" target="_self">
              contacto@nursing-advisory.com
            </a>
            {' '}
            at least 7 business days before the event. Please note that ticket
            purchase processing fees are non-refundable and are not eligible for
            any type of credit. If your ticket includes the purchase of a hotel
            room, the hotel room is not refundable or transferable to another
            event. Any request for credit or to transfer the ticket to another
            event made less than 7 days prior to the event will be denied in
            accordance with this policy.
          </p>

          <p>&nbsp;</p>

          <p>
            Any request made less than 7 days before the event will be denied.
            In the event of canceling and wanting to use the credit for another
            event, there will be a one-time exchange fee of 25% of the ticket
            price or $ 50.00, whichever is less if the credit request is
            approved for another event.
          </p>

          <p>&nbsp;</p>

          <p>
            If your ticket purchase comes with a free t-shirt, gift, study
            material, or any other gift as a promotional gift, please note that
            there are no refunds, exchanges, or replacements for the ticket or
            gift. All gifts with the purchase of tickets are subject to
            availability and until the end of supplies. If we are out of
            merchandise, no monetary value will be attributed to any promotional
            item.
          </p>

          <p>&nbsp;</p>

          <p>
            If you do not attend the event and request a credit AFTER the event
            date, the credit will be denied. All credit requests must be made in
            writing and must be made 7 days prior to the event date.
          </p>

          <p>&nbsp;</p>

          <p>
            The Nash Group., Reserves the right to refund tickets, deny access
            or deny entry to any live or virtual event if we consider it in the
            best interest for the safety of the public, speakers, employees and
            / or participants .
          </p>

          <p>&nbsp;</p>

          <p>
            Tickets for any type of event are non-transferable. Once you have
            redeemed the ticket and / or booked for the event, they cannot be
            canceled or changed to any other event at a future date unless The
            Nash Group. approve it.
          </p>

          <p>&nbsp;</p>

          <p>
            If any of our events are postponed or canceled due to an overcoming
            temporary event that arises through no fault of their own, tickets
            purchased for that live event will automatically carry over to the
            next available date for another event. Supervening events are
            defined as acts of God; governmental requests provoked by political
            motives or not; changes of law, including the elimination of laws
            and executive orders; strikes; labor disputes; riots, rebellion and
            war; local government closures due to weather; Government shutdowns
            due to widespread disease, pandemics, and any other reason for the
            safety and health of the community at large. If a live event cannot
            be rescheduled within 90 days, the event will be transformed into a
            virtual format. In the case of The Nash Group make a refund to a
            ticket holder for the purchase of said ticket, The Nash Group, DOES
            NOT GUARANTEE 100% of the refund since taxes, transfer charges or
            administrative charges may apply. The Nash Group reserves the right
            to make any type of refund in case an event is canceled; Either due
            to a supervening temporary event or any other type of event that
            warrants the cancellation of the event based purely on the sole
            criticism of The Nash Group.
          </p>

          <p>&nbsp;</p>

          <p>
            <SuiTypography fontWeight="bold">
              You are subject to being searched yourself and your belongings:
            </SuiTypography>
          </p>

          <p>
            You consent to such searches and waive any related claim that may
            arise. If you choose not to consent to such searches, you may be
            denied entry to the event without refund or other compensation.
            Under certain facility rules, certain items cannot be brought into
            the facility, such as, but not limited to, firearms, alcohol, drugs,
            and narcotic substances.
          </p>

          <p>&nbsp;</p>

          <p>
            <SuiTypography fontWeight="bold">
              Illegal reselling of tickets:
            </SuiTypography>
          </p>

          <p>
            The illegal resale (or attempted resale) of any ticket is grounds
            for seizure and cancellation and the issuance of any type of credit.
            We reserve the right to restrict or deny ticket purchase privileges
            to anyone we determine is violating or has violated our policies.
            Because we do not guarantee the authenticity of tickets purchased
            from any unauthorized third-party reseller, we recommend that you
            purchase tickets directly through us. Tickets may not be used for
            advertising, promotions, contests, or sweepstakes, unless we grant
            formal written authorization.
          </p>

          <p>&nbsp;</p>

          <p>
            <SuiTypography fontWeight="bold">Advertising:</SuiTypography>
          </p>

          <p>
            You agree that the event for which you purchase tickets is a public
            event, that your appearance and actions in and out of the venue are
            public in nature, and that you have no expectations of privacy
            regarding your actions or conduct at the event. . You grant us,
            event provider (s), our affiliates / partners including but not
            limited to our brand and media partners, permission to use your
            name, likeness, likeness, acts, poses, appearance, movements, and
            statements in any live or recorded audio, video or photography
            presentation or other transmission, display, publication or
            reproduction made of, or at, the event (regardless of whether
            before, during or after the reproduction or performance) for any
            purpose, in any way, in any medium or context now known or developed
            in the future, without additional authorization or compensation to
            you or anyone acting on your behalf.
          </p>

          <p>&nbsp;</p>

          <p>
            By attending any live or virtual event, you irrevocably grant The
            Nash Group., Affiliates, appointees, successors, assignees and
            licensees, the right to film and record you and use your name, image
            and likeness in each and every one of the means for any purpose,
            including, without limitation, for advertising and promotional
            purposes, as well as in connection with future events of The Nash
            Group and / or other events produced by The Nash Group or any of the
            affiliates of The Nash Group ; and we hereby release The Nash Group
            and each of the respective appointees, affiliates, successors,
            assignees and licensees from any liability in this regard. In
            addition, there will be photographs and video clips taken throughout
            the event by The Nash Group and any other external participants,
            such as speakers and sponsors. Therefore, the purchase of any ticket
            to a live or virtual event is made with the understanding that it
            also contains a WAIVER AND RELEASE, and that you agree to the
            following:
          </p>

          <p>&nbsp;</p>

          <p>
            I give IRREVOCABLE permission to Nash, And to all affiliated
            companies, to use my image in a photograph, video or other digital
            medium (&quot;photo&quot;) in each and every one of its
            publications, including publications based on the web, without
            payment or other consideration. I waive any right to inspect or
            approve the finished product in which my image appears. Furthermore,
            I waive any rights to royalties or other compensation arising out of
            or related to the use of the photo. My actual testimonial will not
            be edited. I acknowledge that there will be no compensation for my
            testimony. I understand and agree that all photos and videos will
            become the property of The Nash Group., And all affiliated companies
            and will not be returned. I hereby release, release and forever
            release Nash and all affiliated companies from all claims, demands
            and causes of action that I, my heirs, representatives, executors,
            administrators or any other person acting on my behalf or in my name
            on behalf of my estate have or may have by reason of this
            authorization. If my testimonial is cut off or not used, I agree
            that this was at the artistic discretion of The Nash Group., And all
            affiliated companies or their affiliates and that I will not take
            legal action. I also agree that any claim will be arbitrated through
            the American Arbitration Association and that the jurisdiction for
            all claims is Miami, Florida. I hereby RELEASE, WAIVE and RELEASE
            FOREVER any and all claims arising out of, or in connection with,
            such use by The Nash Group and all affiliated companies, including,
            without limitation, each and every one of the claims for defamation
            or invasion or privacy. I have read the above statement and am
            completely familiar with its content. This communication contains
            the entire agreement between the parties hereto and supersedes any
            other agreements that may exist.
          </p>

          <p>&nbsp;</p>

          <p>
            <SuiTypography fontWeight="bold">
              WE DO NOT ALLOW ANY VIDEO RECORDING OF OUR EVENTS FOR BROADCASTING
              OR COMMERCIAL USE.
            </SuiTypography>
          </p>

          <p>&nbsp;</p>

          <p>
            Be sure to return to these terms, conditions, and policies
            periodically to review the most current version. We reserve the
            right at any time, at our sole discretion, to change or modify these
            terms, conditions and policies without prior notice; however, the
            date of any effective changes will be reflected at the bottom of
            this page and, upon request, we will provide you with information on
            any changes made.
          </p>

          <p>&nbsp;</p>

          <p>
            By clicking, you agree to the terms of The Nash Group. Refund
            policy, all terms, conditions and privacy policy.
          </p>

          <p>&nbsp;</p>
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
}

export default TermsUse;
