// @mui material components
import Grid from '@mui/material/Grid';

import 'assets/styles/spinner.css';

export default function LoadingSpinner() {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item md={12}>
        <div className="spinner-container">
          <div className="loading-spinner" />
        </div>
      </Grid>
    </Grid>
  );
}
