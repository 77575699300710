import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  cancelInvoice,
  clearInvoice, createInvoice,
  setCreated,
  setDeleted,
  setFailed,
  setSaved, updateInvoice,
} from 'reducers/invoicesSlice';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';

// Function
import Swal from 'sweetalert2';
import { errorsToString, MissingDataWarning, NoChangesWarning } from 'Util';

const selector = (state) => ({
  invoiceInfo: state.invoice.invoiceInfo,
  editing: state.invoice.editing,
  deleted: state.invoice.deleted,
  created: state.invoice.created,
  saved: state.invoice.saved,
  failed: state.invoice.failed,
  errors: state.invoice.errors,
});

function SavePanel() {
  const { t } = useTranslation('translation', { keyPrefix: 'invoices' });

  const {
    invoiceInfo,
    editing,
    deleted,
    created,
    saved,
    failed,
    errors,
  } = useSelector(selector, shallowEqual);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  if (created === true) {
    dispatch(setCreated(false));
    dispatch(clearInvoice());

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('invoice-created-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      navigate('/billing/invoices', { replace: true });
    });
  }

  if (saved === true) {
    dispatch(setSaved(false));
    dispatch(clearInvoice());

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('invoice-saved-successfully'),
      icon: 'success',
    }).then(() => {
      navigate('/billing/invoices', { replace: true });
    });
  }

  if (deleted === true) {
    dispatch(setDeleted(false));
    dispatch(clearInvoice({}));

    Swal.fire({
      title: `${t('deleted', { keyPrefix: 'common' })}!`,
      text: t('invoice-canceled-successfully'),
      icon: 'success',
    }).then(() => {
      navigate('/billing/invoices', { replace: true });
    });
  }

  if (failed) {
    dispatch(setFailed(false));

    Swal.fire({
      title: t('error', { keyPrefix: 'common' }),
      text: errorsToString(errors),
      icon: 'error',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    });
  }

  const handleSave = () => {
    if (invoiceInfo.changed === true) {
      if (invoiceInfo.candidate_id !== undefined && invoiceInfo.candidate_id !== ''
        && invoiceInfo.invoice_items !== undefined && invoiceInfo.invoice_items !== '') {
        const data = {
          candidateId: invoiceInfo.candidate_id,
          date: invoiceInfo.date,
          due: invoiceInfo.due,
          invoiceItems: invoiceInfo.invoice_items,
        };

        if (editing === true) {
          data.id = id;

          dispatch(updateInvoice(data));
        } else {
          dispatch(createInvoice(data));
        }
      } else {
        MissingDataWarning(t);
      }
    } else {
      NoChangesWarning(t);
    }
  };

  const handleCancel = () => {
    Swal.fire({
      text: t('are-you-sure-you-want-to-void-invoice', {
        num: `${invoiceInfo.invoice_number}`,
      }),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('delete', { keyPrefix: 'common' }),
    }).then((result) => {
      if (result.isConfirmed) {
        const data = { id };
        dispatch(cancelInvoice(data));
      }
    });
  };

  return (
    <SuiBox mt={5} mb={3}>
      <SuiBox display="flex" justifyContent="flex-end" mt={6}>
        {editing && (
          <SuiBox mr={1}>
            <SuiButton
              variant="gradient"
              color="error"
              size="small"
              onClick={handleCancel}
            >
              {t('cancel', { keyPrefix: 'common' })}
            </SuiButton>
          </SuiBox>
        )}
        <SuiBox mr={1}>
          <SuiButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleSave}
          >
            {t('save', { keyPrefix: 'common' })}
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default SavePanel;
