import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { setImportData } from 'reducers/candidatesSlice';

// @mui material components
import { DropzoneDialog } from 'material-ui-dropzone';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiButton from 'components/SuiButton';

// Other
import * as Excel from 'exceljs';

const selector = (state) => ({
  importData: state.candidate.importData,
});

function LoadFile() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.import' });
  const { importData } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const dateToString = (d) => {
    if (d instanceof Date) {
      const dt = d;
      dt.setHours(dt.getHours() + 6);

      let month = `${dt.getMonth() + 1}`;
      let day = `${dt.getDate()}`;
      const year = dt.getFullYear();

      if (month.length < 2) {
        month = `0${month}`;
      }
      if (day.length < 2) {
        day = `0${day}`;
      }

      return [year, month, day].join('-');
    }
    return d;
  };
  const handleUpload = (files) => {
    setOpen(false);
    const workbook = new Excel.Workbook();
    const reader = new FileReader();

    reader.readAsArrayBuffer(files[0]);

    reader.onload = () => {
      const buffer = reader.result;
      workbook.xlsx.load(buffer).then((wb) => {
        const sheet = wb.worksheets[0];
        const fileData = [];
        sheet.eachRow((row, rowIndex) => {
          if (rowIndex !== 1) {
            fileData.push({
              first_name: row.values[1],
              middle_name: row.values[2],
              last_name: row.values[3],
              preferred_name: row.values[4],
              date_of_birth: dateToString(row.values[5]),
              social_security: row.values[6],
              native_language: row.values[7],
              country_of_origin: row.values[8],
              country_of_residence: row.values[9],
              citizenship: row.values[10],
              contact_method: row.values[11],
              email1: row.values[12],
              email2: row.values[13],
              email3: row.values[14],
              phone1: row.values[15],
              phone2: row.values[16],
              phone3: row.values[17],
              address: row.values[18],
              city: row.values[19],
              state: row.values[20],
              country: row.values[21],
              postal_code: row.values[22],
              weight: row.values[23],
              height: row.values[24],
              hair_color: row.values[25],
              eye_color: row.values[26],
              race: row.values[27],
              covid_vaccine: row.values[28],
              university_name1: row.values[29],
              university_address1: row.values[30],
              university_city1: row.values[31],
              university_state1: row.values[32],
              university_country1: row.values[33],
              university_postal_code1: row.values[34],
              university_entered_date1: dateToString(row.values[35]),
              university_graduation_date1: dateToString(row.values[36]),
              university_name2: row.values[37],
              university_address2: row.values[38],
              university_city2: row.values[39],
              university_state2: row.values[40],
              university_country2: row.values[41],
              university_postal_code2: row.values[42],
              university_entered_date2: dateToString(row.values[43]),
              university_graduation_date2: dateToString(row.values[44]),
              university_name3: row.values[45],
              university_address3: row.values[46],
              university_city3: row.values[47],
              university_state3: row.values[48],
              university_country3: row.values[49],
              university_postal_code3: row.values[50],
              university_entered_date3: dateToString(row.values[51]),
              university_graduation_date3: dateToString(row.values[52]),
              university_name4: row.values[53],
              university_address4: row.values[54],
              university_city4: row.values[55],
              university_state4: row.values[56],
              university_country4: row.values[57],
              university_postal_code4: row.values[58],
              university_entered_date4: dateToString(row.values[59]),
              university_graduation_date4: dateToString(row.values[60]),
              elementary_name1: row.values[61],
              elementary_address1: row.values[62],
              elementary_city1: row.values[63],
              elementary_state1: row.values[64],
              elementary_country1: row.values[65],
              elementary_postal_code1: row.values[66],
              elementary_entered_date1: dateToString(row.values[67]),
              elementary_graduation_date1: dateToString(row.values[68]),
              elementary_name2: row.values[69],
              elementary_address2: row.values[70],
              elementary_city2: row.values[71],
              elementary_state2: row.values[72],
              elementary_country2: row.values[73],
              elementary_postal_code2: row.values[74],
              elementary_entered_date2: dateToString(row.values[75]),
              elementary_graduation_date2: dateToString(row.values[76]),
              elementary_name3: row.values[77],
              elementary_address3: row.values[78],
              elementary_city3: row.values[79],
              elementary_state3: row.values[80],
              elementary_country3: row.values[81],
              elementary_postal_code3: row.values[82],
              elementary_entered_date3: dateToString(row.values[83]),
              elementary_graduation_date3: dateToString(row.values[84]),
              elementary_name4: row.values[85],
              elementary_address4: row.values[86],
              elementary_city4: row.values[87],
              elementary_state4: row.values[88],
              elementary_country4: row.values[89],
              elementary_postal_code4: row.values[90],
              elementary_entered_date4: dateToString(row.values[91]),
              elementary_graduation_date4: dateToString(row.values[92]),
              middle_school_name1: row.values[93],
              middle_school_address1: row.values[94],
              middle_school_city1: row.values[95],
              middle_school_state1: row.values[96],
              middle_school_country1: row.values[97],
              middle_school_postal_code1: row.values[98],
              middle_school_entered_date1: dateToString(row.values[99]),
              middle_school_graduation_date1: dateToString(row.values[100]),
              middle_school_name2: row.values[101],
              middle_school_address2: row.values[102],
              middle_school_city2: row.values[103],
              middle_school_state2: row.values[104],
              middle_school_country2: row.values[105],
              middle_school_postal_code2: row.values[106],
              middle_school_entered_date2: dateToString(row.values[107]),
              middle_school_graduation_date2: dateToString(row.values[108]),
              middle_school_name3: row.values[109],
              middle_school_address3: row.values[110],
              middle_school_city3: row.values[111],
              middle_school_state3: row.values[112],
              middle_school_country3: row.values[113],
              middle_school_postal_code3: row.values[114],
              middle_school_entered_date3: dateToString(row.values[115]),
              middle_school_graduation_date3: dateToString(row.values[116]),
              middle_school_name4: row.values[117],
              middle_school_address4: row.values[118],
              middle_school_city4: row.values[119],
              middle_school_state4: row.values[120],
              middle_school_country4: row.values[121],
              middle_school_postal_code4: row.values[122],
              middle_school_entered_date4: dateToString(row.values[123]),
              middle_school_graduation_date4: dateToString(row.values[124]),
              middle_school_diploma_obtained: row.values[125],
              high_school_name1: row.values[126],
              high_school_address1: row.values[127],
              high_school_city1: row.values[128],
              high_school_state1: row.values[129],
              high_school_country1: row.values[130],
              high_school_postal_code1: row.values[131],
              high_school_entered_date1: dateToString(row.values[132]),
              high_school_graduation_date1: dateToString(row.values[133]),
              high_school_name2: row.values[134],
              high_school_address2: row.values[135],
              high_school_city2: row.values[136],
              high_school_state2: row.values[137],
              high_school_country2: row.values[138],
              high_school_postal_code2: row.values[139],
              high_school_entered_date2: dateToString(row.values[140]),
              high_school_graduation_date2: dateToString(row.values[141]),
              high_school_name3: row.values[142],
              high_school_address3: row.values[143],
              high_school_city3: row.values[144],
              high_school_state3: row.values[145],
              high_school_country3: row.values[146],
              high_school_postal_code3: row.values[147],
              high_school_entered_date3: dateToString(row.values[148]),
              high_school_graduation_date3: dateToString(row.values[149]),
              high_school_name4: row.values[150],
              high_school_address4: row.values[151],
              high_school_city4: row.values[152],
              high_school_state4: row.values[153],
              high_school_country4: row.values[154],
              high_school_postal_code4: row.values[155],
              high_school_entered_date4: dateToString(row.values[156]),
              high_school_graduation_date4: dateToString(row.values[157]),
              high_school_diploma_obtained: row.values[158],
              clinical_experience: row.values[159],
              clinical_detail: row.values[160],
              english_academy: row.values[161],
              english_last_designation_date: dateToString(row.values[162]),
              english_last_designation: row.values[163],
              english_test: row.values[164],
              english_test_date: dateToString(row.values[165]),
              english_test_result: row.values[166],
              license_received: row.values[167],
              license_number: row.values[168],
              license_issue_date: dateToString(row.values[169]),
              license_expiration_date: dateToString(row.values[170]),
              evaluation_pathway_selected: row.values[171],
              evaluation_pathway_state: row.values[172],
              evaluation_submission_status: row.values[173],
              evaluation_date_submitted: dateToString(row.values[174]),
              evaluation_university_docs_date1: dateToString(row.values[175]),
              evaluation_university_docs_date2: dateToString(row.values[176]),
              evaluation_license_date: dateToString(row.values[177]),
              evaluation_translation_date: dateToString(row.values[178]),
              evaluation_university_verification: dateToString(row.values[179]),
              evaluation_university_docs_currier: row.values[180],
              evaluation_university_docs_tracking: row.values[181],
              evaluation_result: row.values[182],
              evaluation_target_date: dateToString(row.values[183]),
              bon_state: row.values[184],
              bon_date: dateToString(row.values[185]),
              bon_username: row.values[186],
              bon_password: row.values[187],
              bon_applicant_id: row.values[188],
              bon_fingerprint_candidate: dateToString(row.values[189]),
              bon_fingerprint_currier: row.values[190],
              bon_fingerprint_tracking: row.values[191],
              pearson_registration_date: dateToString(row.values[192]),
              pearson_user: row.values[193],
              pearson_password: row.values[194],
              ncsbn_id: row.values[195],
              att: row.values[196],
              att_received: dateToString(row.values[197]),
              nclex_completed_pretests: row.values[198],
              nclex_test_status: row.values[199],
              nclex_test_date: dateToString(row.values[200]),
              deferral_payment_candidate: row.values[201],
              deferral_payment_candidate_amount: row.values[202],
              first_payment_date: dateToString(row.values[203]),
              payment_plan: row.values[204],
              deferral_payment_plan: row.values[205],
              deferral_payment_plan_agreement: row.values[206],
              deferral_payment_plan_email_sent: dateToString(row.values[207]),
              deferral_payment_plan_first_appointment: row.values[208],
              first_appointment_date: dateToString(row.values[209]),
              first_appointment_status: row.values[210],
              second_appointment_date: dateToString(row.values[211]),
              second_appointment_status: row.values[212],
              last_contact_date: dateToString(row.values[213]),
              nas_agreement_sent: row.values[214],
              nas_agreement_date: dateToString(row.values[215]),
              nash_agreement_sent: row.values[216],
              nash_agreement_date: dateToString(row.values[217]),
              general_info_form_sent: row.values[218],
              general_info_form_date: dateToString(row.values[219]),
              interview_email_sent: row.values[220],
              interviewPrep_email_sent: row.values[221],
              interviewPrep_date: dateToString(row.values[222]),
              hospital_info_email_sent: row.values[223],
              option_returned: row.values[224],
              hospital_interviewing: row.values[225],
              interview_expectation_email_sent: row.values[226],
              hospital_interview_date: dateToString(row.values[227]),
              confirm_interview_date: dateToString(row.values[228]),
              hospital_offer_made: row.values[229],
              previous_interviews: row.values[230],
              sendgrid: row.values[231],
              university_diploma_received: row.values[232],
              resume: row.values[233],
              resume_reviewed: row.values[234],
              passport: row.values[235],
              immigration: row.values[236],
              recruit: row.values[237],
              commit_assigned: row.values[238],
              notes: row.values[239],
            });
          }
        });
        dispatch(
          setImportData(
            {
              ...importData,
              data: fileData,
              loaded: true,
            },
          ),
        );
        setLoaded(true);
      });
    };
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <DropzoneDialog
        acceptedFiles={[
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ]}
        cancelButtonText="cancel"
        submitButtonText="submit"
        maxFileSize={10000000}
        filesLimit={1}
        open={open}
        onClose={() => setOpen(false)}
        showPreviews
        showFileNamesInPreview
        onSave={(files) => {
          handleUpload(files);
        }}
      />
      {loaded && (
        <Grid item md={12}>
          <Grid item>
            {t('file-loaded')}
          </Grid>
        </Grid>
      )}
      <Grid item>
        <Grid item md={12}>
          <SuiButton
            onClick={() => setOpen(true)}
            color="secondary"
          >
            {t('upload-file')}
          </SuiButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LoadFile;
