import Service from './service';

class PaymentPlanService extends Service {
  // eslint-disable-next-line class-methods-use-this
  async getPaymentPlans() {
    const response = await this.api
      .get(
        'payment-plans',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getPaymentPlan(id) {
    const response = await this.api
      .get(
        `payment-plans/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createPaymentPlan(
    name,
    hasDownPayment,
    downPaymentAmount,
    downPaymentType,
    hasDeferredPayment,
    deferredAmount,
    deferredType,
    paymentNumber,
    paymentFrequency,
    enabled,
  ) {
    const data = {
      name,
      has_down_payment: hasDownPayment,
      down_payment_amount: downPaymentAmount,
      down_payment_type: downPaymentType,
      has_deferred_payment: hasDeferredPayment,
      deferred_amount: deferredAmount,
      deferred_type: deferredType,
      payment_number: paymentNumber,
      payment_frequency: paymentFrequency,
      enabled,
    };

    const response = await this.api
      .post(
        'payment-plans',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updatePaymentPlan(
    id,
    name,
    hasDownPayment,
    downPaymentAmount,
    downPaymentType,
    hasDeferredPayment,
    deferredAmount,
    deferredType,
    paymentNumber,
    paymentFrequency,
    enabled,
  ) {
    const data = {
      name,
      has_down_payment: hasDownPayment,
      down_payment_amount: downPaymentAmount,
      down_payment_type: downPaymentType,
      has_deferred_payment: hasDeferredPayment,
      deferred_amount: deferredAmount,
      deferred_type: deferredType,
      payment_number: paymentNumber,
      payment_frequency: paymentFrequency,
      enabled,
    };

    const response = await this.api
      .put(
        `payment-plans/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deletePaymentPlan(id) {
    const response = await this.api
      .delete(
        `payment-plans/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new PaymentPlanService();
