import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// Reducers
import {
  fetchDocument,
  setDocumentEditing,
  setSucceeded,
} from 'reducers/documentsSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import { setTitle } from 'Util';
import DocumentInfo from '../components/DocumentInfo';
import SavePanel from '../components/SavePanel';

// Functions

function EditDocument() {
  const dispatch = useDispatch();

  setTitle('Edit Document');

  const { id } = useParams();
  useEffect(() => {
    dispatch(setSucceeded(false));
    dispatch(setDocumentEditing(true));
    dispatch(
      fetchDocument({
        id,
      }),
    );
  }, [dispatch, id]);

  return (
    <BaseLayout>
      <SuiBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SuiBox mb={3}>
              <DocumentInfo />
              <SavePanel />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </BaseLayout>
  );
}

export default EditDocument;
