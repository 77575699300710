import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

// The Timeline main context
const Timeline = createContext();

// Timeline context provider
function TimelineProvider({ children, value }) {
  return <Timeline.Provider value={value}>{children}</Timeline.Provider>;
}

// Timeline custom hook for using context
function useTimeline() {
  return useContext(Timeline);
}

// Typechecking props for TimelineProvider
TimelineProvider.propTypes = {
  children: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export { TimelineProvider, useTimeline };
