import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

// @mui material components
import PaymentTwoToneIcon from '@mui/icons-material/PaymentTwoTone';

// Layout components
import LoadingSpinner from 'layouts/components/LoadingSpinner';
import SimpleInfoCard from 'layouts/components/Cards/InfoCards/SimpleInfoCard';

const selector = (state) => ({
  balance: state.account.balance,
  status: state.account.status,
});

function LastPayment() {
  const { t } = useTranslation('translation', { keyPrefix: 'widgets' });
  const {
    balance,
    status,
  } = useSelector(selector, shallowEqual);

  const [isLoading, setIsLoading] = useState(false);
  const [lastPayment, setLastPayment] = useState('');

  useEffect(() => {
    if (balance.last_payment_date !== null) {
      const lp = Date.parse(balance.last_payment_date);
      if (lp !== null) {
        setLastPayment(Date.parse(balance.last_payment_date)
          .toString('MM-dd-yyyy'));
      } else {
        setLastPayment('');
      }
    } else {
      setLastPayment('');
    }

    if (status === 'loading') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [balance, status]);

  return (
    <div>
      {isLoading && (<LoadingSpinner />)}
      {!isLoading && (
        <SimpleInfoCard
          title={t('last-payment')}
          value={lastPayment}
          icon={<PaymentTwoToneIcon fontSize="large" />}
        />
      )}
    </div>
  );
}

export default LastPayment;
