import { shallowEqual, useSelector } from 'react-redux';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard P+9RO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Images
import logoNAS from 'assets/images/nas-logo.png';
import PropTypes from 'prop-types';
import PaidIcon from 'layouts/components/Icons/PaidIcon';
import OverdueIcon from 'layouts/components/Icons/OverdueIcon';
import VoidIcon from 'layouts/components/Icons/VoidIcon';

const selector = (state) => ({
  invoiceInfo: state.invoice.invoiceInfo,
});

function InvoiceHeader({ printing }) {
  const { invoiceInfo } = useSelector(selector, shallowEqual);

  return (
    <SuiBox p={3}>
      <Grid container justifyContent="space-between">
        <Grid item xs={printing ? 6 : 12} md={4}>
          <SuiTypography variant="h5" fontWeight="medium">
            Nursing Advisory Services
          </SuiTypography>
          <SuiBox
            component="img"
            src={logoNAS}
            width={printing ? '80%' : '90%'}
            p={1}
            mb={1}
          />
          <SuiTypography variant="h6" fontWeight="medium">
            5017 West 95th Street
          </SuiTypography>
          <SuiTypography variant="h6" fontWeight="medium">
            Oak Lawn, IL 60453
          </SuiTypography>
          <SuiBox mt={1} mb={2}>
            <SuiTypography display="block" variant="body2" color="secondary">
              Phone: (847) 425-4783 Ext: 5002
            </SuiTypography>
          </SuiBox>
        </Grid>
        <Grid item xs={printing ? 4 : 12} md={7} lg={3}>
          <SuiBox width="100%" textAlign={{ xs: 'left', md: 'right' }} mt={6}>
            <SuiBox mt={1}>
              <SuiTypography variant="h2" fontWeight="medium">
                INVOICE
              </SuiTypography>
            </SuiBox>
            <SuiBox mb={1}>
              <SuiTypography variant="body2" color="secondary">
                INVOICE #
                {' '}
                {invoiceInfo.id}
                <br />
                DATE:
                {' '}
                {invoiceInfo.date}
              </SuiTypography>
              <SuiTypography variant="h4" fontWeight="medium">
                Due
                {' '}
                {invoiceInfo.due}
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={1}>
              <SuiTypography variant="h2" fontWeight="medium">
                {invoiceInfo.status === 'paid' && (
                  <PaidIcon size="80" color="success" />
                )}
                {invoiceInfo.status === 'overdue' && (
                  <OverdueIcon size="80" color="error" />
                )}
                {invoiceInfo.status === 'void' && (
                  <VoidIcon size="80" color="warning" />
                )}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </Grid>
      </Grid>
      <SuiBox mt={{ xs: 3, md: 5 }}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={4}>
            <SuiTypography variant="h6" color="secondary" fontWeight="medium">
              TO:
            </SuiTypography>
            <SuiTypography variant="h5" fontWeight="medium">
              {invoiceInfo.candidate_name !== undefined
                ? invoiceInfo.candidate_name
                : ''}
            </SuiTypography>
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

InvoiceHeader.propTypes = {
  printing: PropTypes.bool,
};

InvoiceHeader.defaultProps = {
  printing: false,
};

export default InvoiceHeader;
