import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  createTemplate,
  deleteTemplate,
  setCreated,
  setFailed,
  setSaved,
  updateTemplate,
} from 'reducers/templatesSlice';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';

// Functions
import Swal from 'sweetalert2';
import { modifiedRecords, NoChangesWarning } from 'Util';

const selector = (state) => ({
  templateInfo: state.template.templateInfo,
  editing: state.template.editing,
  created: state.template.created,
  saved: state.template.saved,
  failed: state.template.failed,
  errors: state.template.errors,
});

function SavePanel() {
  const {
    templateInfo,
    editing,
    created,
    saved,
    failed,
    errors,
  } = useSelector(
    selector,
    shallowEqual,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'templates' });

  const { id } = useParams();

  const handleSave = () => {
    if (templateInfo.changed === true) {
      const data = {
        name: templateInfo.name,
        templateTypeId: templateInfo.template_type_id,
        templateVersions: modifiedRecords(templateInfo.template_versions),
      };

      if (editing === true) {
        data.id = id;

        dispatch(updateTemplate(data));
      } else {
        dispatch(createTemplate(data));
      }
    } else {
      NoChangesWarning(t);
    }
  };

  const handleDelete = () => {
    Swal.fire({
      text: t('are-you-sure-you-want-to-delete', {
        name: `${templateInfo.name}`,
        keyPrefix: 'common',
      }),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('delete', { keyPrefix: 'common' }),
    }).then((result) => {
      if (result.isConfirmed) {
        const data = { id };
        dispatch(deleteTemplate(data));
      }
    });
  };

  if (failed === true) {
    dispatch(setFailed(false));
    const errMsg = errors.join('; ');

    Swal.fire({
      title: t('error', { keyPrefix: 'common' }),
      text: `${errMsg}`,
      icon: 'error',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    });
  }

  if (created === true || saved === true) {
    dispatch(setCreated(false));
    dispatch(setSaved(false));

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('template-saved-successfully'),
      icon: 'success',
    }).then(() => {
      navigate('/templates', { replace: true });
    });
  }

  return (
    <SuiBox mt={5} mb={3}>
      <SuiBox display="flex" justifyContent="flex-end" mt={6}>
        {editing && (
          <SuiBox mr={1}>
            <SuiButton
              variant="gradient"
              color="error"
              size="small"
              onClick={handleDelete}
            >
              delete
            </SuiButton>
          </SuiBox>
        )}
        <SuiBox mr={1}>
          <SuiButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleSave}
          >
            save
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default SavePanel;
