import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Menu from '@mui/material/Menu';
import Icon from '@mui/material/Icon';

// Soft UI Dashboard PRO React base styles
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';

// Components
import LanguageItem from 'layouts/components/Items/LanguageItem';

const style = {
  position: 'fixed',
  zIndex: 15,
  top: '0.9rem',
  right: '0.9rem',
};

function LanguageSelector({ languages }) {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'common' });

  const [openMenu, setOpenMenu] = useState(false);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleChangeLanguage = (e, lang) => {
    e.preventDefault();
    i18n.changeLanguage(lang);
    setOpenMenu(false);
  };

  const langsOps = [
    {
      key: 'en',
      name: t('english'),
    },
    {
      key: 'es',
      name: t('spanish'),
    },
    {
      key: 'pt',
      name: t('portuguese'),
    },
  ];

  const languageInfo = (key, l) => {
    const lang = langsOps.filter((obj) => obj.key === l)[0];
    return lang[key];
  };

  const renderMenu = (langs) => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {Object.keys(langs).length > 0
        && langs.map((l, i) => (
          <LanguageItem
            key={`language-${i}`}
            color="secondary"
            language={languageInfo('name', l)}
            onClick={(e) => {
              handleChangeLanguage(e, l);
            }}
          />
        ))}
    </Menu>
  );

  return (
    <SuiBox color="inherit" sx={style}>
      <SuiButton
        variant="gradient"
        color="light"
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleOpenMenu}
        iconOnly
      >
        <Icon>language</Icon>
      </SuiButton>
      {renderMenu(languages)}
    </SuiBox>
  );
}

// Setting admin values for the props of LanguageSelector
LanguageSelector.defaultProps = {
  languages: ['en-US'],
};

// Typechecking props for the LanguageSelector
LanguageSelector.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.string),
};

export default LanguageSelector;
