import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Reducers
import { clearAgreement, setAgreementEditing } from 'reducers/agreementsSlice';
import { fetchAgreementTypes } from 'reducers/commonSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import { setTitle } from 'Util';
import AgreementInfo from '../components/AgreementInfo';
import SavePanel from '../components/SavePanel';

// Functions

const selector = (state) => ({
  agreementInfo: state.agreement.agreementInfo,
});

function NewAgreement() {
  const dispatch = useDispatch();

  setTitle('New Agreement');

  const { agreementInfo } = useSelector(selector, shallowEqual);

  useEffect(() => {
    dispatch(setAgreementEditing(false));
    dispatch(fetchAgreementTypes());
    dispatch(clearAgreement());
  }, [dispatch]);

  return (
    <BaseLayout>
      <SuiBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SuiBox mb={3}>
              {agreementInfo.new === true && <AgreementInfo />}
              <SavePanel />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </BaseLayout>
  );
}

export default NewAgreement;
