import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Soft UI Dashboard PRO React example components
import DashboardLayout from 'layouts/components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'layouts/components/Navbars/DashboardNavbar';
import Footer from 'layouts/components/Footer';

// Components
import ReactGA from 'react-ga4';

const selector = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
});

function BaseLayout(
  {
    stickyNavbar,
    children,
  },
) {
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  const { isLoggedIn } = useSelector(selector, shallowEqual);

  if (!isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <DashboardLayout>
      <DashboardNavbar absolute={!stickyNavbar} />
      <SuiBox mt={stickyNavbar ? 3 : 10}>
        {children}
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

// Setting admin values for the props of BaseLayout
BaseLayout.defaultProps = {
  stickyNavbar: false,
};

// Typechecking props for BaseLayout
BaseLayout.propTypes = {
  stickyNavbar: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default BaseLayout;
