import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Reducer functions
import { createLink, sendLink } from 'reducers/tokenLinksSlice';

// Soft UI Dashboard PRO React components
import SuiButton from 'components/SuiButton';
import SuiTypography from 'components/SuiTypography';

// @material-ui core components
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

// Components
import FormField from 'layouts/components/FormField';
import ReactLoading from 'react-loading';

const selector = (state) => ({
  tokenLinkInfo: state.link.tokenLinkInfo,
});

function TokenLink({ section, candidateId }) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.token-links' });
  const {
    tokenLinkInfo,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState(undefined);
  const [openCopyToolTip, setOpenCopyToolTip] = useState(false);
  const [openSendToolTip, setOpenSendToolTip] = useState(false);

  useEffect(() => {
    if (tokenLinkInfo !== undefined) {
      if (tokenLinkInfo.token !== undefined && tokenLinkInfo.token !== null) {
        setLink(`${process.env.REACT_APP_APP_URL}url/${tokenLinkInfo.token}`);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [tokenLinkInfo]);

  const handleCopyTooltipClose = () => {
    setOpenCopyToolTip(false);
  };

  const handleSendTooltipClose = () => {
    setOpenSendToolTip(false);
  };

  const open = Boolean(anchorEl);

  const handleOptions = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setLoading(false);
    setAnchorEl(null);
  };

  const handleGenerate = () => {
    setLoading(true);
    dispatch(createLink({ section, candidateId }));
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link)
      .then(setOpenCopyToolTip(true));
  };

  const handleSendLink = () => {
    setOpenSendToolTip(true);
    dispatch(sendLink({ token: tokenLinkInfo.token }));
  };

  return (
    <div>
      <SuiButton
        variant="text"
        color="info"
        onClick={handleOptions}
      >
        {t('share')}
      </SuiButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {loading && (
          <Grid
            container
            direction="row"
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              width: '100px',
            }}
          >
            <Grid item>
              <ReactLoading
                type="spin"
                color="#03fc4e"
                height={100}
                width={100}
              />
            </Grid>
          </Grid>
        )}
        {(!loading && link === undefined) && (
          <MenuItem onClick={handleGenerate}>{t('generate-link')}</MenuItem>
        )}
        {(!loading && link !== undefined) && (
          <MenuItem>
            <Grid
              container
              direction="row"
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                width: '250px',
              }}
            >
              <Grid item md={12}>
                <SuiTypography variant="caption" color="text">
                  {t('link-description')}
                </SuiTypography>
              </Grid>
              <Grid item md={12}>
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Grid item md={12}>
                    <FormField
                      size="small"
                      defaultValue={link}
                      disabled
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Grid item>
                        <ClickAwayListener onClickAway={handleCopyTooltipClose}>
                          <Tooltip
                            title={t('link-copied')}
                            placement="top"
                            onClose={handleCopyTooltipClose}
                            open={openCopyToolTip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            PopperProps={{
                              disablePortal: true,
                            }}
                          >
                            <SuiButton
                              variant="outlined"
                              color="secondary"
                              size="small"
                              sx={{ padding: '0.5rem 1rem' }}
                              onClick={handleCopyLink}
                            >
                              {t('copy')}
                            </SuiButton>
                          </Tooltip>
                        </ClickAwayListener>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={6}>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Grid item>
                        <ClickAwayListener onClickAway={handleSendTooltipClose}>
                          <Tooltip
                            title={t('link-send')}
                            placement="top"
                            onClose={handleSendTooltipClose}
                            open={openSendToolTip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            PopperProps={{
                              disablePortal: true,
                            }}
                          >
                            <SuiButton
                              variant="outlined"
                              color="info"
                              size="small"
                              sx={{ padding: '0.5rem 1rem' }}
                              onClick={handleSendLink}
                            >
                              {t('send')}
                            </SuiButton>
                          </Tooltip>
                        </ClickAwayListener>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

TokenLink.propTypes = {
  section: PropTypes.string.isRequired,
  candidateId: PropTypes.string.isRequired,
};

export default TokenLink;
