import { forwardRef } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Invoice page components
import InvoiceHeader from '../Header';
import InvoiceFooter from '../Footer';
import InvoiceDetail from '../Detail';

// eslint-disable-next-line react/display-name
export const InvoicePrint = forwardRef((props, ref) => (
  <div ref={ref}>
    <SuiBox mt={{ xs: 4, md: 10 }} mb={{ xs: 4, md: 8 }}>
      <Grid container justifyContent="center">
        <Grid item xs={10} sm={10} md={9}>
          <Card>
            <InvoiceHeader printing />
            <InvoiceDetail />
            <InvoiceFooter />
          </Card>
        </Grid>
      </Grid>
    </SuiBox>
  </div>
));

export default InvoicePrint;
