import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// Reducer functions
import { fetchCandidatesLatestStatuses } from 'reducers/reportsSlice';

// Components
import BaseTable from 'layouts/reports/components/BaseTable';

// Functions
import { setTitle } from 'Util';

const selector = (state) => ({
  candidateLatestStatuses: state.reports.candidateLatestStatuses,
});

function CandidatesLatestStatuses() {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { candidateLatestStatuses } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  setTitle('Candidates Latest Statuses Report');

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(fetchCandidatesLatestStatuses());
  }, [dispatch]);

  useEffect(() => {
    if (candidateLatestStatuses !== undefined) {
      setData(candidateLatestStatuses);
    }
  }, [candidateLatestStatuses]);

  const handleDateFormat = (d) => {
    if (d !== null) {
      return Date.parse(d);
    }
    return null;
  };

  const columns = [
    {
      caption: t('candidate-name'),
      field: 'candidate_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('current-status'),
      field: 'current_status',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('current-status-date'),
      field: 'current_status_date',
      calculateCellValue: (d) => handleDateFormat(d.current_status_date),
      dataType: 'datetime',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('current-status-days'),
      field: 'current_status_days',
      dataType: 'number',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('previous-status'),
      field: 'previous_status',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('previous-status-date'),
      field: 'previous_status_date',
      calculateCellValue: (d) => handleDateFormat(d.previous_status_date),
      dataType: 'datetime',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('previous-status-days'),
      field: 'previous_status_days',
      dataType: 'number',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('advisor'),
      field: 'advisor_name',
      visible: true,
      allowFixing: true,
    },
  ];

  return (
    <BaseTable name="CandidatesSummaries" data={data} keyExpr="uuid" columns={columns} />
  );
}

export default CandidatesLatestStatuses;
