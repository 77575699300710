import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// @mui material components
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiTypography from 'components/SuiTypography';

// Functions
import dayjs from 'dayjs';
import parse from 'html-react-parser';

const rowStyle = {
  maxHeight: '90px',
};

const selectedStyle = {
  border: '1px solid #31bf95',
  backgroundColor: '#e4fff7',
};

const divStyle = {
  maxHeight: '90px',
  position: 'relative',
  overflow: 'hidden',
};

const pStyle = {
  position: 'absolute',
  bottom: '0',
  left: '0',
  width: '100%',
  textAlign: 'center',
  margin: '0',
  padding: '30px 0',
  backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0), #FFF)',
};

const pStyleSelected = {
  position: 'absolute',
  bottom: '0',
  left: '0',
  width: '100%',
  textAlign: 'center',
  margin: '0',
  padding: '30px 0',
  backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0), #e4fff7)',
};

function CommunicationNoteCard({
  id,
  title,
  date,
  note,
  contactMethod,
  selected,
  fade,
  editFunc,
  removeFunc,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'common' });

  const [menu, setMenu] = useState(null);
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  return (
    <Card sx={selected ? selectedStyle : null}>
      <CardHeader
        title={title}
        action={(
          <IconButton onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
      />
      <Menu
        anchorEl={menu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(menu)}
        onClose={closeMenu}
        keepMounted
      >
        <MenuItem
          onClick={() => {
            editFunc(id);
            closeMenu();
          }}
        >
          {t('edit')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeFunc(id);
            closeMenu();
          }}
        >
          {t('remove')}
        </MenuItem>
      </Menu>
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item md={3} mb={2}>
            <SuiTypography variant="subtitle2" fontWeight="regular" color="text">
              {dayjs(date).format('YYYY-MM-DD')}
            </SuiTypography>
          </Grid>
          <Grid item md={3} mb={2}>
            <SuiTypography variant="subtitle2" fontWeight="regular" color="text">
              {dayjs(date).format('hh:mm A')}
            </SuiTypography>
          </Grid>
          <Grid item md={6} mb={2}>
            <SuiTypography variant="subtitle2" fontWeight="regular" color="text">
              {contactMethod}
            </SuiTypography>
          </Grid>
          <Grid item md={12} mb={2} style={title ? rowStyle : null}>
            <div style={fade ? divStyle : null}>
              <SuiTypography variant="body2" fontWeight="regular" color="text">
                {parse(note)}
              </SuiTypography>
              {fade && (<p style={selected ? pStyleSelected : pStyle} />)}
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

CommunicationNoteCard.defaultProps = {
  id: undefined,
  title: '',
  date: '',
  note: '',
  contactMethod: '',
  selected: false,
  fade: false,
  editFunc: null,
  removeFunc: null,
};

CommunicationNoteCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  date: PropTypes.string,
  note: PropTypes.string,
  contactMethod: PropTypes.string,
  selected: PropTypes.bool,
  fade: PropTypes.bool,
  editFunc: PropTypes.func,
  removeFunc: PropTypes.func,
};

export default CommunicationNoteCard;
