// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @material-ui core components
import MaterialInput from '@mui/material/Input';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Components
import InputMask from 'react-input-mask';

function FormMaskInput({
  label, mask, maskPlaceholder, alwaysShowMask, beforeMaskedStateChange, ...rest
}) {
  return (
    <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
      <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </SuiTypography>
      </SuiBox>
      <InputMask
        mask={mask}
        maskPlaceholder={maskPlaceholder}
        alwaysShowMask={alwaysShowMask}
        beforeMaskedStateChange={beforeMaskedStateChange}
        value={rest.value}
        onChange={rest.onChange}
      >
        <MaterialInput disableUnderline {...rest} />
      </InputMask>
    </SuiBox>
  );
}

// Setting admin values for the props of FormField
FormMaskInput.defaultProps = {
  label: '',
  mask: '',
  maskPlaceholder: '',
  alwaysShowMask: false,
  beforeMaskedStateChange: undefined,
};

// Typechecking props for FormField
FormMaskInput.propTypes = {
  label: PropTypes.string,
  mask: PropTypes.string,
  maskPlaceholder: PropTypes.string,
  alwaysShowMask: PropTypes.bool,
  beforeMaskedStateChange: PropTypes.func,
};

export default FormMaskInput;
