// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

function FormDateRangePicker(
  {
    label,
    textTransform,
    ...rest
  },
) {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
      <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform={textTransform}
        >
          {label}
        </SuiTypography>
      </SuiBox>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
          {...rest}
        />
      </LocalizationProvider>
    </SuiBox>
  );
}

// Setting admin values for the props of FormField
FormDateRangePicker.defaultProps = {
  label: ' ',
  textTransform: 'capitalize',
};

// Typechecking props for FormField
FormDateRangePicker.propTypes = {
  label: PropTypes.string,
  textTransform: PropTypes.string,
};

export default FormDateRangePicker;
