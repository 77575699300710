import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Reducer functions
// eslint-disable-next-line object-curly-newline
import { clearCandidate, setEditing } from 'reducers/candidatesSlice';
import { fetchCandidateStatuses } from 'reducers/commonSlice';

// Functions
import { setTitle } from 'Util';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import AdminView from '../components/AdminView';

function EditCandidate() {
  const dispatch = useDispatch();

  setTitle('Edit Candidate');

  useEffect(() => {
    dispatch(setEditing(true));
    dispatch(clearCandidate());
    dispatch(fetchCandidateStatuses());
  }, [dispatch]);

  return (
    <BaseLayout>
      <AdminView />
    </BaseLayout>
  );
}

export default EditCandidate;
