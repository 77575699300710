import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// DevExtreme components
import DataGrid from 'devextreme-react/data-grid';

// Functions
import { renderColumns } from 'Util';

const selector = (state) => ({
  invoicePayments: state.invoicePayment.invoicePayments,
});

function PaymentInvoiceDetail() {
  const { t } = useTranslation('translation', { keyPrefix: 'payments' });
  const { invoicePayments } = useSelector(selector, shallowEqual);

  const columns = [
    {
      caption: t('invoice-number'),
      field: 'invoice_number',
    },
    {
      caption: t('date'),
      field: 'invoice_date',
      dataType: 'date',
    },
    {
      caption: t('due-date'),
      field: 'invoice_due',
      dataType: 'date',
    },
    {
      caption: t('total'),
      field: 'invoice_total',
      dataType: 'number',
      format: { type: 'currency', precision: 2 },
    },
    {
      caption: t('balance'),
      field: 'invoice_balance',
      dataType: 'number',
      format: { type: 'currency', precision: 2 },
    },
    {
      caption: t('status'),
      field: 'invoice_status',
    },
    {
      caption: t('amount-credited'),
      field: 'amount',
      dataType: 'number',
      format: { type: 'currency', precision: 2 },
    },
  ];

  return (
    <div>
      <DataGrid
        dataSource={invoicePayments}
        showBorders
        columnAutoWidth
      >
        {renderColumns(columns)}
      </DataGrid>
    </div>
  );
}

export default PaymentInvoiceDetail;
