import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  createCandidateDocument,
  deleteCandidateDocument,
  fetchCandidateDocuments,
  fetchCandidateProfile,
  setCurrentSubView,
  updateCandidateDocument,
} from 'reducers/candidatesSlice';
import { fetchDocuments } from 'reducers/documentsSlice';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// @material-ui core components
import Grid from '@mui/material/Grid';

// Components
import DefaultDocumentCard from 'layouts/components/Cards/DocumentCards/DefaultDocumentCard';
import FormSelect from 'layouts/components/FormSelect';

// Functions
import { checkPermission, getCandidateUUID, setTitle } from 'Util';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';

const selector = (state) => ({
  currentUser: state.auth.user,
  candidateDocuments: state.candidate.documents,
  documents: state.document.documents,
  candidateProfile: state.candidate.profile,
  editing: state.candidate.editing,
});

function Documents() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'candidates.documents',
  });
  const {
    currentUser,
    candidateDocuments,
    documents,
    candidateProfile,
    editing,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    dispatch(setCurrentSubView('documents'));
    dispatch(fetchDocuments());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateProfile({ uuid }));
      dispatch(fetchCandidateDocuments({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  setTitle('Candidate Documents');

  const [availableDoc, setAvailableDoc] = useState(0);
  const [availableDocs, setAvailableDocs] = useState([]);

  useEffect(() => {
    setAvailableDocs(
      documents.filter((doc) => doc.document_type_id === 1)
        .filter(
          (d) => !candidateDocuments.some((cd) => d.id === cd.document.id),
        ),
    );
    // eslint-disable-next-line
  }, [documents, candidateDocuments]);

  const handleAssign = () => {
    if (availableDoc > 0) {
      const assigned = candidateDocuments.find(
        (d) => d.document.id === availableDoc,
      );
      if (assigned === undefined) {
        dispatch(
          createCandidateDocument({
            id: availableDoc,
            candidateId: candidateProfile.uuid,
          }),
        );
      }
    }
  };

  const handleRemove = (id, candidateId) => {
    if (id > 0) {
      dispatch(
        deleteCandidateDocument({
          id,
          candidateId,
        }),
      );
    }
  };
  const handleChange = (files, candidateId, docId) => {
    dispatch(
      updateCandidateDocument({
        id: docId,
        candidateId,
        file: files[0],
      }),
    );
  };

  return (
    <div>
      {checkPermission('CAPM', currentUser) && editing && (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item md={4}>
            <FormSelect
              label={t('available-documents')}
              options={availableDocs}
              onChange={(e) => {
                setAvailableDoc(e.value);
              }}
            />
          </Grid>
          <Grid item md={2} pr={1} mt={3}>
            <IconButton
              aria-label="add"
              onClick={handleAssign}
            >
              <AddIcon />
            </IconButton>
          </Grid>
          <Grid item md={4} />
          <Grid item md={2} />
        </Grid>
      )}
      <SuiBox mt={5} mb={3}>
        {t('uploads-appearance')}
      </SuiBox>
      <SuiBox mt={5} mb={3}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {Object.keys(candidateDocuments).length > 0
            && candidateDocuments.map((d, i) => (
              <Grid item xs={12} lg={4} key={`document${i}`}>
                <SuiBox m={1}>
                  <DefaultDocumentCard
                    key={`document-${i}`}
                    id={d.id}
                    candidateId={d.candidate.uuid}
                    description={d.document.description}
                    title={d.document.name}
                    fileUrl={d.file_url}
                    filesLimit={d.document.uploads_number}
                    changeFunc={handleChange}
                    removeFunc={(id, candidateId) => {
                      handleRemove(d.document.id, candidateId);
                    }}
                  />
                </SuiBox>
              </Grid>
            ))}
        </Grid>
      </SuiBox>
    </div>
  );
}

export default Documents;
