import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import InvoiceService from 'services/invoice.service';
import {
  fulfilledCreatedReducer,
  fulfilledReducer,
  fulfilledSavedReducer,
  pendingReducer,
  rejectionReducer,
} from '../Util';

export const fetchInvoices = createAsyncThunk(
  'invoice/fetchInvoices',
  async (payload, { rejectWithValue }) => {
    try {
      return await InvoiceService.getInvoices();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchInvoice = createAsyncThunk(
  'invoice/fetchInvoice',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await InvoiceService.getInvoice(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchInvoiceLimited = createAsyncThunk(
  'invoice/fetchInvoiceLimited',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await InvoiceService.getInvoiceLimited(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createInvoice = createAsyncThunk(
  'invoice/createInvoice',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      date,
      due,
      invoiceItems,
    } = payload;
    try {
      return await InvoiceService.createInvoice(
        candidateId,
        date,
        due,
        invoiceItems,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateInvoice = createAsyncThunk(
  'invoice/updateInvoice',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      date,
      due,
      invoiceItems,
    } = payload;
    try {
      return await InvoiceService.updateInvoice(
        id,
        candidateId,
        date,
        due,
        invoiceItems,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const cancelInvoice = createAsyncThunk(
  'invoice/cancelInvoice',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await InvoiceService.cancelInvoice(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

/* eslint-disable no-param-reassign */
export const InvoicesSlice = createSlice({
  name: 'invoice',
  initialState: {
    invoiceInfo: {},
    invoices: [],
    message: '',
    editing: false,
    succeeded: false,
    schedule: [],
  },
  reducers: {
    clearInvoice: (state) => {
      state.invoiceInfo = {
        id: null,
        date: new Date().toISOString().slice(0, 10),
        due: new Date().toISOString().slice(0, 10),
        invoice_number: '',
        invoice_items: [
          {
            id: null,
            description: '',
            quantity: 0,
            unit_price: 0,
          },
        ],
      };
      state.invoices = [];
      state.message = '';
      state.editing = false;
      state.deleted = false;
      state.created = false;
      state.saved = false;
      state.failed = false;
      state.succeeded = false;
    },
    setInvoiceEditing: (state, action) => {
      state.editing = action.payload;
    },
    setCreated: (state, action) => {
      state.created = action.payload;
    },
    setDeleted: (state, action) => {
      state.deleted = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setSaved: (state, action) => {
      state.saved = action.payload;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setInvoiceInfo: (state, action) => {
      state.invoiceInfo = action.payload;
    },
  },
  extraReducers: (builder) => builder
    .addMatcher(
      (action) => action.type.endsWith('/rejected'),
      rejectionReducer,
    )
    .addMatcher((action) => action.type.endsWith('/pending'), pendingReducer)
    .addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, action) => {
        const performedAction = action.type.split('/');

        if (performedAction[0] === 'invoice') {
          switch (performedAction[1]) {
            case 'fetchInvoices':
              fulfilledReducer(state, action);
              state.invoices = action.payload;
              break;
            case 'fetchInvoice':
              fulfilledReducer(state, action);
              state.invoiceInfo = action.payload;
              break;
            case 'fetchInvoiceLimited':
              fulfilledReducer(state, action);
              state.invoiceInfo = action.payload;
              break;
            case 'createInvoice':
              fulfilledCreatedReducer(state, action);
              break;
            case 'updateInvoice':
            case 'cancelInvoice':
              fulfilledSavedReducer(state, action);
              break;
            default:
              fulfilledReducer(state, action);
              state.message = action.payload;
              break;
          }
        }
      },
    ),
});
/* eslint-disable no-param-reassign */

export const {
  clearInvoice,
  setInvoiceEditing,
  setCreated,
  setDeleted,
  setFailed,
  setSaved,
  setSucceeded,
  setInvoiceInfo,
} = InvoicesSlice.actions;

export default InvoicesSlice.reducer;
