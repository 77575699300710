import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Reducer function
import {
  clearVendor, createVendor, deleteVendor, updateVendor, setCreated, setFailed, setSaved,
} from 'reducers/vendorSlice';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';

// Functions
import Swal from 'sweetalert2';
import { errorsToString, MissingDataWarning, NoChangesWarning } from 'Util';

const selector = (state) => ({
  vendorInfo: state.vendor.vendorInfo,
  editing: state.vendor.editing,
  created: state.vendor.created,
  saved: state.vendor.saved,
  deleted: state.vendor.deleted,
  failed: state.vendor.failed,
  errors: state.vendor.errors,
});

function SavePanel() {
  const { t } = useTranslation('translation', { keyPrefix: 'vendors' });
  const {
    vendorInfo, editing, created, saved, deleted, failed, errors,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  if (created) {
    dispatch(setCreated(false));
    dispatch(clearVendor());

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('vendor-created-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      navigate('/vendors', { replace: true });
    });
  }

  if (saved) {
    dispatch(setSaved(false));
    dispatch(clearVendor());

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('saved-saved-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      navigate('/vendors', { replace: true });
    });
  }

  if (deleted) {
    dispatch(setSaved(false));
    dispatch(clearVendor());

    Swal.fire({
      title: `${t('deleted', { keyPrefix: 'common' })}!`,
      text: t('vendor-deleted-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      navigate('/vendors', { replace: true });
    });
  }

  if (failed) {
    dispatch(setFailed(false));

    Swal.fire({
      title: t('error', { keyPrefix: 'common' }),
      text: errorsToString(errors),
      icon: 'error',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    });
  }

  const handleSave = () => {
    if (vendorInfo.changed === true) {
      if (vendorInfo.name !== undefined && vendorInfo.name !== '') {
        const data = {
          name: vendorInfo.name,
          businessId: vendorInfo.business_id,
          paymentTerm: vendorInfo.payment_term,
          rate: vendorInfo.rate,
          notes: vendorInfo.notes,
          vendorContacts: vendorInfo.vendor_contacts,
          vendorAddresses: vendorInfo.vendor_addresses,
        };

        if (editing === true) {
          data.id = id;

          dispatch(updateVendor(data));
        } else {
          dispatch(createVendor(data));
        }
      } else {
        MissingDataWarning(t);
      }
    } else {
      NoChangesWarning(t);
    }
  };

  const handleDelete = () => {
    Swal.fire({
      text: t('are-you-sure-you-want-to-delete', {
        name: `${vendorInfo.name}`,
        keyPrefix: 'common',
      }),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('delete', { keyPrefix: 'common' }),
    }).then((result) => {
      if (result.isConfirmed) {
        const data = { id };
        dispatch(deleteVendor(data));
      }
    });
  };

  return (
    <SuiBox mt={5} mb={3}>
      <SuiBox display="flex" justifyContent="flex-end" mt={6}>
        {editing && (
          <SuiBox mr={1}>
            <SuiButton
              variant="gradient"
              color="error"
              size="small"
              onClick={handleDelete}
            >
              {t('delete', { keyPrefix: 'common' })}
            </SuiButton>
          </SuiBox>
        )}
        <SuiBox mr={1}>
          <SuiButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleSave}
          >
            {t('save', { keyPrefix: 'common' })}
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default SavePanel;
