import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import DocumentService from 'services/document.service';
import { pendingReducer, rejectionReducer } from 'Util';

export const fetchDocuments = createAsyncThunk(
  'document/fetchDocuments',
  async (payload, { rejectWithValue }) => {
    try {
      return await DocumentService.getDocuments();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchDocument = createAsyncThunk(
  'document/fetchDocument',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await DocumentService.getDocument(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createDocument = createAsyncThunk(
  'document/createDocument',
  async (payload, { rejectWithValue }) => {
    const {
      name,
      description,
      isRequired,
      autoAssigned,
      uploadsNumber,
      documentTypeId,
      maxSize,
    } = payload;
    try {
      return await DocumentService.createDocument(
        name,
        description,
        isRequired,
        autoAssigned,
        uploadsNumber,
        documentTypeId,
        maxSize,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateDocument = createAsyncThunk(
  'document/updateDocument',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      name,
      description,
      isRequired,
      autoAssigned,
      uploadsNumber,
      documentTypeId,
      maxSize,
    } = payload;
    try {
      return await DocumentService.updateDocument(
        id,
        name,
        description,
        isRequired,
        autoAssigned,
        uploadsNumber,
        documentTypeId,
        maxSize,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteDocument = createAsyncThunk(
  'document/deleteDocument',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await DocumentService.deleteDocument(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchDocumentsByType = createAsyncThunk(
  'document/fetchDocumentsByType',
  async (payload, { rejectWithValue }) => {
    const { typeId } = payload;
    try {
      return await DocumentService.getDocumentsByType(typeId);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

const fulfilledReducer = (state) => {
  // eslint-disable-next-line no-param-reassign
  state.status = 'succeeded';
};

const generateDropboxProperties = ({ payload }) => payload.map((item) => ({
  ...item,
  value: item.id,
  label: item.name,
}));

/* eslint-disable no-param-reassign */
export const documentSlice = createSlice({
  name: 'document',
  initialState: {
    documentInfo: {},
    documents: [],
    message: '',
    errors: [],
    editing: false,
    succeeded: false,
  },
  reducers: {
    clearDocument: (state) => {
      state.message = '';
      state.errors = [];
      state.editing = false;
      state.succeeded = false;
      state.documentInfo = {
        uploads_number: 1,
        document_type_id: 1,
      };
    },
    setDocumentEditing: (state, action) => {
      state.editing = action.payload;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setDocumentInfo: (state, action) => {
      state.documentInfo = action.payload;
    },
  },
  extraReducers: (builder) => builder
    .addMatcher(
      (action) => action.type.endsWith('/rejected'),
      rejectionReducer,
    )
    .addMatcher((action) => action.type.endsWith('/pending'), pendingReducer)
    .addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, action) => {
        const performedAction = action.type.split('/');
        if (performedAction[0] === 'document') {
          switch (performedAction[1]) {
            case 'fetchDocuments':
              fulfilledReducer(state);
              state.documents = generateDropboxProperties(action);
              break;
            case 'fetchDocumentsByType':
              fulfilledReducer(state);
              state.documents = generateDropboxProperties(action);
              break;
            case 'fetchDocument':
              fulfilledReducer(state, action);
              state.documentInfo = action.payload;
              break;
            case 'createDocument':
              fulfilledReducer(state, action);
              state.succeeded = true;
              break;
            case 'updateDocument':
              fulfilledReducer(state, action);
              state.succeeded = true;
              break;
            default:
              fulfilledReducer(state, action);
              state.message = action.payload;
              break;
          }
        }
      },
    ),
});

/* eslint-disable no-param-reassign */
export const {
  clearDocument,
  setDocumentEditing,
  setSucceeded,
  setFailed,
  setDocumentInfo,
} = documentSlice.actions;

export default documentSlice.reducer;
