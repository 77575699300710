import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { setPositionListingInfo } from 'reducers/positionListingsSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';

// Styles
import 'assets/styles/decoupled-editor.css';

// Other components
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

// Functions
import { defaultValue, findSelectValue } from 'Util';

const selector = (state) => ({
  positionListingInfo: state.positionListing.positionListingInfo,
  clients: state.client.clients,
});

function PositionListingInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'position-listings' });

  const {
    positionListingInfo,
    clients,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const priority = [
    {
      value: 1,
      label: t('low'),
    },
    {
      value: 2,
      label: t('normal'),
    },
    {
      value: 3,
      label: t('high'),
    },
    {
      value: 4,
      label: t('urgent'),
    },
  ];

  const setValue = (key, value) => {
    dispatch(
      setPositionListingInfo(
        {
          ...positionListingInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  return (
    <Card id="position-listing-info" sx={{ overflow: 'visible' }}>
      <SuiBox p={3}>
        <SuiTypography variant="h5">{t('position-listing-info')}</SuiTypography>
      </SuiBox>
      <SuiBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <FormField
              label={t('name')}
              value={defaultValue(positionListingInfo.name)}
              error={!positionListingInfo.name || positionListingInfo.name === ''}
              onChange={(e) => {
                setValue('name', e.target.value);
              }}
            />
          </Grid>
          <Grid item md={12}>
            <FormSelect
              label={t('client')}
              options={clients}
              value={findSelectValue(clients, positionListingInfo.client_id)}
              error={!positionListingInfo.client_id || positionListingInfo.client_id === ''}
              onChange={(e) => {
                setValue('client_id', e.value);
              }}
              sx={{ overflow: 'visible' }}
            />
          </Grid>
          <Grid item md={4}>
            <FormField
              label={t('date-opened')}
              type="date"
              value={defaultValue(positionListingInfo.date_opened)}
              error={!positionListingInfo.date_opened || positionListingInfo.date_opened === ''}
              onChange={(e) => {
                setValue('date_opened', e.target.value);
              }}
            />
          </Grid>
          <Grid item md={4}>
            <FormField
              label={t('openings')}
              value={defaultValue(positionListingInfo.openings)}
              error={!positionListingInfo.openings || positionListingInfo.openings === ''}
              onChange={(e) => {
                setValue('openings', e.target.value);
              }}
            />
          </Grid>
          <Grid item md={4}>
            <FormSelect
              label={t('priority')}
              options={priority}
              value={findSelectValue(priority, positionListingInfo.priority_id)}
              error={!positionListingInfo.priority_id || positionListingInfo.priority_id === ''}
              onChange={(e) => {
                setValue('priority_id', e.value);
              }}
              sx={{ overflow: 'visible' }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <SuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              {t('description')}
            </SuiTypography>
            <div className="document-editor">
              <div className="document-editor__toolbar" />
              <CKEditor
                editor={DecoupledEditor}
                data={positionListingInfo.description}
                onReady={(editor) => {
                  window.editor = editor;
                  if (document.querySelector('.document-editor__toolbar') !== undefined
                    && document.querySelector('.document-editor__toolbar') !== null) {
                    const toolbarContainer = document.querySelector('.document-editor__toolbar');
                    toolbarContainer.appendChild(editor.ui.view.toolbar.element);
                  }
                }}
                onChange={(event, editor) => {
                  if (positionListingInfo.description !== undefined) {
                    setValue('description', editor.getData());
                  }
                }}
              />
            </div>
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default PositionListingInfo;
