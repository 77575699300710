import Service from './service';

class TemplateService extends Service {
  // eslint-disable-next-line class-methods-use-this
  async getTemplates() {
    const response = await this.api
      .get(
        'templates',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getTemplate(id) {
    const response = await this.api
      .get(
        `templates/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getTemplateVersion(id, version) {
    const response = await this.api
      .get(
        `templates/${id}/version/${version}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createTemplate(name, content, templateTypeId, templateVersions) {
    const data = {
      template: {
        name,
        content,
        template_type_id: templateTypeId,
        template_versions_attributes: templateVersions,
      },
    };

    const response = await this.api
      .post(
        'templates',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateTemplate(id, name, templateTypeId, templateVersions) {
    const data = {
      name,
      template_type_id: templateTypeId,
      template_versions_attributes: templateVersions,
    };

    const response = await this.api
      .put(
        `templates/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteTemplate(id) {
    const response = await this.api
      .delete(
        `templates/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new TemplateService();
