import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Reducer functions
import { clearPositionListing, setPositionListingEditing } from 'reducers/positionListingsSlice';
import { fetchClients } from 'reducers/clientSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import SavePanel from '../components/SavePanel';
import PositionInfo from '../components/PositionInfo';

function NewPositionListing() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearPositionListing());
    dispatch(setPositionListingEditing(false));
    dispatch(fetchClients());
  }, [dispatch]);

  return (
    <BaseLayout>
      <SuiBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <PositionInfo />
                  <SavePanel />
                </Grid>
              </Grid>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </BaseLayout>
  );
}

export default NewPositionListing;
