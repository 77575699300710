import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// Reducers
import { fetchOrder, setOrderEditing } from 'reducers/ordersSlice';
import { fetchServices } from 'reducers/servicesSlice';
import { fetchPaymentPlans } from 'reducers/paymentPlansSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import OrderInfo from '../components/OrderInfo';
import OrderDetails from '../components/OrderDetails';
import SavePanel from '../components/SavePanel';

function EditOrder() {
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchServices());
    dispatch(fetchPaymentPlans());
    dispatch(fetchOrder({ id }));
    dispatch(setOrderEditing(true));
  }, [dispatch, id]);

  return (
    <BaseLayout>
      <SuiBox mt={4}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <OrderInfo />
                  <Divider />
                  <OrderDetails />
                  <SavePanel />
                </Grid>
              </Grid>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </BaseLayout>
  );
}

export default EditOrder;
