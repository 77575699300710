import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import TemplateService from 'services/template.service';

// Functions
import {
  fulfilledCreatedReducer,
  fulfilledReducer,
  fulfilledSavedReducer,
  pendingReducer,
  rejectionReducer,
} from 'Util';

export const fetchTemplates = createAsyncThunk(
  'template/fetchTemplates',
  async (payload, { rejectWithValue }) => {
    try {
      return await TemplateService.getTemplates();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchTemplate = createAsyncThunk(
  'template/fetchTemplate',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await TemplateService.getTemplate(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchTemplateVersion = createAsyncThunk(
  'agreement/fetchTemplateVersion',
  async (payload, { rejectWithValue }) => {
    const { id, version } = payload;
    try {
      return await TemplateService.getTemplateVersion(id, version);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createTemplate = createAsyncThunk(
  'template/createTemplate',
  async (payload, { rejectWithValue }) => {
    const {
      name,
      content,
      templateTypeId,
      templateVersions,
    } = payload;
    try {
      return await TemplateService.createTemplate(name, content, templateTypeId, templateVersions);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateTemplate = createAsyncThunk(
  'template/updateTemplate',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      name,
      templateTypeId,
      templateVersions,
    } = payload;
    try {
      return await TemplateService.updateTemplate(id, name, templateTypeId, templateVersions);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteTemplate = createAsyncThunk(
  'template/deleteTemplate',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await TemplateService.deleteTemplate(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

const generateDropboxProperties = ({ payload }) => payload.map((item) => ({
  ...item,
  value: item.id,
  label: item.name,
}));

/* eslint-disable no-param-reassign */
export const templateSlice = createSlice({
  name: 'template',
  initialState: {
    templateInfo: {},
    templates: [],
    message: '',
    errors: [],
    editing: false,
    created: false,
    saved: false,
    failed: false,
    succeeded: false,
  },
  reducers: {
    clearTemplate: (state) => {
      state.message = '';
      state.errors = [];
      state.editing = false;
      state.succeeded = false;
      state.created = false;
      state.saved = false;
      state.failed = false;
      state.templateInfo = {
        name: '',
        content: '',
        current_version: 1,
        template_type_id: 1,
        new: true,
        version: 1,
        template_versions: [{
          id: null,
          header: '',
          content: '',
          footer: '',
          current: true,
          version: 1,
          settings: null,
        }],
      };
    },
    setTemplateEditing: (state, action) => {
      state.editing = action.payload;
    },
    setCreated: (state, action) => {
      state.created = action.payload;
    },
    setSaved: (state, action) => {
      state.saved = action.payload;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setTemplateInfo: (state, action) => {
      state.templateInfo = action.payload;
    },
  },
  extraReducers: (builder) => builder
    .addMatcher(
      (action) => action.type.endsWith('/rejected'),
      rejectionReducer,
    )
    .addMatcher((action) => action.type.endsWith('/pending'), pendingReducer)
    .addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, action) => {
        const performedAction = action.type.split('/');
        if (performedAction[0] === 'template') {
          switch (performedAction[1]) {
            case 'fetchTemplates':
              fulfilledReducer(state, action);
              state.templates = generateDropboxProperties(action);
              break;
            case 'fetchTemplate':
            case 'fetchTemplateVersion':
              fulfilledReducer(state, action);
              state.templateInfo = action.payload;
              break;
            case 'createTemplate':
              fulfilledCreatedReducer(state, action);
              state.succeeded = true;
              break;
            case 'updateTemplate':
              fulfilledSavedReducer(state, action);
              state.succeeded = true;
              break;
            default:
              fulfilledReducer(state, action);
              state.message = action.payload;
              break;
          }
        }
      },
    ),
});

/* eslint-disable no-param-reassign */
export const {
  clearTemplate,
  setTemplateEditing,
  setCreated,
  setSaved,
  setFailed,
  setSucceeded,
  setTemplateInfo,
} = templateSlice.actions;

export default templateSlice.reducer;
