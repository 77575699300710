import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { fetchUserActivity } from 'reducers/usersSlice';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { Pagination } from '@mui/lab';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import UserActivityTimeline from './components/UserActivityTimeline';

const selector = (state) => ({
  activity: state.user.activity,
});

function UserActivityLog() {
  const { t } = useTranslation('translation', { keyPrefix: 'users' });
  const {
    activity,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const { uuid } = useParams();

  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(fetchUserActivity({ uuid, page }));
  }, [dispatch, page, uuid]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <Card id="activity-log" sx={{ overflow: 'visible' }}>
      <CardHeader
        title={t('activity-log')}
      />
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item md={12}>
            <UserActivityTimeline activity={activity.data} />
          </Grid>
          <Grid item md={3} />
          <Grid item md={6}>
            <Pagination
              count={activity.meta.pages}
              showFirstButton
              showLastButton
              onChange={handleChange}
              size="large"
            />
          </Grid>
          <Grid item md={3} />
        </Grid>
      </CardContent>
    </Card>
  );
}

export default UserActivityLog;
