import { useEffect, useState } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './i18n';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Components
import Sidenav from 'layouts/components/Sidenav';

// Soft UI Dashboard React themes
import theme from 'assets/theme';

// Soft UI Dashboard PRO React contexts
import { setMiniSidenav, useSoftUIController } from 'context';

// Layouts
import SignIn from 'layouts/authentication/sign-in';
import SignUp from 'layouts/authentication/sign-up';
import Terms from 'layouts/authentication/terms';
import Confirmation from 'layouts/authentication/confirmation';
import Unlock from 'layouts/authentication/unlock';
import PaymentForm from 'layouts/accounts/components/PaymentForm';
import PaymentConfirmation from 'layouts/accounts/PaymentConfirmation';
import Error404 from 'layouts/error/404';
import Links from 'layouts/links';

// Images
import brand from 'assets/images/nas-icon.png';

// Components
import ReactGA from 'react-ga4';
import jwtDecode from 'jwt-decode';
import { useIdleTimer } from 'react-idle-timer';
import Swal from 'sweetalert2';
import * as Sentry from '@sentry/react';
import ErrorFallback from './layouts/components/ErrorFallback';

// Reducer functions
import { logout, ping, setEndSession } from './reducers/authSlice';

// Routes
import routes from './routes/routes';

const selector = (state) => ({
  currentUser: state.auth.user,
  endSession: state.auth.endSession,
});

if (process.env.NODE_ENV !== 'development') {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID, {
    testMode: process.env.NODE_ENV === 'test',
  });

  if (process.env.NODE_ENV !== 'test') {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.NODE_ENV,
      release: `nrp-ui@${process.env.REACT_APP_VERSION}`,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
        new Sentry.Replay({
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
      tracePropagationTargets: ['localhost:8080', process.env.REACT_APP_SENTRY_PROPAGATION_TARGET],
      tracesSampleRate: Number(process.env.REACT_APP_SENTRY_SAMPLE_RATE),
      replaysSessionSampleRate: Number(process.env.REACT_APP_SENTRY_SESSION_SAMPLE_RATE),
      replaysOnErrorSampleRate: Number(process.env.REACT_APP_SENTRY_ERROR_SAMPLE_RATE),
      debug: process.env.NODE_ENV !== 'production',
    });
  }
}

export default function App() {
  const { t } = useTranslation('translation');
  const {
    currentUser,
    endSession,
  } = useSelector(selector, shallowEqual);
  const disp = useDispatch();

  const [controller, dispatch] = useSoftUIController();
  const {
    layout,
    sidenavColor,
    miniSidenav,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [userRoutes, setUserRoutes] = useState([]);

  const timeout = 3600_000;
  const promptBeforeIdle = 300_000;

  const [sessionTimeout, setSessionTimeout] = useState(7200);
  const [active, setActive] = useState(false);

  const onIdle = () => {
    if (currentUser) {
      disp(logout());
      window.location.href = process.env.REACT_APP_APP_URL;
    }
  };

  const onActive = () => {
    setActive(false);
  };

  const onPrompt = () => {
    if (currentUser) {
      const alert = Swal.mixin({
        customClass: {
          confirmButton: 'button button-success',
        },
        buttonsStyling: false,
      });

      alert
        .fire({
          title: t('are-you-still-here', { keyPrefix: 'common' }),
          text: t('your-session-will-be-closed-shortly', { keyPrefix: 'common' }),
          icon: 'warning',
          confirmButtonText: t('i-still-here', { keyPrefix: 'common' }),
        })
        .then((result) => {
          if (result.isConfirmed) {
            setActive(true);
          }
        });
    }
  };

  const onAction = () => {
    const checkSession = localStorage.getItem('checkSession');
    if (checkSession) {
      disp(ping());
    }
  };

  const {
    activate,
  } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    onAction,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  if (active) {
    activate();
  }

  useEffect(() => {
    if (sessionTimeout <= 0) {
      disp(logout());
      window.location.href = process.env.REACT_APP_APP_URL;
    }
  });

  useEffect(() => {
    if (currentUser) {
      const decoded = jwtDecode(currentUser.token);
      setSessionTimeout(decoded.exp - Math.floor(Date.now() / 1000));
      setUserRoutes(routes(t, currentUser));

      if (process.env.NODE_ENV !== 'test') {
        Sentry.setUser({ email: currentUser.email });
      }
    }
  }, [currentUser, t]);

  useEffect(() => {
    if (endSession) {
      disp(setEndSession(false));
      localStorage.removeItem('checkSession');
      disp(logout());
      window.location.href = process.env.REACT_APP_APP_URL;
    }
  }, [endSession, disp]);

  const getRoutes = (allRoutes) => allRoutes.map((route) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }

    if (route.route && route.sidebar) {
      if (currentUser) {
        return (
          <Route path={route.route} element={route.element} key={route.key} />
        );
      }
      return (
        <Route
          path={route.route}
          element={<Navigate replace to="/sign-in" />}
          key={route.key}
        />
      );
    }
    if (route.route && !route.sidebar) {
      if (currentUser) {
        return (
          <Route
            exact
            path={route.route}
            element={route.element}
            key={route.key}
          />
        );
      }
      return (
        <Route
          exact
          path={route.route}
          element={<Navigate replace to={`/sign-in?${route.route}`} />}
          key={route.key}
        />
      );
    }
    return null;
  });

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {layout === 'dashboard' && currentUser && (
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName="Nursing Advisory"
            routes={userRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        )}
        <Routes>
          <Route index element={<SignIn />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/terms-conditions" element={<Terms />} />
          <Route
            exact
            path="/confirmation/:confirmationToken"
            element={<Confirmation />}
          />
          <Route
            exact
            path="/unlock/:unlockToken"
            element={<Unlock />}
          />
          <Route exact path="/payment/:id" element={<PaymentForm />} />
          <Route
            exact
            path="/payment_confirmation/:id"
            element={<PaymentConfirmation />}
          />
          <Route exact path="/url/:token" element={<Links />} />

          {getRoutes(userRoutes)}
          <Route path="*" element={<Error404 />} />
        </Routes>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
}
