// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';
import colors from '../../../assets/theme/base/colors';

// Soft UI Dashboard PRO React base styles

function VoidIcon({ color, size }) {
  return (
    <svg
      width={size * 2}
      height={size}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
      viewBox="0 0 600 300"
    >
      <title>void</title>
      <g
        fill={colors[color] ? colors[color].main : colors.dark.main}
        fillRule="nonzero"
      >
        <path
          d="M321.8,241.1c-0.3-0.7-0.6-1.4-0.9-2.2c4.2-0.7,8.2-1.4,12.3-2c4.8-0.8,9.5-1.5,14.3-2.3c3.6-0.6,6.7,0.8,8.2,4.1
          c0.8,1.8,1.5,3.1,4,2.2c1.4-0.5,1.8,1,1.6,1.6c-0.6,2,0.7,2.4,2.3,2.8c-4.1,0.7-8.3,1.4-12.4,2.1c-7.8,1.3-15.5,2.5-23.3,3.7
          c-0.7,0.1-1.5-0.2-2.3-0.2c-0.7,0-1.4,0.3-2,0.5c-0.6,0.2-1.1,0.6-1.6,0.7c-7.5,1.1-14.9,2.5-22.3,3.6c-7.5,1.1-15,2.5-22.5,3.6
          c-1.3,0.2-2.6-0.1-4.1-0.2c0,0.1,0,0.5,0.1,0.9c-9.2,1.5-18.4,3-27.6,4.5c-0.9-1.7-1.7-3.4-2.5-5c-0.3,0.1-0.5,0.2-0.8,0.3
          c0.5,1,1,1.9,1.3,2.9c0.2,0.6-0.1,1.4-0.2,2.1c-0.7-0.1-1.5-0.2-2.4-0.4c-0.3,0.2-0.8,0.9-1.5,1c-4.8,0.8-9.5,1.6-14.3,2.3
          c-0.4,0.1-0.7,0-1.4,0.1c0.4-1.6,0.7-3.1,1.1-4.6l-0.4-0.1c-0.5,1-1,1.9-1.3,2.9c-0.5,1.5-1.3,2.3-3.1,2.6
          c-15.6,2.5-31.2,5-46.9,7.6c0.1-0.6,0.1-1.1,0.2-2c-0.8,1-1.3,1.6-1.9,2.4c-0.4-1.2-0.8-2.2-1.2-3.2c-0.2,0-0.5,0.1-0.7,0.1
          c0.2,0.9,0.3,1.9,0.5,3.1c-2.4,0.4-5,0.9-7.6,1.3c-3.6,0.6-7.1,1.2-10.7,1.7c-0.7,0.1-1.5-0.2-2.7-0.4c-2.1,2.3-5.7,1.7-8.9,2.3
          c-13.6,2.4-27.2,4.5-40.8,6.7c-1.1,0.2-2.3,0.4-3.2,0.5c-0.6-0.8-1.1-1.5-1.7-2.2l-0.7,0c0.3,3.2-2.1,3-4.2,3.4
          c-15.9,2.6-31.8,5.1-47.7,7.8c-1.5,0.2-2,0-2.3-1.4c-3.8-20.2-7.7-40.5-11.6-60.7c0-0.1,0-0.1,0-0.2c-0.8-2.2-0.8-4.1,1.5-5.6
          c0.3-0.2,0.4-0.6,0.7-1c-0.5-0.2-0.9-0.4-1.4-0.6v-2.5c-2.5,1.4-0.7,3.5-1.7,5.2c-0.6-3.2-1.2-6.2-1.8-9.1
          c-0.9-4.4-1.7-8.9-2.5-13.3c-0.1-0.6,0.2-1.2,0.4-1.9c-0.3-0.4-0.9-0.9-1-1.4c-1.6-7.8-3.1-15.7-4.5-23.5c-0.2-0.9,0.3-1.9,0.4-2.6
          c-0.5-1-1.2-2-1.5-3c-1.2-5.6-2.2-11.2-3.3-16.8c-0.1-0.7,0.2-1.5,0.3-2.4c-1.9-0.2-0.9-2.1-1.5-3.3h3.2l0.1-0.6
          c-0.5-0.2-0.9-0.6-1.4-0.6c-2.2-0.1-2.5-1.5-2.8-3.1c-0.5-3.1-1.1-6.1-1.6-9.1c-0.1-0.4,0-0.9,0.2-1.3c0.6-1.4,1.2-2.8,1.9-4.5
          c0.9,0.1,2.2,0.5,3,1.8c0.5,0.8,1.7,1.3,2.9,2.1l-2.3,0.1c0.3,1.2,0.5,2.4,1,3.4c0.2,0.5,1.2,0.7,1.8,0.8c0.2,0,0.9-0.9,0.8-1.3
          c-0.4-1.5-1-3-1.7-4.5c-0.4-0.8-0.9-1.6-1.3-2.2c0.6-1.1,1.5-2.1,1.8-3.2c0.7-3.2-2.5-5.4-6.5-4.3c-1.3,0.4-2,0.4-2.5-0.9
          c-0.2-0.5-1.1-0.8-1.6-1.3l-0.6,0.2l0.5-5.1c-0.6-0.5-1.1-0.9-1.7-1.4c1.3-0.7,2.3-1.2,3.3-1.7c-0.7-1-1.8-1-3.3,0
          c-1.6-8.2-3.1-16.3-4.7-24.7c8.4-1.4,16.8-2.7,25.4-4.1c-0.5,2.3-1.2,4.6,2.3,6c-0.4-1.5-0.6-2.7-1.1-3.8c-0.5-1.5,0.1-2.3,1.6-2.6
          c2.4-0.5,4.8-0.9,7.2-1.2c0.5-0.1,1.1,0.2,1.9,0.3c0.2-0.1,0.5-0.7,0.9-0.8c7.7-1.3,15.4-2.5,23-3.8c1.6-0.3,3.2-0.6,4.8-0.8
          c0.6-0.1,1.3,0.2,1.8,0.3c0.5-0.2,1.2-0.7,1.9-0.8c12.1-2,24.3-4,36.4-6c0.2,0,0.4,0,0.6-0.1c3-1,5.4-0.8,7,2.2
          c0.5,0.8,1.7,1.3,2.8,1.6c-1.5-1.4-3-2.8-4.9-4.6c27.9-4.5,55.2-8.9,82.8-13.4c0.3,1.1,0.4,2.1,0.8,3.1c0.1,0.4,0.7,0.6,1.1,0.9
          c0.2-0.4,0.5-0.8,0.5-1.2c0-1-0.2-2-0.3-2.9c2.4-1.2,4.2,1.2,6.8,0.7c-1.1-0.5-1.9-0.9-3.1-1.5c30.1-4.9,59.9-9.7,89.7-14.5
          c0.1,0.4,0,0.7,0.1,0.9c0.2,0.5,0.5,1,0.7,1.5c0.5-0.4,1.4-0.8,1.3-1.2c-0.1-1.7,1.1-1.7,2.4-1.9c14.1-2.3,28.2-4.6,42.3-6.9
          c0.2,0,0.5,0,0.7,0c0.3,1.4,0.6,2.9,0.9,4.1h2.8c-0.6-1.4-1.3-2.9-2-4.7c2.5-0.1,4.7-0.1,6.9-0.5c2.6-0.4,5.1-1.2,7.7-1.7
          c4-0.7,8-1.4,12-1.9c1.1-0.2,2.3,0.1,3.3,0.2c0.9-0.4,1.7-1,2.6-1.2c14.2-2.3,28.5-4.6,42.7-6.9c10.7-1.7,21.5-3.4,32.2-5.3
          c2.2-0.4,2.5,1.2,3.9,2.1c-2.8,1.4-5.1,2.9-3.6,6.1c0.7,1.5,2.7,2.5,4.5,2.3c2.3-0.3,3.5-1.8,4.1-5.8c2.6,0.7,4.9,0.3,6.3-2
          c0.3-0.5,0-1.2,0-1.8h-0.5c-0.8,0.8-1.5,1.6-2.3,2.4l-0.6-0.2c0.2-1.5,0.1-3,0.5-4.4c0.4-1.2,1.2-0.6,1.8,0c1.5,1.6,3,3.2,4.5,4.8
          c1-0.6,0.9-1.6-0.1-3c1.1-1.3,2.1-2.4,3.2-3.5c0.4-0.4,0.9-0.6,1.4-0.7c4.8-0.8,9.7-1.6,14.5-2.4c0.6-0.1,1.3,0,1.7,0.3
          c1.8,1.3,3.6,2.7,5.7,4c-2.3-1.7-1.2-3.6-0.3-5.5c22.5-3.7,45.4-7.4,68.2-11.1c1.9,1.8-0.8,2.8-0.7,4.5c0.7-0.4,1.1-0.6,1.8-1
          c1.6,8.4,3.2,16.8,4.9,25.3c-1.2-0.4-2.1-0.6-2.9-0.9c4.1,2.9,4.1,7.2,5,11.2c0.6,2.8,1,5.6,1.6,8.4c0.7,3.1,0.2,3.8-2.9,4.6
          c-0.7,1.2-1.3,2.3-1.8,3.3c1.7,0.1,1.8,0.5,1.2,2.2c-0.5,1.4-0.7,2.9-1.1,4.8c1.8-1.5,2.8-2.8,2.5-4.8c-0.3-2.1,0.7-3.5,3-4.4
          c0.6,3,1.2,5.9,1.7,8.9c0.6,3.3,1,6.7,1.6,10c0.5,2.6,1.3,5.1,1.8,7.6c1.1,6.8,2.8,13.5,3.9,20.3c0.2,1-0.7,2.2-1.1,3.2
          c-0.4,1.1-1.1,2.1-1.4,3.2c-0.6,2-1.6,3.8-3.4,5.5c-1.8-2.9,1-5.3,0.3-7.8c-0.8,2.5-1.6,4.9-2.4,7.4c-1.6-0.5-2.6-2.1-4.2-0.4
          c-0.9-4.4-1.6-8.4-2.4-12.4c-1.1-5.8-2.1-11.7-3.4-17.5c-0.5-2.2-0.1-3.9,1.4-5.8h-1.3c0.2-1.4,0.4-2.7,0.6-4
          c0.2-1.3,1.1-2.6,0.1-3.8c-3.6,2.1-1.1,5.2-1.8,7.9c-1-5.1-2-10.2-3.1-15.8c0.8,0.6,1.4,0.9,1.6,1.3c0.7,1.6,2,2.6,3.6,3.3
          c1.5,0.7,2.2,1.6,1.3,3.1c-0.2,0.2,0,0.6,0.1,1.2c1-1.9,2.3-1.4,3.7-0.5c0.1-0.1,0.3-0.3,0.4-0.4c-0.6-0.8-1.2-1.5-1.8-2.3
          c0.3-0.6,0.7-1.2,1-1.7c-1.3,0.1-2.7,0.5-3.4,0.1c-0.8-0.5-0.9-1.9-1.4-3.1c-0.9,1.6-1.7,1.4-2.3-0.1c-0.2-0.3-0.6-0.8-0.9-0.8
          c-2-0.1-2-1.4-2.3-2.7c-2.1-11.1-4.2-22.1-6.4-33.1c0-0.2,0-0.3,0-0.5c1.1,0.8,2.2,1.6,3.4,2.3c0.2-0.2,0.5-0.4,0.7-0.5
          c-5.1-1.7-4.9-5.9-5.7-9.7c-2.1,0.3-4,0.8-5.9,0.9c-4.1,0.2-8.1,1.2-12.5,2.2c0.3-1.1,0.4-1.7,0.6-2.3c1.4-0.5,2.9,1.6,3.7-0.6
          c-2.7-1.5-4.5-1-5.4,1.9c-0.7-0.4-1.1-0.6-1.6-0.9c-0.1,0-0.2,0.1-0.3,0.1c-0.9,0.5-1.7,1.1-2.6,1.6c-0.9,0.5-1.9,1-2.9,1.2
          c-0.9,0.2-2,0.3-2.8,0c-1.3-0.5-2.4-0.7-3.6,0.1c-0.7,0.4-1.6,0.5-2.5,0.6c-1.6,1.5-4.3,2.2-6.1,1.5c-2.4,1-4.6,1.7-7.1,1.1
          c-0.4-0.1-0.9,0.1-1.4,0.2c-5.1,1-10.2,2.1-15.3,3c-0.5,0.1-1.1-0.5-1.2-0.5c-1.1,0.5-1.9,1.1-2.9,1.3c-19.5,3.2-38.9,6.3-58.4,9.4
          c-2.4,0.4-4.7,0.8-7.1,1.1c-0.9,0.1-1.8-0.1-2.6-0.2c-0.7,0.3-1.5,0.9-2.3,1.1c-12.3,2-24.6,3.9-36.8,5.9
          c-8.2,1.3-16.4,2.7-24.7,4.1c-0.3-0.5-0.5-1-0.9-1.8c-0.4,0.9-0.6,1.5-1.2,2.7c-0.8-2.7-2.3-3.7-4.7-3.3c0.2,1.1,0.4,2.1,0.6,3.3
          c-7.3,1.2-14.6,2.4-21.5,3.5l-2.5-1.6l-0.4,0.5l1.7,1.2c-2.3,0.4-4.3,0.7-6.2,1l-0.2-0.3c0.7-0.5,1.3-1.1,2-1.6
          c-1.2-2.3,2-3.3,1.6-5.6c-0.9,1.1-1.8,2.1-2.6,3.3c-0.9,1.4-1.5,3.1-2.7,4.3c-0.6,0.6-2.2,0.6-3.3,0.8c-7.8,1.3-15.6,2.5-23.5,3.8
          c-0.8,0.1-1.6-0.1-2.7-0.1c-1,1.1-6.3,2-7.2,1.5c-6.4,1.7-12.7,2.2-18.9,3.3c-0.4,0.1-0.8-0.5-1.1-0.6c-0.7,0.4-1.3,1-2.1,1.1
          c-3.4,0.6-6.8,1.1-10.3,1.7c-0.4-0.7-0.7-1.3-1-1.9c0,2.8-2.3,2.5-4.1,2.8c-4.7,0.8-9.4,1.5-14.1,2.3c-1.5,0.3-2.7,0.3-3.7-1
          c-0.6-0.7-1.4-1.4-2.9-0.5c1.1,0.7,2,1.3,3.2,2.1c-10.9,1.8-21.4,3.5-32,5.2c-0.4-0.9-0.7-1.7-1.1-2.7l-1.9,1
          c0.7,0.6,1.4,1.1,2.3,1.8c-3.3,0.5-6.2,1-9.1,1.5c-2.5,0.4-5.1,0.7-7.6,1.2c-1.4,0.3-2.5,0.4-3-1.4c-1.9,3.6-5.6,2.7-8.5,3.2
          c-8.8,1.7-17.7,3-26.5,4.4c-0.2,0-0.4,0-0.8-0.1c0.1-0.7,0.3-1.4,0.4-2l-4.9-0.6c1.2,0.9,2.3,1.6,3.4,2.4l-0.6,0.7
          C107.7,88,87,91.4,66.2,94.8c0-3.6-0.3-3.8-5.2-3.6c0.3-0.5,0.4-0.9,0.6-1.3c0.3-0.4,0.6-0.8,0.9-1.1c-0.7-0.1-2-0.4-2.1-0.2
          c-0.5,0.9-1.1,2.1-0.9,3c0.1,0.6,1.6,0.9,2.6,1.2c0.4,0.1,0.9-0.2,1.3-0.2c0.3,0,0.6,0.3,0.9,0.5c-0.3,0.4-0.6,1-0.9,1
          c-1,0-1.2,2.8-2.6,0.7c-1.2-1.6-2.6-2.5-4.7-2.2c-2.1,0.3-3.1,1.8-3.5,3.6c-0.1,0.3-0.5,0.8-0.8,0.8c-1.4-0.1-2.9-0.4-4.8-0.7
          c-0.1,0.2-0.4,0.8-0.7,1.6c-3.5,0.6-7.2,1.2-10.9,1.8L34.7,98l-0.4,0l-0.4,2c-1.7,0.3-3.4,0.6-5.3,0.9c-0.2-1.3-0.3-2.5-0.5-3.9
          c-1.8,1.2-1.8,2.6-1.5,4.2c-2.6,0.4-5.1,1-7.6,1.3c-1.9,0.2-2,0.9-1.7,2.3c0.9,4.4,1.8,8.9,2.6,13.3c0.2,1,0,2-0.3,3
          c0.6,1.1,1.2,2.3,1.8,3.6c-0.8,0.2-1.3,0.4-1.8,0.4c-0.6,0-1.2-0.1-1.8-0.2c0.4-0.4,0.8-0.8,1.1-1.2c0.2-0.3,0.4-0.6,0.5-0.8
          c-0.4-0.2-0.7-0.5-1.1-0.5c-1.3,0-2.6,1.3-2.6,2.5c0,1.2,1.4,2.2,2.9,2c0.4-0.1,0.9-0.3,1.3-0.2c0.4,0,0.7,0.4,1.1,0.6
          c-0.2,0.3-0.4,0.8-0.7,0.8c-1.3,0.2-1,0.8-0.8,1.7c0.3,1.6,0.3,3.2,0.5,5.1c2.2-1.6-0.3-4,1.8-5.5c0.5,2.5,1,5,1.5,7.5
          c0.9,4.4,1.7,8.9,2.5,13.3c0.1,0.8-0.1,1.7-0.2,2.5c0.3,0.6,0.9,1.2,1,1.9c2.9,15.2,5.8,30.5,8.8,45.8c4.2,21.8,8.4,43.5,12.6,65.3
          c1,5.1,1.9,10.3,2.9,15.4c0.1,0.5,0.2,0.9,0.4,1.6c3.6-0.6,7.1-1.1,10.7-1.7c8-1.3,15.9-2.7,23.9-3.8c4.7-0.7,9.3-1.7,14.1-2.4
          c4.9-0.7,9.7-1.7,14.5-2.2c1.9-0.2,3.9,0.4,5.9,0.6c-0.4-1.6,0.9-1.8,2.4-2c5.9-0.9,11.7-1.9,17.6-2.8c0.6-0.1,1.4,0.3,2.1,0.5
          c1.3,0.4,2.5,0.8,3.7,1.2c0.7,0.2,1.5,0.4,2.3,0.5c-0.3-1.6,1.2-3.7,2.9-3.9c20.3-3.3,40.6-6.6,60.9-9.9c0.3,0,0.6,0,1,0
          c-0.8,3.3-2.2,4.1-4.7,2.4c-0.4,1.1-0.7,2.2-1.2,3.5c0.7,0,1.1,0.1,1.2,0c2.2-1.8,4.3-3.7,6.5-5.5c0.4-0.4,0.9-0.9,1.4-1
          c4.6-0.8,9.3-1.5,13.9-2.3c0.2,0,0.4,0,1,0.1c-0.9,3.9,0.1,7.4,2.5,10.7c0.7-2.7-2.7-5.2-0.8-7.7c-0.4-1.2-0.7-2.2-1-3.3
          c4.7-0.8,9.1-1.5,13.4-2.2c18.1-2.9,36.1-5.9,54.2-8.8c6.1-1,12.2-1.9,18.3-3c1.4-0.2,2.4-0.1,2.8,1.3c0.1,0.3,0.5,0.5,0.7,0.8
          L321.8,241.1L321.8,241.1z M536.7,16.1c1.7,0.9,3.2,0.4,4.9-0.4c1.3-0.7,3.2-0.5,4.8-0.6l-0.1-0.7h-3c-0.1-0.2-0.2-0.5-0.2-0.7
          c1.4-0.6,2.7-1.4,4.1-1.8c1.6-0.4,1.6,1.2,2.2,2c0.2,0.3,0.9,0.3,1.4,0.5c0-0.5,0.2-1,0-1.4c-1.8-3.2-5.4-3.5-7.6-0.6
          C541.4,14.4,539.6,15.9,536.7,16.1L536.7,16.1z M265.5,58c0.1-0.2,0.1-0.3,0.2-0.5c0.9-0.1,1.8-0.1,2.7-0.3c0.5-0.1,1.1-0.4,1.6-0.6
          c-0.3-0.4-0.4-1.2-0.8-1.3c-1.3-0.4-2.7-0.8-4-0.6c-1.9,0.3-3.9,0.9-5.2,2.5c-0.3,0.4-0.3,0.9-0.5,1.3c0.4,0.1,1,0.3,1.3,0.1
          c0.9-0.5,1.6-1.2,2.5-1.9C264,57,264.7,57.5,265.5,58L265.5,58z M556,19.7c-0.5-0.8-0.7-1.3-1.1-1.7c-1.9-2.5-1.8-2.4,0.2-4.8
          c1.3-1.5,2.1-3.2,2.1-5.2c-0.8,0.8-1.4,1.7-2,2.6c-0.6,0.9-1.2,1.8-2,2.6C551.6,15.1,552.8,18.7,556,19.7L556,19.7z M20.3,154
          c1.5,2,4.3,1,6.2,2.2c0-2.5-2.2-4.2-3.9-3.3C22,153.3,21.2,153.6,20.3,154z M88.7,282.4c-0.3-0.1-0.6-0.2-0.8-0.2
          c-1.4,1.9-2.2,4.1-2.7,6.4c0,0.2,0.4,0.6,0.5,0.6c0.4,0,1.1-0.2,1.1-0.4C87.5,286.7,88.1,284.5,88.7,282.4L88.7,282.4z M239.5,57.2
          c0.2,0.2,0.3,0.3,0.5,0.5c0.4-0.4,1-0.6,1.2-1.1c0.5-1,0.8-1.6,2.3-1c1,0.4,2.2,0.5,3.3,0.6c0.2,0,0.5-0.4,0.8-0.6
          c-0.3-0.2-0.5-0.5-0.8-0.6c-0.6-0.2-1.4-0.2-1.9-0.5c-2-1-3-0.9-4.3,0.7C240,55.7,239.8,56.5,239.5,57.2L239.5,57.2z M258.4,56.2
          c-1.8-0.7-2.9-1.2-4.1-1.6c-0.4-0.1-1-0.3-1.3-0.1c-1.2,0.6-1.3,2.2-0.2,3.4c0.7-2.3,2.1-1.9,3.8-1
          C256.9,57.2,257.7,56.5,258.4,56.2L258.4,56.2z M199.3,71.5c-0.5-1.5-0.9-2.9-1.5-4.2c-0.1-0.3-0.6-0.5-1-0.7
          c-0.2,0.3-0.5,0.7-0.5,1c0.2,1.4,0.5,2.8,0.9,4.2c0.1,0.3,0.7,0.5,1,0.8C198.7,72.3,199,71.9,199.3,71.5L199.3,71.5z M561.5,26.8
          c-0.5-0.7-0.8-1.3-1.3-1.8c-0.9-1-1.9-1.8-2.8-2.8c-0.4-0.4-0.7-0.7-1-1.1c-0.2,0.1-0.4,0.2-0.6,0.3c0.6,1.5,1.1,3,1.7,4.6l0.5-0.5
          C559,25.9,560.1,26.3,561.5,26.8L561.5,26.8z M119.3,73.4l-0.7,0.2c0.6,1.5,1.1,3,1.8,4.7c1.7-1.2,2.6-0.3,3.4,0.7l0.5-0.4
          L119.3,73.4L119.3,73.4z M55.5,291.9c1.9,1.6,4,1.6,5.1-0.1c-1.1-0.4-2.2-0.8-3.4-1.2C56.6,291,56.1,291.5,55.5,291.9L55.5,291.9z
           M465.9,28.1c0-0.4-0.1-0.9-0.1-1.4c-1.7-0.2-3.2-0.3-4.8-0.5C461.9,28.4,463.6,28.8,465.9,28.1z M525.3,14.4
          c-1.6-1.7-2-4.2-4.9-5.6C521,11.9,523.2,13.1,525.3,14.4z M46.3,272.1c-1.3,1.9-2.6,3.9-3.8,5.8C45.1,276.6,46.6,274.2,46.3,272.1z
           M214.6,67.1l0.2-0.5c-2.2-1.1-4.4-2.3-6.6-3.4c-0.1,0.2-0.2,0.4-0.4,0.6C210,65.1,211.8,67,214.6,67.1L214.6,67.1z M419.8,33
          c0,0.3,0,0.5,0.1,0.8l2.4,1.4l0.3-0.4c-0.2-0.3-0.4-0.7-0.5-1c0.7-0.4,1.4-0.7,2.1-1.1c-0.1-0.2-0.2-0.4-0.2-0.6
          C422.6,32.4,421.2,32.7,419.8,33z M557.2,52.2l0.7,0.3c0.7-2.4,3.3-4,3.2-6.7c-0.2-0.1-0.4-0.2-0.6-0.2
          C559.5,47.8,558.3,50,557.2,52.2L557.2,52.2z M409.9,30.5c-2.7,0.6-4.7,2.9-4.4,4.8C407,33.7,408.3,32.3,409.9,30.5z M292.1,53.3
          l0.1,0.5c1.1,0.1,2.1,0.1,3.2,0.1c0.9,0,1.8-0.1,2.8-0.1l0-0.8C296.2,53.5,294.1,52.2,292.1,53.3L292.1,53.3z M42.9,89.1
          c0.8,2.9,1.6,3.1,4,1.3C45.5,90,44.3,89.6,42.9,89.1z M414.5,35.1c-0.4,0.8-0.7,1.5-1,2.2l0.3,0.3l3.6-1.7c0-0.1-0.1-0.2-0.1-0.4
          C416.4,35.4,415.5,35.2,414.5,35.1L414.5,35.1z M176.4,272.4l0,0.7c0.7,0.1,1.5,0.5,1.9,0.2c0.8-0.4,1.4-1.2,2.1-1.8l-0.4-0.3
          C178.8,271.7,177.6,272.1,176.4,272.4z M374,35.8c-1.2-0.5-2.1-0.8-2.9-1.1l-0.4,0.5c0.4,0.7,0.8,1.4,1.3,2.3
          C372.7,36.9,373.1,36.5,374,35.8z M569.5,75.4c0.1-2.2-1-3.7-2.8-3.9C567.8,72.9,568.7,74.2,569.5,75.4z M85.4,84.7l-0.5,0.3
          l2.7,3.3l0.4-0.3C87.7,86.5,86.9,85.4,85.4,84.7z M270.3,255.1c0,1.1,0,2,0,2.8c0,0.2,0.4,0.4,0.6,0.5c0.2,0,0.6-0.2,0.6-0.3
          C271.4,257.1,272.5,255.8,270.3,255.1z M130.6,279.1h-2.9l-0.2,0.4l2.2,1.3C129.9,280.4,130.1,279.9,130.6,279.1L130.6,279.1z
           M29.5,221.7c-2.2-1.2-2.6,0.2-3.4,1l0.3,0.6C27.3,222.8,28.2,222.3,29.5,221.7L29.5,221.7z M184.2,68.4l-0.6,0.1l0.5,2.7l1-0.2
          C184.7,70.2,184.5,69.3,184.2,68.4L184.2,68.4z M23.9,176.2l-0.2-0.9l-2.5,0.2c0.1,0.4,0.3,1.2,0.4,1.2
          C22.3,176.6,23.1,176.4,23.9,176.2L23.9,176.2z M413,27.7c-0.9,0.6-1.6,1.1-2.1,1.4c1,0.5,1.8,1,2.7,1.4
          C413.5,29.8,413.3,29,413,27.7L413,27.7z M142.4,77.7l0.7-0.4c-0.7-1.1-1.4-2.2-2.1-3.2l-0.7,0.4L142.4,77.7L142.4,77.7z"
        />
        <path
          d="M445.4,218.7c0.4,1.1,0.7,2,1.1,3.2c0.2-1.3,0.1-2.4,0.5-3.3c0.1-0.3,1.2-0.3,2-0.5v5.6l0.7,0.1c0.2-1.4,0.6-2.8,0.7-4.3
          c0-1.3,0.6-1.8,2-2c17.3-2.8,34.6-5.6,52.1-8.4c-0.4,0.6-0.7,1-1.2,1.9c0.9-0.1,1.8-0.1,1.9-0.3c1-2.5,3.5-2.3,5.6-2.7
          c2.8-0.6,5.7-1,8.6-1.3c0.6-0.1,1.3,0.2,1.9,0.4c0.6,0.1,1.2,0.6,1.6,0.5c0.4-0.1,0.5-0.9,0.9-1.6c12-1.9,24.3-3.9,36.4-5.9
          c0.5,0.5,0.9,0.9,1.2,1.3c0.7-0.5,1.3-1.1,2-1.6c0.6,0.9,1.4,2.1,2.2,3.2l0.5-0.2c-0.2-1.1-0.4-2.3-0.6-3.6c6-1,11.9-1.9,18-2.9
          c-0.6-3-1.2-5.9-1.6-8.8c-0.3-2.1-0.4-4.2-0.7-6.3c-0.1-0.7-0.7-1.4-0.9-2.1c-2.3-12-4.6-24-6.9-35.9c0-0.2,0-0.4,0-0.7
          c4.7,1.2,5.2,1.2,6.2-0.7c-0.9,0.1-1.8,0.3-2.7,0.1c-3.1-0.5-4.2-1.6-4.7-4.5c-1.4-7.5-2.9-15.1-4.4-22.6c0-0.2-0.1-0.5-0.1-0.7
          c0.3-0.1,0.6-0.3,0.7-0.3c2,1,4.4,1,6.3,2.3c1.1,0.7,2.7,1,4,1.4l0.2-0.6c-0.9-0.3-1.8-0.6-2.6-0.9c-1.6-0.7-1.8-1.5-0.9-3
          c1.6-2.6,3.3-5.2,5-7.8c1.2,6.3,2.4,12.6,3.6,18.9c0.4,1.8,0.7,3.6,1,5.4c0.1,0.6-0.2,1.2-0.3,1.8c0,0.3-0.2,0.7-0.1,0.8
          c2.3,2.3,1.5,5.4,2.4,8.1c0.3,1,0.4,2.1,0.6,3.2c0.4,1.9,0.1,2.2-2,2c-0.3,0-0.6,0-1.3-0.1c0.2,0.7,0.3,1.7,0.5,1.8
          c1,0.1,2.1-0.1,3.5-0.2c3.8,19.7,7.6,39.8,11.5,60.1c-3.9,0.3-7.6,1.7-12,1.3c-0.8,1.1-0.9,1.1-2.3-0.9c0.2,2.5-1.8,2.2-3.3,2.4
          c-11.1,1.8-22.1,3.7-33.2,5.4c-1.3,0.2-2.8-0.3-4.3-0.5c0,0.2-0.1,0.8-0.1,1.2c-1-1-1.9-2-2.9-2.9c-0.8-0.8-0.8-1.5,0.2-2
          c1.3-0.7,2.6-1.2,4-1.9c1-0.5,2-1,3.4-1.7c-2.6-0.7-7.1,1.9-10.5,5.4c-0.6-0.5-1.1-1.1-1.7-1.6c-0.4,0.6-0.8,1.1-1.2,1.8
          c-0.2-0.1-0.5-0.4-0.6-0.4c-1.4,0.5-2.5,1.3-3.8,1.4c-2.8,0.2-4.8,1.6-6.3,3.8c1.7-0.6,3.7-0.9,5.1-1.9c2.5-1.8,5.5-1.9,8.3-2.3
          c1.4-0.2,3.1,1.4,4.7,2.1l-0.1,0.5c-2.5,0.4-5.1,0.9-7.6,1.3c-7.8,1.3-15.6,2.5-23.5,3.7c-0.4,0.1-1.1,0-1.4-0.3
          c-0.9-0.8-1.7-1.8-2.7-2.9c1-0.2,1.6-0.3,2.3-0.4c-1.2-1.1-1.2-1-3.1-1.1c-0.6,0-1.2-0.6-2-1.1c-1.5,3.4,2.6,4,3.6,6.5
          c-1-0.3-1.7-0.4-2.2-0.7c-2.3-1.2-4.3-0.9-6,1c-0.4,0.5-1.2,0.9-1.8,1c-6,1-11.9,2-18,2.9c1.3-1.9,2.5-3.8,3.7-5.6l-0.8-0.5
          c-1.1,1.7-2.4,3.3-3.3,5c-0.6,1-1.2,1.4-2.4,1.6c-9.2,1.4-18.4,3-27.7,4.4c-0.7,0.1-1.5,0.2-2.2,0.3c-0.3-1.7-0.7-3.2-1-4.8l-0.7,0
          c-0.4,0.7-1,1.4-1.1,2.1c-0.3,2.8-2.5,3.6-5,4c-3.2,0.5-6.5,0.8-9.7,1.3c-5.8,1-11.5,2.1-17.2,3.1c-8.2,1.4-16.4,2.7-24.6,4
          c-0.8,0.1-1.7-0.3-2.9-0.6c-0.2,1.7-2,1.4-3.5,1.6c-5.1,0.9-10.2,1.6-15.2,2.5c-1.5,0.3-2.3-0.2-2.7-1.6c-0.7-2.3-2.5-3.7-5.1-4.2
          c0.3-0.6,0.6-1.1,0.8-1.5c-1.8-1.3-3.6-2.6-5.7-4.1c4-0.7,7.8-1.3,11.7-1.9c15.3-2.5,30.5-5,45.8-7.4c1-0.2,2.4-1.2,2.9,0.2
          c0.9-0.4,1.8-0.9,2.7-1.1c2.9-0.6,5.9-0.9,8.8-1.5c1.5-0.3,3-0.6,4.3,0.6c0.3,0.3,1,0.4,1.7,0.2c-0.7-0.4-1.3-0.8-2.4-1.4
          C435.4,220.3,440.4,219.5,445.4,218.7L445.4,218.7z M515.1,212.1c-2.3,0-4.2,1.7-4.2,3.8c0,2,1.9,3.9,4.1,3.9c2.3,0,4.3-1.7,4.3-3.8
          C519.4,213.9,517.5,212.1,515.1,212.1L515.1,212.1z M522.3,215.1c1.7-0.6,2.5-1.6,2-3.2c-0.5-1.6-2-1.7-3.5-1.4
          c-0.5,0.1-1-0.2-1.5-0.3c0.3-0.3,0.7-0.6,1-1c0.3-0.3,0.4-0.6,0.7-0.9c-0.4-0.2-0.9-0.6-1-0.5c-0.9,0.7-2,1.4-2.3,2.2
          c-0.4,1.2,0.5,2.1,2,2.3c0.5,0,1-0.2,1.5-0.2c0.5,0.1,0.9,0.4,1.4,0.5c-0.3,0.3-0.6,0.7-0.9,1c-0.2,0.2-0.7,0.3-0.8,0.5
          c-0.3,0.8,1.4,4.1,2.7,5C523.1,217.6,522.7,216.4,522.3,215.1L522.3,215.1z M565.2,209.5c0.3,0.2,0.8,0.5,1.3,0.7
          c0.5,0.2,1.1,0.3,1.7,0.5c0.1-0.5,0.4-1.1,0.2-1.5c-0.5-1.1-0.3-1.9,0.3-2.9c1.3-1.9,1.1-2.5-0.5-3.7
          C567.1,204.9,566.2,207.1,565.2,209.5L565.2,209.5z M597.7,205.4v-2c-1.6-0.3-3.1-0.6-4.5-0.9C592.7,203.4,594.3,204.4,597.7,205.4z
           M426.6,233.8c0.2-3-2-3.9-5.8-2.7C422,233.2,425.5,230.8,426.6,233.8z M389,235.1c0.4,0.4,0.8,0.8,1.1,1.1c3.2-2.4,3.5-3,1.9-4.9
          C391.8,233,390.8,234.3,389,235.1L389,235.1z M585.8,205.1c0.7-0.5,1.2-0.9,1.9-1.3c-3-1.1-0.5-3.2-1.5-4.7
          C584.9,201,585,202.9,585.8,205.1L585.8,205.1z M461.4,219.6c1-0.2,1.7-0.5,2.4-0.4c1.9,0.4,2.1,0.3,2.4-2.1
          C464.9,218.9,462.1,216.7,461.4,219.6L461.4,219.6z M575.3,120.3c-2,2.4-2.7,3.9-2.3,4.7C573.8,123.2,574.6,121.7,575.3,120.3
          L575.3,120.3z M584.8,171.6c-0.1-0.2-0.3-0.5-0.4-0.7c-1.1,0.4-2.2,0.7-3.7,1.2c0.9,0.7,1.4,1,1.5,1.1
          C583.2,172.6,584,172.1,584.8,171.6L584.8,171.6z M409.1,235.8c-0.3-1.9-2.6-3.6-4.7-3.6C406,233.4,407.5,234.6,409.1,235.8z
           M499.7,214.2l-0.3-0.4c-0.5,0.2-1.2,0.3-1.5,0.6c-0.3,0.5-0.3,1.2-0.4,1.8l0.7,0.2C498.6,215.6,499.2,214.9,499.7,214.2
          L499.7,214.2z M559.8,202c-2.5-0.2-3.1,0.3-3,2.2C557.7,203.5,558.6,202.9,559.8,202z"
        />
        <path
          d="M330.6,106.7c-0.9-4-1.7-7.9-2.8-11.7c-1.2-4.4-3.6-6-8.6-6.3c-1.4-0.1-2.8-0.2-4.2-0.5c-1.8-0.4-2.1-1.9-0.7-3
          c-2.1-0.7-1-2.5-1.8-3.7c3.8-1.4,9.3-1.9,9.9-0.9c1.3-1.2,4-2,7.3-1.9c0.3,0.7,0.6,1.4,0.9,2.1l0.6-0.1c-0.1-0.7-0.3-1.4-0.5-2.3
          c3.8-0.6,7.7-1.3,11.8-2c-0.3,0.6-0.5,0.9-0.7,1.2c-0.2,0.4-0.2,0.7-0.4,1.3c2.2-0.9,3.2-3.4,6-3.1v2.7c0.5-0.6,0.8-0.8,0.8-1
          c-0.1-2.3,1.7-2.5,3.6-2.7c2.2-0.3,4.3-0.7,6.7-1.1c-0.3,1-0.5,1.8-0.7,2.6l0.4,0.5c0.9-4.8,5.1-3.4,8.4-4.4
          c0.4,2.2,0.9,4.2,1.1,6.3c0.1,0.4-0.4,1-0.8,1.2c-1.3,0.7-2.5,1.4-3.9,1.9c-4.2,1.5-5.7,4.5-5.4,8.3c0.3,3.2,0.9,6.3,1.4,9.5
          c0.1,0.8,0.3,1.6,0.4,2.5c-1.3,0-2-0.5-3.6-2.8l0.5-1.9h-2.2c-0.2-0.3-0.6-0.8-1.1-1.5c-0.6,1.3-1.2,2.3-1.8,3.5
          c2.5,0.7,4.7,1.9,6.1,4.2c0.2,0.4,1.4,0.3,2.4,0.5c0.7,3.7,1.5,7.5,2.2,11.4c0.7,3.9,1.3,7.8,2,11.7c0.7,3.7,1.6,7.3,2.5,11.1
          c-1.2-0.1-2.1-0.3-3.3-0.4c0.3,0.4,0.4,0.6,0.5,0.6c3,0.4,3.2,2.5,3.6,4.7c1.8,9.8,3.7,19.5,5.6,29.3c0.9,4.6,1.8,9.2,2.7,14
          c-1.3-0.3-2.5-0.6-4.3-1c1.9,1.4,3.2,2.5,4.7,3.4c1.1,0.6,1.2,0.4,0.8,1.8c0.5,0.2,1.2,0.2,1.6,0.5c1.5,1.3,3.3,1.8,5.2,2
          c0.6,0.1,1,0.7,1.7,1.1c0.3-0.2,1-0.7,1.7-0.8c1.7-0.2,2.7,1,3.1,2.2c0.6,1.5,0.6,3.1,1,5c-18,2.9-36,5.8-54.3,8.8
          c-0.1-1.6-0.2-3-0.3-4.4c0-0.2,0.1-0.4,0-0.5c-1.8-1.9,0.2-2.2,1.4-2.8c1.4-0.7,2.9-1.3,4.3-2.1c3.5-1.9,5-4.7,4.3-8.4
          c-2.4-12.9-4.9-25.7-7.4-38.6c-1.4-7.5-2.9-15-4.3-22.6c1.9,0.2,4,2.2,5.5-1.1c-2.3,1.8-3.7-0.5-5.7,0c-0.3-1.7-0.7-3.2-0.9-4.4
          c0.8-1,1.4-1.7,2-2.4c-0.7-0.1-1.4-0.3-2.5-0.5c-0.2-1-0.5-2.3-0.8-3.7c-0.5-2.6-1.1-5.3-1.4-7.9c-0.1-0.7,0.5-1.7,1.1-2.2
          c0.4-0.3,1.5-0.1,2.1,0.1c0.9,0.4,1.7,1.1,2.8,1.9C336.3,107.5,333.1,106.1,330.6,106.7L330.6,106.7z M344.7,113.4
          c0.5-0.1,1.1-0.2,1.3-0.4c0.7-0.7,1.2-1.5,1.8-2.3c1,0.5,1.9,0.9,2.8,1.4c0.5-2.2,0.5-2.2,2.9-3.5c1.5-0.8,2.4-2,2.3-3.7
          c-0.1-1.7-1.6-3.3-3.4-3.7c-1.7-0.3-3.7,0.6-4.6,2c-0.8,1.4-0.5,3.4,0.8,4.6c0.4,0.4,0.8,0.9,1.2,1.3c-7.8-4-7.5-2.8-9.2-0.4
          c-0.9-0.9-1.7-1.8-2.6-2.6c-0.3-0.2-0.9-0.1-1.3-0.1c0,0.4-0.1,0.9,0.1,1.2c1.1,1.3,2.2,2.6,3.3,3.9c-2-0.1-3.7,0.5-5.3,1.5
          c-1.7,1.1-2.3,3.4-1.3,4.8c1.4,1.9,3.1,3.6,4.8,5.4c0.2,0.2,0.9,0.1,1.3,0.2c0-0.4,0.1-0.9-0.1-1.2c-0.4-0.6-0.9-1.1-1.4-1.7
          c0.4-0.3,0.6-0.4,0.8-0.5c3.5-1.7,4-3.8,1.7-6.9c-0.3-0.4-0.3-1-0.5-1.5c1.2,1.2,2.3,2.5,3.5,3.7c0.8,0.9,1.5,1,2.1-0.1
          C345.4,114.3,345.1,113.8,344.7,113.4L344.7,113.4L344.7,113.4z M345.1,125.8l0,0.5c1.1-0.1,2.2-0.2,3.3-0.3c1.6-0.1,2.9,0.5,3.2,2
          c0.2,0.9,0.4,2.6,0,2.9c-2.5,1.2-3.2,3.5-4.5,5.4c-0.2,0.3-0.1,0.8-0.1,1.3c0.5-0.1,1.1-0.2,1.5-0.5c0.3-0.2,0.4-0.7,0.7-1.1
          c0.9-1.2,1.8-2.5,2.9-3.7c0.8-0.9,1.7-1.2,2.6,0.1c0.6,0.9,1.3,0.6,2.1,0.1c1.1-0.8,2.3-1.4,3.6-2.3c-0.4-1.4-1.5-1.6-3.3-0.4
          c-0.6,0.4-1.3,1-1.9,0.9c-1.5-0.1-3-2.6-2.8-4l2-0.1C351.4,124.5,348.3,124.2,345.1,125.8L345.1,125.8z M349.3,100.1
          c-2.9-2-5.5-1-7.4,2.9c0.9-0.2,1.8-0.4,2.6-0.9c1.1-0.7,1.8-2.1,3.6-1.4C348.3,100.9,348.8,100.4,349.3,100.1z M336.4,95.7l-0.5,0.3
          c1,1.8,3.3,3.4,1,5.7l0.4,0.2c1.3-0.5,2.6-1,4.2-1.6c-1.4-0.9-2.5-1.3-3.3-2C337.5,97.6,337,96.6,336.4,95.7L336.4,95.7z
           M336.7,104.9c-2.2-2.4-4.8-2.7-5.6-0.7C335.2,105.3,335.9,105.4,336.7,104.9z M365.1,180.5l-0.1,0.6c2.9,1.4,4.8,1.5,5.9,0.3
          C368.9,181,367,180.8,365.1,180.5L365.1,180.5z M348.5,172l-0.7-0.2c-0.2,0.6-0.5,1.2-0.4,1.7c0.5,2,2.6,3.7,4.4,3.6
          C351.3,174.9,347.3,174.8,348.5,172L348.5,172z M364.1,181.1c-0.5-2.1-2.3-2.9-4.3-3.7C360.3,179.7,361.8,180.8,364.1,181.1z
           M371.5,176.3l-0.2-0.5c-2.1-0.2-5.6,1.3-6.5,3.2C367.3,178,369.4,177.1,371.5,176.3L371.5,176.3z M343.7,205.9
          c0.1,0.1,0.2,0.2,0.2,0.2c0.3-0.1,0.7-0.1,0.8-0.3c0.7-0.9,1.2-1.8,1.8-2.7c-0.9,0-1.9,0-2.9,0c-0.2,0-0.4,0.2-1.1,0.6
          c1.1,0.2,1.8,0.3,2.7,0.5C344.7,204.9,344.2,205.4,343.7,205.9L343.7,205.9z M369.6,198.4l-0.4-0.9c-1.2,0.5-2.4,0.9-3.5,1.5
          c-0.2,0.1-0.3,0.6-0.2,0.7c0.1,0.2,0.6,0.3,0.8,0.2C367.5,199.5,368.6,199,369.6,198.4z M361,127.6c-1.5-0.6-3.1-1.7-4.7,0H361z
           M346.2,106.4c0.2-0.3,0.4-0.5,0.6-0.8c-1-0.6-2-1.2-3-1.8c-0.2,0.2-0.4,0.5-0.5,0.7C344.2,105.2,345.2,105.8,346.2,106.4z
           M351.6,179.3c-0.1,0-0.3,0-0.4,0c-0.1,1-0.1,2-0.2,3.4c0.9-0.7,1.3-1.1,1.8-1.6c-0.3-0.3-0.6-0.5-0.8-0.8
          C351.8,180.1,351.8,179.7,351.6,179.3L351.6,179.3z"
        />
        <path
          d="M406.4,67.6c1,0.1,2,0.2,3.1,0.3c-0.2-0.7-0.3-1.5-0.6-2.4c4.1-0.7,8-1.4,12.3-2.2c0.6,3.2,1.2,6.2,1.8,9.2
          c1,5.3,2,10.5,3,15.8c0.4,1.9-0.2,2.7-2.5,3.7c1.1,0.3,2.1,0.6,3.4,1c1.1,5.9,2.3,12,3.5,18.1c1,5,1.9,10,2.9,15
          c0.2,1.1,0.4,1.9-1,2.4c-0.3,0.1-0.5,0.7-1,1.5c1.1-0.4,1.7-0.5,2.6-0.8c4,20.7,7.9,41.4,11.9,61.9c-0.2,0.3-0.3,0.4-0.4,0.4
          c-0.4,0.1-0.9,0.1-1.6,0.2c-0.4-1.5-0.8-3-1.2-4.5l-0.5,0.1c0.2,1.5,0.4,2.9,0.6,4.7c-12.6,2-25.5,4.1-38.6,6.2
          c-0.4-2.1-0.8-3.9-1-5.7c-0.1-0.4,0.3-1.1,0.7-1.4c1.4-0.8,2.8-1.4,4.3-2.1c4.7-2.2,6.3-5.2,5.4-9.9c-1.5-8-3.1-16.1-4.6-24.1
          c-0.1-0.7-0.9-1.7,1.2-1.6c-2.3-0.8-2.1-2.2-1.9-3.7c0-0.3,0.3-0.7,0.1-0.8c-1.6-2-0.8-4.1-0.6-6.2c0.1-1.6-1-3.2-1.5-4.8
          c-0.6-2-1.3-4-1.8-6c-0.5-2.2-0.8-4.4-1.3-7.2c0.9,0.7,1.4,1.1,1.5,1.3c1.5-0.1,2.8-0.3,4.1-0.2c1.2,0,2.4,0.1,3.5-1.4
          c-2.7-0.5-5-0.9-7.8-1.4c2.5-1,4.4-1.9,6.4-2.7c-2.9-0.5-5.4,2.5-8.6-0.4c-1-5.4-2.2-11.6-3.3-17.5c1.2-0.3,2.1-0.6,3-0.8l-0.1-0.8
          c-1-0.1-2-0.3-3.2-0.4c-0.7-3.7-1.4-7.4-2.2-11.2c-0.3-1.6-0.6-3.3-1-4.9c-1.1-4.1-3.7-6.1-8.3-6.6c-4.1-0.4-6.5-2.5-7.1-6.2
          c0-0.3-0.1-0.6-0.2-1.2c9.3-1.5,18.6-3,28.3-4.6C407.2,66.5,406.8,67.1,406.4,67.6c-1.9,1.9-3.9,3.7-6.1,5.7
          c0.8,0.3,1.2,0.7,1.6,0.6c0.9-0.1,1.8-0.5,2.6-0.8c0.1-0.5,0.1-1.1,0.3-1.7C405.3,70.2,405.9,68.9,406.4,67.6L406.4,67.6z
           M414.4,128.5c1.4,0,2.5-0.1,3.5,0c1.9,0.2,3.9,0.7,5.8,0.9c2.2,0.2,3.2-0.7,3.1-2.4c-0.6,0.2-1.2,0.5-1.8,0.5
          c-2.6,0-5.3,0.2-7.8-0.3C415.4,126.8,415.1,127.4,414.4,128.5L414.4,128.5z M405.9,88.6l0.5-0.1c0.3-2.4,0.7-4.8,1-7.2
          c-3.6-0.4-2.3,2.6-3.3,3.9C402.9,86.9,406.1,87,405.9,88.6z M404.2,88.1c-1.7,2.4-1.6,4.7,0.1,5.9C405.8,92.1,406.9,90.1,404.2,88.1
          z M413.8,149.4l-1.1,0.1c0.1,0.7,0.1,1.3,0.2,2c0.2,0.9,0.7,1.8,0.7,2.7c-0.1,1.7,1.1,2.1,2.2,2.7c0.2-0.1,0.5-0.2,0.7-0.3
          C415.6,154.2,414.7,151.8,413.8,149.4L413.8,149.4z M389.6,71.7l-0.4,0.4c1,0.9,1.9,2.4,3,2.4c1.2,0.1,3.3,0.2,3.7-1.4
          C393.7,72.6,391.6,72.2,389.6,71.7L389.6,71.7z M411.6,165.1c3.4-0.6,2-3.1,2.7-4.9C411.2,160.9,412,163.1,411.6,165.1z M409.6,70.4
          l-0.5,0.2c0,0.7-0.1,1.4,0,2.1c0.1,0.5,0.2,1.1,0.5,1.6c0.1,0.1,1,0,1.1-0.2c0.3-0.5,0.5-1.3,0.4-1.8
          C410.9,71.6,410.2,71.1,409.6,70.4L409.6,70.4z M420.1,190.2c0.3-0.1,0.6-0.1,0.9-0.2l-1.4-5.5c-0.3,0.1-0.6,0.1-0.9,0.2
          C419.2,186.5,419.7,188.4,420.1,190.2z M414.8,157.5l-0.3,0.4c0.4,0.7,0.7,1.3,1.1,2c0.2,0.3,0.6,0.8,0.8,0.7
          c0.4-0.1,0.9-0.4,1.1-0.8c0.1-0.2,0-0.9-0.3-1.1C416.6,158.2,415.7,157.9,414.8,157.5L414.8,157.5z M418,180.8
          c-0.3-0.7-0.4-1.2-0.7-1.5c-0.5-0.4-1.2-0.5-1.8-0.7c-0.3-0.1-0.9-0.3-1-0.2c-0.3,0.3-0.3,0.8-0.4,1.1
          C415.3,180,416.4,180.3,418,180.8L418,180.8z M413.8,97.2l0.4,0.6c1.5-0.9,3.1-1.7,4.6-2.6c-0.1-0.2-0.2-0.3-0.3-0.5
          C417,95.5,415.1,95.9,413.8,97.2L413.8,97.2z M439.6,168.3c0.3,0,0.5,0,0.8-0.1l-0.5-3.8l-0.8,0.1
          C439.2,165.8,439.4,167,439.6,168.3L439.6,168.3z M416.7,163.5l-0.7-0.1v4.8l0.3,0L416.7,163.5L416.7,163.5z M406,100.9
          c-0.2-1.5-0.4-2.5-0.5-3.7C404.1,98.4,404.1,99,406,100.9z"
        />
        <path
          d="M444.4,68.9c-0.1-0.5-0.2-0.9-0.3-1.3c-0.2,0-0.4-0.1-0.4-0.1c-1.2,1.4-2.8,1.6-4.6,1.9c-2.6,0.4-5.3,0.9-8.2,1.5
          c0.5-0.9,0.9-1.6,1.3-2.4c-3.6-1.2-1.7-4.2-3-6c0.1-0.2,0.2-0.4,0.3-0.6c6.9-0.7,13.8-1.7,20.7-2.1c4.3-0.2,8.7,0.2,12.8,1
          c4.3,0.8,8.6,2,12.5,3.6c12.7,5.3,21.6,14.2,28.3,25.3c0.8,1.4,1.5,2.9,2.3,4.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.5-0.3-0.9-0.7-1.4-1
          c-0.6-0.3-1.7-0.6-1.8-0.5c-1.4,1.8-3.5,2.5-5.7,3.1c-0.2,0.1-0.3,0.3-0.4,0.4c1.2,0.1,2.5,0.3,3.7,0.2c0.6,0,1.2-0.4,1.6-0.8
          c1.7-1.5,2.4-1.4,3.6,0.5c0.2,0.3,0.5,0.7,0.9,1.2c0.3-0.6,0.6-1,0.8-1.4l0.6-0.1c1.6,5.5,3.3,11.1,4.9,16.6
          c0.1,0.5-0.2,1.1-0.4,1.8c1.7,1.1,1.9,4.4,0,5.4c2.7,1.6,2.2,3.8,1.6,6.3c-0.7-0.3-1.2-0.5-1.7-0.8c-0.8,0.5,0.1,1.3,1.7,1.5
          c0.2,0,0.4,0,0.7,0.1c0.4,7.8-0.1,15.5-2.8,22.9c-1.1,3-1.6,6.2-3.4,9c-1.9,2.9-3.5,5.9-5.6,8.7c-3.7,5-8.4,9.2-13.9,12.6
          c-8,4.8-16.7,7.6-26.1,9c-1.6,0.2-3.2,0.5-4.8,0.7c-0.2,0-0.5,0-1,0c0.7-1,1.3-1.8,1.9-2.6l-0.2-0.2c-0.9,0.5-1.9,1-2.9,1.6
          c0.1,0.4,0.2,0.9,0.4,1.5c-0.7,0.1-1.3,0.3-2.1,0.5c-0.6-2.9-1.1-5.7-1.6-8.6c4.7-0.8,9.4-1.6,14.1-2.4c0.2,0.8,0.4,1.8,0.7,2.9
          l0.6-0.1c0-0.4,0.2-0.8,0.1-1.1c-0.8-1.9,0.1-2.8,1.9-3.7c7.8-3.8,11.4-10.3,12.9-17.9c2-10.2,1.1-20.4-0.6-30.5
          c-1.3-8-3-15.9-5-23.8c-2.3-9.2-5.8-18.1-11.9-25.8C460,73,453.1,69.4,444.4,68.9L444.4,68.9z M491.7,166.9
          c3.9-2.7,8.7-1.2,11.2-2.1c-0.1-0.3-0.4-1.1-0.7-1.8c0.2-0.4-0.2-0.3-0.3-0.1c-1.3,1.7-3.8,1.7-5,1.3c-2.7-0.9-4.6,1.1-6.9,0.6
          l-0.1,0.5C490.5,165.9,491.1,166.4,491.7,166.9L491.7,166.9z M507.8,126.2c0.5,0.4,0.9,0.8,1.4,1.2c0.5-0.8,1-1.7,1.7-2.8
          c-3.4,1.7-6.3,0.8-8.6-1.9c-1.3-0.3-2.1-0.5-3-0.7c0.4,0.8,1.1,1.3,1.8,1.8c0.9,0.6,2,1,2.6,1.7C504.9,127,506.1,127.5,507.8,126.2
          L507.8,126.2z M488.7,116.9c2.4,1.3,4.4-0.5,6.4-0.1C493,115.1,490.8,115.2,488.7,116.9z M435.5,68.4c1.3-0.3,2.5-0.4,3.5-0.7
          c0.3-0.1,0.5-0.7,0.5-1.1c0-0.2-0.7-0.5-1-0.5C437.3,66.3,435.9,66.3,435.5,68.4z M463,181.3c-2.3-0.6-3.3,0.3-3.4,2.9
          C461,183,462,182.1,463,181.3z M458.9,64l-0.6,0.1c0.3,1.3,0.7,2.7,1,4L460,68C460.8,66.4,459.6,65.3,458.9,64L458.9,64z
           M504.6,98.6l-0.2,0.6c0.8,0.4,1.5,0.8,2.3,1.1c0.4,0.1,0.9-0.1,1.3-0.2c-0.2-0.4-0.3-1-0.6-1.1C506.6,98.8,505.6,98.7,504.6,98.6
          L504.6,98.6z M442.3,65.4l0.6,0.7c0.9-0.4,1.9-0.9,3.4-1.6c-1-0.4-1.7-0.9-2-0.8C443.5,64.2,442.9,64.8,442.3,65.4L442.3,65.4z
           M504.6,134.1c0.3-1.5,0.5-2.8,0.8-4.3C503,132.1,502.9,133.2,504.6,134.1z M488.6,175.8l0.5-0.6c-1.2-0.9-2.5-1.8-3.7-2.8l-0.5,0.6
          C486.2,173.9,487.4,174.9,488.6,175.8L488.6,175.8z M489.2,100.9c0-0.2-0.1-0.4-0.1-0.6c-0.5,0.1-1.3,0-1.6,0.4
          c-0.7,0.6-1.4,1.4-0.3,2.5c0.6-0.6,1.1-1.1,1.7-1.6C488.9,101.3,489,101.1,489.2,100.9L489.2,100.9z"
        />
        <path
          d="M157.9,220c-1.8-1.4-3-0.2-3.9,0.9c-0.2,0.2,1,1.4,1.8,2.6c0.7-1,1.1-1.6,1.4-2.1l0.5,0.2c-0.4,3-0.8,5.9-1.3,8.9
          c-0.4,2.4-0.7,4.8-1.1,7.2c-0.1,0.5-0.7,1.1-1.2,1.2c-4.2,0.8-8.4,1.5-12.6,2.1c-0.6,0.1-1.6-0.2-1.9-0.6c-3.2-5.5-6.4-11-9.5-16.5
          c-0.1-0.1,0-0.2-0.1-0.5h6.2c-0.2-0.8-0.3-1.4-0.3-1.7c-2,0.1-3.8,0.4-5.7,0.4c-0.6,0-1.4-0.5-1.7-1c-6.7-11.5-13.3-23.1-19.9-34.6
          c-1-1.8-2-3.5-3.1-5.4c1.5-1,2.3-2.8,4.8-2.1c0.7,0.2,1.9-1.6,2.6-2.7c-2,0.7-4,1.4-6,1.9c-1.5,0.4-3,0.3-3.9-1.3
          c-7.1-12.5-14.3-25-21.4-37.5c-0.4-0.6,0-1.6,0-2.5c-0.3,0.1-0.7,0.2-1.2,0.3c-0.7-1.2-1.4-2.3-2.2-3.5c1.1-1.5,2.3-3,3.4-4.5
          l-0.3-0.3c-1.2,1.3-2.4,2.6-3.8,4.1c-2.8-2.8-6.4-3.8-10.3-4.1c-1.8-0.1-2.6-0.9-2.8-2.5c-0.1-1.5-0.6-3-0.9-4.7c4.1-0.7,8-1.3,12-2
          c3.3-0.5,6.6-1,9.9-1.6c1.4-0.2,2.8-0.6,3.7,1.3c0.3-0.9,0.5-1.4,0.8-2c3.6-0.6,7.2-1.1,10.8-1.7l0.4,0.5c-1,0.9-2,1.8-3.2,2.8
          c0.6,0.9,1.4,2,2.1,3l0.6,0c-0.8-2.3,0.4-4.1,1.7-5.8c0.4-0.5,1-1,1.6-1.1c5.1-0.9,10.3-1.7,15.6-2.6c0.4,1.9,0.9,3.7,1.1,5.6
          c0.1,0.4-0.4,1.1-0.9,1.4c-1.8,1-3.7,1.9-5.6,2.8c-4.6,2.4-5.9,5.4-3.6,9.8c4.6,8.8,9.5,17.5,14.3,26.2c1.2,2.1,2.3,4.2,3.5,6.3
          l-0.4,0.5c-0.8-0.4-1.6-0.8-2.3-1.3c-0.8-0.6-1.6-1-2.6,0c0.7,0.4,1.2,0.8,1.8,1c1.9,0.6,2.2,1.7,1.7,3.3c-0.6,2-1.2,4-1.6,6
          c-0.2,0.7,0,1.4,0.3,2.2c1.4-3.2,1.3-6.9,4.2-9.8c1.8,3.1,3.4,6.1,5,9c3.8,6.8,7.6,13.6,11.4,20.4c3.9,7,7.8,13.9,11.6,20.9
          c0.5,0.9,0.5,1.9,0.8,2.9L157.9,220L157.9,220z M114.5,148.6c-1.5,0.8-2.2,1.8-2.2,3c0,1.7-0.3,1.9-2,1c-1.2,2.5-2.4,5-3.6,7.5
          l0.5,0.2c1.5-1.7,3.1-3.4,4.5-5.3c1-1.2,1.7-2.7,2.4-4C114.5,150.5,114.4,149.7,114.5,148.6L114.5,148.6z M127,193.5
          c0.5,0.9,0.7,1.4,1.1,1.8c0.2,0.2,0.8,0.4,0.9,0.3c0.3-0.3,0.5-0.7,0.5-1c-0.2-0.9-0.5-1.8-0.7-2.3c-2.8,0-4.7,1.8-7,2.9
          c1,1,1.9,1.9,3.1,3.2c-0.1-1.2-0.4-2.1-0.1-2.8C125.2,194.9,126.1,194.3,127,193.5z M132.3,193c1.5,0.6,2.9,1.2,4.4,1.7
          c1.1,0.4,2.3,0.6,3.5,0.7c1.1,0.1,1.9-0.5,1.9-1.2c-1.4-0.2-2.6-0.2-3.8-0.5c-1.1-0.3-2.1-0.8-3.1-1.4
          C133.8,191.5,132.9,192,132.3,193L132.3,193z M98.3,128.2c1.1,2.9,4.6,4.8,6.1,3.6C102.5,130.7,100.6,129.6,98.3,128.2z
           M112.4,158.5h4c0-1.6-1-2.4-2.8-2.2C111.6,156.5,112.5,157.9,112.4,158.5L112.4,158.5z M102.4,157.5c-0.8-0.7-0.9-0.7-1.7,0.2
          c-1,1.2-2,2.3-3,3.5c-0.3,0.4-0.3,1-0.5,1.9C99.3,160.9,100.8,159.2,102.4,157.5L102.4,157.5z M89.6,140.5l0.6,0.3l5.7-6.7
          c-0.2-0.2-0.5-0.3-0.7-0.5C93.4,136,91.5,138.3,89.6,140.5L89.6,140.5z M140.1,223.4c-1.4,1.3-1.3,3.8,0.8,6
          C140.7,227.4,140.4,225.4,140.1,223.4z M100.4,153.3c2.8,2,4.8,2.1,6,0.3C104.4,153.5,102.3,153.4,100.4,153.3z M147.3,223.7
          l-0.4-0.7c-1.1,0.4-2.2,0.7-3.8,1.2c0.9,0.7,1.4,1.1,1.8,1.3C145.8,224.8,146.6,224.3,147.3,223.7L147.3,223.7z M88.9,122.4
          c1.3,0.6,2.1,1,3.3,1.5c-0.2-1.2-0.3-2-0.5-3.1C90.8,121.3,90,121.7,88.9,122.4z M126.4,183.3c0.2,0,0.5-0.1,0.7-0.1
          c-0.2-1.6-0.4-3.2-0.6-4.8C124.6,180.2,126.8,181.6,126.4,183.3L126.4,183.3z M115.7,114.9c-0.3-0.1-0.5-0.2-0.8-0.3
          c-0.9,1.4-1.7,2.8-2.6,4.2l0.7,0.3C113.9,117.7,114.8,116.3,115.7,114.9L115.7,114.9z M116.7,181.7l0.5-1l-2.1-1.1
          c-0.1,0.4-0.5,1.1-0.4,1.2C115.3,181.2,116.1,181.4,116.7,181.7L116.7,181.7z M136.6,198.1c-1.3-0.6-2.2-1-3.3-1.5l0.5,2.4
          C134.7,198.6,135.4,198.4,136.6,198.1L136.6,198.1z"
        />
        <path
          d="M226.2,157.5c-0.8-1.7-1.5-3.4-2.3-5c-0.3-0.6-0.6-1.5-1.2-1.8c-1.1-0.6-1.1-1.1-0.3-1.9c0.2-0.2,0.3-0.7,0.1-0.9
          c-1.1-1.7-1.5-4.1-4.2-4.7c0.9,3.9,3,7.4,3.9,11.2c0.9,3.8,2.1,7.6,3.1,11.5c-1-0.1-1.8-0.2-3-0.4c1.6,1.1,2.7,1.9,3.8,2.7
          c0.4,0.3,0.8,0.9,0.7,1.1c-0.9,1.7,0.4,3,0.7,4.4c0.3,1.5,1,2,2.6,1c0.2,0.6,0.4,1.2,0.6,1.9c-1.3,0.2-2.5,0.3-3.8,0.5
          c-0.7,2.3-0.4,3.8,1.2,4.6l0.2-1.7l0.3-0.2c0.3,0.6,0.6,1.2,0.9,1.7c0.7,1.2,1.2,2.5,3.1,2.7c0.4,0,0.7,0.8,0.9,1.3
          c2.3,6,4.7,12,9.1,17c2.3,2.5,5.2,4.6,7.7,6.9c0.6,0.5,0.9,1.3,1.1,1.5c2.9,0.3,5.6,0.6,8.3,1c0.3,0,0.7,0.5,0.8,0.9
          c0.4,1.8,0.7,3.6,1,5.5c-0.6-0.1-0.9-0.2-1.6-0.4c0.3,0.6,0.4,1.2,0.7,1.2c2.1,0.4,1.2,2.2,1.7,3.3c0.1,0.2-0.8,1-1.2,1
          c-4.5,0-9-0.1-13.5-0.3c-0.6,0-1.2-0.6-2.1-1.1c-0.2,0.1-0.6,0.4-0.9,0.6c-1-1-2-2-3.3-3.4c-0.3,1.2-0.4,1.8-0.5,2.5
          c-7-1.8-13.3-4.9-18.6-9.2c-3.8-3.1-7.3-6.5-10.7-10c-2.2-2.4-4.1-5.1-5.8-7.8c-1.8-2.8-3.2-5.8-4.7-8.8c-0.2-0.5,0-1.1,0-1.7
          c0-0.3,0.1-0.8-0.1-1c-3.5-3.8-4-8.6-5.1-13.1c-2.7-11.1-3.5-22.3-1.4-33.6c0.8-4.2,2.1-8.3,3.1-12.4c0.8,0.5,1.3,0.9,1.8,1.2
          c0.2-0.6,0.7-1.2,0.5-1.7c-0.9-2.2-0.2-4.2,1-6.1c2.7-3.9,5.2-7.9,8.2-11.6c1.1-1.3,3.1-2.1,4.8-2.8c0.6-0.3,1.6,0.3,2.8,0.6
          c-0.3-1-0.7-1.8-0.8-2.7c-0.1-0.5,0-1.4,0.4-1.6c5.9-4.4,12.6-7.2,20.1-8.7c0.2,0.9,0.4,1.8,0.4,2.1c-1.1,0.8-1.9,1.3-2.7,1.9
          c1,0.3,1.9,0.6,3.3,1c0.3,1.4,0.8,3.2,0.9,5.1c0.1,0.6-0.6,1.5-1.2,1.8c-4.1,2.1-6.6,4.3-8.8,8.2c-2.5,4.5-3.5,9.4-3.9,14.4
          c-0.9,9.5,0.4,18.9,1.7,28.2c0.2,1.4,0.4,2.7,0.6,4.1L226.2,157.5L226.2,157.5z M200.5,178.8l0.7,0.8c0.7-0.5,1.5-1.1,2.3-1.6
          c1.9-1.3,3.1-1,3.9,1c0.8,2,1.6,4,2.5,6c0.2,0.4,0.9,0.5,1.4,0.7c0-0.6,0.3-1.4,0-1.6c-2.3-1.8-2.4-4.4-3.1-6.7
          c-0.3-1.1,0-1.8,1.3-2.4c1.4-0.6,2.6-1.7,3-3.2c-1.1,0.6-2,1.3-2.9,2c-1.6,1.2-2.4,1-3.1-0.8c-0.3-0.8-0.6-1.5-0.9-2.3l-0.5,0.1
          c0.1,0.7,0.2,1.4,0.3,2.1c0.6,3.3,0.6,3.2-2.7,4.6C201.9,177.7,201.3,178.3,200.5,178.8L200.5,178.8z M232.3,187.5
          c-0.4,0.9-1.2,1.8-1.1,2.5c0.4,2.4,1.1,4.7,1.8,7.1c0.1,0.4,0.8,0.8,1.2,0.8c0.4,0,0.8-0.6,1-1c0.4-0.6,0.6-1.3,0.9-1.9
          c0.2-0.4,0.4-1,0.2-1.3C235.2,191.8,233.9,189.9,232.3,187.5L232.3,187.5z M209.9,153.4c-1.8,2.8,1.5,3.9,2.7,5.8
          c-0.1-1.2-0.4-2.3-0.8-3.4c-1.1-2.8-2.2-5.6-3.4-8.3c-0.1-0.3-0.7-0.4-1.1-0.6c-0.2,0.4-0.7,1-0.6,1.3c0.2,0.6,0.9,1.1,1.3,1.7
          c0.3,0.5,0.7,0.9,0.9,1.4C209.3,151.9,209.6,152.6,209.9,153.4L209.9,153.4z M229.2,103.1c0.2-3.2-0.5-5.8-4.6-6.2
          c0.5-0.7,0.9-1.1,1.2-1.6l-0.5-0.4c-0.4,0.5-1.1,1-1.1,1.6c-0.1,0.6,0.3,1.7,0.8,1.9c1.9,0.9,3,2.1,3.7,3.9
          C228.7,102.6,229,102.8,229.2,103.1L229.2,103.1z M202.1,154.4l1.2,0c-2.4-2.8-1.9-4.7,1.2-6c0.2-0.1,0.1-0.7,0.2-1.1
          c-0.4,0-0.8-0.1-1.2,0.1c-1.3,0.5-2.5,1.1-4.1,1.8C200.3,150.7,201.2,152.5,202.1,154.4L202.1,154.4z M215.5,168.7
          c-0.5-2.6-0.9-5.1-1.3-7.5c-0.3,0-0.6,0.1-0.9,0.1c-1.2,3,1.9,5.8,0.5,9c1.5-0.5,2.5-0.8,3.4-1.1l-0.1-0.3L215.5,168.7L215.5,168.7z
           M223.7,188.6l-0.6,0.1c-0.7,2.4,0.6,6.8,2.5,7.2C225,193.4,224.4,191,223.7,188.6L223.7,188.6z M221.7,126.9
          c-0.6-0.1-1.2-0.3-1.6-0.2c-0.7,0.2-1.4,0.6-2.1,1.1c-0.5,0.4-1.1,0.8-1.4,1.4c-0.2,0.4,0,0.9,0,1.4c0.5-0.1,1.2-0.1,1.6-0.3
          C219.3,129.3,220.3,128.3,221.7,126.9L221.7,126.9z M206.1,146.3c0.4-0.5,0.8-1,1.2-1.5c-0.5-0.4-1-0.6-1.3-0.9
          c-0.2-0.3-0.1-0.8-0.1-1.2c0-0.4,0-0.8,0-1.6C203.9,144.2,203.9,144.4,206.1,146.3L206.1,146.3z M212.5,124.5
          c-1.1-1.2-1.9-2.1-2.7-3l-0.5,0.2c0.5,0.9,0.9,1.9,1.5,2.7C211,124.7,211.8,124.5,212.5,124.5z M216.6,142.9
          c-0.8-1.4-1.8-1.6-3.1-1.1c-0.3,0.1-0.5,0.5-0.5,0.8c0,0.2,0.5,0.5,0.8,0.5C214.6,143.1,215.5,143,216.6,142.9L216.6,142.9z
           M200.4,161.1c-0.3,0-0.6,0-0.9,0.1c0.1,1.3,0.2,2.7,0.3,4l0.9-0.1C200.6,163.8,200.5,162.5,200.4,161.1L200.4,161.1z M223.6,202.5
          c2.1-1.1,2.2-1.8,0.8-3.6C224.2,200.2,223.9,201.1,223.6,202.5z M240,210.2l0.4-0.9c-1.1-0.3-2.1-0.7-3.2-1l-0.3,0.8
          C237.9,209.5,239,209.9,240,210.2L240,210.2z M214.4,128.3c-0.3,0.1-0.7,0.1-1,0.2c0.1,0.6,0.2,1.3,0.3,1.9c0.5-0.1,0.9-0.2,1.4-0.3
          C214.8,129.5,214.6,128.9,214.4,128.3L214.4,128.3z"
        />
        <path
          d="M296.1,156.4c2.4-1.6,2.9-3.6,1.5-5.7c4.4-0.8,4.9-1.4,5.8-6.1c2.7,0.7,5,0.3,6.5-2.1c2.6,6.4,3.8,24.7,2.1,31.7
          c-0.9-0.4-1.8-0.9-2.7-1.3c0,0.1,0,0.2-0.1,0.4c0.4,0.5,1.2,1.3,1,1.6c-0.6,1-1.3,2.2-2.3,2.6c-1.7,0.7-3,1.6-3.8,3.1
          c-0.3,0.5-0.1,1.3-0.1,1.9c0.6-0.3,1.2-0.6,1.8-0.9c1-0.6,2.1-1.2,3.1-1.9c0.9-0.6,1.7-1.4,2.6-2.1c-0.1,0.7-0.2,1.6-0.5,2.4
          c-2.5,10.4-6.8,20-14.7,28c-6.8,7-15.3,11.3-25.1,13.7c-0.3,0.1-0.6,0-1,0c-0.7-3.4-1.4-6.8-2-10.2c-0.1-0.4,0.3-1.1,0.6-1.2
          c7.9-3.3,10.8-9.6,12-16.8c0.9-5.5,0.9-11.1,1.3-16.3c0.4,0.5,1.1,1.5,2,2.3c0.3,0.3,1.1,0.4,1.5,0.3c0.2,0,0.4-0.7,0.4-1.2
          c0.1-2.4,0.5-4.5,3.2-5.8c1.4-0.7,2.2-1.8,1.1-3.9c-1.4,1.4-2.6,2.7-3.8,3.9c-0.5,0.6-0.8,1.5-1.4,1.9c-0.7,0.5-1.8,0.9-2.6,0.7
          c-0.5-0.1-1-1.2-1.1-2c-1.7-15.5-4.6-30.7-9.4-45.6c-0.8-2.3-1.9-4.4-2.8-6.7c-2.2-5.2-5-10.2-9.2-14.4c-3.4-3.5-7.4-5.8-12.7-6.2
          c-0.5,0-1.3-0.7-1.5-1.1c-0.4-1.2-0.5-2.5-0.8-4.2c1.3,0.6,2.3,1,3.2,1.4c0-2.3-1.5-3.2-3.4-2.2c-0.3-1.7-0.6-3.4-1-5.3
          c4.5,0,8.9-0.4,13.1,0.1c15.1,1.6,26.5,9.1,35.9,19.5c0.9,1,0.8,1.9-0.5,2.6c-0.3,0.2-0.7,0.3-1,0.4l0.6,0.8
          c0.9-0.4,1.8-0.7,3.2-1.2c1.4,1.9,3.3,4.2,4.7,6.7c2.7,5.1,5.1,10.4,7.5,15.6c0.6,1.3,0.5,2.8,0.6,4.2c0,0.4-0.7,0.9-1.4,1.7
          c0.1-1.1,0.1-1.7,0.1-2.3c-2.8-1-4.9,0.8-5.4,5.1c-0.9-0.9-1.7-1.7-2.4-2.4c-0.8-0.8-1.5-1.6-2.3-2.3c-1.7,0.5-1.3,1.3-0.5,2.1
          c0.6,0.7,1.2,1.3,2,2.1c-0.8,0.5-1.5,0.9-2.2,1.3c-2.1,1.6-2.3,4-0.4,5.8c0.9,0.8,0.9,1.5,0.7,2.5
          C296.1,153.1,296.2,154.7,296.1,156.4L296.1,156.4z M291.6,195.5c1-0.2,1.9-0.3,2.5-0.4c0.4-1,0.4-2,1-2.4c0.5-0.4,1.5-0.1,2.5-0.1
          c-0.5-1.6,0.4-2.6,1.9-3.3c0.5-0.2,0.7-1.1,1-1.6c-0.6,0.1-1.4,0.2-1.8,0.5c-1.9,1.3-3.6,2.6-5.5,4c-0.4-0.5-0.8-1.1-0.9-1.1
          c-1.9,0.5-1.8-1-2.3-1.9c-0.5-0.9-1-1.9-1.7-2.7c-0.3-0.4-1.1-0.5-1.6-0.7c-0.1,0.1-0.2,0.3-0.3,0.4c0.4,0.6,0.7,1.2,1.1,1.8
          c0.6,0.7,1.6,1.3,1.7,2.1c0.4,1.6,1.1,2.9,3.1,3.2C292,193.9,291.8,194.5,291.6,195.5L291.6,195.5z M297.7,161.9
          c0.5,0.6,0.9,1.1,1.3,1.6c0.7,0.9,1.4,1.8,2.3,2.6c1.3,1.1,2.1,2.3,1.2,3.9c-0.2,0.3,0,0.7,0,1.5c0.8-1,1.2-1.5,1.7-2.2
          c1,0.4,1.8,0.8,2.8,1.3c0-0.3,0-0.5-0.1-0.5c-2.7-2.8-5.5-5.5-8.3-8.3c-0.3-0.3-0.9-0.4-1.4-0.6c-1.8-0.6-2.8-2.7-2.3-4.3
          c0.2-0.6,0-1.3,0-2.2c-1.4,1-1.7,3.1-4,1.6c0.8,1.3,1.3,3,2.2,3.2c2.3,0.5,2.8,2,3.2,4c0.3-0.4,0.4-0.6,0.6-0.8
          C297.1,162.5,297.3,162.3,297.7,161.9L297.7,161.9z M289.3,197.3c-1.4,0.9-2,1.7-1.6,3.1c0.5,1.5-0.1,2.4-1.3,3.1
          c-0.8,0.5-1.7,0.8-2.5,1.2c1.7,0.6,4.4-0.8,4.7-2.4C288.8,200.6,289,198.9,289.3,197.3L289.3,197.3z M292.1,168.9
          c0.5-2.2,1-4.3,1.5-6.8C290.8,164.3,290.3,167.4,292.1,168.9z M265.3,101.6c-1.5-0.7-2.9-1.3-4.2-1.8c-0.3,1.2,0.8,2.5,3.1,3.2
          L265.3,101.6L265.3,101.6z M304.2,134.4l-0.4-0.3c-2,0.7-3.6,2.7-3.5,4.9C301.8,137.2,303,135.8,304.2,134.4L304.2,134.4z
           M285,172.4l0.6,0c0.1-2,0.3-4,0.4-6l-0.6,0C284.5,168.3,284.3,170.3,285,172.4L285,172.4z M309.4,158.3l-0.6-0.3
          c-1.3,2.1-2.6,4.2-3.9,6.3c0.2,0.1,0.4,0.2,0.6,0.3C306.8,162.5,308.1,160.4,309.4,158.3L309.4,158.3z M296.3,170.5
          c-0.2-1.8,0.7-2.7,2.5-3c-0.1-0.2-0.2-0.3-0.4-0.5l-4,1.1C295.1,168.9,295.6,169.5,296.3,170.5L296.3,170.5z M256.7,99.2
          c-0.1,1.5,0.9,2.4,2.3,2.1c0.3-0.1,0.7-0.4,0.7-0.6c0-0.3-0.3-0.7-0.5-0.8C258.4,99.5,257.5,99.4,256.7,99.2L256.7,99.2z M300,132.7
          c-0.3-0.1-0.6-0.2-0.9-0.3c-0.6,1.7-1.2,3.5-1.8,5.2l0.6,0.2L300,132.7z M291.2,154c0.2-0.1,0.5-0.3,0.7-0.4
          c-0.8-1.1-1.6-2.2-2.4-3.3c-0.2,0.1-0.4,0.2-0.6,0.4C289.7,151.7,290.4,152.9,291.2,154L291.2,154z"
        />
        <path
          d="M163.9,149.8c-1.8-2.5-0.9-6.1-4.8-7.7c-0.5-0.2-0.6-1.5-0.5-2.3c0.5-4.4,1.2-8.7,1.6-13.1c0.2-2-0.2-4.1-0.6-6.2
          c-0.2-0.9-0.9-1.9-1.6-2.6c-1.4-1.4-1.8-3.3-0.9-5c0.3-0.6,0.3-1.3,0.6-1.8c0.5-0.9,1.1-1.8,1.7-2.6c0.2-0.3,0.8-0.6,0.8-1
          c0.1-2.1,1.8-2,3.2-2.2c5.7-0.9,11.3-1.9,17-2.8c0.3,0,0.6,0,1.1,0c0.4,2,0.9,3.9,1.2,5.9c0.1,0.4-0.3,1-0.7,1.3
          c-1,0.8-2.1,1.4-3.2,2c-4,2.1-6.1,5.2-6.7,9.4c-0.5,3.4-1.4,6.7-1.9,10c-0.4,2.5-0.4,5-0.6,7.5c-0.1,0.6-0.5,1.1-0.7,1.5
          c0.1,0.8,0.3,1.5,0.2,2.2c-2.7,18.8-5.4,37.6-8.1,56.5c-0.5,3.8-1.1,7.5-1.7,11.7c-0.5-0.7-0.7-1.2-1-1.7c-1.2-2.1-2.5-4.2-3.5-6.3
          c-0.7-1.4-1.2-2.7-2.2-4c-1-1.3-1.8-3-0.5-5c0.8-1.3,0.9-3,1-4.6c0.1-1.4,0.8-1.8,2.2-1.5c1,0.2,2,0.5,3.1,0.8
          c-0.1-2.3-1.8-3.3-5.9-3.8c0.9-6.6,1.8-13.2,2.7-19.9c0.4,0.1,0.8,0.2,1.3,0.3c-0.1-0.4,0-0.9-0.2-1.2c-1.1-1.1-0.7-2.4-0.5-3.7
          c0.7-5.1,1.4-10.1,2-15.2c0.1-0.4,0.2-0.8,0.3-1.4c1.9,1.7,4.4,2.9,4.3,5.8c0,0.3,0.5,0.6,0.8,0.9L163.9,149.8L163.9,149.8z
           M155.4,176.7c1.9-0.1,3.7,0.7,4.8-1.4c-3.3-0.2-2.8-2.7-2.9-4.8c-0.2,0-0.5,0.1-0.7,0.1C156.2,172.6,155.8,174.5,155.4,176.7
          L155.4,176.7z M164.5,115.3c0.7,0,1.6,0.3,1.9,0.1c0.7-0.7,1.1-1.6,1.6-2.4l-0.7-0.5c-0.4,0.3-0.8,0.6-1.1,1
          C165.7,113.9,165.3,114.4,164.5,115.3L164.5,115.3z M162.2,116.6c-2.4,1.8-2,4.1,1,5.8C162,120.6,160.3,118.9,162.2,116.6
          L162.2,116.6z M158.5,171.6c1.4-3,3.1,0,4.7-0.5c-1.3-0.7-2.7-1.3-4.3-2.1C158.7,170.1,158.6,170.8,158.5,171.6L158.5,171.6z"
        />
        <path
          d="M40.8,192.8c0.4,0.7,0.8,1.1,0.9,1.6c1.6,8.2,3.2,16.4,4.7,24.4c-0.6,0.3-1.1,0.5-1.7,0.8c0.5,0.3,0.9,0.6,1.3,1
          c0.4,0.3,0.8,0.7,0.9,1.2c2.1,10.7,4.2,21.4,6.2,32.1c0.1,0.5-0.2,1.1-0.3,1.8c0.2,0.3,0.7,0.7,0.8,1.2c0.8,4.6,1.6,9.2,2.4,13.8
          c0.2,1.3,0.8,2.6,1.3,4c5.2-0.8,10.3-1.6,15.5-2.5c3.2-0.5,6.3-1.2,9.5-1.6c7-0.9,13.9-2.4,20.9-3.3c1-0.1,2,0.1,3.2,0.2
          c0,0,0.2-0.4,0.5-1.2c0.5,1.3,0.8,2.3,1.2,3.4c-9,1.3-17.9,2.9-26.7,4.3c-9,1.4-17.9,2.9-27,4.4c-0.9-4.5-1.7-8.9-2.5-13.3
          c-3.6-18.5-7.1-37.1-10.7-55.6c-0.9-4.7-1.6-9.5-2.5-14.2c-2.4-12.2-4.9-24.5-7.2-36.7c-0.2-1,0.1-2.1,0.1-3.2c0-0.2,0.1-0.5,0-0.7
          c-2.5-3.3-2.1-7.2-2.9-10.8c-1.2-5.1-2.1-10.3-3-15.4c-0.2-0.8,0.1-1.7,0.1-2.6c0-0.3-0.1-0.7-0.2-1c-0.5-1.4-1.1-2.7-1.3-4.2
          c1.9,1.6,4.1,2.7,4.5,5.6c1,6.7,2.5,13.3,3.7,20c1.2,6.1,2.3,12.3,3.5,18.4c0.1,0.5,0.1,0.9,0.2,1.5c-1.5,0.5-2.3,1.2-0.8,2.8
          l1.1-1.1C38.3,173.1,41.4,192.2,40.8,192.8L40.8,192.8z"
        />
        <path
          d="M265.9,244.2c-0.5-1-0.9-1.8-1.5-2.9c3.3-0.5,6.5-1.1,9.7-1.6c18.1-2.9,36.1-5.9,54.2-8.8c1.1-0.2,2.2-0.7,3.1,0.6
          c0.1,0.2,1,0.2,1.3,0c0.3-0.2,0.4-0.7,0.7-1.4c4-0.7,8.4-1.4,12.8-2.1c0.7-0.1,1.4,0.3,2.4,0.5c5.8-2.9,12.8-2.7,19.4-4
          c8.1-1.6,16.4-2.7,24.6-4c0.3,0,0.5,0,0.8,0c0.3,2-0.5,3.2-2.6,3.5c-9.5,1.5-18.9,3.1-28.4,4.6c-3,0.5-5.9,1-8.9,1.4
          c-1.5,0.2-3.3-0.3-4.6,0.2c-4.3,1.9-9,1.9-13.4,2.7c-14.8,2.5-29.6,4.9-44.4,7.3C282.7,241.5,274.4,242.8,265.9,244.2L265.9,244.2z"
        />
        <path
          d="M243.3,72.8c-0.4-1.1-0.7-2-1-3c27-4.4,54.2-8.8,81.5-13.2V59c0.2-0.1,0.4-0.2,0.4-0.2c0.1-2.5,2-2.6,4.1-2.9
          c11.9-1.9,23.7-3.8,35.6-5.8c1.8-0.3,3.7-0.6,5.5-0.9c-0.3,0.3-0.7,0.6-1,1c1,0.7,2,1.3,2.8,1.9c-1.9,0.3-4.5,0.8-7.2,1.2
          c-5.1,0.8-10.3,1.7-15.5,2.5c-0.8,0.1-1.7-0.3-2.3-0.4c-1,0.4-1.9,1.1-2.9,1.2c-6.7,1.1-13.3,2.2-20,3.3c-4.1,0.7-8.2,1.3-12.7,2
          c-0.2-0.4-0.5-1.1-0.9-1.7l-0.7,0.5c0,0.5,0.1,1,0.1,1.6C287.3,65.7,265.3,69.3,243.3,72.8L243.3,72.8z"
        />
        <path
          d="M549.3,52.7c0.1-0.2,0.2-0.6,0.2-0.6c-2.3-0.9-1-2.3-0.6-3.6c0.2-0.7,0.3-1.3,0.5-2c-0.2-0.1-0.3-0.1-0.5-0.2
          c-0.2,0.2-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.7,0.9c-1.5-7.9-3-15.6-4.5-23.4c-2.2,0.3-4.2,0.6-6.2,1c-5.3,0.9-10.6,1.7-15.8,2.6
          c-4.5,0.7-9.1,1.5-13.6,2.2c-0.6,0.1-1.2-0.2-1.9-0.3c-0.3,0.2-0.7,0.7-1.3,0.8c-12.9,2.1-25.8,4.2-38.7,6.3c-0.4,0.1-0.7,0-1.2,0
          c0.2-0.8,0.3-1.5,0.6-2.1c0.3-0.5,0.8-1.1,1.3-1.2c6.6-1.1,13.2-2.2,19.8-3.2c14.2-2.3,28.5-4.6,42.7-6.9c2-0.3,4.1,0.1,6.3,0.1
          c0,0,0-0.5-0.1-1.2c3.5-0.6,7-1.1,10.8-1.8c2.2,11.3,4.3,22.6,6.6,34.1C551.2,53.9,550.2,53.3,549.3,52.7L549.3,52.7L549.3,52.7z"
        />
        <path
          d="M579,193.4c-4.1,0.7-7.9,1.3-11.7,1.9c-0.6-2.8-0.4-3.1,2.4-3.6c1.7-0.3,3.4-0.6,5.3-0.9c-1.2-6.6-2.5-13-3.7-19.6l2.4-0.2
          c-0.2-0.7-0.3-1.3-0.5-2.1c-0.7,0.3-1.3,0.6-2.2,1c-0.8-4-1.5-7.9-2.3-11.8c-0.7-3.8-1.5-7.6-2.2-11.4c-0.2-1.2-0.9-2.3,0.2-3.6
          c0.5-0.5-0.4-2-0.6-3.1c-0.2,0.1-0.4,0.2-0.8,0.4c-0.5-2.8-1.5-5.5-1.4-8.2c0-1.5,0.6-3.2,0.2-4.8c-0.1-0.4-0.6-0.8-0.9-1.2
          l-0.5,0.2c-0.2-0.7-0.3-1.5-0.5-2.2l0.5,0.2c0.5-1.9,1.1-3.8,1.6-5.7h0.5c1.3,8.3,2.5,16.7,4,24.9c1.5,8.2,3.6,16.3,5.2,24.5
          C575.9,176.5,577.4,184.9,579,193.4L579,193.4z"
        />
        <path
          d="M93.1,94.2c0.7,0,1.4,0,2-0.1c1.6-0.4,3.1-0.9,4.7-1.2c10.4-1.7,20.8-3.4,31.3-5.1c0.5,0.9,1,1.8,1.6,2.9
          c-4.1,0.7-8.1,1.3-12.1,2l-0.3-1.2c-0.3,0.1-0.6,0.1-0.7,0.2c-0.2,1.2-1.2,1.3-2.2,1.5c-14.2,2.3-28.4,4.6-42.5,6.9
          c-1.4,0.2-2.6,0.4-3.3-1.1c-0.2-0.5-0.9-0.8-1.3-1.1l-0.4,0.3l1.6,2.4c-2.4,0.4-4.4,0.8-6.5,1.1c-0.4,0-0.9-0.3-1.2-0.6
          c-0.5-0.5-0.8-1.1-1.1-1.7c-0.3,0.2-0.5,0.4-0.8,0.6c0.4,0.6,0.8,1.2,1.2,2c-2.6,0.4-5.1,0.8-7.8,1.3c1-2.9-1.8-1-2.9-1.9
          c1-0.4,1.9-1.2,2.5-1c2.7,0.6,4.7-1,7.1-1.3c9.7-1.5,19.5-3.1,29.2-4.7c0.6-0.1,1.2,0.2,1.8,0.3L93.1,94.2L93.1,94.2z"
        />
        <path
          d="M549.2,52.6c0.6,0.7,1,1.6,1.8,2c1.5,0.8,1.8,2,2.1,3.3c1.7,8.7,3.5,17.5,4.9,26.3c0.3,1.9,1.2,3.8,1.1,5.7
          c-0.1,2.2,0.8,4,1.2,6c1,5.1,2,10.1,3,15.3h-2.5c0,0.1-0.1,0.3-0.2,0.4c0.4,0.3,0.8,0.6,1.3,0.9c2,1,2.4,2,1.9,4.1
          c-0.5,1.9-1,3.8-1.8,5.7c-0.4-2.1-0.9-4.2-1.3-6.3c-2.5-13.2-5-26.4-7.6-39.6c-0.5-2.9-1.2-5.7-1.6-8.6c-0.1-0.9,0.3-1.9,0.5-2.8
          c0.2-0.8,0.6-1.5,0.5-2.4l-1.7,2.1c-0.8-4-1.5-7.8-2.2-11.6C548.6,52.9,549,52.8,549.2,52.6L549.2,52.6L549.2,52.6z"
        />
        <path
          d="M418.9,42.7c0.1,0.4,0.3,1,0.4,1.7c-4.4,0.7-8.7,1.5-13,2.1c-0.6,0.1-1.4-0.2-1.8-0.3c-3.2,1.7-6.9,1.4-10.3,2.3
          c0-1.8,0.3-2.9,2.2-3.2c8.6-1.5,17.2-3.1,25.8-4.6c2.4-0.4,4.8-0.8,7.3-1.2c0.2,0.5,0.4,1,0.8,1.9c0.1-1,0.2-1.5,0.2-2
          c2.4-0.4,4.9-0.8,7.4-1.2c0.4,0.9,0.8,1.7,1.2,2.5l0.4-0.1c0-0.8-0.1-1.7-0.1-2.6c5.4-0.9,10.6-1.7,15.9-2.6
          c1.9-0.3,3.8-0.6,5.7-0.9c0.8-0.1,1.8-0.5,1.9,0.7c0.1,1.1,0.1,2.2-1.5,2.4c-3.2,0.5-6.4,1-9.5,1.5c-4.1,0.7-8.1,1.4-12.2,1.9
          c-0.9,0.1-1.8-0.2-2.9-0.3c0,0,0,0.4,0.1,0.8c-5.1,0.8-10.1,1.8-15.2,2.5C420.9,44,419.9,43.1,418.9,42.7L418.9,42.7z"
        />
        <path
          d="M565,192.7c0.2,3,0.2,3-2.6,3.4c-14.7,2.4-29.3,4.8-44,7.1c-0.6,0.1-1.3-0.1-1.9-0.3c-0.7-0.2-1.4-0.5-2.5-1
          c0.3,0.9,0.5,1.3,0.7,1.7c-0.1,0.1-0.1,0.2-0.2,0.2c-3.4,1-6.5,0.9-9.3-1.5c1.7-0.1,3.3-1.6,5.1-0.2c0.2,0.1,0.7,0.1,0.9,0
          c1.7-1.6,4-1.5,6.1-1.8c15.7-2.6,31.4-5.1,47.1-7.6C564.4,192.6,564.6,192.7,565,192.7L565,192.7z"
        />
        <path
          d="M450.4,214.3c-0.1-1.2-0.2-2.1-0.4-3.1c5-0.8,9.9-1.7,14.8-2.5c12.2-2,24.4-3.9,36.6-5.9c1.3-0.2,2.5-0.5,3.2,1
          c0.2,0.5,1.1,0.7,2.1,1.3C487.7,208.2,469.2,211.3,450.4,214.3L450.4,214.3z"
        />
        <path
          d="M196.6,77.9c1.1-0.4,2.2-1,3.4-1.2c5.6-1,11.2-1.9,16.8-2.8c0.7-0.1,1.4-0.1,2.3-0.2c0.1,1,0.2,1.9,0.3,3.1
          c-2.7-0.2-5.2,2-7.8-0.3l0.5,1.4c-4.5,0.7-8.9,1.5-13.3,2.1c-0.5,0.1-1.1-0.3-1.8-0.6c-1.4,2-4,1.5-6.1,1.9c-8,1.4-16,2.6-24,3.9
          c-0.3,0-0.6,0.1-1,0.1c-0.3-0.9-0.6-1.8-1-2.9c3.6-0.6,7.2-1.2,10.7-1.8c6-1,12-2,17.9-2.9C194.4,77.6,195.5,77.9,196.6,77.9
          L196.6,77.9z"
        />
        <path
          d="M448.7,214.6c-4.3,0.7-8.5,1.4-12.6,2.1c-2.9,0.5-5.8,1-8.8,1.4c-0.7,0.1-1.5,0.1-2.3-0.1c-0.7-0.1-1.3-0.5-1.5,0.6
          c0,0.2-0.9,0.3-1.4,0.3c-2.1,0-3.9,1-6.3,0.5c-1.6-0.4-3.7,1.1-5.6,1.4c-4.6,0.9-9.1,1.6-13.7,2.3c-0.6,0.1-1.2,0-1.8,0
          c0.4-1,0.7-1.8,1.2-3.1c16.4-2.7,33.3-5.4,50.4-8.1v2.3l0.5,0c0.3-0.8,0.5-1.6,0.8-2.4l0.3-0.1C448.1,212.6,448.4,213.5,448.7,214.6
          L448.7,214.6z"
        />
        <path
          d="M159.6,259.5c3.3-2.3,7-2.3,10.7-2.8c4.3-0.6,8.6-1.5,12.9-2.2c6-1,11.9-1.9,17.6-2.8c-1.1,1.1-2.3,2.3-3.5,3.4
          c-0.4,0.3-1,0.4-1.6,0.5c-11,1.8-22,3.6-33,5.4C161.2,261.3,160.4,260.8,159.6,259.5z"
        />
        <path
          d="M144.7,261.6c-0.4,0.2-1,0.4-1.3,0.7c-2.1,2.8-5.4,2.6-8.5,3.1c-4.4,0.8-8.8,1.5-13.2,2.1c-0.9,0.1-2-0.2-3.1-0.4
          c-0.5,1.2-4.3,2.2-6.7,1.3c-0.8,0.4-1.4,0.8-1.9,1c0.1-0.6,0.3-1.2,0.1-1.6c-0.3-0.5-0.8-0.9-1.4-1.5c5.8-1,11.8-2,17.9-2.9
          c6-0.9,11.9-2.2,17.9-2.7C144.7,261.1,144.7,261.4,144.7,261.6L144.7,261.6z"
        />
        <path
          d="M231.8,249.8c-1.1-2.7-1-3,1.6-3.4c2-0.3,3.9-0.7,5.9-0.9c0.8-0.1,1.7,0.2,2.6,0.3c0.3,0,0.8,0.1,0.9,0
          c1.7-1.8,4.2-1.5,6.4-1.9c4.2-0.8,8.5-1.4,12.7-2.1c0.6,0.9,1.1,1.7,1.6,2.5c-1.6,2-1.5-1.5-2.9-0.5c0.1,0.3,0.2,0.7,0.3,1.3
          c-5.3,0.9-10.4,1.8-15.6,2.5c-0.9,0.1-2-0.7-3-0.8c-0.5,0-1.1,0.5-1.6,0.9c-0.4,0.2-0.7,0.7-1,0.8
          C237,248.9,234.4,249.3,231.8,249.8L231.8,249.8z"
        />
        <path
          d="M153.1,87.4c-0.6-1-1-1.7-1.7-2.8c-0.6,2.9-1.1,3.3-4.3,3.8c-2.3,0.3-4.5,0.7-6.8,1.1c-0.8,0.1-1.6,0.3-2.5,0.4
          c-3.1,0.6-3.3,0.4-5.2-2.3c10.2-1.7,20.3-3.3,30.4-4.9c0.2,1.1,0.3,2,0.5,3.1C160.2,86.3,156.6,86.9,153.1,87.4L153.1,87.4z"
        />
        <path
          d="M229.6,247c0.3,1.1,0.6,1.9,0.9,3c-10.8,1.7-21.4,3.5-32.1,5.2c0-0.2,0-0.4,0-0.4c2.2-1.5,4.4-3.1,7.1-3.9
          c0.6-0.2,1.4,0.3,2.2,0.5c-0.1-1,1.2-1.2,2.4-1.3c3.3-0.5,6.6-1,10-1.6c-0.6,1-1.1,1.8-1.6,2.7l0.4,0.5c0.9-0.5,2.1-0.8,2.7-1.6
          C223.5,247.2,226.6,247.5,229.6,247L229.6,247z"
        />
        <path
          d="M145.9,108.1c0.7,0.8,1.3,1.6,2,2.4c1.2,1.2,2.5,2.4,3.8,3.4c0.3,0.2,1.1-0.1,1.7-0.2c-0.3-0.5-0.5-1.2-0.9-1.4
          c-1.9-1.1-4-2-4.5-4.5c3.8-0.6,7.6-1.2,11.4-1.9c-2.1,3.4-4.9,6.2-5.3,10.1c-0.2,0.1-0.4,0.2-0.5,0.2
          C145.5,116.1,146.7,116.3,145.9,108.1L145.9,108.1z"
        />
        <path
          d="M370.6,49.1c2.3-0.4,4.2-0.7,6.2-1c4.3-0.7,8.6-1.4,12.9-2.1c0.7-0.1,1.5,0.2,2.4,0.4c0.3-0.3,1-0.8,1.7-1.4
          c-1,4-6.3,5.4-7.9,4.1c-1.1,0.4-2.1,0.9-3,1.1c-2.5,0.5-5.1,0.8-7.6,1.3c-1.1,0.2-1.9,0.1-2.6-0.9C372.4,50,371.6,49.7,370.6,49.1
          L370.6,49.1z"
        />
        <path
          d="M27.6,121.5c-0.9-1-1.6-1.7-2.2-2.5c-0.2-0.2-0.2-0.6-0.2-1c0-0.5,0.2-1,0.3-1.5c-0.6,0.1-1.3,0.1-2.1,0.2
          c-0.7-3.6-1.4-7.3-2.1-11.2c2.1-0.3,4.1-0.7,6.7-1.1c-0.6,1-0.8,1.6-1.2,2c-1.1,0.9-1.5,1.9-1.3,3.2c0.7,3.6,1.4,7.1,2.1,10.7
          C27.6,120.6,27.6,120.8,27.6,121.5L27.6,121.5z"
        />
        <path
          d="M223.6,72.9c5.4-0.9,10.5-1.7,15.5-2.5c0.8,0.9,1.6,1.6,2.6,2.7c-2.8,0.5-5.4,0.9-8,1.4c-1.8,0.3-3.7,0.5-5.5,0.9
          c-1.3,0.2-2.4,0.5-3-1.1C225,73.8,224.3,73.4,223.6,72.9L223.6,72.9z"
        />
        <path
          d="M32.2,105.6c-2.4,1.3-2.6,1.2-3.4-1.1c0.9-0.2,1.8-0.4,2.7-0.6c0.9-0.2,1.8-0.3,2.9-0.4l-0.3,2.8c1-0.2,1.8-0.4,2.9-0.6
          c-0.7-0.9-1.2-1.5-1.8-2.3c2.6-0.4,5.1-0.9,7.5-1.3c0,0.2,0,0.3,0,0.4c-0.6,4.1-4.4,3.4-7.3,4C34.2,106.7,32.8,107.5,32.2,105.6
          L32.2,105.6z"
        />
        <path
          d="M304.1,141.5c1.7,4,0.6,8-2.4,9.1c-2.9,1-6.4-1.1-8.1-4.8c-1.8-3.9-0.7-7.9,2.4-9C299.3,135.7,302.5,137.6,304.1,141.5
          L304.1,141.5z"
        />
        <path
          d="M469.6,24.6c0.2,3.3-5.8,6.1-13.3,6.3c-7,0.2-13.1-2.4-13.5-5.6c-0.4-3.4,5.3-6,13.3-6C464.3,19.2,469.4,21.2,469.6,24.6
          L469.6,24.6z"
        />
        <path
          d="M14.5,144.1c-4-6.5-4.7-16.1-1.6-21.9c0.7-1.4,2.2-0.2,3.3,0.1c0.3,0.1,0.8,1.5,0.9,1.4c2.6-6.4,3.2,0.1,3.9,4.3
          c0.9,5.6,0.1,10-2.5,15c-0.9-3.3-1.9-6.6-2.8-10l-1,0.5C14.7,136.7,14.6,139.9,14.5,144.1L14.5,144.1z"
        />
        <path
          d="M153.5,259.5c2.8-1.6,3.7,0.4,5.1,1.9c-0.5,0.2-0.9,0.4-1.4,0.5l-1-1.7c-1,0.9-1.7,1.6-2.5,2.3c-0.2-0.1-0.4-0.2-0.6-0.3
          C153.2,261.4,153.4,260.4,153.5,259.5L153.5,259.5z"
        />
        <path
          d="M223.8,76c-1.2,0.2-2.4,0.4-4.1,0.7c0.4-1.3,0.7-2.3,1-3.3c1.1,0.7,2.3,1.3,3.4,2L223.8,76L223.8,76z"
        />
        <path d="M7,127.1c0.8,0.4,1.8,0.8,2.8,1.3l-0.3,4C6.5,131.2,8,128.5,7,127.1L7,127.1z" />
        <path
          d="M67.5,94.6c-6.4,1.1-12.8,2.3-19.2,3.4c-0.6-0.6-1.3-1.2-1.9-1.8c3.3-2.3,6.1-5.8,9.9-6.4c3.8-0.6,8.4,1.6,12.6,2.6
          L67.5,94.6L67.5,94.6z"
        />
        <path
          d="M48.3,104.4c-1.5,0.3-2.9,0.5-5,0.9c0.5-1,0.7-1.7,1.2-2c0.3-0.2,1.1,0,1.6,0.1c0.7,0.2,1.4,0.4,2.2,0.6L48.3,104.4
          L48.3,104.4z"
        />
        <path
          d="M450.7,19.2c1.6-0.3,2.6-0.4,3.8-0.6c-0.1,1.2-0.3,2.4-0.4,3.5C453.2,21.3,452.2,20.4,450.7,19.2z"
        />
        <path
          d="M456.9,22.8c-0.4-0.6-1-1.2-1-1.7c0.1-0.8,0.6-1.7,1-2.5l0.9,0.2c-0.1,1.2-0.2,2.5-0.3,3.7L456.9,22.8z"
        />
        <path
          d="M306.1,146.3c-3.7-2.5-6.8-3.6-7.1-4.9c-0.5-2.2,0.7-4.6,1.7-6.9c0.2-0.4,4.9-0.6,5-0.5c1.2,2,2.4,4.1,2.7,6.2
          C308.6,141.6,307.4,143.1,306.1,146.3z"
        />
        <path d="M226.7,158.6c0.4,2.3,0.8,4.3,1.2,6.4C226.7,164.2,225.9,160.8,226.7,158.6z" />
        <path d="M467.2,16.5c-1.8,1.9-2.5,2-4.5,0.7C464.3,17,465.5,16.8,467.2,16.5z" />
        <path
          d="M49.7,101.5c0.9,0.6,1.8,1.3,2.7,1.9c-2.1,0.9-2.3,0.8-3.1-1.5C49.3,101.9,49.7,101.5,49.7,101.5z"
        />
        <path
          d="M152,259.7l-0.3,2.8l-0.6,0.1c-0.4-0.6-0.9-1.3-1.3-1.9l0.3-0.2c0.3,0.2,0.6,0.5,1.2,0.9v-1.7
          C151.5,259.7,151.7,259.7,152,259.7L152,259.7z"
        />
        <path
          d="M516.5,208.1c6.3,0,11.4,3,11.3,6.5c-0.1,3.5-5.3,6.2-11.8,6c-6.8-0.2-10.4-2.5-10.9-6.1C504.7,211.1,510.5,208,516.5,208.1
          z"
        />
        <path
          d="M354.2,106.6c-0.3,5.6-2.5,10-6.7,10.7c-3.9,0.7-7.7-5-7.8-11.3c-0.2-7.2,3.1-12.4,7.6-12.2
          C351.4,94,354.1,98.5,354.2,106.6L354.2,106.6z"
        />
        <path
          d="M344.8,113.4c-0.7-0.7-1.6-1.4-2.2-2.2c-0.3-0.6-0.2-1.4-0.1-2.1c0-0.2,0.9-0.6,1-0.5c0.5,0.4,1.2,1,1.3,1.5
          C345,111.2,344.8,112.3,344.8,113.4L344.8,113.4L344.8,113.4z"
        />
        <path
          d="M333.1,116.3c0-3.6,2.1-6.1,5.1-6.1c2.8,0,4.7,2.4,4.7,6c0,2.8-2.7,5.7-5.4,5.9C335.1,122.4,333.1,119.7,333.1,116.3
          L333.1,116.3z"
        />
      </g>
    </svg>
  );
}

// Setting admin values for the props of VoidIcon
VoidIcon.defaultProps = {
  color: 'dark',
  size: '16px',
};

// Typechecking props for the VoidIcon
VoidIcon.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
    'light',
    'white',
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default VoidIcon;
