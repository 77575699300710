import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducers
import {
  fetchCandidatesMonthStatsByAdvisor,
  fetchCandidatesStatusesStatsByAdvisor,
  fetchCandidatesTotalsStatsByAdvisor,
} from 'reducers/reportsSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Layout components
import StatsInfoCard from 'layouts/components/Cards/InfoCards/StatsInfoCard';
import NewCandidates from 'layouts/components/Widgets/NewCandidates';
import BarChar from 'layouts/components/Charts/BarChart';

// Functions
import { setTitle } from 'Util';

const selector = (state) => ({
  candidatesStatusesStats: state.reports.candidatesStatusesStats,
  candidatesTotalsStats: state.reports.candidatesTotalsStats,
  candidatesMonthStats: state.reports.candidatesMonthStats,
  user: state.auth.user,
});

function DefaultRecruiter() {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboards' });
  const {
    candidatesStatusesStats,
    candidatesTotalsStats,
    candidatesMonthStats,
    user,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  setTitle('Advisor Dashboard');

  const [statusData, setStatusData] = useState([]);
  const [totalCandidates, setTotalCandidates] = useState({
    total: 0,
    active: 0,
  });
  const [month, setMonth] = useState({
    months: [],
    active: {
      total: 0,
      variance: 0,
    },
    total: {
      total: 0,
      variance: 0,
    },
  });
  const [newLastMonths, setNewLastMonths] = useState([]);

  useEffect(() => {
    dispatch(fetchCandidatesStatusesStatsByAdvisor({ id: user.uuid }));
    dispatch(fetchCandidatesTotalsStatsByAdvisor({ id: user.uuid }));
    dispatch(fetchCandidatesMonthStatsByAdvisor({ id: user.uuid }));
  }, [dispatch, user]);

  useEffect(() => {
    if (candidatesStatusesStats !== undefined) {
      setStatusData(candidatesStatusesStats);
    }

    if (candidatesTotalsStats !== undefined) {
      setTotalCandidates(candidatesTotalsStats);
    }

    if (candidatesMonthStats.total !== undefined) {
      setMonth(candidatesMonthStats);

      const currentDate = new Date();
      const lastSixMonths = [];

      for (let i = 0; i < 6; i += 1) {
        const year = currentDate.getFullYear();
        const m = currentDate.getMonth() + 1;
        const formattedDate = `${year}-${m < 10 ? '0' : ''}${m}`;
        lastSixMonths.push(formattedDate);
        currentDate.setMonth(currentDate.getMonth() - 1);
      }

      const lastMonths = [];

      for (let i = 0; i < 6; i += 1) {
        const val = candidatesMonthStats.months.filter(
          (w) => w.month === lastSixMonths[i],
        );

        if (val[0] === undefined) {
          lastMonths.push({
            month: lastSixMonths[i],
            total: 0,
          });
        } else {
          lastMonths.push(val[0]);
        }
      }

      setNewLastMonths(lastMonths);
    }
  }, [candidatesMonthStats, candidatesStatusesStats, candidatesTotalsStats]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={2}>
        <StatsInfoCard
          title={t('total-candidates')}
          description={totalCandidates.total}
          caption={(
            <Grid container>
              <Grid item md={12}>
                <div style={{ opacity: '0.0001' }}>0</div>
              </Grid>
            </Grid>
          )}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <NewCandidates
          title={t('new-candidates')}
          total={month.total.total}
          variance={month.total.variance}
          label={t('vs-last-month')}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <StatsInfoCard
          title={t('active-candidates')}
          description={totalCandidates.active}
          caption={(
            <Grid container>
              <Grid item md={12}>
                <div style={{ opacity: '0.0001' }}>0</div>
              </Grid>
            </Grid>
          )}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <NewCandidates
          title={t('new-active-candidates')}
          total={month.active.total}
          variance={month.active.variance}
          label={t('vs-last-month')}
        />
      </Grid>
      <Grid item xs={12} md={4} />
      <Grid item xs={12} md={4}>
        <BarChar
          title={t('new-per-month')}
          data={newLastMonths.reverse()}
          series={[
            {
              name: ' ',
              valueField: 'total',
              argumentField: 'month',
              color: '#FFAA66',
            },
          ]}
          height="300px"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <BarChar
          title={t('statuses')}
          rotated
          data={statusData}
          series={[
            {
              name: ' ',
              valueField: 'total',
              argumentField: 'status',
              color: '#42A5F5',
            },
          ]}
          height="300px"
        />
      </Grid>
      <Grid item xs={12} md={4} />
    </Grid>
  );
}

export default DefaultRecruiter;
