import Service from './service';

class VendorService extends Service {
  // eslint-disable-next-line class-methods-use-this
  async getVendors() {
    const response = await this.api
      .get(
        'vendors',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getVendor(id) {
    const response = await this.api
      .get(
        `vendors/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createVendor(name, businessId, paymentTerm, rate, notes, vendorContacts, vendorAddresses) {
    const data = {
      vendor: {
        name,
        business_id: businessId,
        payment_term: paymentTerm,
        rate,
        notes,
        vendor_contacts_attributes: vendorContacts,
        vendor_addresses_attributes: vendorAddresses,
      },
    };

    const response = await this.api
      .post(
        'vendors',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateVendor(
    id,
    name,
    businessId,
    paymentTerm,
    rate,
    notes,
    vendorContacts,
    vendorAddresses,
  ) {
    const data = {
      vendor: {
        name,
        business_id: businessId,
        payment_term: paymentTerm,
        rate,
        notes,
        vendor_contacts_attributes: vendorContacts,
        vendor_addresses_attributes: vendorAddresses,
      },
    };

    const response = await this.api
      .put(
        `vendors/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteVendor(id) {
    const response = await this.api
      .delete(
        `vendors/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new VendorService();
