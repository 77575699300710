import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// @material-ui core components
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { CardActions } from '@mui/material';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Card from '@mui/material/Card';
import { DropzoneDialog } from 'material-ui-dropzone';

// Soft UI Dashboard PRO React components
import SuiButton from 'components/SuiButton';
import SuiTypography from 'components/SuiTypography';
import SuiBox from 'components/SuiBox';

// Components
import useWindowDimensions from 'layouts/components/Hooks/WindowDimensions';
import FormField from 'layouts/components/FormField';

require('datejs');

function AgreementInfo({
  id,
  name,
  fileUrl,
  signed,
  expires,
  signedOn,
  assignedOn,
  expiresOn,
  signFunc,
  uploadFunc,
  modifyFunc,
  removeFunc,
  showUpload,
  showModify,
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'candidates.agreements',
  });
  const {
    height,
  } = useWindowDimensions();

  const [parentHeight, setParentHeight] = useState(500);
  const [dropzoneOpen, setDropzoneOpen] = useState(false);
  const [signedDate, setSignedDate] = useState('1970-01-01 00:00:00');
  const [assignedDate, setAssigned] = useState('1970-01-01 00:00:00');
  const [expiresDate, setExpires] = useState('1970-01-01 00:00:00');
  const [modified, setModified] = useState({});

  useEffect(() => {
    setParentHeight(height - 25);
  }, [height]);

  useEffect(() => {
    if (signedOn !== null && signedOn !== undefined) {
      const val = Date.parse(signedOn);
      setSignedDate(Number.isNaN(val) ? '1970-01-01 00:00:00' : val);
    }
  }, [signedOn]);

  useEffect(() => {
    if (assignedOn !== null && assignedOn !== undefined) {
      const val = Date.parse(assignedOn);
      setAssigned(Number.isNaN(val) ? '1970-01-01 00:00:00' : val);
    }
  }, [assignedOn]);

  useEffect(() => {
    if (expiresOn !== null && expiresOn !== undefined) {
      const val = Date.parse(expiresOn);
      setExpires(Number.isNaN(val) ? '1970-01-01 00:00:00' : val);
    }
  }, [expiresOn]);

  const handleSign = () => {
    signFunc(id);
  };

  const handleUpload = (files) => {
    uploadFunc(id, files);
    setDropzoneOpen(false);
  };

  const handleDownload = (e) => {
    e.preventDefault();
    window.open(fileUrl);
  };

  const handleModify = () => {
    modifyFunc(id, modified);
    setModified({});
  };

  const handleRemove = () => {
    removeFunc(id);
  };

  const handleDate = (field, part, value) => {
    const dateMapping = {
      signedDate: {
        stateVar: signedDate,
        setter: setSignedDate,
        key: 'signedOn',
      },
      assignedDate: {
        stateVar: assignedDate,
        setter: setAssigned,
        key: 'assignedOn',
      },
      expiresDate: {
        stateVar: expiresDate,
        setter: setExpires,
        key: 'expiresOn',
      },
    };

    const {
      stateVar,
      setter,
      key,
    } = dateMapping[field];

    const defaultDate = part === 'd' ? '00:00:00' : '1970-01-01';
    const curDate = Date.parse(stateVar);
    const curVal = Number.isNaN(curDate)
      ? defaultDate : curDate.toString(part === 'd' ? 'HH:mm:ss' : 'yyyy-MM-dd');
    const dateVal = part === 'd' ? `${value}T${curVal}` : `${curVal}T${value}`;

    const parsedDate = Date.parse(dateVal);
    const fVal = Number.isNaN(parsedDate)
      ? '1970-01-01 00:00:00' : new Date(parsedDate).toISOString().replace('Z', ' UTC');

    setModified({
      ...modified,
      [key]: fVal,
    });
    setter(dateVal);
  };

  return (
    <Card
      id={id}
      sx={{
        minHeight: parentHeight,
        overflow: 'auto',
      }}
    >
      <CardHeader title={name} />
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item md={12}>
            <iframe
              src={fileUrl}
              title="pdf"
              width="100%"
              height={parentHeight * 0.6}
            />
          </Grid>
          <Grid item md={5}>
            <SuiTypography variant="h6">{`${t('assigned-on')}:`}</SuiTypography>
          </Grid>
          <Grid item md={5}>
            {expires && (
              <SuiTypography variant="h6">{`${t('expiration-term')}:`}</SuiTypography>
            )}
          </Grid>
          <Grid item md={2} />
          <Grid item md={5}>
            {showModify ? (
              <SuiBox display="flex" flexDirection="row" alignItems="flex-end" height="100%">
                <FormField
                  type="date"
                  value={Date.parse(assignedDate).toString('yyyy-MM-dd')}
                  onChange={(e) => handleDate('assignedDate', 'd', e.target.value)}
                />
                <FormField
                  type="time"
                  value={Date.parse(assignedDate).toString('HH:mm:ss')}
                  onChange={(e) => handleDate('assignedDate', 't', e.target.value)}
                />
              </SuiBox>
            ) : (
              <SuiTypography variant="h5">{Date.parse(assignedDate).toLocaleString()}</SuiTypography>
            )}
          </Grid>
          <Grid item md={5}>
            {expires && (
              (showModify) ? (
                <SuiBox display="flex" flexDirection="row" alignItems="flex-end" height="100%">
                  <FormField
                    type="date"
                    value={Date.parse(expiresDate).toString('yyyy-MM-dd')}
                    onChange={(e) => handleDate('expiresDate', 'd', e.target.value)}
                  />
                  <FormField
                    type="time"
                    value={Date.parse(expiresDate).toString('HH:mm:ss')}
                    onChange={(e) => handleDate('expiresDate', 't', e.target.value)}
                  />
                </SuiBox>
              ) : (
                <SuiTypography variant="h5">{Date.parse(expiresDate).toLocaleString()}</SuiTypography>
              )
            )}
          </Grid>
          <Grid item md={2} />
          <Grid item md={5}>
            <SuiTypography variant="h6">{`${t('status')}:`}</SuiTypography>
          </Grid>
          {signed
            ? (
              <Grid item md={5}>
                <SuiTypography variant="h6">{`${t('signed-on')}:`}</SuiTypography>
              </Grid>
            )
            : <Grid item md={5} />}
          <Grid item md={5}>
            {signed ? <SuiTypography variant="h5" color="good">{t('signed')}</SuiTypography>
              : <SuiTypography variant="h5" color="warning">{t('awaiting-signature')}</SuiTypography>}
          </Grid>
          {signed
            ? (
              <Grid item md={5}>
                {showModify ? (
                  <SuiBox display="flex" flexDirection="row" alignItems="flex-end" height="100%">
                    <FormField
                      type="date"
                      value={Date.parse(signedDate).toString('yyyy-MM-dd')}
                      onChange={(e) => handleDate('signedDate', 'd', e.target.value)}
                    />
                    <FormField
                      type="time"
                      value={Date.parse(signedDate).toString('HH:mm:ss')}
                      onChange={(e) => handleDate('signedDate', 't', e.target.value)}
                    />
                  </SuiBox>
                ) : (
                  <SuiTypography variant="h5">{Date.parse(signedDate).toLocaleString()}</SuiTypography>
                )}
              </Grid>
            )
            : <Grid item md={5} />}
        </Grid>
        <DropzoneDialog
          acceptedFiles={[
            'image/*',
            'application/pdf',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/msword',
          ]}
          cancelButtonText="cancel"
          submitButtonText="submit"
          maxFileSize={15728640}
          filesLimit={1}
          open={dropzoneOpen}
          onClose={() => setDropzoneOpen(false)}
          onSave={(files) => {
            handleUpload(files);
          }}
        />
      </CardContent>
      <CardActions>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          {!signed && (
            <Grid item>
              <SuiButton size="medium" color="good" onClick={handleSign}>
                {t('sign')}
                <DrawOutlinedIcon />
              </SuiButton>
            </Grid>
          )}
          {showUpload && (
            <Grid item>
              <SuiButton size="medium" onClick={() => setDropzoneOpen(true)}>
                {t('upload')}
                <FileUploadOutlinedIcon />
              </SuiButton>
            </Grid>
          )}
          <Grid item>
            <SuiButton size="medium" color="dark" onClick={handleDownload}>
              {t('download')}
              <FileDownloadOutlinedIcon />
            </SuiButton>
          </Grid>
          {showModify && (
            <Grid item md={1} />
          )}
          {showModify && (
            <Grid item>
              <SuiButton size="medium" onClick={handleModify}>
                {t('modify')}
                <EditOutlinedIcon />
              </SuiButton>
            </Grid>
          )}
          {showModify && (
            <Grid item>
              <SuiButton size="medium" color="error" onClick={handleRemove}>
                {t('remove')}
                <DeleteOutlinedIcon />
              </SuiButton>
            </Grid>
          )}
        </Grid>
      </CardActions>
    </Card>
  );
}

AgreementInfo.defaultProps = {
  signed: false,
  expires: false,
  signedOn: null,
  assignedOn: null,
  expiresOn: null,
  modifyFunc: undefined,
  removeFunc: undefined,
  showUpload: true,
  showModify: false,
};

AgreementInfo.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  fileUrl: PropTypes.string.isRequired,
  signed: PropTypes.bool,
  expires: PropTypes.bool,
  signedOn: PropTypes.string,
  assignedOn: PropTypes.string,
  expiresOn: PropTypes.string,
  signFunc: PropTypes.func.isRequired,
  uploadFunc: PropTypes.func.isRequired,
  modifyFunc: PropTypes.func,
  removeFunc: PropTypes.func,
  showUpload: PropTypes.bool,
  showModify: PropTypes.bool,
};
export default AgreementInfo;
