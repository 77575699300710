import PropTypes from 'prop-types';
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import KeyIcon from '@mui/icons-material/Key';
import Grid from '@mui/material/Grid';
import SuiTypography from 'components/SuiTypography';
import { Link } from 'react-router-dom';

function UserActivityTimeline({ activity }) {
  let lastDate = '1900-01-01';
  const items = [];
  activity.forEach(
    ({
      type,
      candidate,
      date,
      payload,
    }) => {
      items.push(
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            {Date.parse(lastDate).toLocaleDateString() !== Date.parse(date.replace('UTC', 'GMT')).toLocaleDateString()
              && (Date.parse(date.replace('UTC', 'GMT')).toLocaleDateString())}
          </TimelineOppositeContent>
          <TimelineSeparator>
            {type === 1 && (
              <TimelineDot color="secondary">
                <LibraryAddIcon fontSize="small" />
              </TimelineDot>
            )}
            {type === 2 && (
              <TimelineDot color="dark">
                <EditIcon fontSize="small" color="white" />
              </TimelineDot>
            )}
            {type === 3 && (
              <TimelineDot color="error">
                <DeleteOutlineIcon fontSize="small" />
              </TimelineDot>
            )}
            {type === 4 && (
              <TimelineDot color="warning">
                <KeyIcon fontSize="small" color="white" />
              </TimelineDot>
            )}
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Grid container direction="row">
              <Grid item md={12}>
                {type === 2 && (
                  <Link to={`/candidates/edit/${candidate.uuid}`}>
                    <SuiTypography variant="body2" fontWeight="bold">
                      {candidate.name}
                    </SuiTypography>
                  </Link>
                )}
              </Grid>
              <Grid item md={1}>
                <SuiTypography variant="body2">{Date.parse(date.replace('UTC', 'GMT')).toLocaleTimeString()}</SuiTypography>
              </Grid>
              <Grid item md={11}>
                {type === 2 && payload.map((c) => (
                  <Grid item md={12}>
                    <SuiTypography variant="body2">{`${c.field}: ${c.from} → ${c.to}`}</SuiTypography>
                  </Grid>
                ))}
                {(type === 1 || type === 3) && (
                  <SuiTypography variant="body2">{`${payload.detail} ${payload.action}`}</SuiTypography>
                )}
                {(type === 4) && (
                  <div>
                    <Grid item md={12}>
                      <SuiTypography variant="body2">{`${payload.action} from ${payload.ip}`}</SuiTypography>
                    </Grid>
                    <Grid item md={12}>
                      <SuiTypography variant="body2">{`${payload.user_agent}`}</SuiTypography>
                    </Grid>
                  </div>
                )}
              </Grid>
            </Grid>
          </TimelineContent>
        </TimelineItem>,
      );
      lastDate = date;
    },
  );

  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2,
        },
      }}
    >
      {items}
    </Timeline>
  );
}

// Typechecking props for the UserActivityTimeline
UserActivityTimeline.propTypes = {
  activity: PropTypes.arrayOf(
    PropTypes.instanceOf({
      type: PropTypes.number.isRequired,
      candidate: PropTypes.instanceOf({
        name: PropTypes.string.isRequired,
        uuid: PropTypes.string.isRequired,
      }),
      date: PropTypes.string.isRequired,
      payload: PropTypes.any.isRequired,
    }),
  ).isRequired,
};
export default UserActivityTimeline;
