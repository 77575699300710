import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// @mui material components
import Grid from '@mui/material/Grid';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Icon from '@mui/material/Icon';
import Card from '@mui/material/Card';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiInput from 'components/SuiInput';
import SuiButton from 'components/SuiButton';

// Functions
import { currencyFormatter } from 'Util';
import PropTypes from 'prop-types';

function DefaultInvoiceDetails(
  {
    invoiceDetails,
    setValue,
    removeFunc,
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'invoices' });

  const [invoiceItems, setInvoiceItems] = useState([]);
  const [invoiceTotal, setInvoiceTotal] = useState(0);

  useEffect(() => {
    if (invoiceDetails !== undefined) {
      setInvoiceItems(invoiceDetails);
    }
  }, [invoiceDetails]);

  useEffect(() => {
    let oTotal = 0;

    invoiceItems.forEach((i) => {
      // eslint-disable-next-line no-underscore-dangle
      if (i._destroy !== true) {
        oTotal += i.quantity * i.unit_price;
      }
    });

    setInvoiceTotal(oTotal);
  }, [invoiceItems]);

  const setItemValue = (key, value, i) => {
    setValue(
      invoiceItems.map((obj, index) => {
        if (index === i) {
          return {
            ...obj,
            [key]: value,
            changed: true,
          };
        }
        return obj;
      }),
    );
  };

  const handleAddItem = (e) => {
    e.preventDefault();

    const items = invoiceItems.concat({
      id: null,
      description: '',
      quantity: 0,
      unit_price: 0,
    });

    setValue(items);
  };

  const handleRemoveItem = (e, i) => {
    e.preventDefault();
    removeFunc(i);
  };

  const renderItems = (i, n) => (
    <Grid container key={`item-${n}`}>
      <Grid item md={1}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          mt={1}
        >
          <CancelOutlinedIcon
            color="error"
            onClick={(e) => { handleRemoveItem(e, n); }}
          />
        </Grid>
      </Grid>
      <Grid item md={1} pr={1}>
        <SuiInput
          type="text"
          value={i.quantity}
          onFocus={(e) => {
            e.target.select();
          }}
          onChange={(e) => {
            setItemValue('quantity', e.target.value, n);
          }}
        />
      </Grid>
      <Grid item md={5} pr={1}>
        <SuiInput
          type="text"
          value={i.description}
          onFocus={(e) => { e.target.select(); }}
          onChange={(e) => {
            setItemValue('description', e.target.value, n);
          }}
        />
      </Grid>
      <Grid item md={2} pr={1}>
        <SuiInput
          type="text"
          value={i.unit_price}
          onFocus={(e) => { e.target.select(); }}
          data-type="currency"
          onChange={(e) => {
            setItemValue('unit_price', e.target.value, n);
          }}
        />
      </Grid>
      <Grid item md={3} pr={1}>
        <SuiInput
          type="text"
          value={currencyFormatter.format(Math.round(i.quantity * i.unit_price))}
        />
      </Grid>
    </Grid>
  );

  return (
    <Card id="invoice-details" sx={{ overflow: 'visible' }}>
      <SuiBox component="form" pb={3} px={3} mt={2}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item md={12}>
            <SuiTypography variant="h5" fontWeight="medium">
              {t('invoice-details')}
            </SuiTypography>
          </Grid>
          <Grid item md={1} pr={1} />
          <Grid item md={1} pr={1}>
            <SuiTypography variant="h6" fontWeight="medium">
              {t('qty')}
            </SuiTypography>
          </Grid>
          <Grid item md={5} pr={1}>
            <SuiTypography variant="h6" fontWeight="medium">
              {t('item-description')}
            </SuiTypography>
          </Grid>
          <Grid item md={2} pr={1}>
            <SuiTypography variant="h6" fontWeight="medium">
              {t('unit-price')}
            </SuiTypography>
          </Grid>
          <Grid item md={3} pr={1}>
            <SuiTypography variant="h6" fontWeight="medium">
              {t('total')}
            </SuiTypography>
          </Grid>
          {Object.keys(invoiceItems).length > 0
            // eslint-disable-next-line no-underscore-dangle
            && invoiceItems.filter((i) => i._destroy !== true).map((i, n) => renderItems(i, n))}
          <Grid item md={9} />
          <Grid item md={3}>
            <SuiButton
              variant="text"
              color="info"
              size="small"
              onClick={handleAddItem}
            >
              <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
              {` ${t('add-new-item')}`}
            </SuiButton>
          </Grid>
          <Grid item md={7} />
          <Grid item md={2} pr={1}>
            <SuiTypography variant="h3" fontWeight="medium">
              {t('total')}
            </SuiTypography>
          </Grid>
          <Grid item md={3} pr={1}>
            <SuiTypography variant="h3" fontWeight="medium">
              {currencyFormatter.format(Math.round(invoiceTotal))}
            </SuiTypography>
          </Grid>
          <Grid item md={7} />
        </Grid>
      </SuiBox>
    </Card>
  );
}

DefaultInvoiceDetails.propTypes = {
  invoiceDetails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
      quantity: PropTypes.number,
      unit_price: PropTypes.number,
    }),
  ).isRequired,
  setValue: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
};

export default DefaultInvoiceDetails;
