import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
// eslint-disable-next-line object-curly-newline
import { setCreated, setFailed, setProfile, setSaved, updateCandidateProfile } from 'reducers/candidatesSlice';
import { fetchRecruiters } from 'reducers/recruiterSlice';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';
import { CardActions } from '@mui/material';

// Soft UI Dashboard PRO React components
import SuiButton from 'components/SuiButton';

// Components
import FormSelect from 'layouts/components/FormSelect';

// Functions
import {
  failedCandidateMessage,
  findSelectValue,
  getCandidateUUID,
  savedCandidateMessage,
} from 'Util';

const selector = (state) => ({
  profile: state.candidate.profile,
  recruiters: state.recruiter.recruiters,
  editing: state.candidate.editing,
  created: state.candidate.created,
  saved: state.candidate.saved,
  failed: state.candidate.failed,
  errors: state.candidate.errors,
});

function Recruiters() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.recruiters' });
  const {
    profile,
    recruiters,
    editing,
    created,
    saved,
    failed,
    errors,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    if (editing && uuid !== undefined) {
      dispatch(fetchRecruiters());
    }
  }, [dispatch, editing, uuid]);

  const [recruitersExpanded, setRecruitersExpanded] = useState(true);
  const [recruitersMenu, setRecruitersMenu] = useState(null);

  const openRecruitersMenu = (event) => setRecruitersMenu(event.currentTarget);
  const closeRecruitersMenu = () => setRecruitersMenu(null);
  const handleRecruitersExpand = () => {
    closeRecruitersMenu();
    setRecruitersExpanded(!recruitersExpanded);
  };

  const setValue = (key, value) => {
    dispatch(
      setProfile(
        {
          ...profile,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  const handleSaveRecruiters = () => {
    const data = {
      uuid,
      recruiterId: profile.recruiter_id,
    };

    dispatch(updateCandidateProfile(data));
  };

  if (created) {
    dispatch(setCreated(false));
    savedCandidateMessage(t);
  }
  if (saved) {
    dispatch(setSaved(false));
    savedCandidateMessage(t);
  }
  if (failed) {
    dispatch(setFailed(false));
    failedCandidateMessage(t, errors);
  }

  return (
    <Card id="recruiters" sx={{ overflow: 'visible' }}>
      <CardHeader
        action={(
          <IconButton onClick={openRecruitersMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        title={t('recruiters')}
      />
      <Menu
        anchorEl={recruitersMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(recruitersMenu)}
        onClose={closeRecruitersMenu}
        keepMounted
      >
        <MenuItem
          onClick={handleRecruitersExpand}
        >
          {recruitersExpanded
            ? t('collapse', { keyPrefix: 'common' })
            : t('expand', { keyPrefix: 'common' })}
        </MenuItem>
      </Menu>
      <Collapse in={recruitersExpanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} md={4}>
              <FormSelect
                label={t('recruiter')}
                options={recruiters}
                value={findSelectValue(recruiters, profile.recruiter_id)}
                onChange={(e) => {
                  setValue('recruiter_id', e.value);
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
      <CardActions>
        <Grid
          container
          direction="row-reverse"
          justifyContent="flex-start"
          alignItems="center"
        >
          <SuiButton
            size="small"
            color="info"
            onClick={handleSaveRecruiters}
          >
            {t('save')}
          </SuiButton>
        </Grid>
      </CardActions>
    </Card>
  );
}

export default Recruiters;
