import { useState } from 'react';

// @mui material components
import Card from '@mui/material/Card';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';

// Soft UI Dashboard PRO React components
import BasicLayout from 'layouts/authentication/components/BasicLayout';
import TermsUse from 'layouts/authentication/components/TermsUse';
import PrivacyPolicy from 'layouts/authentication/components/PrivacyPolicy';

// Images
import curved9 from 'assets/images/curved-images/curved9.jpg';

// Components

function Terms() {
  const [tabsOrientation] = useState('horizontal');
  const [tabValue, setTabValue] = useState(0);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <BasicLayout title="Terms & Service" image={curved9}>
      <Card>
        <AppBar position="static">
          <Tabs
            orientation={tabsOrientation}
            value={tabValue}
            onChange={handleSetTabValue}
          >
            <Tab label="Terms of Use" />
            <Tab label="Privacy Policy" />
          </Tabs>
        </AppBar>
        {tabValue === 0 && <TermsUse />}
        {tabValue === 1 && <PrivacyPolicy />}
      </Card>
    </BasicLayout>
  );
}

export default Terms;
