import { useEffect } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Components
import DashboardNoSideBarLayout from 'layouts/components/LayoutContainers/DashboardNoSideBarLayout';
import Footer from 'layouts/components/Footer';

// Components
import ReactGA from 'react-ga4';

function BaseLayoutNoBars({ children }) {
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  return (
    <DashboardNoSideBarLayout>
      {children}
      <Footer />
    </DashboardNoSideBarLayout>
  );
}

// Typechecking props for BaseLayout
BaseLayoutNoBars.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BaseLayoutNoBars;
