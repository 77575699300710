import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

function PrivacyPolicy() {
  return (
    <SuiBox p={3} mb={1}>
      <SuiBox p={3} mb={1} textAlign="center">
        <SuiTypography variant="h5" fontWeight="medium">
          Privacy Policy
        </SuiTypography>
      </SuiBox>
      <SuiBox p={3} mb={1} textAlign="justify">
        <SuiTypography variant="body1">
          <p>
            At The Nash Group., We take privacy concerns very seriously. By
            ensuring that you know and understand the Privacy Policy of The Nash
            Group., We can serve you better. Please take a moment to read the
            following policy to learn how we handle your personal information.
          </p>

          <p>&nbsp;</p>

          <p>
            <SuiTypography fontWeight="bold">
              Why do we collect personal information?
            </SuiTypography>
          </p>

          <p>
            The Nash Group., Collects personal information to improve the way we
            operate, offer our products and services, communicate with you about
            those products and services, and provide effective and timely
            customer support when necessary. We will never rent or sell the
            personal information we collect from you to third parties, and we
            will not share your personal information except as set forth in this
            Privacy Policy.
          </p>

          <p>&nbsp;</p>

          <p>
            <SuiTypography fontWeight="bold">
              What types of personal information do we collect?
            </SuiTypography>
          </p>

          <p>
            The Nash Group., Collects personal information in various ways when
            you interact with The Nash Group., Including when you register or
            create an account with The Nash Group., When you place an order with
            The Nash Group., When you use products or services of The Nash
            Group., when you visit the pages of The Nash Group., or the pages of
            certain partners of The Nash Group., when you participate in
            promotions or sweepstakes, when you subscribe to a newsletter or
            wish to be added to our mailing lists for other products or
            services, when you communicate with or interact with us, and when
            you provide feedback on any of our online accounts or surveys.
          </p>

          <p>&nbsp;</p>

          <p>
            The information we collect may include: your name, email address,
            telephone number, address, product preference information, billing
            information, demographic information provided by you (such as
            language, gender and age and, if applicable, preferences of content
            and personalization information) and information of personal
            interest. At times, we may request additional information that
            allows us to provide access and use of certain information,
            materials, and services. The Nash Group may combine information
            about you that we have with information that we obtain from business
            partners or other companies.
          </p>

          <p>&nbsp;</p>

          <p>
            <SuiTypography fontWeight="bold">
              What are cookies and how do we use them?
            </SuiTypography>
          </p>

          <p>
            Cookies are small data files that are written to your hard drive for
            record keeping purposes when you visit a website. Cookies allow The
            Nash Group. To measure traffic activity and improve your user
            experience, for example, by remembering your passwords and display
            preferences. Like other major websites, The Nash Group. Uses cookies
            to provide you with a personalized experience when you visit The
            Nash Group., And use our main products. We treat any personal
            information that may be contained in cookies with the same level of
            confidentiality as the rest of the information you provide us. If
            you wish to disable the functionality of cookies, you can do so by
            changing the security settings of your browser. However, it is
            possible that some parts of the The Nash Group. Websites may not
            function or function as effectively without having cookies enabled.
          </p>

          <p>&nbsp;</p>

          <p>
            <SuiTypography fontWeight="bold">
              Social media widgets:
            </SuiTypography>
          </p>

          <p>
            The websites include social media features, such as the Facebook
            &quot;Like&quot; button, and widgets, such as the &quot;Share
            This&quot; button or interactive mini-programs that run on our
            websites. These functions may collect your IP address (internet
            protocol = internet protocol), which page you are visiting on the
            websites, and may set a cookie to allow the function to function
            properly. The social media widgets and functions are hosted by a
            third party or directly on the websites. Your interactions with
            these features are governed by the privacy statement of the
            companies that provide them.
          </p>

          <p>&nbsp;</p>

          <p>
            <SuiTypography fontWeight="bold">
              Information from third party services:
            </SuiTypography>
          </p>

          <p>
            We may also obtain other information, including personal
            information, from third parties and combine it with the information
            we collect through our websites. For example, we may have access to
            certain information from a third-party social network or
            authentication service if you log into our services through that
            service or provide us with access to information from the service.
            Any access that we may have to such information from a third-party
            social network or authentication service is done in accordance with
            the authorization procedures determined by that service. If you
            authorize us to connect to a third-party service, we will access and
            store your name, email address (es), current city, profile picture
            URL, and other personal information that the third-party service
            makes available to us, and use it. and disclose it in accordance
            with this Policy. You must check your privacy settings on these
            third party services to understand and change the information that
            is sent to us through these services. For example, you can log into
            servers using single sign-on services such as Facebook Connect or an
            Open ID provider. These single sign-on services will authenticate
            your identity, provide you with the option to share certain personal
            information (such as your name and email address) with us, and
            pre-fill our registration form. Servers like Facebook Connect give
            you the option to post information about your activities on the
            services on your profile page to share with others within your
            network.
          </p>

          <p>&nbsp;</p>

          <p>
            <SuiTypography fontWeight="bold">
              How do we protect your personal information?
            </SuiTypography>
          </p>

          <p>
            The Nash Group., Safeguards the security of the data that you send
            us with physical, electronic and administrative procedures. We urge
            you to take every precaution to protect your personal data when you
            are on the Internet. To make purchases on the The Nash Group.
            Website, you must use an SS-enabled browser as The Nash Group. Uses
            industry-standard Secure Sockets Layer (SSL) encryption on the web
            pages used to complete a purchase. This protects the confidentiality
            of your personal and credit card information as it is transmitted
            over the Internet. While The Nash Group., Takes precautions to
            ensure the security of your data, no one can COMPLETELY guarantee
            the security of data transmitted over the internet. It is important
            that you take steps to ensure the security of your personal
            information, for example by NOT sharing passwords or other
            registration data.
          </p>

          <p>&nbsp;</p>

          <p>
            <SuiTypography fontWeight="bold">
              How do we protect your personal information?
            </SuiTypography>
          </p>

          <p>
            The Nash Group., Uses your personal information in the following
            ways:
          </p>

          <p>&nbsp;</p>

          <p>
            &bull; Delivery and billing of our products and services, and to
            communicate with you about your account (s), Event (s) and customer
            service issues. This may include verifying the authorization of the
            use of the credit card before any purchase of content or services.
          </p>
          <p>&nbsp;</p>
          <p>
            &bull; Improve our products and services by providing personalized
            content recommendations, language and location customization, and /
            or personalized help and instructions. &bull; Ask you to participate
            in surveys about products, services, news and events.
          </p>
          <p>&nbsp;</p>
          <p>
            &bull; Offer you product updates, content, services, and other
            training courses for you to purchase.
          </p>
          <p>&nbsp;</p>
          <p>
            &bull; Provide you with content, services, instructions, and a more
            personalized experience based on personal information, such as your
            language preferences, zip code, country locations, and any content
            or other preferences that you provide.
          </p>
          <p>&nbsp;</p>
          <p>
            &bull; Provide you with news, free newsletters, updates, targeted
            advertising, promotions, and special offers.
          </p>
          <p>&nbsp;</p>
          <p>
            &bull; To better understand how our products are used, including web
            traffic patterns and what types of content are most popular with
            users of our products and services.
          </p>
          <p>&nbsp;</p>
          <p>
            &bull; Provide anonymous reports for internal and external partners.
          </p>
          <p>&nbsp;</p>
          <p>
            &bull; Facilitate your participation in our online forums and help
            manage inappropriate behavior of people who use the forums.
          </p>

          <p>&nbsp;</p>

          <p>
            <SuiTypography fontWeight="bold">
              How and when do we disclose your information that we collect?
            </SuiTypography>
          </p>

          <p>
            The Nash Group., May share your personal information with trusted
            vendors and partners who work with The Nash Group. These partners
            receive your personal information to help The Nash Group communicate
            with you about our offerings and our marketing partners. They are
            contractually bound to keep your personal information secure and to
            use it only for the purposes for which it is provided. The Nash
            Group may send personal information about you to other companies or
            individuals when we need to share your information to facilitate the
            product or service you have requested or to provide you with a
            product or service. However, these companies can only use personal
            information for such purposes.
          </p>

          <p>&nbsp;</p>

          <p>
            The Nash Group., May also disclose your personal information if
            required by law or if you believe in good faith that such action is
            necessary to:
          </p>
          <p>&nbsp;</p>
          <p>
            (1) comply with the law or a legal process or if requested by duly
            authorized public authorities.
          </p>

          <p>
            (2) comply with the order of a competent judicial authority in any
            jurisdiction.
          </p>

          <p>
            (3) protect and defend the rights or property of ANP HEALTH SERVICES
            INC or our affiliated companies, their employees and agents.
          </p>

          <p>(4) enforce our terms of use.</p>

          <p>
            (5) protect against fraud or other illegal activity or for risk
            management purposes.
          </p>

          <p>
            (6) allow us to pursue available remedies or limit the damages we
            may suffer.
          </p>

          <p>
            (7) act in urgent circumstances to protect the personal safety of
            users of The Nash Group products or the general public.
          </p>

          <p>&nbsp;</p>

          <p>
            If The Nash Group. Ever files for bankruptcy, or becomes insolvent,
            or is acquired by a third party, or merges with a third party, or
            sells all or part of its assets, or transfers substantially all or
            part of your relevant assets to a third party, then The Nash Group
            has the right to share your personal information and any other
            information that you have provided through the products and services
            to potential and subsequent business and merger partners. The Nash
            Group may share cookies and other anonymous data on traffic or usage
            patterns collected at The Nash Group with affiliated companies,
            their business partners, or third-party advertisers or ad networks.
          </p>

          <p>&nbsp;</p>

          <p>
            The Nash Group., May also include third party advertising. The
            websites and advertisers of these third parties, or internet
            advertising companies working on their behalf, may use technology
            such as cookies to deliver (or &quot;servers&quot;) the
            advertisements that appear on our website directly to your browser.
            They automatically receive your IP address when this happens. They
            may also use cookies, JavaScript, electronic images, and other
            technologies to deliver advertisements, collect information about
            the effectiveness of their advertisements, and to collect anonymous
            information about your visits to The Nash Group, such as the number
            of times you have seen an advertisement. The Nash Group recommends
            that you independently learn more about this practice and your
            options so that these ad networks do not use this information for
            online behavioral advertising.
          </p>

          <p>&nbsp;</p>

          <p>
            The Nash Group also contains links to other websites and products.
            The Nash Group is not responsible for the privacy practices or the
            content of such websites or products. Please visit the privacy
            policies of those third party websites to understand their privacy
            policies and data collection practices.
          </p>

          <p>&nbsp;</p>

          <p>
            <SuiTypography fontWeight="bold">
              What rights do you have over your data?
            </SuiTypography>
          </p>

          <p>
            You always have the right to request information about your stored
            data, its origin, its recipients and the purpose of its collection
            free of charge. You also have the right to request that your
            processed data be corrected, blocked or deleted. You can contact us
            at any time through our Customer Service department at +1
            651-285-0394 from 9 am to 5 pm EST (USA), Monday through Friday or
            contact us by email at
            {' '}
            <a href="mailto:contacto@nursing-advisory.com" target="_self">
              contacto@nursing-advisory.com
            </a>
            {' '}
            if you have any further questions about privacy and data protection.
          </p>

          <p>&nbsp;</p>

          <p>
            Many data processing operations are only possible with your express
            consent. You can revoke your consent at any time with future effect.
            An informal email making this request is sufficient. The data
            processed before receiving your request can still be used in legal
            proceedings. If you no longer wish to receive communications from
            The Nash Group, you may choose not to receive such messages in the
            future by following the instructions in the message or by contacting
            us directly. With regard to the use of cookies and other similar
            technologies by the site, by adjusting your browser settings, you
            can choose not to allow cookies to be used and, therefore, opt out
            of receiving certain functions of the website and the automatic
            collection of certain data by the website about you. You can also
            adjust your advertising preferences on various social media websites
            by adjusting your settings on the platform. If you are accessing the
            site from the European Economic Area, which includes the European
            Union, Iceland, Liechtenstein and Norway, or you are a citizen of a
            member state of the European Economic Area, you have the following
            rights under the EU General Data Protection Regulation (GDPR ).
          </p>
          <p>&nbsp;</p>
          <p>
            1. Right to be informed about the collection and use of your
            personal data.
          </p>

          <p>2. Right to access your personal data.</p>

          <p>3. Right to have inaccurate personal data corrected.</p>

          <p>4. Right to have your personal data deleted.</p>

          <p>5. Right to restrict the use of your personal data.</p>

          <p>6. Right to portability of personal data.</p>

          <p>7. Right to object to the processing of your personal data.</p>

          <p>&nbsp;</p>

          <p>
            <SuiTypography fontWeight="bold">
              What are the legal reasons we collect and disclose certain
              information?
            </SuiTypography>
          </p>

          <p>
            Legal basis for processing (EEA visitors only): If you are a visitor
            from the European Economic Area, our legal basis for collecting and
            using the personal information described above will depend on the
            personal information in question and the specific context in which
            we collect it. However, we will normally collect personal
            information from you only when we need it to perform a contract with
            you (for example, to provide you with our services or products),
            when the processing is in our legitimate interest and is not
            overridden by your data protection interests. or fundamental rights
            and freedoms, or when we have your consent. In some cases, we may
            also have a legal obligation to collect your personal information.
            If we ask you to provide personal information to comply with a legal
            requirement or to make contact with you, we will make this clear at
            the relevant time and inform you if the provision of your personal
            information is mandatory or not (as well as the possible
            consequences if does not provide your personal information).
            Similarly, if we collect and use your personal information based on
            our legitimate interests (or those of any third party), we will make
            it clear to you at the relevant time why we need to use your
            personal information. We process your personal information based on
            your consent, you have the right to withdraw your consent at any
            time. If you have questions or need more information about the legal
            basis on which we collect and use your personal information, please
            contact us at any time through our Customer Service department at +1
            651-285-0394 between the hours of 9 am to 5 pm EST (USA), Monday
            through Friday or contact us by email at
            {' '}
            <a href="mailto:contacto@nursing-advisory.com" target="_self">
              contacto@nursing-advisory.com
            </a>
          </p>

          <p>&nbsp;</p>

          <p>
            <SuiTypography fontWeight="bold">
              Compliance with Laws and Law Enforcement Requests. Protection of
              rights:
            </SuiTypography>
          </p>

          <p>
            In certain situations, we may be required to disclose personal
            information in response to lawful requests from public authorities,
            including to comply with national security or law enforcement
            requirements. We may disclose personal information to respond to
            subpoenas, court orders or legal process, or to establish or
            exercise our legal rights or defend against legal claims. We may
            also share such information if we believe it is necessary to
            investigate, prevent or take action with respect to illegal
            activities, suspected fraud, situations involving potential threats
            to the physical safety of any person, violations of our service
            agreement or otherwise. ; required by law.
          </p>

          <p>&nbsp;</p>

          <p>
            Be sure to return to these terms, conditions, and policies
            periodically to review the most current version. We reserve the
            right at any time, at our sole discretion, to change or modify these
            terms, conditions and policies without prior notice; however, the
            date of any effective changes will be reflected at the bottom of
            this page and, upon request, we will provide you with information on
            any changes made. This policy was last updated on October 31, 2020.
          </p>

          <p>&nbsp;</p>

          <p>&nbsp;</p>
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
}

export default PrivacyPolicy;
