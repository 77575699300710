import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Reducer functions
import {
  createCandidateNote,
  createCandidateReminder,
  deleteCandidateNote,
  deleteCandidateReminder,
  fetchCandidateNote,
  fetchCandidateNotesTimeline,
  fetchCandidateProfile,
  fetchCandidateReminder,
  setCreated,
  setFailed,
  setSaved,
  updateCandidateNote,
  updateCandidateReminder,
} from 'reducers/candidatesSlice';
import { fetchContactMethods, fetchContactOutcomes } from 'reducers/commonSlice';
import { fetchUsersInfo } from 'reducers/usersSlice';

// @material-ui core components
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import ConnectWithoutContactOutlinedIcon from '@mui/icons-material/ConnectWithoutContactOutlined';
import Icon from '@mui/material/Icon';
import { Backdrop, CircularProgress } from '@mui/material';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';
import SuiEditor from 'components/SuiEditor';

// Functions
import { findSelectValue, getCandidateUUID, setTitle } from 'Util';
import Swal from 'sweetalert2';

// Other components
import useWindowDimensions from 'layouts/components/Hooks/WindowDimensions';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';
import NoteCard from './components/NoteCard';

require('datejs');

const selector = (state) => ({
  editing: state.candidate.editing,
  profile: state.candidate.profile,
  note: state.candidate.note,
  reminder: state.candidate.reminder,
  failed: state.candidate.failed,
  created: state.candidate.created,
  saved: state.candidate.saved,
  errors: state.candidate.errors,
  users: state.user.users,
  contactOutcomes: state.common.contactOutcomes,
  contactMethods: state.common.contactMethods,
  notesTimeline: state.candidate.notesTimeline,
});

function Notes() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.notes' });
  const {
    editing,
    profile,
    note,
    reminder,
    failed,
    created,
    saved,
    errors,
    users,
    notesTimeline,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();
  const {
    height,
  } = useWindowDimensions();

  useEffect(() => {
    dispatch(fetchContactMethods());
    dispatch(fetchContactOutcomes());
    dispatch(fetchUsersInfo());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateProfile({ uuid }));
      dispatch(fetchCandidateNotesTimeline({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  setTitle('Candidate Notes');

  const noteTypes = [
    {
      value: 1,
      label: 'Note',
    },
    {
      value: 3,
      label: 'Reminder',
    },
  ];

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [showNoteForm, setShowNoteForm] = useState(false);
  const [noteId, setNoteId] = useState(null);
  const [noteDate, setNoteDate] = useState(null);
  const [noteType, setNoteType] = useState(noteTypes[0]);
  const [noteTitle, setNoteTitle] = useState('');
  const [noteContent, setNoteContent] = useState('');
  const [isReminder, setIsReminder] = useState(false);
  const [remindOn, setRemindOn] = useState('');
  const [remindTo, setRemindTo] = useState({
    value: '*',
    label: t('all'),
  });
  const [usersList, setUsersList] = useState({});
  const [notes, setNotes] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedNote, setSelectedNote] = useState(null);
  const [parentHeight, setParentHeight] = useState(500);

  useEffect(() => {
    setParentHeight(height - 80);
  }, [height]);

  useEffect(() => {
    const allUsr = [
      {
        value: '*',
        label: t('all'),
      },
    ];
    Array.prototype.push.apply(allUsr, users);
    setUsersList(allUsr);
    // eslint-disable-next-line
  }, [users]);

  useEffect(() => {
    if (note.id !== undefined) {
      setIsReminder(false);
      setNoteId(note.id);
      setNoteDate(note.date);
      setNoteType(findSelectValue(noteTypes, 1));
      setNoteTitle(note.title);
      setNoteContent(note.note);
    }
    // eslint-disable-next-line
  }, [note]);

  useEffect(() => {
    if (reminder.id !== undefined) {
      setIsReminder(true);
      setNoteType(findSelectValue(noteTypes, 3));
      setRemindTo(findSelectValue(usersList, reminder.remind_to));
      const d = new Date(reminder.remind_on);
      setRemindOn(
        `${d.getFullYear()}-${
          d.getMonth() < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1
        }-${d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()}`,
      );
      setNoteTitle(reminder.title);
      setNoteContent(reminder.note);
    }
    // eslint-disable-next-line
  }, [reminder]);

  useEffect(() => {
    if (notesTimeline !== undefined && notesTimeline !== null) {
      setNotes(notesTimeline);
      setSelectedNote(notesTimeline[0] !== undefined ? notesTimeline[0] : null);
    }
  }, [notesTimeline]);

  const sections = [
    {
      value: 1,
      label: t('general'),
    },
    {
      value: 2,
      label: t('status'),
    },
    {
      value: 3,
      label: t('contact'),
    },
    {
      value: 4,
      label: t('personal'),
    },
    {
      value: 5,
      label: t('education'),
    },
    {
      value: 6,
      label: t('experience'),
    },
    {
      value: 7,
      label: t('language'),
    },
    {
      value: 8,
      label: t('licenses'),
    },
    {
      value: 9,
      label: t('evaluations'),
    },
    {
      value: 10,
      label: t('bon'),
    },
    {
      value: 11,
      label: t('fingerprints'),
    },
    {
      value: 12,
      label: t('nclex'),
    },
  ];

  const iconMapping = (type) => {
    let icon;
    switch (type) {
      case 1:
        icon = <CommentOutlinedIcon sx={{ color: '#2980B9' }} />;
        break;
      case 2:
        icon = <ConnectWithoutContactOutlinedIcon sx={{ color: '#A569BD' }} />;
        break;
      case 3:
        icon = <NotificationsActiveOutlinedIcon sx={{ color: '#E74C3C' }} />;
        break;
      default:
        break;
    }

    return icon;
  };

  const getSection = (id) => {
    if (id !== null) {
      return sections.filter((s) => s.value === id)[0].label;
    }

    return null;
  };

  const handleNewNote = () => {
    setIsReminder(false);
    setNoteId(null);
    setNoteType(findSelectValue(noteTypes, 1));
    setNoteTitle('');
    setNoteContent('');
    setRemindTo({
      value: '*',
      name: 'All',
    });
    setRemindOn('');
    setShowNoteForm(true);
  };
  const handleCloseNoteForm = () => setShowNoteForm(false);
  const handleNoteType = (e) => {
    switch (e.value) {
      case 1:
        setIsReminder(false);
        break;
      case 3:
        setIsReminder(true);
        break;
      default:
        setNoteType(e);
    }

    setNoteType(e);
  };
  const handleRemindOn = (e) => {
    e.preventDefault();
    setRemindOn(e.target.value);
  };
  const handleRemindTo = (e) => {
    setRemindTo(e);
  };
  const handleNoteTitle = (e) => {
    e.preventDefault();
    setNoteTitle(e.target.value);
  };
  const handleNoteContent = (e) => setNoteContent(e);
  const handleSaveNote = () => {
    if ((!noteTitle || noteTitle !== '') && (!noteContent || noteContent !== '')) {
      setOpenBackdrop(true);
      const funcMapping = {
        1: {
          create: createCandidateNote,
          edit: updateCandidateNote,
        },
        3: {
          create: createCandidateReminder,
          edit: updateCandidateReminder,
        },
      };

      const {
        create,
        edit,
      } = funcMapping[noteType.value];

      const data = {
        candidateId: profile.uuid,
        title: noteTitle,
        note: noteContent,
      };

      if (noteType.value === 3) {
        data.remindOn = remindOn;
        data.remindTo = remindTo.value;
      }

      if (noteId !== null) {
        data.id = noteId;
        data.date = noteDate;

        dispatch(edit(data));
      } else {
        const currentDate = Date.parse(Date().toString()).toString('u').replace('Z', ' UTC');
        data.date = currentDate;
        data.section = 1;

        dispatch(create(data));
      }

      setShowNoteForm(false);
    }
  };
  const handleEditNote = (e, type, id) => {
    setNoteType(noteTypes[0]);
    setIsReminder(false);
    setNoteTitle('');
    setNoteContent('');
    setRemindOn('');
    setRemindTo('');

    const funcMapping = {
      1: {
        fetch: fetchCandidateNote,
      },
      3: {
        fetch: fetchCandidateReminder,
      },
    };

    const { fetch } = funcMapping[type];

    dispatch(
      fetch({
        uuid: profile.uuid,
        id,
      }),
    );

    setShowNoteForm(true);
  };
  const handleRemoveNote = (e, type, id) => {
    const alert = Swal.mixin({
      customClass: {
        confirmButton: 'button button-success',
        cancelButton: 'button button-error',
      },
      buttonsStyling: false,
    });

    alert
      .fire({
        title: t('are-you-sure', { keyPrefix: 'common' }),
        text: t('are-you-sure-you-want-to-delete-it', { keyPrefix: 'common' }),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('yes-delete-it', { keyPrefix: 'common' }),
      })
      .then((result) => {
        if (result.isConfirmed) {
          setOpenBackdrop(true);
          const funcMapping = {
            1: {
              remove: deleteCandidateNote,
            },
            3: {
              remove: deleteCandidateReminder,
            },
          };

          const { remove } = funcMapping[type];
          dispatch(remove({
            candidateId: profile.uuid,
            id,
          }));

          setShowNoteForm(false);
        }
      });
  };
  const handleSelect = (e, i) => {
    e.preventDefault();
    setSelectedIndex(i);
    setSelectedNote(notes[i]);
  };

  if (created) {
    setOpenBackdrop(false);
    dispatch(setCreated(false));

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('candidate-created-successfully', { keyPrefix: 'candidates.save' }),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    });
  }
  if (saved) {
    setOpenBackdrop(false);
    dispatch(setSaved(false));

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('candidate-saved-successfully', { keyPrefix: 'candidates.save' }),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    });
  }
  if (failed) {
    setOpenBackdrop(false);
    dispatch(setFailed(false));

    Swal.fire({
      title: t('error', { keyPrefix: 'common' }),
      text: errors,
      icon: 'error',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    });
  }

  return (
    <SuiBox>
      <SuiBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <SuiTypography variant="h5" fontWeight="medium">
              {t('candidate-notes')}
            </SuiTypography>
          </Grid>
          <Grid item xs={12} md={6}>
            <SuiBox display="flex" justifyContent="flex-end">
              <SuiButton
                variant="gradient"
                color="info"
                size="small"
                onClick={handleNewNote}
              >
                <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
                &nbsp;
                {t('new-note')}
              </SuiButton>
            </SuiBox>
          </Grid>
          <Grid item md={4}>
            <div
              style={{
                minHeight: '300',
                height: parentHeight,
                overflowY: 'scroll',
              }}
            >
              {notes.map((n, i) => (
                <div key={`note${i}`}>
                  <SuiBox onClick={(e) => handleSelect(e, i)}>
                    <NoteCard
                      id={n.id}
                      title={n.title}
                      icon={iconMapping(n.note_type)}
                      content={n.note}
                      dateTime={n.date}
                      selected={selectedIndex === i}
                      badges={n.section !== null
                        ? [
                          {
                            label: getSection(n.section),
                            color: 'primary',
                          },
                          {
                            label: n.username,
                            color: 'secondary',
                          },
                        ]
                        : [
                          {
                            label: n.username,
                            color: 'secondary',
                          },
                        ]}
                      fade
                    />
                    <Divider />
                  </SuiBox>
                </div>
              ))}
            </div>
          </Grid>
          <Grid item md={8}>
            {selectedNote !== null && (
              <Grid container>
                <Grid item md={12}>
                  <NoteCard
                    id={selectedNote.id}
                    title={selectedNote.title}
                    content={selectedNote.note}
                    dateTime={selectedNote.date}
                    editFunc={(e) => handleEditNote(e, selectedNote.note_type, selectedNote.id)}
                    removeFunc={(e) => handleRemoveNote(e, selectedNote.note_type, selectedNote.id)}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </SuiBox>
      <Dialog
        open={showNoteForm}
        onClose={handleCloseNoteForm}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>Note</DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item md={2}>
              <FormSelect
                label="Type"
                options={noteTypes}
                value={noteType}
                onChange={handleNoteType}
              />
            </Grid>
            {isReminder && (
              <Grid item md={2}>
                <FormField
                  label="Remind on"
                  type="date"
                  placeholder="Select a date"
                  value={remindOn || ''}
                  onChange={handleRemindOn}
                />
              </Grid>
            )}
            {isReminder && (
              <Grid item md={3}>
                <FormSelect
                  label="To"
                  options={usersList}
                  value={remindTo}
                  onChange={handleRemindTo}
                />
              </Grid>
            )}
            <Grid item md={isReminder ? 5 : 10}>
              <FormField
                id="title"
                label="title"
                value={noteTitle !== undefined ? noteTitle : ''}
                error={!noteTitle || noteTitle === ''}
                onChange={handleNoteTitle}
              />
            </Grid>
            <Grid item md={12}>
              <SuiEditor
                value={noteContent}
                onChange={handleNoteContent}
                error={!noteContent || noteContent === ''}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SuiButton onClick={handleCloseNoteForm}>Cancel</SuiButton>
          <SuiButton
            variant="contained"
            color="info"
            onClick={handleSaveNote}
          >
            {t('save', { keyPrefix: 'common' })}
          </SuiButton>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </SuiBox>
  );
}

export default Notes;
