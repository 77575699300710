import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Reducers
import { clearPaymentPlan, setPaymentPlanEditing, setPaymentPlanInfo } from 'reducers/paymentPlansSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import PlanInfo from '../components/PlanInfo';
import SavePanel from '../components/SavePanel';

function NewPlan() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPaymentPlanEditing(false));
    dispatch(clearPaymentPlan());
    dispatch(
      setPaymentPlanInfo({
        name: '',
        has_down_payment: false,
        down_payment_amount: 0,
        down_payment_type: undefined,
        has_deferred_payment: false,
        deferred_amount: 0,
        deferred_type: undefined,
        payment_number: 0,
        payment_frequency: undefined,
        enabled: true,
      }),
    );
  }, [dispatch]);

  return (
    <BaseLayout>
      <SuiBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SuiBox mb={3}>
              <PlanInfo />
              <SavePanel />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </BaseLayout>
  );
}

export default NewPlan;
