import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fulfilledReducer, pendingReducer, rejectionReducer } from 'Util';
import ReportService from '../services/report.service';

export const fetchCandidatesOverview = createAsyncThunk(
  'reports/fetchCandidatesOverview',
  async (payload, { rejectWithValue }) => {
    try {
      return await ReportService.getCandidatesOverview();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidatesContracts = createAsyncThunk(
  'reports/fetchCandidatesContracts',
  async (payload, { rejectWithValue }) => {
    try {
      return await ReportService.getCandidatesContracts();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidatesProgress = createAsyncThunk(
  'reports/fetchCandidatesProgress',
  async (payload, { rejectWithValue }) => {
    const {
      statuses,
    } = payload;
    try {
      return await ReportService.getCandidatesProgress(statuses);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidatesStatusesStats = createAsyncThunk(
  'reports/fetchCandidatesStatusesStats',
  async (payload, { rejectWithValue }) => {
    try {
      return await ReportService.getCandidateStatusesStats();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidatesStatusesStatsByAdvisor = createAsyncThunk(
  'reports/fetchCandidatesStatusesStatsByAdvisor',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await ReportService.getCandidateStatusesStatsByAdvisor(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidatesTotalsStats = createAsyncThunk(
  'reports/fetchCandidatesTotalsStats',
  async (payload, { rejectWithValue }) => {
    try {
      return await ReportService.getCandidatesTotalsStats();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidatesTotalsStatsByAdvisor = createAsyncThunk(
  'reports/fetchCandidatesTotalsStatsByAdvisor',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await ReportService.getCandidatesTotalsStatsByAdvisor(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidatesMonthStats = createAsyncThunk(
  'reports/fetchCandidatesMonthStats',
  async (payload, { rejectWithValue }) => {
    try {
      return await ReportService.getCandidatesMonthStats();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidatesMonthStatsByAdvisor = createAsyncThunk(
  'reports/fetchCandidatesMonthStatsByAdvisor',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await ReportService.getCandidatesMonthStatsByAdvisor(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateSummary = createAsyncThunk(
  'reports/fetchCandidateSummary',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await ReportService.getCandidateSummary(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateSummaries = createAsyncThunk(
  'reports/fetchCandidateSummaries',
  async (payload, { rejectWithValue }) => {
    try {
      return await ReportService.getCandidateSummaries();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateRecruitmentSummary = createAsyncThunk(
  'reports/fetchCandidateRecruitmentSummary',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await ReportService.getCandidateRecruitmentSummary(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidatesDataview = createAsyncThunk(
  'reports/fetchCandidatesDataview',
  async (payload, { rejectWithValue }) => {
    const { section } = payload;
    try {
      return await ReportService.getCandidateDataview(section);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidatesPaymentPlans = createAsyncThunk(
  'reports/fetchCandidatesPaymentPlans',
  async (payload, { rejectWithValue }) => {
    try {
      return await ReportService.getCandidatePaymentPlans();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const matchBsrCandidates = createAsyncThunk(
  'reports/matchBsrCandidates',
  async (payload, { rejectWithValue }) => {
    const { candidates } = payload;
    try {
      return await ReportService.matchBsrCandidates(candidates);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidatesLatestStatuses = createAsyncThunk(
  'reports/fetchCandidatesLatestStatuses',
  async (payload, { rejectWithValue }) => {
    try {
      return await ReportService.getCandidatesLatestStatuses();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchDueInvoices = createAsyncThunk(
  'reports/fetchDueInvoices',
  async (payload, { rejectWithValue }) => {
    const { date } = payload;
    try {
      return await ReportService.getDueInvoices(date);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchEnglishEnrollmentDays = createAsyncThunk(
  'reports/fetchEnglishEnrollmentDays',
  async (payload, { rejectWithValue }) => {
    const { date, statuses } = payload;
    try {
      return await ReportService.getEnglishEnrollmentDays(date, statuses);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchLeadConversionRate = createAsyncThunk(
  'reports/fetchLeadConversionRate',
  async (payload, { rejectWithValue }) => {
    const { from, to } = payload;
    try {
      return await ReportService.getLeadConversion(from, to);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCommitmentMeetings = createAsyncThunk(
  'reports/fetchCommitmentMeetings',
  async (payload, { rejectWithValue }) => {
    const { from, to } = payload;
    try {
      return await ReportService.getCommitmentMeetings(from, to);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchEnglishLearningSpans = createAsyncThunk(
  'reports/fetchEnglishLearningSpans',
  async (payload, { rejectWithValue }) => {
    const { statuses } = payload;
    try {
      return await ReportService.getEnglishLearningSpans(statuses);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

/* eslint-disable no-param-reassign */
export const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    candidatesOverview: [],
    candidatesContracts: [],
    candidatesProgress: [],
    candidatesStatusesStats: {},
    candidatesTotalsStats: {},
    candidatesMonthStats: {},
    candidateSummary: {},
    candidateSummaries: [],
    candidateDataview: {},
    candidatesPaymentPlans: {},
    bsrCandidatesMatches: [],
    candidateLatestStatuses: [],
    dueInvoices: [],
    englishEnrollmentDays: [],
    leadConversionRate: [],
    commitmentMeetings: [],
    englishLearningSpans: [],
  },
  reducers: {
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setBsrCandidatesMatches: (state, action) => {
      state.bsrCandidatesMatches = action.payload;
    },
  },
  extraReducers: (builder) => builder.addMatcher(
    (action) => action.type.endsWith('/rejected'),
    rejectionReducer,
  ).addMatcher(
    (action) => action.type.endsWith('/pending'),
    pendingReducer,
  ).addMatcher(
    (action) => action.type.endsWith('/fulfilled'),
    (state, action) => {
      const performedAction = action.type.split('/');
      if (performedAction[0] === 'reports') {
        switch (performedAction[1]) {
          case 'fetchCandidatesOverview':
            fulfilledReducer(state, action);
            state.candidatesOverview = action.payload;
            break;
          case 'fetchCandidatesContracts':
            fulfilledReducer(state, action);
            state.candidatesContracts = action.payload;
            break;
          case 'fetchCandidatesStatusesStats':
          case 'fetchCandidatesStatusesStatsByAdvisor':
            fulfilledReducer(state, action);
            state.candidatesStatusesStats = action.payload;
            break;
          case 'fetchCandidatesTotalsStats':
          case 'fetchCandidatesTotalsStatsByAdvisor':
            fulfilledReducer(state, action);
            state.candidatesTotalsStats = action.payload;
            break;
          case 'fetchCandidatesMonthStats':
          case 'fetchCandidatesMonthStatsByAdvisor':
            fulfilledReducer(state, action);
            state.candidatesMonthStats = action.payload;
            break;
          case 'fetchCandidatesProgress':
            fulfilledReducer(state, action);
            state.candidatesProgress = action.payload;
            break;
          case 'fetchCandidateSummary':
          case 'fetchCandidateRecruitmentSummary':
            fulfilledReducer(state, action);
            state.candidateSummary = action.payload;
            break;
          case 'fetchCandidateSummaries':
            fulfilledReducer(state, action);
            state.candidateSummaries = action.payload;
            break;
          case 'fetchCandidatesDataview':
            fulfilledReducer(state, action);
            state.candidateDataview[action.payload.section] = action.payload.data;
            break;
          case 'fetchCandidatesPaymentPlans':
            fulfilledReducer(state, action);
            state.candidatesPaymentPlans = action.payload;
            break;
          case 'matchBsrCandidates':
            fulfilledReducer(state, action);
            state.bsrCandidatesMatches = action.payload;
            break;
          case 'fetchCandidatesLatestStatuses':
            fulfilledReducer(state, action);
            state.candidateLatestStatuses = action.payload;
            break;
          case 'fetchDueInvoices':
            fulfilledReducer(state, action);
            state.dueInvoices = action.payload;
            break;
          case 'fetchEnglishEnrollmentDays':
            fulfilledReducer(state, action);
            state.englishEnrollmentDays = action.payload;
            break;
          case 'fetchLeadConversionRate':
            fulfilledReducer(state, action);
            state.leadConversionRate = action.payload;
            break;
          case 'fetchCommitmentMeetings':
            fulfilledReducer(state, action);
            state.commitmentMeetings = action.payload;
            break;
          case 'fetchEnglishLearningSpans':
            fulfilledReducer(state, action);
            state.englishLearningSpans = action.payload;
            break;
          default:
            fulfilledReducer(state, action);
            break;
        }
      }
    },
  ),
});
/* eslint-disable no-param-reassign */

export const {
  setSucceeded,
  setBsrCandidatesMatches,
} = reportsSlice.actions;

export default reportsSlice.reducer;
