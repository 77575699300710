import Grid from '@mui/material/Grid'; // Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

function InvoiceFooter() {
  return (
    <SuiBox p={3} mt={5}>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <SuiTypography variant="h5" fontWeight="medium">
            Thank you for your business!
          </SuiTypography>
          <SuiBox mt={1} mb={2} lineHeight={0}>
            <SuiTypography
              variant="button"
              fontWeight="regular"
              color="secondary"
            >
              If you have any questions concerning this invoice, contact Deb
              Terry:
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={1} mb={2} lineHeight={0}>
            <SuiTypography
              component="span"
              variant="h6"
              fontWeight="medium"
              color="secondary"
            >
              phone:
              {' '}
              <SuiTypography component="span" variant="h6" fontWeight="medium">
                (847) 425-4783 Ext: 5002
              </SuiTypography>
            </SuiTypography>
            <br />
            <SuiTypography
              component="span"
              variant="h6"
              fontWeight="medium"
              color="secondary"
            >
              email:
              {' '}
              <SuiTypography component="span" variant="h6" fontWeight="medium">
                dterry@nashgroup.com
              </SuiTypography>
            </SuiTypography>
          </SuiBox>
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default InvoiceFooter;
