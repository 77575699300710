import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

// Reducers functions
import { setCurrentSubView } from 'reducers/candidatesSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Functions
import { checkPermission } from 'Util';

// Components
import MailLists from '../MailLists';

const selector = (state) => ({
  currentUser: state.auth.user,
});

function Marketing() {
  const {
    currentUser,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentSubView('marketing'));
  }, [dispatch]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        {checkPermission('CAAE', currentUser) && <MailLists />}
        <Divider />
      </Grid>
    </Grid>
  );
}

export default Marketing;
