import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  createCandidatePreferences,
  fetchCandidatePreferences,
  setCreated,
  setFailed,
  setPreferences,
  setSaved,
  updateCandidatePreferences,
} from 'reducers/candidatesSlice';
import { fetchTimezones } from 'reducers/commonSlice';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';
import { CardActions } from '@mui/material';

// Soft UI Dashboard PRO React components
import SuiButton from 'components/SuiButton';

// Components
import FormCheckbox from 'layouts/components/FormCheckbox';
import FormSelect from 'layouts/components/FormSelect';

// Functions
import {
  defaultValue,
  failedCandidateMessage,
  findSelectValue,
  getCandidateUUID,
  NoChangesWarning,
  savedCandidateMessage,
} from 'Util';

const selector = (state) => ({
  editing: state.candidate.editing,
  preferences: state.candidate.preferences,
  timezones: state.common.timezones,
  created: state.candidate.created,
  saved: state.candidate.saved,
  failed: state.candidate.failed,
  errors: state.candidate.errors,
});

function Preferences() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.preferences' });
  const {
    editing,
    preferences,
    timezones,
    created,
    saved,
    failed,
    errors,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    dispatch(fetchTimezones());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidatePreferences({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  const [preferencesExpanded, setPreferencesExpanded] = useState(true);
  const [preferencesMenu, setPreferencesMenu] = useState(null);

  const openPreferencesMenu = (event) => setPreferencesMenu(event.currentTarget);
  const closePreferencesMenu = () => setPreferencesMenu(null);
  const handlePreferencesExpand = () => {
    closePreferencesMenu();
    setPreferencesExpanded(!preferencesExpanded);
  };

  const preferredLanguages = [
    {
      value: 118,
      label: t('english'),
    },
    {
      value: 373,
      label: t('spanish'),
    },
    {
      value: 328,
      label: t('portuguese'),
    },
  ];

  const setValue = (key, value) => {
    dispatch(
      setPreferences(
        {
          ...preferences,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  const handleEmailNotifications = (e) => {
    e.preventDefault();

    setValue('email_notifications', !preferences.email_notifications);
  };

  const handleSavePreferences = () => {
    if (uuid !== undefined && preferences.changed) {
      const data = {
        candidateId: uuid,
        emailNotifications: preferences.email_notifications,
        languageId: preferences.language_id,
        timezoneId: preferences.timezone_id,
      };

      if (typeof preferences.id !== 'undefined') {
        data.id = preferences.id;
        dispatch(updateCandidatePreferences(data));
      } else {
        dispatch(createCandidatePreferences(data));
      }
    } else {
      NoChangesWarning(t);
    }
  };

  if (created) {
    dispatch(setCreated(false));
    savedCandidateMessage(t);
  }
  if (saved) {
    dispatch(setSaved(false));
    savedCandidateMessage(t);
  }
  if (failed) {
    dispatch(setFailed(false));
    failedCandidateMessage(t, errors);
  }

  return (
    <Card id="preferences" sx={{ overflow: 'visible' }}>
      <CardHeader
        action={(
          <IconButton onClick={openPreferencesMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        title={t('preferences')}
      />
      <Menu
        anchorEl={preferencesMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(preferencesMenu)}
        onClose={closePreferencesMenu}
        keepMounted
      >
        <MenuItem
          onClick={handlePreferencesExpand}
        >
          {preferencesExpanded
            ? t('collapse', { keyPrefix: 'common' })
            : t('expand', { keyPrefix: 'common' })}
        </MenuItem>
      </Menu>
      <Collapse in={preferencesExpanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <FormSelect
                label={t('preferred-contact-language')}
                options={preferredLanguages}
                value={defaultValue(findSelectValue(preferredLanguages, preferences.language_id))}
                onChange={(e) => {
                  setValue('language_id', e.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormSelect
                label={t('timezone')}
                options={timezones}
                value={defaultValue(findSelectValue(timezones, preferences.timezone_id))}
                onChange={(e) => {
                  setValue('timezone_id', e.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormCheckbox
                label={t('send-email-notifications')}
                checked={preferences.email_notifications || false}
                onChange={handleEmailNotifications}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
      <CardActions>
        <Grid
          container
          direction="row-reverse"
          justifyContent="flex-start"
          alignItems="center"
        >
          <SuiButton
            size="small"
            color="info"
            onClick={handleSavePreferences}
          >
            {t('save')}
          </SuiButton>
        </Grid>
      </CardActions>
    </Card>
  );
}

export default Preferences;
