import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// @mui material components
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';

// DevExtreme components
import DataGrid, {
  ColumnChooser,
  ColumnFixing,
  Export,
  HeaderFilter,
  Item,
  LoadPanel,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Sorting,
  Toolbar,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.material.blue.light.css';

// Other components
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';

// Functions
import { renderColumns } from 'Util';
import useWindowDimensions from 'layouts/components/Hooks/WindowDimensions';

function BaseTable({
  name, keyExpr, data, columns,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });

  const [parentHeight, setParentHeight] = useState(500);
  const [fullScreen, setFullScreen] = useState(false);
  const allowedPageSizes = [10, 30, 50, 'all'];

  const { height } = useWindowDimensions();

  useEffect(() => {
    setParentHeight(height - (fullScreen ? 0 : 150));
  }, [fullScreen, height]);

  const handleExport = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(name);

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${name}.xlsx`);
      });
    });
  };

  const handleOpen = () => {
    setFullScreen(true);
  };

  const handleClose = () => {
    setFullScreen(false);
  };

  const renderTable = () => (
    <Card style={{ width: '100%' }}>
      <Paper>
        <DataGrid
          id={name}
          dataSource={data}
          keyExpr={keyExpr}
          allowColumnReordering
          allowColumnResizing
          columnAutoWidth
          onExporting={handleExport}
          height={parentHeight}
        >
          <ColumnFixing enabled />
          <HeaderFilter visible />
          <Scrolling rowRenderingMode="virtual" columnRenderingMode="virtual" />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={30} />
          <Pager
            visible
            allowedPageSizes={allowedPageSizes}
            displayMode="full"
            showPageSizeSelector
            showInfo
            showNavigationButtons
          />
          <LoadPanel enabled />
          <SearchPanel
            visible
            width={240}
            placeholder={t('search', { keyPrefix: 'common' })}
          />
          <Export enabled />
          <ColumnChooser enabled mode="select" />
          {renderColumns(columns)}
          <Toolbar>
            {!fullScreen && (
              <Item location="before">
                <IconButton aria-label="full-screen" onClick={handleOpen}>
                  <FullscreenIcon />
                </IconButton>
              </Item>
            )}
            <Item name="columnChooserButton" />
            <Item name="exportButton" />
            <Item name="searchPanel" />
          </Toolbar>
        </DataGrid>
      </Paper>
    </Card>
  );

  return (
    <Grid
      container
      direction="row"
      spacing={3}
    >
      <Grid item md={12}>
        {renderTable()}
        <Dialog
          fullScreen
          open={fullScreen}
          onClose={handleClose}
        >
          {renderTable()}
        </Dialog>
      </Grid>
    </Grid>
  );
}

BaseTable.propTypes = {
  name: PropTypes.string.isRequired,
  keyExpr: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.any.isRequired,
};

export default BaseTable;
