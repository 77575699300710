import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Reducers
import { createPaymentIntent } from 'reducers/paymentsSlice';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import SuiAlert from 'components/SuiAlert';

// Components
import {
  Elements, PaymentElement, useElements, useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Swal from 'sweetalert2';

// Functions
import { currencyFormatter } from 'Util';
import PropTypes from 'prop-types';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const selector = (state) => ({
  invoiceInfo: state.invoice.invoiceInfo,
  clientSecret: state.payment.clientSecret,
  amount: state.payment.amount,
  fee: state.payment.fee,
});

function PaymentForm({ isExternal }) {
  const stripe = useStripe();
  const elements = useElements();

  const { invoiceInfo, amount, fee } = useSelector(selector, shallowEqual);

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const alert = Swal.mixin({
    customClass: {
      confirmButton: 'button button-success',
      cancelButton: 'button button-error',
    },
    buttonsStyling: false,
  });

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // eslint-disable-next-line no-undef
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your PaymentForm is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your PaymentForm was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${
          process.env.REACT_APP_APP_URL
        }${
          isExternal ? 'payment_confirmation' : 'invoice'
        }/${
          invoiceInfo.uuid
        }?provider=stripe`,
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  const handleConfirmation = (e) => {
    e.preventDefault();

    alert
      .fire({
        title: 'Are you sure?',
        text:
          `We will charged your credit card with ${
            currencyFormatter.format(amount)
          }, ${
            currencyFormatter.format(invoiceInfo.total)
          } plus a surcharge fee of ${
            currencyFormatter.format(fee)
          }?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue',
      })
      .then((result) => {
        if (result.isConfirmed) {
          handleSubmit();
        }
      });
  };

  return (
    <div>
      {amount && (
        <div>
          Total Payment
          {' '}
          {currencyFormatter.format(amount)}
          {' '}
          (
          {currencyFormatter.format(invoiceInfo.total)}
          {' '}
          +
          {' '}
          {currencyFormatter.format(fee)}
          {' '}
          fee)
        </div>
      )}
      <SuiBox id="payment-form" component="form">
        <PaymentElement />
        {/* Show any error or success messages */}
        {message && (
          <SuiAlert color="error" dismissible>
            {message}
          </SuiAlert>
        )}
        <SuiBox mt={2} display="flex" flexDirection="row-reverse">
          <SuiButton
            disabled={isLoading || !stripe || !elements}
            onClick={handleConfirmation}
          >
            {isLoading ? (
              <div className="spinner" id="spinner" />
            ) : (
              'Pay now'
            )}
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </div>
  );
}

// Setting values for the props of PaymentForm
PaymentForm.defaultProps = {
  isExternal: false,
};

// Typechecking props for PaymentForm
PaymentForm.propTypes = {
  isExternal: PropTypes.bool,
};

function StripePaymentForm({ isExternal }) {
  const { invoiceInfo, clientSecret } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (invoiceInfo.total !== undefined) {
      dispatch(
        createPaymentIntent({
          total: invoiceInfo.total,
          invoice: invoiceInfo.id,
          candidate: invoiceInfo.candidate_id,
        }),
      );
    }
  }, [dispatch, invoiceInfo]);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div>
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <PaymentForm isExternal={isExternal} />
        </Elements>
      )}
    </div>
  );
}

// Setting values for the props of StripePaymentForm
StripePaymentForm.defaultProps = {
  isExternal: false,
};

// Typechecking props for StripePaymentForm
StripePaymentForm.propTypes = {
  isExternal: PropTypes.bool,
};

export default StripePaymentForm;
