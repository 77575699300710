import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Reducers functions
import { setPaymentPlanInfo } from 'reducers/paymentPlansSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Components
import FormField from 'layouts/components/FormField';
import FormSwitch from 'layouts/components/FormSwitch';
import FormSelect from 'layouts/components/FormSelect';

// Prop Types
import { findSelectValue } from 'Util';

const selector = (state) => ({
  paymentPlanInfo: state.plan.paymentPlanInfo,
});

function PlanInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'payment-plans' });
  const { paymentPlanInfo } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const paymentFrequencies = [
    {
      value: 'once',
      label: 'Once',
    },
    {
      value: 'weekly',
      label: t('weekly'),
    },
    {
      value: 'biweekly',
      label: t('biweekly'),
    },
    {
      value: 'monthly',
      label: t('monthly'),
    },
    {
      value: 'quarterly',
      label: t('quarterly'),
    },
  ];

  const paymentType = [
    {
      value: 1,
      label: t('amount'),
    },
    {
      value: 2,
      label: t('percentage'),
    },
  ];

  const setValue = (key, value) => {
    dispatch(
      setPaymentPlanInfo(
        {
          ...paymentPlanInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  const handleDownPayment = () => {
    dispatch(
      setPaymentPlanInfo(
        {
          ...paymentPlanInfo,
          has_down_payment: !paymentPlanInfo.has_down_payment,
          changed: true,
        },
      ),
    );
  };

  const handleDeferredPayment = () => {
    dispatch(
      setPaymentPlanInfo(
        {
          ...paymentPlanInfo,
          has_deferred_payment: !paymentPlanInfo.has_deferred_payment,
          changed: true,
        },
      ),
    );
  };

  const handleEnable = () => {
    dispatch(
      setPaymentPlanInfo(
        {
          ...paymentPlanInfo,
          enabled: !paymentPlanInfo.enabled,
          changed: true,
        },
      ),
    );
  };

  return (
    <Card id="plan-info" sx={{ overflow: 'visible' }}>
      <SuiBox p={3}>
        <SuiTypography variant="h5">{t('payment-plan')}</SuiTypography>
      </SuiBox>
      <SuiBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={10} sm={10}>
            <FormField
              label={t('name')}
              value={paymentPlanInfo.name !== undefined ? paymentPlanInfo.name : ''}
              error={!paymentPlanInfo.name || paymentPlanInfo.name === ''}
              onChange={(e) => {
                setValue('name', e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={2} sm={2}>
            <FormSwitch
              label={`${t('enabled', { keyPrefix: 'common' })}: ${paymentPlanInfo.enabled
                ? t('yes', { keyPrefix: 'common' }) : t('no', { keyPrefix: 'common' })}`}
              checked={!!paymentPlanInfo.enabled}
              onChange={handleEnable}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <FormSwitch
              label={`${t('down-payment')}: ${paymentPlanInfo.has_down_payment
                ? t('yes', { keyPrefix: 'common' }) : t('no', { keyPrefix: 'common' })}`}
              checked={!!paymentPlanInfo.has_down_payment}
              onChange={handleDownPayment}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            {(paymentPlanInfo.has_down_payment) && (
              <FormField
                label={t('amount')}
                value={paymentPlanInfo.down_payment_amount !== undefined ? paymentPlanInfo.down_payment_amount : ''}
                error={!paymentPlanInfo.down_payment_amount || paymentPlanInfo.down_payment_amount === ''}
                onChange={(e) => {
                  setValue('down_payment_amount', e.target.value);
                }}
              />
            )}
          </Grid>
          <Grid item xs={4} sm={4}>
            {(paymentPlanInfo.has_down_payment) && (
              <FormSelect
                label={t('payment-type')}
                options={paymentType}
                value={
                  findSelectValue(paymentType, paymentPlanInfo.down_payment_type)
                }
                error={!paymentPlanInfo.down_payment_type || paymentPlanInfo.down_payment_type === ''}
                onChange={(e) => {
                  setValue('down_payment_type', e.value);
                }}
              />
            )}
          </Grid>
          <Grid item xs={4} sm={4}>
            <FormSwitch
              label={`${t('deferred-payment')}: ${paymentPlanInfo.has_deferred_payment
                ? t('yes', { keyPrefix: 'common' }) : t('no', { keyPrefix: 'common' })}`}
              checked={!!paymentPlanInfo.has_deferred_payment}
              onChange={handleDeferredPayment}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            {(paymentPlanInfo.has_deferred_payment) && (
              <FormField
                label={t('amount')}
                value={paymentPlanInfo.deferred_amount !== undefined ? paymentPlanInfo.deferred_amount : ''}
                error={!paymentPlanInfo.deferred_amount || paymentPlanInfo.deferred_amount === ''}
                onChange={(e) => {
                  setValue('deferred_amount', e.target.value);
                }}
              />
            )}
          </Grid>
          <Grid item xs={4} sm={4}>
            {(paymentPlanInfo.has_deferred_payment) && (
              <FormSelect
                label={t('payment-type')}
                options={paymentType}
                value={
                  findSelectValue(paymentType, paymentPlanInfo.deferred_type)
                }
                error={!paymentPlanInfo.deferred_type || paymentPlanInfo.deferred_type === ''}
                onChange={(e) => {
                  setValue('deferred_type', e.value);
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label={t('number-payments')}
              value={paymentPlanInfo.payment_number !== undefined ? paymentPlanInfo.payment_number : ''}
              error={!paymentPlanInfo.payment_number || paymentPlanInfo.payment_number === ''}
              onChange={(e) => {
                setValue('payment_number', e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelect
              label={t('payment-frequency')}
              options={paymentFrequencies}
              value={
                findSelectValue(paymentFrequencies, paymentPlanInfo.payment_frequency)
              }
              error={!paymentPlanInfo.payment_frequency || paymentPlanInfo.payment_frequency === ''}
              onChange={(e) => {
                setValue('payment_frequency', e.value);
              }}
            />
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default PlanInfo;
