import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import VendorService from 'services/vendor.service';
import {
  fulfilledCreatedReducer,
  fulfilledSavedReducer,
  pendingReducer,
  rejectionReducer,
} from 'Util';

export const fetchVendors = createAsyncThunk(
  'vendor/fetchVendors',
  async (payload, { rejectWithValue }) => {
    try {
      return await VendorService.getVendors();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchVendor = createAsyncThunk(
  'vendor/fetchVendor',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await VendorService.getVendor(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createVendor = createAsyncThunk(
  'vendor/createVendor',
  async (payload, { rejectWithValue }) => {
    const {
      name, businessId, paymentTerm, rate, notes, vendorContacts, vendorAddresses,
    } = payload;
    try {
      return await VendorService.createVendor(
        name,
        businessId,
        paymentTerm,
        rate,
        notes,
        vendorContacts,
        vendorAddresses,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateVendor = createAsyncThunk(
  'vendor/updateVendor',
  async (payload, { rejectWithValue }) => {
    const {
      id, name, businessId, paymentTerm, rate, notes, vendorContacts, vendorAddresses,
    } = payload;
    try {
      return await VendorService.updateVendor(
        id,
        name,
        businessId,
        paymentTerm,
        rate,
        notes,
        vendorContacts,
        vendorAddresses,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteVendor = createAsyncThunk(
  'vendor/deleteVendor',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await VendorService.deleteVendor(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

const fulfilledReducer = (state) => {
  // eslint-disable-next-line no-param-reassign
  state.status = 'succeeded';
};

const generateDropboxProperties = ({ payload }) => payload.map((item) => ({
  ...item,
  value: item.id,
  label: item.name,
}));

/* eslint-disable no-param-reassign */
export const vendorSlice = createSlice({
  name: 'vendor',
  initialState: {
    vendorInfo: {},
    vendors: [],
    message: '',
    errors: [],
    editing: false,
    created: false,
    saved: false,
    deleted: false,
    failed: false,
    succeeded: false,
  },
  reducers: {
    clearVendor: (state) => {
      state.message = '';
      state.errors = [];
      state.editing = false;
      state.created = false;
      state.saved = false;
      state.deleted = false;
      state.failed = false;
      state.succeeded = false;
      state.vendorInfo = {
        id: null,
        name: null,
        business_id: null,
        payment_term: null,
        rate: null,
        notes: null,
        vendor_contacts: [],
        vendor_addresses: [],
      };
    },
    setVendorEditing: (state, action) => {
      state.editing = action.payload;
    },
    setCreated: (state, action) => {
      state.created = action.payload;
    },
    setSaved: (state, action) => {
      state.saved = action.payload;
    },
    setDeleted: (state, action) => {
      state.deleted = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setVendorInfo: (state, action) => {
      state.vendorInfo = action.payload;
    },
  },
  extraReducers: (builder) => builder
    .addMatcher(
      (action) => action.type.endsWith('/rejected'),
      rejectionReducer,
    )
    .addMatcher((action) => action.type.endsWith('/pending'), pendingReducer)
    .addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, action) => {
        const performedAction = action.type.split('/');

        if (performedAction[0] === 'vendor') {
          switch (performedAction[1]) {
            case 'fetchVendors':
              fulfilledReducer(state, action, true);
              state.vendors = generateDropboxProperties(action);
              break;
            case 'fetchVendor':
              fulfilledReducer(state, action);
              state.vendorInfo = action.payload;
              break;
            case 'createVendor':
              fulfilledCreatedReducer(state, action);
              state.vendors = generateDropboxProperties(action);
              break;
            case 'updateVendor':
            case 'deleteVendor':
              fulfilledSavedReducer(state, action);
              state.vendors = generateDropboxProperties({
                payload: action.payload.data,
              });
              state.succeeded = true;
              break;
            default:
              fulfilledReducer(state, action);
              state.message = action.payload;
              break;
          }
        }
      },
    ),
});

/* eslint-disable no-param-reassign */
export const {
  clearVendor,
  setVendorEditing,
  setCreated,
  setSaved,
  setDeleted,
  setFailed,
  setSucceeded,
  setVendorInfo,
} = vendorSlice.actions;

export default vendorSlice.reducer;
