import Service from './service';

class RecruiterService extends Service {
  // eslint-disable-next-line class-methods-use-this
  async getRecruiters() {
    const response = await this.api
      .get(
        'recruiters',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getRecruiter(id) {
    const response = await this.api
      .get(
        `recruiters/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createRecruiter(name, description) {
    const data = {
      recruiter: {
        name,
        description,
      },
    };

    const response = await this.api
      .post(
        'recruiters',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateRecruiter(id, name, description) {
    const data = {
      recruiter: {
        name,
        description,
      },
    };

    const response = await this.api
      .put(
        `recruiters/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteRecruiter(id) {
    const response = await this.api
      .delete(
        `recruiters/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new RecruiterService();
