import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// Reducer functions
// eslint-disable-next-line object-curly-newline
import { fetchRole, setRoleEditing } from 'reducers/roleSlice';
import { fetchPermission, fetchPermissions } from 'reducers/permisionsSlice';
import { fetchRoleTypes } from 'reducers/commonSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import BasicInfo from '../components/BasicInfo';
import SavePanel from '../components/SavePanel';

function EditRole() {
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(
      fetchRole({
        id,
      }),
    );
    dispatch(setRoleEditing(true));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(fetchPermissions());
    dispatch(fetchPermission({ id: 1 }));
    dispatch(fetchRoleTypes());
  }, [dispatch]);

  return (
    <BaseLayout>
      <SuiBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <BasicInfo />
                  <SavePanel />
                </Grid>
              </Grid>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </BaseLayout>
  );
}

export default EditRole;
