import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { setCurrentSubView } from 'reducers/candidatesSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Functions
import { checkPermission, moveSubView, setTitle } from 'Util';

// Components
import TabBar from 'layouts/components/TabBar';
import Meetings from '../Meetings';
import Notes from '../Notes';
import CommunicationNotes from '../CommunicationNotes';

const selector = (state) => ({
  editing: state.candidate.editing,
  similar: state.candidate.similar,
  currentUser: state.auth.user,
  currentSubView: state.candidate.currentSubView,
  goToSubView: state.candidate.goToSubView,
});

function Communications() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.communications' });
  const {
    editing,
    currentUser,
    currentSubView,
    goToSubView,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [defaultTab, setDefaultTab] = useState(0);

  setTitle('Candidate Communications');

  const tabs = [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const views = [];

  const handleSetProfileTabValue = (event, newValue) => {
    switch (tabs[newValue]) {
      case t('meetings'):
        dispatch(setCurrentSubView('meetings'));
        break;
      case t('notes'):
        dispatch(setCurrentSubView('notes'));
        break;
      case t('communications'):
        dispatch(setCurrentSubView('communications'));
        break;
      default:
        dispatch(setCurrentSubView('meetings'));
        break;
    }
  };

  if (checkPermission('CANM', currentUser)) {
    tabs.push(t('meetings'));
    views.push('meetings');
  }

  if (checkPermission('CANT', currentUser) && editing) {
    tabs.push(t('notes'));
    views.push('notes');
  }

  if (checkPermission('CACC', currentUser) && editing) {
    tabs.push(t('communications'));
    views.push('communications');
  }

  useEffect(() => {
    moveSubView(views, goToSubView, setDefaultTab, dispatch, currentSubView);
  }, [dispatch, views, currentSubView, goToSubView]);

  useEffect(() => {
    setDefaultTab(views.indexOf(currentSubView) >= 0 ? views.indexOf(currentSubView) : 0);
    if (views.indexOf(currentSubView) < 0) {
      dispatch(setCurrentSubView(views[0]));
    }
  }, [currentSubView, dispatch, views]);

  return (
    <div id="communications">
      <TabBar
        tabs={tabs}
        setTabValueFunction={handleSetProfileTabValue}
        defaultTabValue={defaultTab}
      />
      {currentSubView === 'meetings' && (
        <div id="view">
          <Grid item xs={12}>
            <Meetings />
          </Grid>
        </div>
      )}
      {currentSubView === 'notes' && (
        <div id="view">
          <Grid item xs={12}>
            <Notes />
          </Grid>
        </div>
      )}
      {currentSubView === 'communications' && (
        <div id="view">
          <Grid item xs={12}>
            <CommunicationNotes />
          </Grid>
        </div>
      )}
    </div>
  );
}

export default Communications;
