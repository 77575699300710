import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// Reducers
import { fetchExpense, setExpenseEditing } from 'reducers/expenseSlice';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import ExpenseInfo from '../components/ExpenseInfo';

function EditExpense() {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(
      setExpenseEditing(true),
    );
    dispatch(fetchExpense({ id }));
  }, [dispatch, id]);

  return (
    <BaseLayout>
      <ExpenseInfo />
    </BaseLayout>
  );
}

export default EditExpense;
