import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// Reducer functions
import {
  assignCandidateNclexTestDocument,
  deleteCandidateNclexTestDocument,
  uploadCandidateNclexTestDocument,
} from 'reducers/candidatesSlice';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardContent from '@mui/material/CardContent';
import AddIcon from '@mui/icons-material/Add';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';
import DefaultDocumentCard from 'layouts/components/Cards/DocumentCards/DefaultDocumentCard';

// Utils
import { defaultValue, findSelectValue, isDocumentsUploaded } from 'Util';

function DefaultNclexCard(
  {
    i,
    candidateId,
    c,
    ops,
    setValue,
    removeFunc,
    permissions,
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.nclex' });
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(true);
  const [credentialMenu, setCredentialMenu] = useState(null);
  const [documentMenu, setDocumentMenu] = useState(null);
  const [expandedDocumentState, setExpandedDocumentState] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [availTestDocs, setAvailTestDocs] = useState([]);

  const testResult = [
    {
      value: 1,
      label: t('passed'),
    },
    {
      value: 2,
      label: t('failed'),
    },
    {
      value: 3,
      label: t('scheduled'),
    },
  ];

  const openCredentialMenu = (event) => setCredentialMenu(event.currentTarget);
  const closeCredentialMenu = () => setCredentialMenu(null);
  const handleExpand = () => {
    closeCredentialMenu();
    setExpanded(!expanded);
  };

  const openDocumentMenu = (event) => setDocumentMenu(event.currentTarget);
  const closeDocumentMenu = () => setDocumentMenu(null);
  const handleDocumentExpand = () => {
    closeDocumentMenu();
    setExpandedDocumentState(!expandedDocumentState);
  };

  const handleAddDocument = () => {
    dispatch(assignCandidateNclexTestDocument({
      candidateId,
      testId: c.id,
      documentId: selectedDoc,
    }));
    setSelectedDoc(null);
  };

  const handleUploadDocument = (files, documentId) => {
    dispatch(uploadCandidateNclexTestDocument({
      candidateId,
      testId: c.id,
      documentId,
      file: files[0],
    }));
  };

  const handleDeleteDocument = (id) => {
    dispatch(deleteCandidateNclexTestDocument({
      candidateId,
      testId: c.id,
      documentId: id,
    }));
  };

  useEffect(() => {
    setAvailTestDocs(ops.documents.filter(
      (ad) => !c.documents.some((cd) => ad.id === cd.document.id),
    ));
  }, [c.documents, ops.documents]);

  return (
    <Card sx={{ overflow: 'visible' }} key={i}>
      <CardHeader
        action={(
          <IconButton onClick={openCredentialMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        title={`${t('nclex')} ${i + 1}`}
      />
      <Menu
        anchorEl={credentialMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(credentialMenu)}
        onClose={closeCredentialMenu}
        keepMounted
      >
        <MenuItem
          onClick={handleExpand}
        >
          {expanded
            ? t('collapse', { keyPrefix: 'common' })
            : t('expand', { keyPrefix: 'common' })}
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeFunc(i);
          }}
        >
          {t('remove', { keyPrefix: 'common' })}
        </MenuItem>
      </Menu>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <SuiBox mb={2}>
            <Grid container spacing={3}>
              {permissions.attNum && (
                <Grid item md={4}>
                  <FormField
                    label={t('att')}
                    error={c.att_number ? false
                      : !c.att_number || c.att_number === ''}
                    onChange={(event) => {
                      setValue('att_number', event.target.value, i);
                    }}
                    value={defaultValue(c.att_number)}
                  />
                </Grid>
              )}
              {permissions.attReceived && (
                <Grid item md={4}>
                  <FormField
                    label={t('att-received')}
                    type="date"
                    error={c.att_received ? false
                      : !c.att_received || c.att_received === ''}
                    onChange={(event) => {
                      setValue('att_received', event.target.value, i);
                    }}
                    value={defaultValue(c.att_received)}
                  />
                </Grid>
              )}
              {permissions.attExpiration && (
                <Grid item md={4}>
                  <FormField
                    label={t('att-expiration')}
                    type="date"
                    error={c.att_expiration ? false
                      : !c.att_expiration || c.att_expiration === ''}
                    onChange={(event) => {
                      setValue('att_expiration', event.target.value, i);
                    }}
                    value={defaultValue(c.att_expiration)}
                  />
                </Grid>
              )}
              {permissions.schedule && (
                <Grid item md={4}>
                  <FormField
                    label={t('schedule')}
                    type="date"
                    onChange={(event) => {
                      setValue('schedule', event.target.value, i);
                    }}
                    value={defaultValue(c.schedule)}
                  />
                </Grid>
              )}
              {permissions.country && (
                <Grid item md={4}>
                  <FormSelect
                    label={t('country')}
                    options={ops.countries}
                    id={`country[${i}]`}
                    value={
                      ops.countries !== undefined
                        ? findSelectValue(ops.countries, c.country_id, i) : undefined
                    }
                    onChange={(event) => {
                      setValue('country_id', event.value, i);
                    }}
                  />
                </Grid>
              )}
              {permissions.result && (
                <Grid item md={4}>
                  <FormSelect
                    label={t('result')}
                    options={testResult}
                    id={`nclex_result[${i}]`}
                    value={
                      testResult !== undefined
                        ? findSelectValue(testResult, c.nclex_result_id, i) : undefined
                    }
                    onChange={(event) => {
                      setValue('nclex_result_id', event.value, i);
                    }}
                  />
                </Grid>
              )}
              {permissions.document && (
                <Grid item md={12}>
                  {c.id !== undefined && (
                    <Card sx={{ overflow: 'visible' }}>
                      <CardHeader
                        action={(
                          <IconButton onClick={openDocumentMenu}>
                            <MoreVertIcon />
                          </IconButton>
                        )}
                        title={t('documents')}
                        subheader={isDocumentsUploaded(c.documents)
                          ? t('uploaded', { keyPrefix: 'common' }) : undefined}
                      />
                      <Menu
                        anchorEl={documentMenu}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={Boolean(documentMenu)}
                        onClose={closeDocumentMenu}
                        keepMounted
                      >
                        <MenuItem
                          onClick={handleDocumentExpand}
                        >
                          {expandedDocumentState
                            ? t('collapse', { keyPrefix: 'common' })
                            : t('expand', { keyPrefix: 'common' })}
                        </MenuItem>
                      </Menu>
                      <Collapse in={expandedDocumentState} timeout="auto" unmountOnExit>
                        <CardContent>
                          <Grid container spacing={3}>
                            {Object.keys(availTestDocs).length > 0 && (
                              <>
                                <Grid item md={3} pr={1}>
                                  <FormSelect
                                    label={t('assign-document')}
                                    placeholder={t('select-document')}
                                    options={availTestDocs}
                                    onChange={(ev) => {
                                      setSelectedDoc(ev.value);
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={3} pr={1} mt={5}>
                                  <IconButton
                                    aria-label="add"
                                    onClick={handleAddDocument}
                                  >
                                    <AddIcon />
                                  </IconButton>
                                </Grid>
                                <Grid item md={6} pr={1} />
                              </>
                            )}
                            {Object.keys(c.documents).length > 0
                              && c.documents.map((f, j) => (
                                <Grid item md={12} pr={1}>
                                  <DefaultDocumentCard
                                    key={`evaluation-document-${j}`}
                                    id={f.id}
                                    candidateId={candidateId}
                                    description={f.document.description}
                                    title={f.document.name}
                                    fileUrl={f.file_url}
                                    filesLimit={f.document.uploads_number}
                                    maxFilesSize={f.document.max_size}
                                    changeFunc={(files) => {
                                      handleUploadDocument(files, f.id);
                                    }}
                                    removeFunc={() => {
                                      handleDeleteDocument(f.id);
                                    }}
                                  />
                                </Grid>
                              ))}
                          </Grid>
                        </CardContent>
                      </Collapse>
                    </Card>
                  )}
                </Grid>
              )}
            </Grid>
          </SuiBox>
        </CardContent>
      </Collapse>
    </Card>
  );
}

DefaultNclexCard.defaultProps = {
  permissions: {
    attNum: false,
    attReceived: false,
    attExpiration: false,
    schedule: false,
    country: false,
    result: false,
    document: false,
  },
};

DefaultNclexCard.propTypes = {
  i: PropTypes.number.isRequired,
  candidateId: PropTypes.string.isRequired,
  c: PropTypes.shape(
    {
      att_number: PropTypes.string,
      att_received: PropTypes.string,
      att_expiration: PropTypes.string,
      file_url: PropTypes.string,
      nclex_result_id: PropTypes.number,
      schedule: PropTypes.string,
      country_id: PropTypes.number,
      id: PropTypes.number,
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          file_url: PropTypes.string,
          document: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            description: PropTypes.string,
            is_required: PropTypes.bool,
            uploads_number: PropTypes.number,
            auto_assigned: PropTypes.bool,
          }),
        }),
      ),
    },
  ).isRequired,
  ops: PropTypes.shape({
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    attNum: PropTypes.bool,
    attReceived: PropTypes.bool,
    attExpiration: PropTypes.bool,
    schedule: PropTypes.bool,
    country: PropTypes.bool,
    result: PropTypes.bool,
    document: PropTypes.bool,
  }),
};

export default DefaultNclexCard;
