import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// DevExtreme components
import DataGrid from 'devextreme-react/data-grid';

// Functions
import { renderColumns } from 'Util';

const selector = (state) => ({
  invoicePayments: state.invoicePayment.invoicePayments,
});

function InvoicePaymentDetail() {
  const { t } = useTranslation('translation', { keyPrefix: 'invoices' });
  const { invoicePayments } = useSelector(selector, shallowEqual);

  const columns = [
    {
      caption: t('date'),
      field: 'payment_date',
      dataType: 'date',
      sortOrder: 'asc',
    },
    {
      caption: t('payment-method'),
      field: 'payment_method',
    },
    {
      caption: t('amount'),
      field: 'payment_amount',
      dataType: 'number',
      format: { type: 'currency', precision: 2 },
    },
  ];

  return (
    <div>
      <DataGrid
        dataSource={invoicePayments}
        showBorders
        columnAutoWidth
      >
        {renderColumns(columns)}
      </DataGrid>
    </div>
  );
}

export default InvoicePaymentDetail;
