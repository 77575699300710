import { useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Column } from 'devextreme-react/data-grid';
// eslint-disable-next-line import/no-cycle
import {
  setCurrentSubView,
  setCurrentView,
  setGoToSubView,
  setGoToView,
  setMoveSubView,
  setMoveView,
} from './reducers/candidatesSlice';

export const getDateFormatted = (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

export const getDateFormat = (date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) {
    month = `0${month}`;
  }

  if (day.length < 2) {
    day = `0${day}`;
  }

  return [year, month, day].join('-');
};

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const percentageFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
});

export const getErrorDetails = (data) => {
  let msg = '';

  const {
    message,
    errors,
  } = data;

  if (message !== undefined) {
    msg = message;
  }

  if (errors !== undefined) {
    Object.keys(errors).forEach((k) => {
      msg = `${msg} ${k}: ${errors[k]}`;
    });
  }

  return msg;
};

export const defaultValue = (value) => value || '';

export const findSelectValue = (values, value) => values.filter((v) => v.value === value)[0];

export const reduceArrayOptions = (obj) => {
  const red = Object.entries(obj).reduce(
    // eslint-disable-next-line no-unused-vars
    (acc, [id, o]) => {
      acc.push({
        value: o.value,
        label: o.label,
      });
      return acc;
    },
    [],
  );
  return red;
};

export const reduceUsers = (obj) => {
  const red = Object.entries(obj).reduce(
    // eslint-disable-next-line no-unused-vars
    (acc, [id, o]) => {
      acc.push({
        id: o.uuid,
        name: `${o.first_name} ${o.last_name}`,
      });
      return acc;
    },
    [],
  );
  return red;
};

export const rejectionReducer = (state, action) => {
  const errors = [];

  const {
    payload,
    type,
  } = action;

  if (payload !== undefined) {
    if (payload.status !== undefined && payload.status === 401) {
      if (type !== 'auth/login/rejected'
        && type !== 'auth/logout/rejected'
        && type !== 'auth/register/rejected'
        && type !== 'auth/confirmation/rejected'
        && type !== 'auth/unlock/rejected') {
        localStorage.setItem('checkSession', true);
      }
    }

    if (payload.data.message !== undefined) {
      errors.push(payload.data.message);
    }

    if (payload.data.error !== undefined) {
      errors.push(payload.data.error);
    }

    if (payload.data.errors !== undefined) {
      payload.data.errors.forEach((e) => errors.push(e));
    }

    if (payload.data.message === undefined && payload.data.error === undefined) {
      errors.push(payload.data);
    }
  }

  // eslint-disable-next-line no-param-reassign
  state.status = 'failed';
  // eslint-disable-next-line no-param-reassign
  state.errors = errors;
  // eslint-disable-next-line no-param-reassign
  state.failed = true;
};

export const pendingReducer = (state) => {
  // eslint-disable-next-line no-param-reassign
  state.status = 'loading';
};

export const fulfilledReducer = (state, { payload }) => {
  // eslint-disable-next-line no-param-reassign
  state.message = payload.message !== undefined ? payload.message : '';
  // eslint-disable-next-line no-param-reassign
  state.status = 'succeeded';
  // eslint-disable-next-line no-param-reassign
  state.succeeded = true;
  // eslint-disable-next-line no-param-reassign
  state.failed = false;
};

export const fulfilledSavedReducer = (state, { payload }) => {
  fulfilledReducer(state, { payload });
  // eslint-disable-next-line no-param-reassign
  state.saved = true;
};

export const fulfilledCreatedReducer = (state, { payload }) => {
  fulfilledReducer(state, { payload });
  // eslint-disable-next-line no-param-reassign
  state.created = true;
};

export const fulfilledDeletedReducer = (state, { payload }) => {
  fulfilledReducer(state, { payload });
  // eslint-disable-next-line no-param-reassign
  state.deleted = true;
};

export const getCandidateUUID = () => {
  const selector = (state) => ({
    currentUser: state.auth.user,
  });

  const {
    currentUser,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useSelector(selector, shallowEqual);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { uuid } = useParams();

  let cUuid;

  if (uuid === undefined) {
    if (currentUser.candidate !== undefined) {
      cUuid = currentUser.candidate.uuid !== undefined
        ? currentUser.candidate.uuid
        : '';
    }
  } else {
    cUuid = uuid;
  }

  return cUuid;
};

export const errorsToString = (errors) => {
  let error = '';

  if (Array.isArray(errors)) {
    errors.forEach((e) => {
      if (e instanceof Object) {
        Object.entries(e).forEach((k) => {
          error += `${k[0]}, `;
          error += errorsToString(k[1]);
        });
      } else {
        error += `${e}. `;
      }
    });
  }

  return error;
};

export const isValid = (key, value, e) => {
  const isNotEmpty = Object.keys(e).every(
    (k) => e[k] !== '' && e[k] !== undefined,
  );

  const isValueValid = value !== '' && value !== undefined;

  return isNotEmpty || isValueValid;
};

export const generateDropboxProperties = ({ payload }) => payload.map((item) => ({
  ...item,
  value: item.id,
  label: item.name,
}));

// eslint-disable-next-line max-len
export const checkPermission = (permission, currentUser) => currentUser.permissions.find((p) => p.code === permission) !== undefined;

export const NoChangesWarning = (t) => {
  Swal.fire({
    text: t('no-changes-to-save', { keyPrefix: 'common' }),
    icon: 'warning',
    confirmButtonText: t('close', { keyPrefix: 'common' }),
  });
};

export const MissingDataWarning = (t) => {
  Swal.fire({
    text: t('entry-is-required-or-has-an-invalid-value', {
      keyPrefix: 'common',
    }),
    icon: 'error',
    confirmButtonText: t('close', { keyPrefix: 'common' }),
  });
};

export const prepareModifyData = (obj) => obj
  .map((e) => {
    if (typeof e.changed !== 'undefined' && e.changed === true) {
      return e;
    }
    return null;
  })
  .filter((x) => x !== null);

export const prepareRemoveData = (obj) => obj
  .map((e) => {
    if (typeof e !== 'undefined') {
      return e;
    }
    return null;
  })
  .filter((x) => x !== 'undefined');

export const findTemplateVariables = (content) => {
  const regex = /<%=.+?%>/gm;
  const found = content.replaceAll('&lt;', '<').replaceAll('&gt;', '>').match(regex);
  if (found !== null) {
    return found.filter((v, i, self) => self.indexOf(v) === i);
  }
  return [];
};
export const numToWordsEn = (n) => {
  const string = n.toString();
  let start;
  let end;
  let chunk;
  let ints;
  let i;

  const and = 'and';

  /* Is number zero? */
  if (parseInt(string, 10) === 0) {
    return 'zero';
  }

  /* Array of units as words */
  const units = [
    '',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
  ];

  /* Array of tens as words */
  const tens = [
    '',
    '',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety',
  ];

  /* Array of scales as words */
  const scales = [
    '',
    'thousand',
    'million',
    'billion',
    'trillion',
    'quadrillion',
    'quintillion',
    'sextillion',
    'septillion',
    'octillion',
    'nonillion',
    'decillion',
    'undecillion',
    'duodecillion',
    'tredecillion',
    'quatttuor-decillion',
    'quindecillion',
    'sexdecillion',
    'septen-decillion',
    'octodecillion',
    'novemdecillion',
    'vigintillion',
    'centillion',
  ];

  /* Split user argument into 3 digit chunks from right to left */
  start = string.length;
  const chunks = [];
  while (start > 0) {
    end = start;
    chunks.push(string.slice((start = Math.max(0, start - 3)), end));
  }

  /* Check if function has enough scale words to be able to stringify the user argument */
  const chunksLen = chunks.length;
  if (chunksLen > scales.length) {
    return '';
  }

  /* Stringify each integer in each chunk */
  const words = [];
  for (i = 0; i < chunksLen; i += 1) {
    chunk = parseInt(chunks[i], 10);

    if (chunk) {
      /* Split chunk into array of individual integers */
      ints = chunks[i].split('').reverse().map(parseFloat);

      /* If tens integer is 1, i.e. 10, then add 10 to units integer */
      if (ints[1] === 1) {
        ints[0] += 10;
      }

      /* Add scale word if chunk is not zero and array item exists */
      if (scales[i] !== undefined && scales[i] !== '') {
        words.push(scales[i]);
      }

      /* Add unit word if array item exists */
      if (units[ints[0]] !== undefined && units[ints[0]] !== '') {
        words.push(units[ints[0]]);
      }

      /* Add tens word if array item exists */
      if (tens[ints[1]] !== undefined && tens[ints[1]] !== '') {
        words.push(tens[ints[1]]);
      }

      /* Add 'and' string after units or tens integer if: */
      if (ints[0] !== undefined || ints[1] !== undefined) {
        /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
        if (ints[2] !== undefined || i === chunksLen) {
          words.push(and);
        }
      }

      /* Add hundreds word if array item exists */
      if (units[ints[2]] !== undefined && units[ints[2]] !== '') {
        words.push(`${units[ints[2]]} hundred`);
      }
    }
  }
  return words.reverse().join(' ');
};

export const numToWordsEs = (n) => {
  const string = n.toString();
  let start;
  let end;
  let chunk;
  let ints;
  let i;

  const and = 'y';

  if (parseInt(string, 10) === 0) {
    return 'cero';
  }

  const units = [
    '',
    'un',
    'dos',
    'tres',
    'cuatro',
    'cinco',
    'seis',
    'siete',
    'ocho',
    'nueve',
    'diez',
    'once',
    'doce',
    'trece',
    'catorce',
    'quince',
    'dieciséis',
    'diecisiete',
    'dieciocho',
    'diecinueve',
    'veinte',
    'veintiun',
    'veintidos',
    'veintitres',
    'veinticuatro',
    'veinticinco',
    'veintiseis',
    'veintisiete',
    'veintiocho',
    'veintinueve',
  ];

  const tens = [
    '',
    '',
    '',
    'treinta',
    'cuarenta',
    'cincuenta',
    'sesenta',
    'setenta',
    'ochenta',
    'noventa',
  ];

  const hundreds = [
    '',
    'ciento',
    'doscientos',
    'trescientos',
    'cuatrocientos',
    'quinientos',
    'seiscientos',
    'setecientos',
    'ochocientos',
    'novecientos',
  ];

  const scales = [
    '',
    'mil',
    'millónes',
    'mil millones',
    'trillón',
    'cuatrillón',
    'quintillón',
    'sextillón',
    'septillón',
    'octillón',
    'nonillón',
    'decillón',
    'undecillón',
    'duodecilión',
    'tredecilión',
    'cuatodecilión',
    'quindecilión',
    'sexdecilión',
    'septendecilión',
    'octodecilión',
    'novemdecilión',
    'vigintillón',
    'centillón',
  ];

  start = string.length;
  const chunks = [];
  while (start > 0) {
    end = start;
    chunks.push(string.slice((start = Math.max(0, start - 3)), end));
  }

  const chunksLen = chunks.length;
  if (chunksLen > scales.length) {
    return '';
  }

  const words = [];
  for (i = 0; i < chunksLen; i += 1) {
    chunk = parseInt(chunks[i], 10);

    if (chunk) {
      ints = chunks[i].split('').reverse().map(parseFloat);

      if (ints[1] === 1) {
        ints[0] += 10;
      }

      if (ints[1] === 2) {
        ints[0] += 20;
      }

      if (scales[i] !== undefined && scales[i] !== '') {
        if (i === 2 && ints[0] === 1) {
          words.push('millón');
        } else {
          words.push(scales[i]);
        }
      }

      if (units[ints[0]] !== undefined && units[ints[0]] !== '') {
        if (scales[i] === 'mil') {
          if (ints[0] > 1) {
            words.push(units[ints[0]]);
          }
        } else {
          words.push(units[ints[0]]);
        }
      }

      if (ints[0] !== undefined || ints[1] !== undefined) {
        if (ints[1] >= 3 && ints[0] !== 0) {
          words.push(and);
        }
      }

      if (tens[ints[1]] !== undefined && tens[ints[1]] !== '') {
        words.push(tens[ints[1]]);
      }

      if (hundreds[ints[2]] !== undefined && hundreds[ints[2]] !== '') {
        if (ints[0] === 0 && ints[1] === 0 && ints[2] === 1) {
          words.push('cien');
        } else {
          words.push(`${hundreds[ints[2]]}`);
        }
      }
    }
  }
  return words.reverse().join(' ');
};

export const numToWordsPt = (n) => {
  const string = n.toString();
  let start;
  let end;
  let chunk;
  let ints;
  let i;

  const and = 'e';

  if (parseInt(string, 10) === 0) {
    return 'zero';
  }

  const units = [
    '',
    'um',
    'dois',
    'três',
    'quatro',
    'cinco',
    'seis',
    'sete',
    'oito',
    'nove',
    'dez',
    'onze',
    'doze',
    'treze',
    'catorze',
    'quinze',
    'dezasseis',
    'dezassete',
    'dezoito',
    'dezanove',
  ];

  const tens = [
    '',
    '',
    'vinte',
    'trinta',
    'quarenta',
    'cinquenta',
    'sessenta',
    'setenta',
    'oitenta',
    'noventa',
  ];

  const hundreds = [
    '',
    'cento',
    'duzentos',
    'trezentos',
    'quatrocentos',
    'quinhentos',
    'seiscentos',
    'setecentos',
    'oitocentos',
    'novecentos',
    'novecentos',
    'setecentos',
    'oitocentos',
    'novecentos',
    'novecentos',
  ];

  const scales = [
    '',
    'mil',
    'millónes',
    'mil millones',
    'trillón',
    'cuatrillón',
    'quintillón',
    'sextillón',
    'septillion',
    'octillion',
    'nontillion',
    'decillon',
    'undecillon',
    'duodecillon',
    'tredecillon',
    'quatodecillon',
    'quindecilhão',
    'sexdecilhão',
    'septendecilhão',
    'octodecilhão',
    'novemdecilhão',
    'vigintilhão',
    'centilhão',
  ];

  start = string.length;
  const chunks = [];
  while (start > 0) {
    end = start;
    chunks.push(string.slice((start = Math.max(0, start - 3)), end));
  }

  const chunksLen = chunks.length;
  if (chunksLen > scales.length) {
    return '';
  }

  const words = [];
  for (i = 0; i < chunksLen; i += 1) {
    chunk = parseInt(chunks[i], 10);

    if (chunk) {
      // words.push(and);
      ints = chunks[i].split('').reverse().map(parseFloat);

      if (ints[1] === 1) {
        ints[0] += 10;
      }

      if (scales[i] !== undefined && scales[i] !== '') {
        if (i === 2 && ints[0] === 1) {
          words.push('milhão');
        } else {
          words.push(scales[i]);
        }
      }

      if (units[ints[0]] !== undefined && units[ints[0]] !== '') {
        if (scales[i] === 'mil') {
          if (ints[0] > 1) {
            words.push(units[ints[0]]);
          }
        } else {
          words.push(units[ints[0]]);
        }
      }

      if (ints[0] !== undefined && ints[1] !== undefined) {
        if (ints[1] >= 2) {
          if (ints[0] >= 1) {
            words.push(and);
          }
        }
      }

      if (tens[ints[1]] !== undefined && tens[ints[1]] !== '') {
        words.push(tens[ints[1]]);
      }

      if (ints[1] !== undefined && ints[2] !== undefined) {
        if ((ints[0] >= 1 || ints[1] >= 1) && ints[2] >= 1) {
          words.push(and);
        }
      }

      if (hundreds[ints[2]] !== undefined && hundreds[ints[2]] !== '') {
        if (ints[0] === 0 && ints[1] === 0 && ints[2] === 1) {
          words.push('cem');
        } else {
          // words.push(and);
          words.push(`${hundreds[ints[2]]}`);
        }
      }
    }
  }
  // words.shift();
  return words.reverse().join(' ');
};

export const numToWords = (lang, num) => {
  switch (lang) {
    case 'en':
      return numToWordsEn(num);
    case 'es':
      return numToWordsEs(num);
    case 'pt':
      return numToWordsPt(num);
    default:
      return numToWordsEn(num);
  }
};

export const getUrlExtension = (url) => {
  if (url !== undefined && url !== null) {
    return url.split(/[#?]/)[0].split('.').pop().trim().toLowerCase();
  }
  return '';
};

export const setTitle = (title) => {
  document.title = `NAS - ${title}`;
};

export const isValidValue = (value) => value !== '' && value !== undefined && value !== null;

export const englishLevels = [
  {
    value: 'A1',
    label: 'A1',
  },
  {
    value: 'A1.1',
    label: 'A1.1',
  },
  {
    value: 'A1.2',
    label: 'A1.2',
  },
  {
    value: 'A1.3',
    label: 'A1.3',
  },
  {
    value: 'A1.4',
    label: 'A1.4',
  },
  {
    value: 'A2.1',
    label: 'A2.1',
  },
  {
    value: 'A2.2',
    label: 'A2.2',
  },
  {
    value: 'A2.3',
    label: 'A2.3',
  },
  {
    value: 'A2.4',
    label: 'A2.4',
  },
  {
    value: 'B1',
    label: 'B1',
  },
  {
    value: 'B1.1',
    label: 'B1.1',
  },
  {
    value: 'B1.2',
    label: 'B1.2',
  },
  {
    value: 'B1.3',
    label: 'B1.3',
  },
  {
    value: 'B1.4',
    label: 'B1.4',
  },
  {
    value: 'B2',
    label: 'B2',
  },
  {
    value: 'B2.1',
    label: 'B2.1',
  },
  {
    value: 'B2.2',
    label: 'B2.2',
  },
  {
    value: 'B2.3',
    label: 'B2.3',
  },
  {
    value: 'B2.4',
    label: 'B2.4',
  },
  {
    value: 'C1',
    label: 'C1',
  },
  {
    value: 'C1.1',
    label: 'C1.1',
  },
  {
    value: 'C1.2',
    label: 'C1.2',
  },
  {
    value: 'C1.3',
    label: 'C1.3',
  },
  {
    value: 'C1.4',
    label: 'C1.4',
  },
  {
    value: 'C2.1',
    label: 'C2.1',
  },
  {
    value: 'C2.2',
    label: 'C2.2',
  },
  {
    value: 'C2.3',
    label: 'C2.3',
  },
  {
    value: 'C2.4',
    label: 'C2.4',
  },
  {
    value: 'TOEFLicious',
    label: 'TOEFLicious',
  },
  {
    value: 'Fluency Boost',
    label: 'Fluency Boost',
  },
];

export const moveSubView = (views, goToSubView, setDefaultTab, dispatch, currentSubView) => {
  if (views.length > 1) {
    if (goToSubView !== undefined) {
      const i = views.indexOf(goToSubView);

      if (i >= 0) {
        setDefaultTab(i);
        dispatch(setMoveSubView(goToSubView));
      } else if (views.indexOf(currentSubView) < 0) {
        setDefaultTab(0);
        dispatch(setMoveSubView(views[0]));
      } else {
        setDefaultTab(views.indexOf(currentSubView));
        dispatch(setGoToSubView(undefined));
      }
    } else if (views.indexOf(currentSubView) < 0) {
      setDefaultTab(0);
      dispatch(setCurrentSubView(views[0]));
    }
  }
};

export const moveView = (views, goToView, setDefaultTab, dispatch, currentView) => {
  if (views.length > 1) {
    if (goToView !== undefined) {
      const i = views.indexOf(goToView);

      if (i >= 0) {
        setDefaultTab(i);
        dispatch(setMoveView(goToView));
      } else if (views.indexOf(currentView) < 0) {
        setDefaultTab(0);
        dispatch(setMoveView(views[0]));
      } else {
        setDefaultTab(views.indexOf(currentView));
        dispatch(setGoToView(undefined));
      }
    } else if (views.indexOf(currentView) < 0) {
      setDefaultTab(0);
      dispatch(setCurrentView(views[0]));
    }
  }
};

export const timestampToDate = (date) => new Date(date * 1000);

export const savedCandidateMessage = (t) => {
  Swal.fire({
    title: `${t('success', { keyPrefix: 'candidates.save' })}!`,
    text: t('candidate-saved-successfully', { keyPrefix: 'candidates.save' }),
    icon: 'success',
    confirmButtonText: t('close', { keyPrefix: 'candidates.save' }),
  });
};

export const failedCandidateMessage = (t, errors) => {
  Swal.fire({
    title: t('error', { keyPrefix: 'common' }),
    text: errors,
    icon: 'error',
    confirmButtonText: t('close', { keyPrefix: 'common' }),
  });
};

export const getTimeDifference = (startDate, endDate) => {
  const millisecondsPerSecond = 1000;
  const millisecondsPerMinute = millisecondsPerSecond * 60;
  const millisecondsPerHour = millisecondsPerMinute * 60;
  const millisecondsPerDay = millisecondsPerHour * 24;
  const millisecondsPerWeek = millisecondsPerDay * 7;

  // Calculate the difference in milliseconds
  const difference = endDate - startDate;

  // Calculate hours, days, and weeks
  const minutes = Math.floor(difference / millisecondsPerMinute);
  const hours = Math.floor(difference / millisecondsPerHour);
  const days = Math.floor(difference / millisecondsPerDay);
  const weeks = Math.floor(difference / millisecondsPerWeek);

  return {
    minutes,
    hours,
    days,
    weeks,
  };
};

export const renderColumns = (columns) => columns.map((c, i) => (
  <Column
    key={`row-${i}`}
    dataField={c.field}
    caption={c.caption}
    visible={c.visible}
    dataType={c.dataType}
    format={c.format}
    sortOrder={c.sortOrder}
    width={c.width}
    allowFixing={c.allowFixing}
    calculateCellValue={c.calculateCellValue}
  />
));

export const removeExtraFields = ({ valid, changed, ...keepAttrs }) => (keepAttrs);

export const invalidRecords = (records) => records.filter((r) => r.valid === false);

export const modifiedRecords = (records) => records
  .map((r) => {
    // eslint-disable-next-line no-underscore-dangle
    if ((typeof r.changed !== 'undefined' && r.changed === true) || r._destroy === true) {
      return removeExtraFields(r);
    }
    return null;
  })
  .filter((x) => x !== null);

// eslint-disable-next-line no-restricted-globals
export const isDateValid = (d) => d instanceof Date && !isNaN(d);

export const isNumber = (n) => typeof n === 'number' && !Number.isNaN(n - n);

export const isDocumentsUploaded = (docs) => {
  if (Object.keys(docs).length > 0) {
    const missing = docs.filter((d) => d.file_url === undefined || d.file_url === null || d.file_url === '');
    return missing.length <= 0;
  }
  return false;
};
