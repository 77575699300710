// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Switch from '@mui/material/Switch';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

function FormSwitch(
  {
    label,
    variant,
    textTransform,
    ...rest
  },
) {
  return (
    <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
      <SuiBox
        mb={1}
        ml={0.5}
        display="flex"
        alignItems="center"
        lineHeight={1}
      >
        <SuiTypography
          component="label"
          variant={variant}
          textTransform={textTransform}
        >
          {label}
        </SuiTypography>
        <SuiBox mx={1}>
          <Switch
            {...rest}
          />
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

// Setting admin values for the props of FormField
FormSwitch.defaultProps = {
  label: ' ',
  variant: 'subtitle1',
  textTransform: 'capitalize',
};

// Typechecking props for FormField
FormSwitch.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.string,
  textTransform: PropTypes.string,
};

export default FormSwitch;
