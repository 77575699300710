import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Reducers
// eslint-disable-next-line object-curly-newline
import { clearExpense, setExpenseEditing, setExpenseInfo, setRemoveData } from 'reducers/expenseSlice';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import ExpenseInfo from '../components/ExpenseInfo';

function NewExpense() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      clearExpense(),
    );
    dispatch(
      setExpenseInfo({
        vendor_id: '',
        candidate_id: '',
        date: new Date().toISOString().slice(0, 10),
        payment_method_id: undefined,
        expense_items: [
          {
            expense_category_id: 0,
            description: '',
            quantity: 0,
            amount: 0,
          },
        ],
        total: 0,
      }),
    );
    dispatch(
      setExpenseEditing(false),
    );
    dispatch(
      setRemoveData({ items: [] }),
    );
  }, [dispatch]);

  return (
    <BaseLayout>
      <ExpenseInfo />
    </BaseLayout>
  );
}

export default NewExpense;
