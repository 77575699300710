import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';

// Components
import FormField from 'layouts/components/FormField';

// DevExtreme components
import DataGrid, { Column, Editing, Paging } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.material.blue.light.css';

import { defaultValue } from 'Util';

function Contacts({ contacts, setValue }) {
  const { t } = useTranslation('translation', { keyPrefix: 'vendors' });

  const [openContact, setOpenContact] = useState(false);
  const [newContact, setNewContact] = useState(false);
  const [contactInfo, setContactInfo] = useState({
    id: null,
    first_name: null,
    last_name: null,
    email: null,
    phone: null,
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    setData(contacts.filter((c) => c._destroy !== true));
  }, [contacts]);

  const dataGrid = useRef(null);

  const setContactValue = (key, value) => {
    setContactInfo(
      {
        ...contactInfo,
        [key]: value,
        changed: true,
      },
    );
  };

  const handleNewContact = (e) => {
    e.preventDefault();
    setNewContact(true);
    setOpenContact(true);
  };

  const handleCloseNewContact = (e) => {
    e.preventDefault();
    setNewContact(false);
    setOpenContact(false);
  };

  const handleSaveContact = (e) => {
    e.preventDefault();

    if (newContact) {
      setValue(contacts.concat(contactInfo));
      setContactInfo({
        id: null,
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
      });
      setOpenContact(false);
    } else {
      setValue(contacts.map((obj) => {
        if (obj.id === contactInfo.id) {
          return {
            ...obj,
            first_name: contactInfo.first_name,
            last_name: contactInfo.last_name,
            email: contactInfo.email,
            phone: contactInfo.phone,
          };
        }
        return obj;
      }));
      setContactInfo({
        id: null,
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
      });
      setOpenContact(false);
    }
    e.cancel = true;
  };
  const handleEditing = (e) => {
    setContactInfo({
      id: e.data.id,
      first_name: e.data.first_name,
      last_name: e.data.last_name,
      email: e.data.email,
      phone: e.data.phone,
    });
    setOpenContact(true);
    e.cancel = true;
  };

  const handleRemoving = (e) => {
    if (e.data.id !== null) {
      setValue(contacts.map((obj) => {
        if (obj.id === e.data.id) {
          return {
            ...obj,
            first_name: e.data.first_name,
            last_name: e.data.last_name,
            email: e.data.email,
            phone: e.data.phone,
            _destroy: true,
          };
        }
        return obj;
      }));
    }
    e.cancel = true;
  };

  return (
    <div id="vendor-contacts">
      <SuiBox p={3}>
        <SuiTypography variant="h5">{t('contacts')}</SuiTypography>
      </SuiBox>
      {!openContact && (
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <SuiButton
              variant="gradient"
              color="info"
              size="small"
              onClick={handleNewContact}
            >
              <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
              &nbsp;
              {t('new-contact')}
            </SuiButton>
          </Grid>
        </Grid>
      )}
      {openContact && (
        <SuiBox component="form" pb={3} px={3}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={3}
          >
            <Grid item md={6}>
              <FormField
                label={t('first-name', { keyPrefix: 'common' })}
                value={defaultValue(contactInfo.first_name)}
                error={!contactInfo.first_name || contactInfo.first_name === ''}
                onChange={(e) => setContactValue('first_name', e.target.value)}
              />
            </Grid>
            <Grid item md={6}>
              <FormField
                label={t('last-name', { keyPrefix: 'common' })}
                value={defaultValue(contactInfo.last_name)}
                error={!contactInfo.last_name || contactInfo.last_name === ''}
                onChange={(e) => setContactValue('last_name', e.target.value)}
              />
            </Grid>
            <Grid item md={6}>
              <FormField
                label={t('email', { keyPrefix: 'common' })}
                value={defaultValue(contactInfo.email)}
                error={!contactInfo.email || contactInfo.email === ''}
                onChange={(e) => setContactValue('email', e.target.value)}
              />
            </Grid>
            <Grid item md={6}>
              <FormField
                label={t('phone')}
                value={defaultValue(contactInfo.phone)}
                error={!contactInfo.phone || contactInfo.phone === ''}
                onChange={(e) => setContactValue('phone', e.target.value)}
              />
            </Grid>
            <Grid item>
              <SuiButton
                variant="gradient"
                color="info"
                size="small"
                onClick={handleSaveContact}
              >
                {t('save', { keyPrefix: 'common' })}
              </SuiButton>
            </Grid>
            <Grid item>
              <SuiButton
                variant="outlined"
                color="info"
                size="small"
                onClick={handleCloseNewContact}
              >
                {t('close', { keyPrefix: 'common' })}
              </SuiButton>
            </Grid>
          </Grid>
        </SuiBox>
      )}
      <DataGrid
        dataSource={data}
        ref={dataGrid}
        keyExpr="id"
        allowColumnReordering
        allowColumnResizing
        columnAutoWidth
        onEditingStart={handleEditing}
        onRowRemoving={handleRemoving}
        showBorders={false}
      >
        <Paging enabled={false} />
        <Editing
          mode="row"
          allowUpdating
          allowDeleting
          allowAdding={false}
        />
        <Column dataField="first_name" caption={t('first-name', { keyPrefix: 'common' })} />
        <Column dataField="last_name" caption={t('last-name', { keyPrefix: 'common' })} />
        <Column dataField="email" caption={t('email', { keyPrefix: 'common' })} />
        <Column dataField="phone" caption={t('phone')} />
      </DataGrid>
    </div>
  );
}

Contacts.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  contacts: PropTypes.arrayOf(PropTypes.any).isRequired,
  setValue: PropTypes.func.isRequired,
};
export default Contacts;
