import Service from './service';

class UserService extends Service {
  // eslint-disable-next-line class-methods-use-this
  async getUsers() {
    const response = await this.api
      .get(
        this.localizeURL('users'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getAdvisors() {
    const response = await this.api
      .get(
        this.localizeURL('users/advisors'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getUserProfile(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`users/${uuid}`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateUserProfile(uuid, email, firstName, lastName, roleId, password, isActive) {
    const data = {
      email,
      role_id: roleId,
      first_name: firstName,
      last_name: lastName,
      password,
      is_active: isActive,
    };

    const response = await this.api
      .put(
        this.localizeURL(`users/${uuid}`),
        data,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createUserProfile(email, firstName, lastName, roleId, password, isActive) {
    const data = {
      user: {
        email,
        password,
        role_id: roleId,
        first_name: firstName,
        last_name: lastName,
        is_active: isActive,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL('register/'),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async createUser(email, firstName, lastName, roleId, password) {
    const data = {
      user: {
        email,
        password,
        role_id: roleId,
        first_name: firstName,
        last_name: lastName,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL('users/'),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteUserProfile(uuid) {
    const response = await this.api
      .delete(
        this.localizeURL(`users/${uuid}`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async assignCandidates(uuid, candidates) {
    const data = {
      user: {
        candidates,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`users/${uuid}/assign-candidates`),
        data,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidates(uuid, candidates) {
    const data = {
      user: {
        candidates,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`users/${uuid}/update-candidates`),
        data,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async removeCandidates(uuid, candidates) {
    const data = {
      user: {
        candidates,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`users/${uuid}/remove-candidates`),
        data,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async unlockCandidate(uuid) {
    const response = await this.api
      .put(
        this.localizeURL(`users/${uuid}/unlock`),
        null,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getUserPermissions(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`users/${uuid}/permissions`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async fetchUserActivity(uuid, page) {
    const response = await this.api
      .get(
        this.localizeURL(`users/${uuid}/activity?page=${page}`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async fetchUserRecentActivity(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`users/${uuid}/recent-activity`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async fetchUserAllActivity(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`users/${uuid}/all-activity`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async fetchUserNotifications(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`users/${uuid}/notifications`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateNotification(uuid, id, readAt) {
    const data = {
      notification: {
        read_at: readAt,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`users/${uuid}/notifications/${id}`),
        data,
        { headers: this.getHeaders() },
      );
    return response.data;
  }
}

export default new UserService();
