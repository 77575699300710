import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  setFailed,
  setSaved,
  signCandidateAgreement,
  updateCandidateAgreementDocument,
} from 'reducers/candidatesSlice';

// @material-ui core components
import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';
import Drawer from '@mui/material/Drawer';

// Soft UI Dashboard PRO React components
import SuiTypography from 'components/SuiTypography';

// Components
import DefaultAgreementCard from 'layouts/components/Cards/AgreementCards/DefaultAgreementCard';
import AgreementInfo from 'layouts/candidates/components/Agreements/components/AgreementInfo';

// Functions
import { setTitle } from 'Util';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

const selector = (state) => ({
  personal: state.candidate.personal,
  candidateAgreements: state.candidate.agreements,
  saved: state.candidate.saved,
  failed: state.candidate.failed,
  errors: state.candidate.errors,
});

function CandidateAgreements({ candidate, disableFunc }) {
  const { t } = useTranslation('translation', { keyPrefix: 'links' });
  const dispatch = useDispatch();

  const {
    personal,
    candidateAgreements,
    saved,
    failed,
    errors,
  } = useSelector(selector, shallowEqual);

  const [signedAgreements, setSignedAgreements] = useState([]);
  const [unsignedAgreements, setUnsignedAgreements] = useState([]);
  const [selectedAgreement, setSelectedAgreement] = useState(
    {
      id: 0,
      agreement: { name: '' },
      file_url: '',
    },
  );
  const [showAgreement, setShowAgreement] = useState(false);

  setTitle('Candidate Agreements Link');

  useEffect(() => {
    if (candidateAgreements !== undefined) {
      setSignedAgreements(candidateAgreements.filter((a) => a.signed === true));
      setUnsignedAgreements(candidateAgreements.filter((a) => a.signed !== true));
    }
  }, [candidateAgreements]);

  const toggleDrawer = (open) => () => {
    setShowAgreement(open);

    if (!open) {
      setSelectedAgreement({
        id: 0,
        agreement: { name: '' },
        file_url: '',
      });
    }
  };

  const handleSelectAgreement = (id) => {
    setSelectedAgreement(candidateAgreements.filter((a) => a.id === id)[0]);
    setShowAgreement(true);
  };

  const handleSign = (agreementId) => {
    if (
      personal.signature_url !== undefined
      && personal.signature_url !== null
    ) {
      dispatch(
        signCandidateAgreement({
          id: agreementId,
          candidateId: candidate,
        }),
      );
      setShowAgreement(false);
    } else {
      Swal.fire({
        text: t('signature-undefined-or-invalid'),
        icon: 'error',
        confirmButtonText: t('close', { keyPrefix: 'common' }),
      });
    }
  };

  const handleUploadFile = (id, files) => {
    dispatch(
      updateCandidateAgreementDocument({
        id,
        candidateId: candidate,
        files: files[0],
      }),
    );
    setShowAgreement(false);
  };

  if (unsignedAgreements.length === 0 && signedAgreements.length > 0) {
    disableFunc();
  }

  if (saved) {
    dispatch(setSaved(false));

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('agreement-saved-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    });
  }
  if (failed) {
    dispatch(setFailed(false));

    Swal.fire({
      title: t('error', { keyPrefix: 'common' }),
      text: errors,
      icon: 'error',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    });
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item md={6}>
        <SuiTypography variant="h4">
          {`${t('awaiting-signature')} (${Object.keys(unsignedAgreements).length})`}
        </SuiTypography>
      </Grid>
      <Grid item md={6}>
        <SuiTypography variant="h4">
          {`${t('signed')} (${Object.keys(signedAgreements).length})`}
        </SuiTypography>
      </Grid>
      <Grid item md={6}>
        {Object.keys(unsignedAgreements).length > 0
          && unsignedAgreements.map((a) => (
            <div key={a.id}>
              <DefaultAgreementCard
                id={a.id}
                name={a.agreement.name}
                selected={a.id === selectedAgreement.id}
                signed={a.signed}
                signedOn={a.signed_on !== null ? a.signed_on.replace('UTC', 'GMT') : null}
                assignedOn={a.assigned_on !== null ? a.assigned_on.replace('UTC', 'GMT') : null}
                selectFunc={handleSelectAgreement}
              />
              <Divider />
            </div>
          ))}
      </Grid>
      <Grid item md={6}>
        {Object.keys(signedAgreements).length > 0
          && signedAgreements.map((a) => (
            <div key={a.id}>
              <DefaultAgreementCard
                id={a.id}
                name={a.agreement.name}
                selected={a.id === selectedAgreement.id}
                signed={a.signed}
                signedOn={a.signed_on}
                assignedOn={a.assigned_on}
                selectFunc={handleSelectAgreement}
              />
              <Divider />
            </div>
          ))}
      </Grid>
      <Drawer
        id="agreementDrawer"
        anchor="right"
        open={showAgreement}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: { width: '60%' },
        }}
      >
        <AgreementInfo
          id={selectedAgreement.id}
          name={selectedAgreement.agreement.name}
          fileUrl={selectedAgreement.file_url}
          signed={selectedAgreement.signed}
          expires={selectedAgreement.agreement.expires}
          signedOn={selectedAgreement.signed_on}
          uploadFunc={handleUploadFile}
          assignedOn={selectedAgreement.assigned_on}
          expiresOn={selectedAgreement.expires_on}
          signFunc={handleSign}
          showUpload={!selectedAgreement.signed}
          showModify={false}
        />
      </Drawer>
    </Grid>
  );
}

CandidateAgreements.propTypes = {
  candidate: PropTypes.string.isRequired,
  disableFunc: PropTypes.func.isRequired,
};

export default CandidateAgreements;
