import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Reducers
import { confirmation } from 'reducers/authSlice';

// @mui material components
import SuiTypography from 'components/SuiTypography';
import SuiBox from 'components/SuiBox';

// Components
import CoverLayout from 'layouts/authentication/components/CoverLayout';

// Images
import curved9 from 'assets/images/curved-images/curved9.jpg';

const selector = (state) => ({
  confirmed: state.auth.confirmed,
  message: state.auth.message,
});

function Confirmation() {
  const { t } = useTranslation('translation', { keyPrefix: 'authentication' });
  const {
    confirmed,
    message,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const { confirmationToken } = useParams();

  useEffect(() => {
    dispatch(
      confirmation({
        confirmationToken,
      }),
    );
  }, [dispatch, confirmationToken]);

  return (
    <CoverLayout
      title={confirmed ? t('thank-you') : t('error_has-occurred')}
      description={
        confirmed
          ? t('email-confirmed')
          : message
      }
      image={curved9}
    >
      <SuiBox mt={3} textAlign="center">
        <SuiTypography
          component={Link}
          to="/"
          variant="button"
          color="info"
          fontWeight="medium"
          textGradient
        >
          {t('sign-in')}
        </SuiTypography>
      </SuiBox>
    </CoverLayout>
  );
}

export default Confirmation;
