import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  fetchCandidatePersonal,
  setCurrentSubView,
  setPersonal,
  updateCandidatePersonalPassport,
  updateCandidatePersonalSignature,
} from 'reducers/candidatesSlice';
import { fetchCountries, fetchRaces } from 'reducers/commonSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';
import FormSignaturePad from 'layouts/components/FormSignaturePad';

// Functions
// eslint-disable-next-line
import { checkPermission, defaultValue, findSelectValue, getCandidateUUID, setTitle } from 'Util';
import { DropzoneDialog } from 'material-ui-dropzone';
import dataURLtoBlob from 'blueimp-canvas-to-blob';

const selector = (state) => ({
  currentUser: state.auth.user,
  editing: state.candidate.editing,
  personal: state.candidate.personal,
  races: state.common.races,
  countries: state.common.countries,
});

function PersonalInfo() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'candidates.personal-info',
  });
  const {
    currentUser,
    editing,
    personal,
    races,
    countries,
  } = useSelector(selector, shallowEqual);

  const lbsKgs = [
    {
      value: 'lbs',
      label: 'lbs',
    },
    {
      value: 'kg',
      label: 'kg',
    },
  ];

  const cmFt = [
    {
      value: 'ft',
      label: 'ft',
    },
    {
      value: 'cm',
      label: 'cm',
    },
  ];

  const hairColors = [
    {
      value: 'Black',
      label: t('black'),
    },
    {
      value: 'Medium Brown',
      label: t('medium-brown'),
    },
    {
      value: 'Light Brown',
      label: t('light-brown'),
    },
    {
      value: 'Brown',
      label: t('brown'),
    },
    {
      value: 'Light Blonde',
      label: t('light-blonde'),
    },
    {
      value: 'Medium Blonde',
      label: t('medium-blonde'),
    },
    {
      value: 'Blonde',
      label: t('blonde'),
    },
    {
      value: 'Other',
      label: t('other'),
    },
  ];

  const eyeColors = [
    {
      value: 'Amber',
      label: t('amber'),
    },
    {
      value: 'Blue',
      label: t('blue'),
    },
    {
      value: 'Brown',
      label: t('brown'),
    },
    {
      value: 'Gray',
      label: t('gray'),
    },
    {
      value: 'Green',
      label: t('green'),
    },
    {
      value: 'Hazel',
      label: t('hazel'),
    },
    {
      value: 'Black',
      label: t('black'),
    },
    {
      value: 'Other',
      label: t('other'),
    },
  ];

  const yesNoStatus = [
    {
      value: true,
      label: t('yes', { keyPrefix: 'common' }),
    },
    {
      value: false,
      label: t('no', { keyPrefix: 'common' }),
    },
  ];

  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    dispatch(setCurrentSubView('personal-info'));
    dispatch(fetchRaces());
    dispatch(fetchCountries());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidatePersonal({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  setTitle('Candidate Personal Info');

  const [firstLoad, setFirstLoad] = useState(true);
  const [weightUnit, setWeightUnit] = useState({
    value: 'lbs',
    label: 'lbs',
  });
  const [weightDisplay, setWeightDisplay] = useState('');
  const [heightUnit, setHeightUnit] = useState({
    value: 'ft',
    label: 'ft',
  });
  const [heightDisplay, setHeightDisplay] = useState('');
  const [dropzoneOpen, setDropzoneOpen] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);

  const closeUploadDropbox = (e) => {
    e.preventDefault();
    setOpenUpload(false);
  };

  const setWeightUnitValue = (e) => {
    setWeightUnit(e);
    if (e.value === 'lbs') {
      setWeightDisplay(Math.round(personal.weight * 100) / 100);
    } else {
      setWeightDisplay(Math.round(personal.weight * 45.3592) / 100);
    }
  };

  const calcHeightInFeet = (h) => {
    const inchesTotal = Math.round(h * 0.393701);
    const feet = Math.floor(inchesTotal / 12);
    const inches = inchesTotal % 12;

    return `${feet}' ${inches}"`;
  };
  const setHeightUnitValue = (e) => {
    setHeightUnit(e);
    if (e.value === 'cm') {
      setHeightDisplay(Math.round(personal.height * 100) / 100);
    } else {
      setHeightDisplay(calcHeightInFeet(personal.height));
    }
  };

  const setValue = (key, value) => {
    dispatch(
      setPersonal({
        ...personal,
        [key]: value,
        changed: true,
      }),
    );
  };

  const setWeightValue = (value) => {
    setWeightDisplay(value);

    if (weightUnit.value === 'lbs') {
      setValue('weight', value);
    } else {
      setValue('weight', value / 0.453592);
    }
  };

  const setHeightValue = (value) => {
    if (heightUnit.value === 'cm') {
      setValue('height', value);
      setHeightDisplay(value);
    } else {
      const unformattedValue = value.replace('"', '').replace(' ', '').split('\'');
      let totalInches = 0;

      if (unformattedValue[0] !== undefined) {
        totalInches = ((unformattedValue[0] !== undefined ? unformattedValue[0] : 0) * 12)
          + ((unformattedValue[1] !== undefined ? unformattedValue[1] : 0) * 1);
      }

      setValue('height', Math.floor(totalInches * 2.54));
      setHeightDisplay(value);
    }
  };

  const saveSignature = (image) => {
    dispatch(
      updateCandidatePersonalSignature({
        id: personal.id,
        candidateId: uuid,
        file: image,
      }),
    );
  };

  const handlePassportChange = (image) => {
    setDropzoneOpen(false);
    dispatch(
      updateCandidatePersonalPassport({
        id: personal.id,
        candidateId: uuid,
        file: image[0],
      }),
    );
  };

  useEffect(() => {
    if (personal.weight !== undefined && firstLoad) {
      setWeightDisplay(personal.weight);
      setHeightDisplay(calcHeightInFeet(personal.height));
      setFirstLoad(false);
    }
  }, [personal, firstLoad]);

  const uploadDialog = () => {
    setOpenUpload(true);
  };

  const uploadSignature = (files) => {
    setOpenUpload(false);

    const reader = new FileReader();
    reader.onloadend = () => {
      saveSignature(dataURLtoBlob(reader.result));
    };
    reader.readAsDataURL(files[0]);
  };

  return (
    <div>
      {checkPermission('CAPI', currentUser) && (
        <>
          <Card id="personal-info" sx={{ overflow: 'visible' }}>
            <CardHeader
              title={t('personal-info')}
            />
            <SuiBox pb={3} px={3}>
              <Grid container spacing={3}>
                {checkPermission('CAIW', currentUser) && (
                  <>
                    <Grid item md={1}>
                      <FormField
                        label={t('weight')}
                        textTransform="none"
                        value={defaultValue(weightDisplay)}
                        onChange={(e) => {
                          setWeightValue(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item md={1}>
                      <FormSelect
                        options={lbsKgs}
                        value={defaultValue(weightUnit)}
                        onChange={setWeightUnitValue}
                      />
                    </Grid>
                  </>
                )}
                {checkPermission('CAIH', currentUser) && (
                  <>
                    <Grid item md={1}>
                      <FormField
                        label={t('height')}
                        textTransform="none"
                        value={defaultValue(heightDisplay)}
                        onChange={(e) => {
                          setHeightValue(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item md={1}>
                      <FormSelect
                        options={cmFt}
                        value={defaultValue(heightUnit)}
                        onChange={setHeightUnitValue}
                      />
                    </Grid>
                  </>
                )}
                {checkPermission('CAIC', currentUser) && (
                  <Grid item md={3}>
                    <FormSelect
                      label={t('hair-color')}
                      textTransform="none"
                      options={hairColors}
                      value={findSelectValue(hairColors, personal.hair_color)}
                      onChange={(e) => {
                        setValue('hair_color', e.value);
                      }}
                    />
                  </Grid>
                )}
                {checkPermission('CAIE', currentUser) && (
                  <Grid item md={2}>
                    <FormSelect
                      label={t('eye-color')}
                      textTransform="none"
                      options={eyeColors}
                      value={findSelectValue(eyeColors, personal.eye_color)}
                      onChange={(e) => {
                        setValue('eye_color', e.value);
                      }}
                    />
                  </Grid>
                )}
                {checkPermission('CAIR', currentUser) && (
                  <Grid item md={3}>
                    <FormSelect
                      label={t('race')}
                      textTransform="none"
                      options={races}
                      value={findSelectValue(races, personal.race_id)}
                      onChange={(e) => {
                        setValue('race_id', e.value);
                      }}
                    />
                  </Grid>
                )}
                {checkPermission('CAIV1', currentUser) && (
                  <Grid item md={3}>
                    <FormSelect
                      label={t('willing-vaccine-covid')}
                      textTransform="none"
                      options={yesNoStatus}
                      value={findSelectValue(yesNoStatus, personal.willing_covid_vaccine)}
                      onChange={(e) => {
                        setValue('willing_covid_vaccine', e.value);
                      }}
                    />
                  </Grid>
                )}
                {checkPermission('CAIV2', currentUser) && (
                  <Grid item md={3}>
                    <FormSelect
                      label={t('willing-vaccine-influenza')}
                      textTransform="none"
                      options={yesNoStatus}
                      value={findSelectValue(yesNoStatus, personal.willing_influenza_vaccine)}
                      onChange={(e) => {
                        setValue('willing_influenza_vaccine', e.value);
                      }}
                    />
                  </Grid>
                )}
                <Grid item md={6} />
              </Grid>
            </SuiBox>
          </Card>
          <Divider />
        </>
      )}
      {checkPermission('CAIS', currentUser) && (
        <>
          <Card id="signature-info" sx={{ overflow: 'visible' }}>
            <CardHeader
              title={t('signature')}
            />
            <SuiBox pb={3} px={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3} />
                <Grid item xs={12} md={6}>
                  {personal.id !== undefined && (
                    <FormSignaturePad
                      label={t('signature')}
                      image={personal.signature_url}
                      saveFunc={saveSignature}
                      clearLabel={t('clear')}
                      saveLabel={t('save')}
                      cancelLabel={t('cancel')}
                      shadow="lg"
                      borderRadius="xl"
                      height={200}
                      width="100%"
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={3} />
                <Grid item xs={12} md={3} />
                <Grid item xs={12} md={6}>
                  <SuiButton onClick={uploadDialog}>{t('upload')}</SuiButton>
                  <DropzoneDialog
                    acceptedFiles={[
                      'image/jpeg',
                      'image/png',
                      'image/tiff',
                      'image/webp',
                    ]}
                    cancelButtonText={t('cancel')}
                    submitButtonText={t('upload')}
                    maxFileSize={10000000}
                    filesLimit={1}
                    open={openUpload}
                    onClose={closeUploadDropbox}
                    onSave={(files) => {
                      uploadSignature(files);
                    }}
                    showPreviews
                    showFileNamesInPreview
                  />
                </Grid>
                <Grid item xs={12} md={3} />
              </Grid>
            </SuiBox>
          </Card>
          <Divider />
        </>
      )}
      {checkPermission('CAIP', currentUser) && (
        <>
          <Card id="passport-info" sx={{ overflow: 'visible' }}>
            <CardHeader
              title={t('passport-info')}
            />
            <SuiBox pb={3} px={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <FormSelect
                    label={t('issuing-country')}
                    options={countries}
                    value={findSelectValue(countries, personal.country_id)}
                    textTransform="none"
                    onChange={(e) => {
                      setValue('country_id', e.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField
                    label={t('date-of-issue')}
                    type="date"
                    textTransform="none"
                    value={defaultValue(personal.passport_date_issue)}
                    onChange={(e) => {
                      setValue('passport_date_issue', e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField
                    label={t('date-of-expiration')}
                    type="date"
                    textTransform="none"
                    value={defaultValue(personal.passport_date_expiration)}
                    onChange={(e) => {
                      setValue('passport_date_expiration', e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormField
                    label={t('passport-number')}
                    textTransform="none"
                    value={defaultValue(personal.passport_number)}
                    onChange={(e) => {
                      setValue('passport_number', e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="passport-content"
                      id="passport"
                    >
                      <SuiTypography>{t('passport')}</SuiTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <SuiBox
                        component="img"
                        src={personal.passport_url}
                        shadow="lg"
                        borderRadius="xl"
                        width="100%"
                      />
                      <SuiButton variant="contained" color="secondary" onClick={() => setDropzoneOpen(true)}>
                        {t('add-passport')}
                      </SuiButton>
                      <DropzoneDialog
                        acceptedFiles={['image/*']}
                        cancelButtonText="cancel"
                        submitButtonText="submit"
                        maxFileSize={10000000}
                        filesLimit={1}
                        open={dropzoneOpen}
                        onClose={() => setDropzoneOpen(false)}
                        onSave={(image) => {
                          handlePassportChange(image);
                        }}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </SuiBox>
          </Card>
          <Divider />
        </>
      )}
    </div>
  );
}

export default PersonalInfo;
