import Service from './service';

class TokenLinkService extends Service {
  async validateLink(token) {
    const response = await this.api
      .get(
        `token-links/${token}/validate`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async getLink(token) {
    const response = await this.api
      .get(
        `token-links/${token}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async disableLink(token) {
    const response = await this.api
      .get(
        `token-links/${token}/disable`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async createLink(section, candidateId) {
    const data = {
      link: {
        section,
        candidate_id: candidateId,
      },
    };

    const response = await this.api
      .post(
        'token-links',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  async sendLink(token) {
    const response = await this.api
      .get(
        `token-links/${token}/send`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new TokenLinkService();
