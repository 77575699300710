import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Reducer functions
import { fetchStatesIndex } from 'reducers/commonSlice';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { DropzoneDialog } from 'material-ui-dropzone';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import CardContent from '@mui/material/CardContent';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';
import FormCheckbox from 'layouts/components/FormCheckbox';

// Utils
import { defaultValue, findSelectValue } from 'Util';

const selector = (state) => ({
  statesIndex: state.common.statesIndex,
});

function DefaultCredentialCard(
  {
    i,
    c,
    ops,
    setValue,
    removeFunc,
    uploadFunc,
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.credentials' });
  const {
    statesIndex,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(true);
  const [credentialMenu, setCredentialMenu] = useState(null);
  const [dropzoneOpen, setDropzoneOpen] = useState(false);
  const [states, setStates] = useState(undefined);
  const [country, setCountry] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [state, setState] = useState('');

  const openCredentialMenu = (event) => setCredentialMenu(event.currentTarget);
  const closeCredentialMenu = () => setCredentialMenu(null);
  const handleExpand = () => {
    closeCredentialMenu();
    setExpanded(!expanded);
  };
  const handleDocChange = (files, id) => {
    setDropzoneOpen(false);
    uploadFunc(files, id);
  };

  const handleCountry = (e) => {
    if (e.value !== undefined) {
      setSelectedCountry(e.value);
      setValue('country_id', e.value, i);
    }
  };

  useEffect(() => {
    if (c.country_id !== undefined && c.country_id !== null) {
      setSelectedCountry(c.country_id);
      if (findSelectValue(ops.countries, c.country_id) !== undefined) {
        setCountry(findSelectValue(ops.countries, c.country_id).name);
      }
    }
  }, [dispatch, ops, c, i]);

  useEffect(() => {
    setStates(statesIndex[i]);
    if (states !== undefined && c.state_id !== null) {
      if (findSelectValue(states, c.state_id) !== undefined) {
        setState(findSelectValue(states, c.state_id).name);
      }
    }
  }, [dispatch, states, c, statesIndex, i]);

  useEffect(() => {
    if (selectedCountry !== null) {
      dispatch(
        fetchStatesIndex({
          id: selectedCountry,
          index: i,
        }),
      );
    }
  }, [selectedCountry, dispatch, i]);

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardHeader
        action={(
          <IconButton onClick={openCredentialMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        title={`${country} - 
        ${state}`}
      />
      <Menu
        anchorEl={credentialMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(credentialMenu)}
        onClose={closeCredentialMenu}
        keepMounted
      >
        <MenuItem
          onClick={handleExpand}
        >
          {expanded
            ? t('collapse', { keyPrefix: 'common' })
            : t('expand', { keyPrefix: 'common' })}
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeFunc(i);
          }}
        >
          {t('remove', { keyPrefix: 'common' })}
        </MenuItem>
      </Menu>
      <CardContent>
        <SuiBox p={1}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <SuiBox mb={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} pr={1}>
                  <FormField
                    label={t('license-number')}
                    error={!c.license_number || c.license_number === ''}
                    onChange={(event) => {
                      setValue('license_number', event.target.value, i);
                    }}
                    value={defaultValue(c.license_number)}
                  />
                </Grid>
                <Grid item xs={12} md={4} pr={1}>
                  <FormSelect
                    label={t('issuing-country')}
                    options={ops.countries}
                    id={`issuing-country[${i}]`}
                    value={
                      ops.countries !== undefined
                        ? findSelectValue(ops.countries, c.country_id, i) : undefined
                    }
                    error={c.country_id ? false : !c.country_id || c.country_id === ''}
                    onChange={(event) => {
                      handleCountry(event);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4} pr={1}>
                  <FormSelect
                    label={t('issuing-state')}
                    options={states}
                    id={`issuing-state[${i}]`}
                    value={
                      states !== undefined
                        ? findSelectValue(states, c.state_id, i) : undefined
                    }
                    error={c.state_id ? false : !c.state_id || c.state_id === ''}
                    onChange={(event) => {
                      setValue('state_id', event.value, i);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3} pr={1}>
                  <FormField
                    label={t('date-issue')}
                    type="date"
                    placeholder={t('issued')}
                    error={c.issue ? false : !c.issue || c.issue === ''}
                    onChange={(event) => {
                      setValue('issue', event.target.value, i);
                    }}
                    value={defaultValue(c.issue)}
                  />
                </Grid>
                <Grid item xs={12} md={2} pr={1}>
                  <FormCheckbox
                    icon={<FavoriteBorder />}
                    checkedIcon={<Favorite />}
                    checked={c.no_expiration}
                    onChange={(event) => {
                      setValue('no_expiration', event.target.checked, i);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                    label={t('no-expiration-date')}
                  />
                </Grid>
                <Grid item xs={12} md={3} pr={1}>
                  <FormField
                    label={t('expiration-date')}
                    type="date"
                    placeholder={t('expiration')}
                    error={c.expiration ? false : (!c.expiration || c.expiration === '') && !c.no_expiration}
                    onChange={(event) => {
                      setValue('expiration', event.target.value, i);
                    }}
                    value={defaultValue(c.expiration)}
                    disabled={c.no_expiration}
                  />
                </Grid>
                {(c.id !== undefined && c.id !== null) && (
                  <Grid item xs={12} md={12} pr={1}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`document${i + 1}-content`}
                        id={`document${i + 1}-header`}
                      >
                        <SuiTypography>{t('document')}</SuiTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <SuiBox
                          component="img"
                          src={c.file_url}
                          shadow="lg"
                          borderRadius="xl"
                          width="100%"
                        />
                        <SuiButton variant="contained" color="secondary" onClick={() => setDropzoneOpen(true)}>
                          {t('add-document')}
                        </SuiButton>
                        <DropzoneDialog
                          acceptedFiles={['image/*']}
                          cancelButtonText="cancel"
                          submitButtonText="submit"
                          maxFileSize={15728640}
                          filesLimit={1}
                          open={dropzoneOpen}
                          onClose={() => setDropzoneOpen(false)}
                          onSave={(files) => {
                            handleDocChange(files, c.id);
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )}
              </Grid>
            </SuiBox>
          </Collapse>
        </SuiBox>
      </CardContent>
    </Card>
  );
}

DefaultCredentialCard.propTypes = {
  i: PropTypes.number.isRequired,
  c: PropTypes.shape(
    {
      license_number: PropTypes.string,
      issue: PropTypes.string,
      country_id: PropTypes.number,
      state_id: PropTypes.number,
      expiration: PropTypes.string,
      no_expiration: PropTypes.bool,
      file_url: PropTypes.string,
      id: PropTypes.number,
    },
  ).isRequired,
  ops: PropTypes.shape({
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
  uploadFunc: PropTypes.func.isRequired,
};

export default DefaultCredentialCard;
