// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiSelect from 'components/SuiSelect';

function FormSelect(
  {
    label,
    textTransform,
    ...rest
  },
) {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
      <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform={textTransform}
        >
          {label}
        </SuiTypography>
      </SuiBox>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <SuiSelect {...rest} />
    </SuiBox>
  );
}

// Setting admin values for the props of FormSelect
FormSelect.defaultProps = {
  label: ' ',
  textTransform: 'capitalize',
};

// Typechecking props for FormSelect
FormSelect.propTypes = {
  label: PropTypes.string,
  textTransform: PropTypes.string,
};

export default FormSelect;
