import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Reducer functions
/* eslint-disable-next-line object-curly-newline */
import { clearCandidate, setCreated, setCurrentSubView, setCurrentView, setEditing } from 'reducers/candidatesSlice';
import { clearAccountInfo } from 'reducers/accountsSlice';
import { fetchCandidateStatuses } from 'reducers/commonSlice';

// Functions
import { setTitle } from 'Util';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import AdminView from '../components/AdminView';

function NewCandidate() {
  const dispatch = useDispatch();

  setTitle('New Candidate');

  useEffect(() => {
    dispatch(setEditing(false));
    dispatch(setCreated(false));
    dispatch(setCurrentSubView('basic-info'));
    dispatch(setCurrentView('profile'));
    dispatch(clearCandidate());
    dispatch(fetchCandidateStatuses());
    dispatch(clearAccountInfo());
  }, [dispatch]);

  return (
    <BaseLayout>
      <AdminView />
    </BaseLayout>
  );
}

export default NewCandidate;
