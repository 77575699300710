import { useEffect, useRef, useState } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @material-ui core components
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';

// Other components
import SignatureCanvas from 'react-signature-canvas';
import dataURLtoBlob from 'blueimp-canvas-to-blob';

// Styles
import 'assets/styles/signature-canvas.css';

// Images
import blank from 'assets/images/blank_rectangle.png';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '65%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function FormSignaturePad(
  {
    label,
    image,
    clearLabel,
    saveLabel,
    cancelLabel,
    saveFunc,
    ...rest
  },
) {
  const sigRef = useRef();

  const [signature, setSignature] = useState(null);
  const [openSignatureModal, setOpenSignatureModal] = useState(false);

  const handleOpenSignatureModal = () => setOpenSignatureModal(true);
  const handleCloseSignatureModal = () => setOpenSignatureModal(false);
  const clearSignature = () => {
    sigRef.current.clear();
    setSignature(null);
  };
  const createSignature = () => {
    const URL = sigRef.current.getTrimmedCanvas().toDataURL('image/png');
    setSignature(URL);
    setOpenSignatureModal(false);
    saveFunc(dataURLtoBlob(URL));
  };

  useEffect(() => {
    setSignature(image === null ? blank : image);
  }, [image]);

  return (
    <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
      <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </SuiTypography>
      </SuiBox>
      <SuiBox
        component="img"
        sx={{
          p: 2,
        }}
        src={signature}
        {...rest}
        onClick={handleOpenSignatureModal}
      />
      <Modal
        open={openSignatureModal}
        onClose={handleCloseSignatureModal}
      >
        <SuiBox
          sx={style}
          borderRadius="xl"
        >
          <div className="sigPadContainer">
            <SignatureCanvas
              ref={sigRef}
              penColor="black"
              canvasProps={{
                className: 'sigCanvas',
              }}
            />
          </div>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <SuiButton onClick={clearSignature}>{clearLabel}</SuiButton>
            </Grid>
            <Grid item>
              <SuiButton onClick={createSignature}>{saveLabel}</SuiButton>
            </Grid>
          </Grid>
        </SuiBox>
      </Modal>
    </SuiBox>
  );
}

// Setting admin values for the props of FormSignaturePad
FormSignaturePad.defaultProps = {
  label: ' ',
  image: blank,
  clearLabel: 'Clear',
  saveLabel: 'Save',
  cancelLabel: 'Cancel',
};

// Typechecking props for FormSignaturePad
FormSignaturePad.propTypes = {
  label: PropTypes.string,
  image: PropTypes.string,
  clearLabel: PropTypes.string,
  saveLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  saveFunc: PropTypes.func.isRequired,
};

export default FormSignaturePad;
