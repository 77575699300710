import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

// Reducer functions
import { fetchOrderTemplates } from 'reducers/orderTemplatesSlice';
import {
  fetchAccountBalance,
  fetchAccountInvoices,
  fetchAccountTransactions,
} from 'reducers/accountsSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Widgets page components
import AccountBalance from 'layouts/components/Widgets/AccountBalance';
import PaymentPlans from 'layouts/components/Widgets/PaymentPlans';

// Components
import Invoices from '../Invoices';
import Transactions from '../Transactions';
import { getCandidateUUID } from '../../../../Util';

const selector = (state) => ({
  editing: state.candidate.editing,
  transactions: state.account.transactions,
  invoices: state.account.invoices,
});

function AccountSummary() {
  const {
    editing,
    transactions,
    invoices,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    dispatch(fetchOrderTemplates());

    if (editing && uuid !== undefined) {
      dispatch(fetchAccountBalance({ id: uuid }));
      dispatch(fetchAccountTransactions({ id: uuid }));
      dispatch(fetchAccountInvoices({ id: uuid }));
    }
  }, [dispatch, editing, uuid]);

  return (
    <SuiBox mt={4}>
      <Grid container spacing={3} pb={1}>
        <Grid item md={3}>
          <AccountBalance uuid={uuid} />
        </Grid>
        <Grid item md={4}>
          <PaymentPlans uuid={uuid} />
        </Grid>
        <Grid item md={5} />
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={4}>
          <Invoices invoices={invoices} />
        </Grid>
        <Grid item md={4}>
          <Transactions transactions={transactions} />
        </Grid>
        <Grid item md={4} />
      </Grid>
    </SuiBox>
  );
}

export default AccountSummary;
