import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// @mui material components
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

function BsrUsersParameters({ defaultParameters, setParametersFunc }) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const dispatch = useDispatch();

  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    if (defaultParameters.tags !== undefined) {
      const sel = [];

      defaultParameters.tags
        .forEach((tag) => sel.push({ value: tag, label: tag }));

      setSelectedTags(sel);
    }
  }, [dispatch, defaultParameters]);

  const tags = [
    { value: 'A1', label: 'A1' },
    { value: 'A1.1', label: 'A1.1' },
    { value: 'A1.2', label: 'A1.2' },
    { value: 'a1.2', label: 'a1.2' },
    { value: 'A1.3', label: 'A1.3' },
    { value: 'A1.4', label: 'A1.4' },
    { value: 'A1.4FINALEXAM', label: 'A1.4FINALEXAM' },
    { value: 'A2', label: 'A2' },
    { value: 'A2P', label: 'A2P' },
    { value: 'A2.1', label: 'A2.1' },
    { value: 'a2.1', label: 'a2.1' },
    { value: 'A2.2', label: 'A2.2' },
    { value: 'A2.3', label: 'A2.3' },
    { value: 'A2.4', label: 'A2.4' },
    { value: 'A2.4FINALEXAM', label: 'A2.4FINALEXAM' },
    { value: 'B.2', label: 'B.2' },
    { value: 'B1', label: 'B1' },
    { value: 'B1EXAM', label: 'B1EXAM' },
    { value: 'B1.', label: 'B1.' },
    { value: 'B1.1', label: 'B1.1' },
    { value: 'b1.1', label: 'b1.1' },
    { value: 'B1.2', label: 'B1.2' },
    { value: 'B1.3', label: 'B1.3' },
    { value: 'b1.3', label: 'b1.3' },
    { value: 'B1.4', label: 'B1.4' },
    { value: 'B1.4FINALEXAM', label: 'B1.4FINALEXAM' },
    { value: 'B2', label: 'B2' },
    { value: 'b2', label: 'b2' },
    { value: 'B2EXAM', label: 'B2EXAM' },
    { value: 'B2-24', label: 'B2-24' },
    { value: 'B2-Final', label: 'B2-Final' },
    { value: 'B2.1', label: 'B2.1' },
    { value: 'b2.1', label: 'b2.1' },
    { value: 'B2.2', label: 'B2.2' },
    { value: 'B2.3', label: 'B2.3' },
    { value: 'B2.4', label: 'B2.4' },
    { value: 'B2.4FINALEXAM', label: 'B2.4FINALEXAM' },
    { value: 'B2C', label: 'B2C' },
    { value: 'B2c', label: 'B2c' },
    { value: 'BRASIL', label: 'BRASIL' },
    { value: 'BSR', label: 'BSR' },
    { value: 'C1', label: 'C1' },
    { value: 'C1.1', label: 'C1.1' },
    { value: 'C1.2', label: 'C1.2' },
    { value: 'C2.1', label: 'C2.1' },
    { value: 'EXAMEN', label: 'EXAMEN' },
    { value: 'EXAMENB1', label: 'EXAMENB1' },
    { value: 'ExamendeNivel', label: 'ExamendeNivel' },
    { value: 'IELTS', label: 'IELTS' },
    { value: 'IETLS', label: 'IETLS' },
    { value: 'NASH', label: 'NASH' },
    { value: 'NASHEMPLOYEE', label: 'NASHEMPLOYEE' },
    { value: 'NASHFamily', label: 'NASHFamily' },
    { value: 'NI', label: 'NI' },
    { value: 'NI-A', label: 'NI-A' },
    { value: 'NI-BP', label: 'NI-BP' },
    { value: 'NI-BS', label: 'NI-BS' },
    { value: 'NursingEnglish', label: 'NursingEnglish' },
    { value: 'ProgressExam1B1', label: 'ProgressExam1B1' },
    { value: 'QUICKASSESSMENT', label: 'QUICKASSESSMENT' },
    { value: 'TOEFL', label: 'TOEFL' },
    { value: 'TOEFLICIOUS', label: 'TOEFLICIOUS' },
  ];

  const handleTags = (newItems) => {
    setSelectedTags(newItems);
    setParametersFunc({
      tags: newItems.map((s) => s.value),
    });
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      spacing={3}
    >
      <Grid item md={5}>
        <Autocomplete
          multiple
          id="tags"
          options={tags}
          getOptionLabel={(option) => option.label}
          value={selectedTags}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={t('tags')}
            />
          )}
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              handleTags(newValue);
            } else if (newValue && newValue.inputValue) {
              handleTags(newValue.inputValue);
            } else {
              handleTags(newValue);
            }
          }}
        />
      </Grid>

      <Grid item md={7} />
    </Grid>
  );
}

BsrUsersParameters.propTypes = {
  defaultParameters: PropTypes.arrayOf(PropTypes.string).isRequired,
  setParametersFunc: PropTypes.func.isRequired,
};
export default BsrUsersParameters;
