// Soft UI Dashboard PRO React base styles
import colors from 'assets/theme/base/colors';

const { dark } = colors;

export default {
  styleOverrides: {
    root: {
      color: dark.main,
    },
  },
};
