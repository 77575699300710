import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Reducers
import { fetchAgreement, setAgreementEditing } from 'reducers/agreementsSlice';
import { fetchAgreementTypes } from 'reducers/commonSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import { setTitle } from 'Util';
import AgreementInfo from '../components/AgreementInfo';
import SavePanel from '../components/SavePanel';

// Functions

const selector = (state) => ({
  agreementInfo: state.agreement.agreementInfo,
});

function EditAgreement() {
  const dispatch = useDispatch();

  setTitle('Edit Agreement');

  const { agreementInfo } = useSelector(selector, shallowEqual);

  const { id } = useParams();
  useEffect(() => {
    dispatch(setAgreementEditing(true));
    dispatch(fetchAgreementTypes());
    dispatch(
      fetchAgreement({
        id,
      }),
    );
  }, [dispatch, id]);

  return (
    <BaseLayout>
      <SuiBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SuiBox mb={3}>
              {agreementInfo.name !== undefined
                && agreementInfo.name !== '' && <AgreementInfo />}
              <SavePanel />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </BaseLayout>
  );
}

export default EditAgreement;
