import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Reducer functions
import {
  fetchCandidatesDataview,
  // fetchCandidatesDataviewEducation,
  // fetchCandidatesDataviewEnglish,
  // fetchCandidatesDataviewProfile,
} from 'reducers/reportsSlice';
import { setCurrentView, setGoToViewSubView } from 'reducers/candidatesSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import Dialog from '@mui/material/Dialog';

// Layout components
import FormSelect from 'layouts/components/FormSelect';

// DevExtreme components
import DataGrid, {
  ColumnChooser,
  ColumnFixing,
  Editing,
  Export,
  HeaderFilter,
  Item,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Sorting,
  Toolbar,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.material.blue.light.css';
import { exportDataGrid } from 'devextreme/excel_exporter';

// Other components
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';

// Functions
import { renderColumns, setTitle } from 'Util';
import useWindowDimensions from 'layouts/components/Hooks/WindowDimensions';

// Columns config
import educationColumns from './columns/educationColumns';
import profileColumns from './columns/profileColumns';
import englishColumns from './columns/englishColumns';
import personalColumns from './columns/personalColumns';

const selector = (state) => ({
  candidateDataview: state.reports.candidateDataview,
});

function DataViewer() {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { candidateDataview } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  setTitle('Candidates Dataviewer Report');

  const [parentHeight, setParentHeight] = useState(500);
  const [fullScreen, setFullScreen] = useState(false);
  const [selected, setSelected] = useState({});
  const [data, setData] = useState([]);

  const { height } = useWindowDimensions();

  useEffect(() => {
    setParentHeight(height - (fullScreen ? 0 : 150));
  }, [fullScreen, height]);

  useEffect(() => {
    if (selected.value !== undefined) {
      dispatch(fetchCandidatesDataview({
        section: selected.value,
      }));
    }
  }, [selected, dispatch]);

  useEffect(() => {
    if (selected.value === 'profile' && candidateDataview.profile !== undefined) {
      setData(candidateDataview.profile);
    }
    if (selected.value === 'personal' && candidateDataview.personal !== undefined) {
      setData(candidateDataview.personal);
    }
    if (selected.value === 'english' && candidateDataview.english !== undefined) {
      setData(candidateDataview.english);
    }
    if (selected.value === 'education' && candidateDataview.education !== undefined) {
      setData(candidateDataview.education);
    }
  }, [candidateDataview, selected]);

  const sections = [
    {
      value: 'profile',
      label: t('profile'),
      file: 'CandidatesProfiles',
    },
    {
      value: 'personal',
      label: t('personal-info'),
      file: 'CandidatesPersonalInfo',
    },
    {
      value: 'education',
      label: t('education'),
      file: 'CandidatesEducation',
    },
    {
      value: 'english',
      label: t('english-level'),
      file: 'CandidatesEnglish',
    },
  ];

  const dataGrid = useRef(null);
  const allowedPageSizes = [10, 30, 50, 'all'];

  const handleSelect = (sec) => setSelected(sec);

  const handleEditing = (e) => {
    let viewSetup = {};
    switch (selected.value) {
      case 'profile':
        viewSetup = {
          view: 'profile',
          subview: 'basic-info',
        };
        break;
      case 'english':
        viewSetup = {
          view: 'profile',
          subview: 'language',
        };
        break;
      case 'personal':
        viewSetup = {
          view: 'profile',
          subview: 'personal-info',
        };
        break;
      case 'education':
        viewSetup = {
          view: 'profile',
          subview: 'education',
        };
        break;
      default:
        viewSetup = {
          view: 'profile',
          subview: 'basic-info',
        };
        break;
    }

    dispatch(setGoToViewSubView(viewSetup));
    dispatch(setCurrentView('profile'));
    navigate(`/candidates/edit/${e.key}`);
  };

  const handleExport = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(selected.file);

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${selected.file}.xlsx`);
      });
    });
  };

  const handleOpen = () => {
    setFullScreen(true);
  };

  const handleClose = () => {
    setFullScreen(false);
  };

  const renderTable = () => (
    <Card style={{ width: '100%' }}>
      <Paper>
        <DataGrid
          id="dataview"
          ref={dataGrid}
          dataSource={data}
          keyExpr="uuid"
          allowColumnReordering
          allowColumnResizing
          columnAutoWidth
          onEditingStart={handleEditing}
          onExporting={handleExport}
          height={parentHeight}
        >
          <ColumnChooser enabled mode="select" />
          <ColumnFixing enabled />
          <Editing
            mode="row"
            allowUpdating
            allowDeleting={false}
            allowAdding={false}
          />
          <Export enabled />
          <HeaderFilter visible />
          <Scrolling rowRenderingMode="virtual" />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={30} />
          <Pager
            visible
            allowedPageSizes={allowedPageSizes}
            displayMode="full"
            showPageSizeSelector
            showInfo
            showNavigationButtons
          />
          <SearchPanel
            visible
            width={240}
            placeholder={t('search', { keyPrefix: 'common' })}
          />
          <Toolbar>
            {!fullScreen && (
              <Item location="before">
                <IconButton aria-label="full-screen" onClick={handleOpen}>
                  <FullscreenIcon />
                </IconButton>
              </Item>
            )}
            <Item name="columnChooserButton" />
            <Item name="exportButton" />
            <Item name="searchPanel" />
          </Toolbar>
          {selected.value === 'profile' && renderColumns(profileColumns(t))}
          {selected.value === 'personal' && renderColumns(personalColumns(t))}
          {selected.value === 'english' && renderColumns(englishColumns(t))}
          {selected.value === 'education' && renderColumns(educationColumns(t))}
        </DataGrid>
      </Paper>
    </Card>
  );

  return (
    <Grid
      container
      direction="row"
      spacing={3}
    >
      <Grid item md={3}>
        <FormSelect
          label={t('section')}
          value={selected}
          options={sections}
          onChange={handleSelect}
        />
      </Grid>
      <Grid item md={9} />
      <Grid item md={12}>
        {renderTable()}
        <Dialog
          fullScreen
          open={fullScreen}
          onClose={handleClose}
        >
          {renderTable()}
        </Dialog>
      </Grid>
    </Grid>
  );
}

export default DataViewer;
