import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { setUserProfile, unlockCandidate, updateCandidates } from 'reducers/usersSlice';
import { updateUserCandidate } from 'reducers/candidatesSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';
import FormCheckbox from 'layouts/components/FormCheckbox';

// Functions
import { findSelectValue } from 'Util';
import 'assets/styles/advisors-drag.css';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const selector = (state) => ({
  userProfile: state.user.userProfile,
  candidates: state.candidate.candidatesOutlook,
  editing: state.user.editing,
  roles: state.role.roles,
});

function BasicInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'users' });
  const {
    userProfile,
    candidates,
    editing,
    roles,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [availableCandidates, setAvailableCandidates] = useState([]);
  const [noUserCandidates, setNoUserCandidates] = useState([]);
  const [assignedCandidates, setAssignedCandidates] = useState([]);

  useEffect(() => {
    if (userProfile.role_id !== 1 && userProfile.role_id !== 2) {
      if (userProfile.user_candidates !== undefined) {
        const assignedToSort = [...userProfile.user_candidates];
        setAssignedCandidates(assignedToSort.sort(
          // eslint-disable-next-line
          (a, b) => (
            a.label.toLowerCase() > b.label.toLowerCase())
            ? 1 : (a.label.toLowerCase() < b.label.toLowerCase()) ? -1 : 0,
        ));
        const availableToSort = [...candidates
          .filter((c) => !userProfile.user_candidates
            .some((ac) => c.value === ac.value))];
        setAvailableCandidates(
          availableToSort.sort(
            // eslint-disable-next-line
            (a, b) => (
              a.label.toLowerCase() > b.label.toLowerCase())
              ? 1 : (a.label.toLowerCase() < b.label.toLowerCase()) ? -1 : 0,
          ),
        );
      }
    }
    if (userProfile.role_id === 2) {
      setNoUserCandidates(
        candidates.filter((c) => (c.user_id === null || c.uuid === userProfile.candidate_uuid)),
      );
    }
    // eslint-disable-next-line
  }, [candidates, userProfile]);

  const setValue = (key, value) => {
    dispatch(
      setUserProfile(
        {
          ...userProfile,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  const handleResetPassword = () => {
    dispatch(
      setUserProfile(
        {
          ...userProfile,
          resetPassword: !userProfile.resetPassword,
          changed: true,
        },
      ),
    );
  };

  const handleAssignCandidate = (e) => {
    e.preventDefault();

    if (userProfile.candidate_uuid !== undefined) {
      dispatch(updateUserCandidate({
        candidateId: userProfile.candidate_uuid,
        uuid: userProfile.uuid,
      }));
    }
  };

  const handleCreateCandidateProfile = (e) => {
    e.preventDefault();
    setValue('create_candidate_profile', !userProfile.create_candidate_profile);
  };

  const handleAdvisorCandidate = (newAssigned) => {
    setAssignedCandidates(newAssigned);
    if (assignedCandidates.length > 0) {
      dispatch(updateCandidates({
        uuid: userProfile.uuid,
        candidates: newAssigned,
      }));
    }
  };

  const handleActive = () => {
    dispatch(
      setUserProfile(
        {
          ...userProfile,
          is_active: !userProfile.is_active,
          changed: true,
        },
      ),
    );
  };

  const handleUnlock = () => {
    dispatch(unlockCandidate({ uuid: userProfile.uuid }));
  };

  return (
    <>
      <Card id="basic-info" sx={{ overflow: 'visible' }}>
        <SuiBox p={3}>
          <SuiTypography variant="h5">{t('user-info')}</SuiTypography>
        </SuiBox>
        <SuiBox component="form" pb={3} px={3}>
          <Grid container alignItems="flex-start" spacing={3}>
            <Grid item md={12}>
              <FormField
                id="email"
                label="e-mail"
                value={userProfile.email !== undefined ? userProfile.email : ''}
                disabled={editing}
                onChange={(e) => {
                  setValue('email', e.target.value);
                }}
              />
            </Grid>
            {(!editing || userProfile.resetPassword) && (
              <Grid item md={12}>
                <FormField
                  id="password"
                  type="password"
                  label="password"
                  onChange={(e) => {
                    setValue('password', e.target.value);
                  }}
                />
              </Grid>
            )}
            <Grid item md={6}>
              <FormField
                id="first_name"
                label={t('first-name')}
                value={userProfile.first_name !== undefined ? userProfile.first_name : ''}
                onChange={(e) => {
                  setValue('first_name', e.target.value);
                }}
              />
            </Grid>
            <Grid item md={6}>
              <FormField
                id="last_name"
                label={t('last-name')}
                value={userProfile.last_name !== undefined ? userProfile.last_name : ''}
                onChange={(e) => {
                  setValue('last_name', e.target.value);
                }}
              />
            </Grid>
            <Grid item md={4}>
              <FormSelect
                label={t('role')}
                options={roles}
                value={findSelectValue(roles, userProfile.role_id)}
                onChange={(e) => {
                  setValue('role_id', e.value);
                }}
              />
            </Grid>
            <Grid item md={2}>
              {editing && (
                <SuiBox
                  display="flex"
                  alignItems="center"
                  lineHeight={1}
                  mt={3}
                >
                  <SuiTypography variant="caption" fontWeight="regular">
                    {userProfile.is_active ? t('active') : t('inactive')}
                  </SuiTypography>
                  <SuiBox mx={1}>
                    <Switch
                      checked={userProfile.is_active}
                      onChange={handleActive}
                    />
                  </SuiBox>
                </SuiBox>
              )}
            </Grid>
            <Grid item md={2}>
              {editing && (
                <SuiBox
                  display="flex"
                  alignItems="center"
                  lineHeight={1}
                  mt={3}
                >
                  <SuiButton
                    color={userProfile.locked ? 'error' : 'white'}
                    onClick={userProfile.locked ? handleUnlock : null}
                  >
                    {userProfile.locked ? t('locked') : t('unlocked')}
                  </SuiButton>
                </SuiBox>
              )}
            </Grid>
            <Grid item md={2} sx={{ ml: 'auto' }}>
              {editing && (
                <SuiBox
                  display="flex"
                  justifyContent={{ md: 'flex-end' }}
                  alignItems="center"
                  lineHeight={1}
                  mt={3}
                >
                  <SuiTypography variant="caption" fontWeight="regular">
                    {t('reset-password')}
                  </SuiTypography>
                  <SuiBox mx={1}>
                    <Switch
                      checked={userProfile.resetPassword}
                      onChange={handleResetPassword}
                    />
                  </SuiBox>
                </SuiBox>
              )}
            </Grid>
          </Grid>
          {(userProfile.role_id !== 1 && userProfile.role_id !== 2 && editing) && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              mt={5}
            >
              <Grid item md={5}>
                <SuiTypography variant="h6">{t('available')}</SuiTypography>
              </Grid>
              <Grid item md={7} />
              <Grid item md={12}>
                <Autocomplete
                  multiple
                  id="candidates"
                  options={availableCandidates}
                  isOptionEqualToValue={(option, newValue) => option.value === newValue.value}
                  getOptionLabel={(option) => option.label}
                  value={assignedCandidates}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={t('assigned')}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      handleAdvisorCandidate(newValue);
                    } else if (newValue && newValue.inputValue) {
                      handleAdvisorCandidate(newValue.inputValue);
                    } else {
                      handleAdvisorCandidate(newValue);
                    }
                  }}
                />
              </Grid>
            </Grid>
          )}
          {(userProfile.role_id === 2 && editing) && (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              mt={5}
            >
              <Grid item md={4}>
                <FormSelect
                  label={t('candidate')}
                  options={noUserCandidates}
                  value={findSelectValue(noUserCandidates, userProfile.candidate_uuid)}
                  onChange={(e) => {
                    setValue('candidate_uuid', e.value);
                  }}
                />
              </Grid>
              <Grid item md={1} />
              <Grid item md={4} mt={3}>
                <SuiButton
                  variant="outlined"
                  color="info"
                  size="small"
                  onClick={handleAssignCandidate}
                >
                  {t('assign', { keyPrefix: 'common' })}
                </SuiButton>
              </Grid>
            </Grid>
          )}
          {(userProfile.role_id === 2 && !editing) && (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              mt={5}
            >
              <Grid item md={4}>
                <FormCheckbox
                  label={t('create-candidate-profile')}
                  checked={userProfile.create_candidate_profile}
                  onClick={handleCreateCandidateProfile}
                />
              </Grid>
            </Grid>
          )}
        </SuiBox>
      </Card>
      <Divider />
    </>
  );
}

export default BasicInfo;
