import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer Functions
import { fetchCandidatesContracts } from 'reducers/reportsSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Functions
import { setTitle } from 'Util';

// Components
import BaseTable from 'layouts/reports/components/BaseTable';

const selector = (state) => ({
  candidatesContracts: state.reports.candidatesContracts,
});

function Agreements() {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const {
    candidatesContracts,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  setTitle('Candidates Agreements Report');

  useEffect(() => {
    dispatch(fetchCandidatesContracts());
  }, [dispatch]);

  const handleDateFormat = (d) => {
    if (d !== null) {
      return Date.parse(d);
    }
    return null;
  };

  const columns = [
    {
      caption: t('first-name', { keyPrefix: 'common' }),
      field: 'first_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('last-name', { keyPrefix: 'common' }),
      field: 'last_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('advisor'),
      field: 'current_advisor_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('agreement'),
      field: 'agreement_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('signed'),
      field: 'signed',
      calculateCellValue: (d) => (d.signed ? t('yes', { keyPrefix: 'common' }) : t('no', { keyPrefix: 'common' })),
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('assigned-on'),
      field: 'assigned_on',
      calculateCellValue: (d) => handleDateFormat(d.assigned_on),
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('days-assigned'),
      field: 'days_assigned',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('signed-on'),
      field: 'signed_on',
      calculateCellValue: (d) => handleDateFormat(d.signed_on),
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('days-signed'),
      field: 'days_signed',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('expires-on'),
      field: 'expires_on',
      calculateCellValue: (d) => handleDateFormat(d.expires_on),
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('expires-in'),
      field: 'expires_in',
      visible: true,
      allowFixing: true,
    },
  ];

  return (
    <Grid
      container
      direction="row"
      spacing={2}
    >
      <Grid item md={12}>
        <BaseTable name="candidates-contracts" data={candidatesContracts} keyExpr="uuid" columns={columns} />
      </Grid>
    </Grid>
  );
}

export default Agreements;
