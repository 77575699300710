import {
  createCandidateExperience,
  deleteCandidateExperience,
  setErrors,
  setFailed,
  updateCandidateExperience,
} from 'reducers/candidatesSlice';
import {
  invalidRecords, modifiedRecords,
} from 'Util';

// eslint-disable-next-line import/prefer-default-export
export const saveExperience = (t, dispatch, experience, candidateId) => {
  if (!invalidRecords(experience).length > 0) {
    const experiences = experience.filter((item) => item.changed === true);
    if (experiences.length > 0) {
      modifiedRecords(experience).forEach((e) => {
        const data = {
          candidateId,
          company: e.company,
          jobTitle: e.job_title,
          from: e.from,
          to: e.to,
          current: e.current,
          address: e.address,
          postalCode: e.postal_code,
          cityId: e.city_id,
          stateId: e.state_id,
          countryId: e.country_id,
          description: e.description,
          clinicalExperiences: e.clinical_experiences,
          workType: e.work_type,
          supervisorName: e.supervisor_name,
          supervisorTitle: e.supervisor_title,
          supervisorEmail: e.supervisor_email,
          supervisorPhone: e.supervisor_phone,
        };

        // eslint-disable-next-line no-underscore-dangle
        if (e._destroy === true) {
          dispatch(deleteCandidateExperience({ id: e.id, candidateId }));
        } else if (typeof e.id !== 'undefined') {
          data.id = e.id;
          dispatch(updateCandidateExperience(data));
        } else {
          dispatch(createCandidateExperience(data));
        }
      });
    } else {
      dispatch(setErrors(t('no-changes-to-save', { keyPrefix: 'common' })));
      dispatch(setFailed(true));
    }
  } else {
    dispatch(setErrors(t('entry-is-required-or-has-an-invalid-value', { keyPrefix: 'common' })));
    dispatch(setFailed(true));
  }
};
