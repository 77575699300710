import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Divider from '@mui/material/Divider';

import SuiTypography from 'components/SuiTypography';
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';

import CounterHeaderCard from 'layouts/components/Cards/HeaderCards/CounterHeaderCard';
import CandidateRecruitmentCard
  from 'layouts/components/Cards/CandidateCards/CandidateRecruitmentCard';

function PositionCandidates() {
  const { t } = useTranslation('translation', { keyPrefix: 'position-listings' });

  return (
    <Card id="position-candidates" sx={{ overflow: 'visible' }}>
      <SuiBox component="form" p={3} pb={3} px={3}>
        <Grid
          container
          direction="row"
          spacing={1}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Grid item md={9}>
            <SuiTypography variant="h5">{t('candidates')}</SuiTypography>
          </Grid>
          <Grid item md={3}>
            <Grid
              container
              direction="row"
              sx={{
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Grid item>
                <SuiButton
                  variant="gradient"
                  color="info"
                  size="small"
                  // onClick={handleNew}
                >
                  <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
                  {` ${t('add-candidate')}`}
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={2}>
            <CounterHeaderCard
              title={t('sourced')}
              counter="4"
              color="#f7ac3b"
            />
          </Grid>
          <Grid item md={2}>
            <CounterHeaderCard
              title={t('screening')}
              counter="4"
              color="#3f63f2"
            />
          </Grid>
          <Grid item md={2}>
            <CounterHeaderCard
              title={t('interview')}
              counter="2"
              color="#8436d1"
            />
          </Grid>
          <Grid item md={2}>
            <CounterHeaderCard
              title={t('offered')}
              counter="2"
              color="#28a4c9"
            />
          </Grid>
          <Grid item md={2}>
            <CounterHeaderCard
              title={t('hired')}
              counter="1"
              color="#2d9e29"
            />
          </Grid>
          <Grid item md={2}>
            <CounterHeaderCard
              title={t('rejected')}
              counter="5"
              color="#c9283e"
            />
          </Grid>
          <Grid item md={2}>
            <CandidateRecruitmentCard
              name="John Doe"
              country="Colombia"
            />
            <Divider />
            <CandidateRecruitmentCard
              name="John Doe"
              country="Colombia"
            />
            <Divider />
            <CandidateRecruitmentCard
              name="John Doe"
              country="Colombia"
            />
            <Divider />
            <CandidateRecruitmentCard
              name="John Doe"
              country="Colombia"
            />
          </Grid>
          <Grid item md={2}>
            <CandidateRecruitmentCard
              name="John Doe"
              country="Colombia"
            />
            <Divider />
            <CandidateRecruitmentCard
              name="John Doe"
              country="Colombia"
            />
            <Divider />
            <CandidateRecruitmentCard
              name="John Doe"
              country="Colombia"
            />
            <Divider />
            <CandidateRecruitmentCard
              name="John Doe"
              country="Colombia"
            />
          </Grid>
          <Grid item md={2}>
            <CandidateRecruitmentCard
              name="John Doe"
              country="Colombia"
            />
            <Divider />
            <CandidateRecruitmentCard
              name="John Doe"
              country="Colombia"
            />
          </Grid>
          <Grid item md={2}>
            <CandidateRecruitmentCard
              name="John Doe"
              country="Colombia"
            />
            <Divider />
            <CandidateRecruitmentCard
              name="John Doe"
              country="Colombia"
            />
          </Grid>
          <Grid item md={2}>
            <CandidateRecruitmentCard
              name="John Doe"
              country="Colombia"
            />
          </Grid>
          <Grid item md={2}>
            <CandidateRecruitmentCard
              name="John Doe"
              country="Colombia"
            />
            <Divider />
            <CandidateRecruitmentCard
              name="John Doe"
              country="Colombia"
            />
            <Divider />
            <CandidateRecruitmentCard
              name="John Doe"
              country="Colombia"
            />
            <Divider />
            <CandidateRecruitmentCard
              name="John Doe"
              country="Colombia"
            />
            <Divider />
            <CandidateRecruitmentCard
              name="John Doe"
              country="Colombia"
            />
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default PositionCandidates;
