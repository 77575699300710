import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Reducer functions
import {
  createPaymentMethod,
  deletePaymentMethod,
  setFailed,
  setSucceeded,
  updatePaymentMethod,
} from 'reducers/paymentMethodSlice';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import Swal from 'sweetalert2';

const selector = (state) => ({
  paymentMethodInfo: state.paymentMethod.paymentMethodInfo,
  editing: state.paymentMethod.editing,
  succeeded: state.paymentMethod.succeeded,
  failed: state.paymentMethod.failed,
  errors: state.paymentMethod.errors,
});

function SavePanel() {
  const {
    paymentMethodInfo, editing, succeeded, failed, errors,
  } = useSelector(
    selector,
    shallowEqual,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  if (failed === true) {
    dispatch(setFailed(false));
    const errMsg = errors.join('; ');

    Swal.fire({
      title: 'Error',
      text: `${errMsg}`,
      icon: 'error',
      confirmButtonText: 'Close',
    });
  }

  if (succeeded === true) {
    dispatch(setSucceeded(false));

    Swal.fire({
      text: `Payment Method ${editing ? 'edited' : 'created'} successfully`,
      icon: 'success',
      confirmButtonText: 'Close',
    }).then(() => {
      navigate('/payment-methods', { replace: true });
    });
  }

  const callUpdatePaymentMethod = async (data) => {
    await dispatch(updatePaymentMethod(data));
  };

  const callCreatePaymentMethod = async (data) => {
    await dispatch(createPaymentMethod(data));
  };

  const callDeletePaymentMethod = async (sId) => {
    await dispatch(
      deletePaymentMethod({
        id: sId,
      }),
    );
  };

  const handleDelete = () => {
    const alert = Swal.mixin({
      customClass: {
        confirmButton: 'button button-success',
        cancelButton: 'button button-error',
      },
      buttonsStyling: false,
    });

    alert
      .fire({
        title: 'Are you sure?',
        text: `Are you sure you want to delete "${paymentMethodInfo.name}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
      })
      .then((result) => {
        if (result.isConfirmed) {
          callDeletePaymentMethod(id).then(() => {
            Swal.fire('Deleted!', '', 'success').then(() => {
              navigate('/payment-methods', { replace: true });
            });
          });
        }
      });
  };

  const handleSave = () => {
    if (paymentMethodInfo.changed === true) {
      const data = {
        name: paymentMethodInfo.name,
      };

      if (editing === true) {
        data.id = id;

        callUpdatePaymentMethod(data);
      } else {
        callCreatePaymentMethod(data);
      }
    }
  };

  return (
    <SuiBox mt={5} mb={3}>
      <SuiBox display="flex" justifyContent="flex-end" mt={6}>
        {editing && (
          <SuiBox mr={1}>
            <SuiButton
              variant="gradient"
              color="error"
              size="small"
              onClick={handleDelete}
            >
              delete
            </SuiButton>
          </SuiBox>
        )}
        <SuiBox mr={1}>
          <SuiButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleSave}
          >
            save
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default SavePanel;
