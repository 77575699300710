import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Reducers functions
import { setCurrentSubView, setFailed, setSaved } from 'reducers/candidatesSlice';
import { clearBsr } from 'reducers/bsrSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Functions
import { failedCandidateMessage, savedCandidateMessage } from 'Util';

// Components
import FindUser from './components/FindUser';
import UserInfo from './components/UserInfo';

const selector = (state) => ({
  saved: state.candidate.saved,
  failed: state.candidate.failed,
  errors: state.candidate.errors,
});

function Bsr({
  uuid,
  bsrUserId,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.bsr' });
  const {
    saved,
    failed,
    errors,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentSubView('bsr'));
    dispatch(clearBsr());
  }, [dispatch]);

  if (saved) {
    dispatch(setSaved(false));
    savedCandidateMessage(t);
  }
  if (failed) {
    dispatch(setFailed(false));
    failedCandidateMessage(t, errors);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        {bsrUserId === null && (
          <FindUser uuid={uuid} />
        )}
        {bsrUserId !== null && (
          <UserInfo id={bsrUserId} />
        )}
      </Grid>
    </Grid>
  );
}

Bsr.defaultProps = {
  bsrUserId: null,
};

Bsr.propTypes = {
  uuid: PropTypes.string.isRequired,
  bsrUserId: PropTypes.string,
};
export default Bsr;
