import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import PayeeService from 'services/payee.service';
import { pendingReducer, rejectionReducer } from 'Util';

export const fetchPayees = createAsyncThunk(
  'payee/fetchPayees',
  async (payload, { rejectWithValue }) => {
    try {
      return await PayeeService.getPayees();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchPayee = createAsyncThunk(
  'payee/fetchPayee',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await PayeeService.getPayee(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createPayee = createAsyncThunk(
  'payee/createPayee',
  async (payload, { rejectWithValue }) => {
    const { name, type } = payload;
    try {
      return await PayeeService.createPayee(name, type);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updatePayee = createAsyncThunk(
  'payee/updatePayee',
  async (payload, { rejectWithValue }) => {
    const { id, name, type } = payload;
    try {
      return await PayeeService.updatePayee(id, name, type);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deletePayee = createAsyncThunk(
  'payee/deletePayee',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await PayeeService.deletePayee(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

const fulfilledReducer = (state) => {
  // eslint-disable-next-line no-param-reassign
  state.status = 'succeeded';
};

const generateDropboxProperties = ({ payload }) => payload.map((item) => ({
  ...item,
  value: item.id,
  label: item.name,
}));

/* eslint-disable no-param-reassign */
export const payeeSlice = createSlice({
  name: 'payee',
  initialState: {
    payeeInfo: {},
    payees: [],
    message: '',
    errors: [],
    editing: false,
    succeeded: false,
  },
  reducers: {
    clearPayee: (state) => {
      state.message = '';
      state.errors = [];
      state.editing = false;
      state.succeeded = false;
      state.payeeInfo = {};
    },
    setPayeeEditing: (state, action) => {
      state.editing = action.payload;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setPayeeInfo: (state, action) => {
      state.payeeInfo = action.payload;
    },
  },
  extraReducers: (builder) => builder
    .addMatcher(
      (action) => action.type.endsWith('/rejected'),
      rejectionReducer,
    )
    .addMatcher((action) => action.type.endsWith('/pending'), pendingReducer)
    .addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, action) => {
        const performedAction = action.type.split('/');

        if (performedAction[0] === 'payee') {
          switch (performedAction[1]) {
            case 'fetchPayees':
              fulfilledReducer(state, action, true);
              state.payees = generateDropboxProperties(action);
              break;
            case 'fetchPayee':
              fulfilledReducer(state, action);
              state.payeeInfo = action.payload;
              break;
            case 'createPayee':
              fulfilledReducer(state, action);
              state.succeeded = true;
              break;
            case 'updatePayee':
              fulfilledReducer(state, action);
              state.succeeded = true;
              break;
            default:
              fulfilledReducer(state, action);
              state.message = action.payload;
              break;
          }
        }
      },
    ),
});

/* eslint-disable no-param-reassign */
export const {
  clearPayee,
  setPayeeEditing,
  setSucceeded,
  setFailed,
  setPayeeInfo,
} = payeeSlice.actions;

export default payeeSlice.reducer;
