import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// @mui material components
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Components
import FormField from 'layouts/components/FormField';
import DefaultDocumentCard from '../../DocumentCards/DefaultDocumentCard';

function DefaultClientContractCard({
  i, c, setValue, removeFunc, uploadFunc,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'clients' });
  const [expanded, setExpanded] = useState(true);
  const [contractMenu, setContractMenu] = useState(null);

  const openContractMenu = (event) => setContractMenu(event.currentTarget);
  const closeContractMenu = () => setContractMenu(null);
  const handleExpand = () => {
    closeContractMenu();
    setExpanded(!expanded);
  };

  return (
    <Card id="contracts" sx={{ overflow: 'visible' }}>
      <CardHeader
        action={(
          <IconButton onClick={openContractMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        title={`${t('contract')} ${i + 1}`}
      />
      <Menu
        anchorEl={contractMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(contractMenu)}
        onClose={closeContractMenu}
        keepMounted
      >
        <MenuItem
          onClick={handleExpand}
        >
          {expanded
            ? t('collapse', { keyPrefix: 'common' })
            : t('expand', { keyPrefix: 'common' })}
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeFunc(i);
          }}
        >
          {t('remove', { keyPrefix: 'common' })}
        </MenuItem>
      </Menu>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3}>
              <FormField
                label={t('start-date')}
                type="date"
                onChange={(event) => {
                  setValue('start_date', event.target.value);
                }}
                value={c.start_date}
              />
            </Grid>
            <Grid item md={3}>
              <FormField
                label={t('expiration-date')}
                type="date"
                onChange={(event) => {
                  setValue('expiration_date', event.target.value);
                }}
                value={c.expiration_date}
              />
            </Grid>
            {c.file_url !== undefined && (
              <Grid item md={12}>
                <DefaultDocumentCard
                  title={t('contract-document')}
                  expanded={false}
                  uploadedSub={c.file_url !== undefined && c.file_url !== null}
                  candidateId={c.id}
                  changeFunc={uploadFunc}
                  fileUrl={c.file_url}
                  id={c.id}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
}

DefaultClientContractCard.propTypes = {
  i: PropTypes.number.isRequired,
  c: PropTypes.shape({
    id: PropTypes.number,
    start_date: PropTypes.string,
    expiration_date: PropTypes.string,
    file_url: PropTypes.string,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
  uploadFunc: PropTypes.func.isRequired,
};

export default DefaultClientContractCard;
