import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Functions
import { findSelectValue } from 'Util';

// @mui material components
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

const selector = (state) => ({
  candidateStatuses: state.common.candidateStatuses,
});

function EnglishLearningSpanParameters({ defaultParameters, setParametersFunc }) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { candidateStatuses } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [selectedCandidateStatuses, setSelectedCandidateStatuses] = useState([]);

  useEffect(() => {
    if (defaultParameters !== undefined) {
      const { statuses } = defaultParameters;
      const sel = [];
      if (statuses === null) {
        if (candidateStatuses !== undefined) {
          candidateStatuses
            .forEach((s) => sel.push(findSelectValue(candidateStatuses, s.id)));
          setSelectedCandidateStatuses(sel);
        }
      } else if (statuses !== undefined) {
        statuses
          .forEach((id) => sel.push(findSelectValue(candidateStatuses, id)));
        setSelectedCandidateStatuses(sel);
      }
    }
  }, [dispatch, defaultParameters, candidateStatuses]);

  const handleCandidateStatuses = (newItems) => {
    setSelectedCandidateStatuses(newItems);
    setParametersFunc({
      statuses: newItems.map((s) => s.id),
    });
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      spacing={3}
    >
      <Grid item md={5}>
        <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
          <SuiBox mb={0.5} ml={0.5} lineHeight={0} display="inline-block">
            <SuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {t('candidate-statuses')}
            </SuiTypography>
          </SuiBox>
          <Autocomplete
            multiple
            id="candidate-statuses"
            options={candidateStatuses}
            getOptionLabel={(option) => option.label}
            value={selectedCandidateStatuses}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('candidate-statuses')}
              />
            )}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                handleCandidateStatuses(newValue);
              } else if (newValue && newValue.inputValue) {
                handleCandidateStatuses(newValue.inputValue);
              } else {
                handleCandidateStatuses(newValue);
              }
            }}
          />
        </SuiBox>
      </Grid>
      <Grid item md={7} />
    </Grid>
  );
}

EnglishLearningSpanParameters.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  defaultParameters: PropTypes.any.isRequired,
  setParametersFunc: PropTypes.func.isRequired,
};

export default EnglishLearningSpanParameters;
