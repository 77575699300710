import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// @mui material components
import Link from '@mui/material/Link';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Soft UI Dashboard PRO React base styles
import typography from 'assets/theme/base/typography';

function Footer({ company, links }) {
  const { t } = useTranslation('translation', { keyPrefix: 'footer' });
  const { href, name } = company;
  const { size } = typography;

  const renderLinks = (tt) => links.map((link) => (
    <SuiBox key={link.key} component="li" px={2} lineHeight={1}>
      <Link href={link.href} target="_blank">
        <SuiTypography variant="button" fontWeight="regular" color="text">
          {tt(link.name)}
        </SuiTypography>
      </Link>
    </SuiBox>
  ));

  return (
    <SuiBox
      width="100%"
      display="flex"
      flexDirection={{ xs: 'column', lg: 'row' }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <SuiBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        {`Copyright © ${new Date().getFullYear()},`}
        &nbsp;
        <Link href={href} target="_blank">
          <SuiTypography variant="button" fontWeight="medium">
            {`${name}.`}
          </SuiTypography>
        </Link>
        &nbsp;
        {t('all-rights-reserved')}
      </SuiBox>
      <SuiBox
        component="ul"
        sx={({ breakpoints }) => ({
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center',
          listStyle: 'none',
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up('lg')]: {
            mt: 0,
          },
        })}
      >
        {renderLinks(t)}
      </SuiBox>
    </SuiBox>
  );
}

// Setting admin values for the props of Footer
Footer.defaultProps = {
  company: { href: 'https://www.nursing-advisory.com/', name: 'Nursing Advisory' },
  links: [
    { key: 1, href: 'https://www.nursing-advisory.com/', name: 'nursing-advisory' },
    { key: 2, href: 'https://www.nursing-advisory.com/about/', name: 'about-us' },
    { key: 3, href: 'https://www.nursing-advisory.com/news/', name: 'blog' },
  ],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
