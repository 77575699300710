import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// Reducers
import { fetchService, setServiceEditing } from 'reducers/servicesSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import ServiceInfo from '../components/ServiceInfo';
import SavePanel from '../components/SavePanel';

function EditService() {
  const dispatch = useDispatch();

  const { id } = useParams();
  useEffect(() => {
    dispatch(setServiceEditing(true));
    dispatch(
      fetchService({
        id,
      }),
    );
  }, [dispatch, id]);

  return (
    <BaseLayout>
      <SuiBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SuiBox mb={3}>
              <ServiceInfo />
              <SavePanel />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </BaseLayout>
  );
}

export default EditService;
