import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

// Reducer functions
import { setVendorInfo } from 'reducers/vendorSlice';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiEditor from 'components/SuiEditor';

// Components
import FormField from 'layouts/components/FormField';

// Functions
import { defaultValue } from 'Util';
import Contacts from '../Contacts';

const selector = (state) => ({
  vendorInfo: state.vendor.vendorInfo,
  editing: state.vendor.editing,
});

function BasicInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'vendors' });
  const { vendorInfo, editing } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [tabValue, setTabValue] = useState(0);

  const setValue = (key, value) => {
    dispatch(setVendorInfo(
      {
        ...vendorInfo,
        [key]: value,
        changed: true,
      },
    ));
  };

  const setContacts = (contacts) => {
    setValue('vendor_contacts', contacts);
  };

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Tabs value={tabValue} onChange={handleSetTabValue}>
        <Tab label={t('vendor-info')} />
        {editing && <Tab label={t('contacts')} />}
      </Tabs>
      {tabValue === 0 && (
        <Card id="vendor-info" sx={{ overflow: 'visible' }}>
          <SuiBox p={3}>
            <SuiTypography variant="h5">{t('vendor')}</SuiTypography>
          </SuiBox>
          <SuiBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <FormField
                  label={t('name')}
                  value={defaultValue(vendorInfo.name)}
                  error={!vendorInfo.name || vendorInfo.name === ''}
                  onChange={(e) => setValue('name', e.target.value)}
                />
              </Grid>
              <Grid item md={4}>
                <FormField
                  label={t('business-id')}
                  value={defaultValue(vendorInfo.business_id)}
                  onChange={(e) => setValue('business_id', e.target.value)}
                />
              </Grid>
              <Grid item md={4}>
                <FormField
                  label={t('payment-term')}
                  value={defaultValue(vendorInfo.payment_term)}
                  onChange={(e) => setValue('payment_term', e.target.value)}
                />
              </Grid>
              <Grid item md={4}>
                <FormField
                  label={t('rate')}
                  value={defaultValue(vendorInfo.rate)}
                  onChange={(e) => setValue('rate', e.target.value)}
                />
              </Grid>
              <Grid item md={12}>
                <div>
                  <SuiEditor
                    value={vendorInfo.notes}
                    onChange={(e) => setValue('notes', e)}
                  />
                </div>
              </Grid>
            </Grid>
          </SuiBox>
        </Card>
      )}
      {tabValue === 1 && (
        <Card id="vendor-contacts" sx={{ overflow: 'visible' }}>
          <Contacts contacts={vendorInfo.vendor_contacts} setValue={setContacts} />
        </Card>
      )}
    </div>
  );
}

export default BasicInfo;
