import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import PaymentPlanService from '../services/paymentplan.service';
import {
  fulfilledCreatedReducer,
  fulfilledReducer,
  fulfilledSavedReducer,
  generateDropboxProperties,
  pendingReducer,
  rejectionReducer,
} from '../Util';

export const fetchPaymentPlans = createAsyncThunk(
  'plan/fetchPaymentPlans',
  async (payload, { rejectWithValue }) => {
    try {
      return await PaymentPlanService.getPaymentPlans();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchPaymentPlan = createAsyncThunk(
  'plan/fetchPaymentPlan',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await PaymentPlanService.getPaymentPlan(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createPaymentPlan = createAsyncThunk(
  'plan/createPaymentPlan',
  async (payload, { rejectWithValue }) => {
    const {
      name,
      hasDownPayment,
      downPaymentAmount,
      downPaymentType,
      hasDeferredPayment,
      deferredAmount,
      deferredType,
      paymentNumber,
      paymentFrequency,
      enabled,
    } = payload;
    try {
      return await PaymentPlanService.createPaymentPlan(
        name,
        hasDownPayment,
        downPaymentAmount,
        downPaymentType,
        hasDeferredPayment,
        deferredAmount,
        deferredType,
        paymentNumber,
        paymentFrequency,
        enabled,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updatePaymentPlan = createAsyncThunk(
  'plan/updatePaymentPlan',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      name,
      hasDownPayment,
      downPaymentAmount,
      downPaymentType,
      hasDeferredPayment,
      deferredAmount,
      deferredType,
      paymentNumber,
      paymentFrequency,
      enabled,
    } = payload;
    try {
      return await PaymentPlanService.updatePaymentPlan(
        id,
        name,
        hasDownPayment,
        downPaymentAmount,
        downPaymentType,
        hasDeferredPayment,
        deferredAmount,
        deferredType,
        paymentNumber,
        paymentFrequency,
        enabled,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deletePaymentPlan = createAsyncThunk(
  'plan/deletePaymentPlan',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await PaymentPlanService.deletePaymentPlan(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

/* eslint-disable no-param-reassign */
export const paymentPlansSlice = createSlice({
  name: 'plan',
  initialState: {
    paymentPlanInfo: {},
    paymentPlans: [],
    message: '',
    errors: [],
    editing: false,
    created: false,
    saved: false,
    deleted: false,
    failed: false,
    succeeded: false,
  },
  reducers: {
    clearPaymentPlan: (state) => {
      state.message = '';
      state.errors = [];
      state.editing = false;
      state.succeeded = false;
      state.created = false;
      state.saved = false;
      state.deleted = false;
      state.failed = false;
      state.removeData = [];
      state.paymentPlanInfo = {
        name: '',
        has_down_payment: false,
        down_payment_amount: 0,
        down_payment_type: undefined,
        has_deferred_payment: false,
        deferred_amount: 0,
        deferred_type: undefined,
        payment_number: 0,
        payment_frequency: undefined,
        enabled: true,
      };
    },
    setPaymentPlanEditing: (state, action) => {
      state.editing = action.payload;
    },
    setCreated: (state, action) => {
      state.created = action.payload;
    },
    setSaved: (state, action) => {
      state.saved = action.payload;
    },
    setDeleted: (state, action) => {
      state.deleted = action.payload;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setPaymentPlanInfo: (state, action) => {
      state.paymentPlanInfo = action.payload;
    },
  },
  extraReducers: (builder) => builder
    .addMatcher(
      (action) => action.type.endsWith('/rejected'),
      rejectionReducer,
    )
    .addMatcher((action) => action.type.endsWith('/pending'), pendingReducer)
    .addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, action) => {
        const performedAction = action.type.split('/');
        if (performedAction[0] === 'plan') {
          switch (performedAction[1]) {
            case 'fetchPaymentPlans':
              fulfilledReducer(state, action);
              state.paymentPlans = generateDropboxProperties(action);
              break;
            case 'fetchPaymentPlan':
              fulfilledReducer(state, action);
              state.paymentPlanInfo = action.payload;
              break;
            case 'createPaymentPlan':
              fulfilledCreatedReducer(state, action);
              break;
            case 'updatePaymentPlan':
            case 'deletePaymentPlan':
              fulfilledSavedReducer(state, action);
              break;
            default:
              fulfilledReducer(state, action);
              state.message = action.payload;
              break;
          }
        }
      },
    ),
});
/* eslint-disable no-param-reassign */

export const {
  clearPaymentPlan,
  setPaymentPlanEditing,
  setCreated,
  setSaved,
  setFailed,
  setDeleted,
  setSucceeded,
  setPaymentPlanInfo,
} = paymentPlansSlice.actions;

export default paymentPlansSlice.reducer;
