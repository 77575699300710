import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { fetchCandidatesPaymentPlans } from 'reducers/reportsSlice';

// Components
import BaseTable from 'layouts/reports/components/BaseTable';

// Functions
import { setTitle } from 'Util';

const selector = (state) => ({
  candidatesPaymentPlans: state.reports.candidatesPaymentPlans,
});

function PaymentPlans() {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { candidatesPaymentPlans } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  setTitle('Payments Plans Report');

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(fetchCandidatesPaymentPlans());
  }, [dispatch]);

  useEffect(() => {
    if (candidatesPaymentPlans !== undefined) {
      setData(candidatesPaymentPlans);
    }
  }, [candidatesPaymentPlans]);

  const columns = [
    {
      caption: t('candidate-name'),
      field: 'candidate_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('status'),
      field: 'status_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('advisor'),
      field: 'advisor_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('country'),
      field: 'country_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('payment-plan'),
      field: 'payment_plan',
      visible: true,
      allowFixing: true,
    },
  ];

  return (
    <BaseTable name="CandidatesPaymentPlans" data={data} keyExpr="uuid" columns={columns} />
  );
}

export default PaymentPlans;
