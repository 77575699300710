function personalColumns(t) {
  return (
    [
      {
        caption: t('candidate-name'),
        field: 'candidate_name',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('status'),
        field: 'candidate_status',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('advisor'),
        field: 'advisor_name',
        visible: false,
        allowFixing: true,
      },
      {
        caption: `${t('weight', { keyPrefix: 'candidates.personal-info' })} *lbs`,
        field: 'weight',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('hair-color', { keyPrefix: 'candidates.personal-info' }),
        field: 'hair_color',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('eye-color', { keyPrefix: 'candidates.personal-info' }),
        field: 'eye_color',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('race', { keyPrefix: 'candidates.personal-info' }),
        field: 'race',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('willing-vaccine-covid', { keyPrefix: 'candidates.personal-info' }),
        field: 'willing_covid_vaccine',
        dataType: 'string',
        calculateCellValue: (d) => (d.willing_covid_vaccine ? 'Yes' : 'No'),
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('covid-vaccine', { keyPrefix: 'candidates.personal-info' }),
        field: 'covid_vaccine',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('willing-vaccine-influenza', { keyPrefix: 'candidates.personal-info' }),
        field: 'willing_influenza_vaccine',
        dataType: 'string',
        calculateCellValue: (d) => (d.willing_influenza_vaccine ? 'Yes' : 'No'),
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('currently-in-us', { keyPrefix: 'candidates.personal-info' }),
        field: 'currently_in_us',
        dataType: 'string',
        calculateCellValue: (d) => (d.currently_in_us ? 'Yes' : 'No'),
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('current-immigration-status', { keyPrefix: 'candidates.personal-info' }),
        field: 'current_immigration_status',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('asylum-approved', { keyPrefix: 'candidates.personal-info' }),
        field: 'asylum_approved',
        dataType: 'string',
        calculateCellValue: (d) => (d.asylum_approved ? 'Yes' : 'No'),
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('issued-ead', { keyPrefix: 'candidates.personal-info' }),
        field: 'issued_ead',
        dataType: 'string',
        calculateCellValue: (d) => (d.issued_ead ? 'Yes' : 'No'),
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('issued-ssn', { keyPrefix: 'candidates.personal-info' }),
        field: 'issued_ssn',
        dataType: 'string',
        calculateCellValue: (d) => (d.issued_ssn ? 'Yes' : 'No'),
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('legally-married', { keyPrefix: 'candidates.personal-info' }),
        field: 'legally_married',
        dataType: 'string',
        calculateCellValue: (d) => (d.legally_married ? 'Yes' : 'No'),
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('spouse-name', { keyPrefix: 'candidates.personal-info' }),
        field: 'spouse_name',
        dataType: 'string',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('immigration-issues', { keyPrefix: 'candidates.personal-info' }),
        field: 'immigration_issues',
        dataType: 'string',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('passport-number', { keyPrefix: 'candidates.personal-info' }),
        field: 'passport_number',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('issuing-country', { keyPrefix: 'candidates.personal-info' }),
        field: 'country',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('date-of-issue', { keyPrefix: 'candidates.personal-info' }),
        field: 'passport_date_issue',
        dataType: 'date',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('date-of-expiration', { keyPrefix: 'candidates.personal-info' }),
        field: 'passport_date_expiration',
        dataType: 'date',
        visible: true,
        allowFixing: true,
      },
    ]
  );
}

export default personalColumns;
