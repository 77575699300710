import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';

// Reducer functions
import { fetchCandidateStatuses } from 'reducers/commonSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import { AccordionActions } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Soft UI Dashboard PRO React components
import SuiButton from 'components/SuiButton';
import SuiTypography from 'components/SuiTypography';

// Functions
import { checkPermission, setTitle } from 'Util';
import dayjs from 'dayjs';

// Components
import FormSelect from 'layouts/components/FormSelect';
import BaseLayout from 'layouts/components/BaseLayout';
import EnglishEnrollmentDays from './components/EnglishEnrollmentDays';
import EnglishEnrollmentDaysParameters
  from './components/Parameters/EnglishEnrollmentDaysParameters';
import LeadConversion from './components/LeadConversion';
import LeadConversionParameters
  from './components/Parameters/LeadConversionParameters';
import CommitmentMeetings from './components/CommitmentMeetings';
import CommitmentMeetingsParameters from './components/Parameters/CommitmentMeetingsParameters';
import EnglishLearningSpan from './components/EnglishLearningSpan';
import EnglishLearningSpanParameters from './components/Parameters/EnglishLearningSpanParameters';

const selector = (state) => ({
  currentUser: state.auth.user,
});

function AnalyticsReports() {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { currentUser } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [selected, setSelected] = useState({ value: '' });
  const [parameters, setParameters] = useState({});
  const [preRunParams, setPreRunParams] = useState({});
  const [showParameters, setShowParameters] = useState(false);
  const [openParameters, setOpenParameters] = useState(false);

  useEffect(() => {
    dispatch(fetchCandidateStatuses());
  }, [dispatch]);

  useEffect(() => {
    switch (selected.value) {
      case 'english-enrollment-days':
        setParameters({
          date: new Date().toISOString().split('T')[0],
          statuses: null,
        });
        setPreRunParams({
          date: new Date().toISOString().split('T')[0],
          statuses: null,
        });
        setShowParameters(true);
        break;
      case 'leads-conversion':
        setParameters({
          from: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
          to: dayjs().format('YYYY-MM-DD'),
        });
        setPreRunParams({
          from: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
          to: dayjs().format('YYYY-MM-DD'),
        });
        setShowParameters(true);
        break;
      case 'commitment-meetings':
        setParameters({
          from: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
          to: dayjs().format('YYYY-MM-DD'),
        });
        setPreRunParams({
          from: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
          to: dayjs().format('YYYY-MM-DD'),
        });
        setShowParameters(true);
        break;
      case 'english-level-learning-span':
        setParameters({ statuses: [2, 3, 4, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17] });
        setPreRunParams({ statuses: [2, 3, 4, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17] });
        setShowParameters(true);
        break;
      default:
        setShowParameters(false);
    }
  }, [selected, dispatch]);

  setTitle('Analytics Reports');

  const reports = useMemo(() => {
    const r = [];

    if (checkPermission('R201', currentUser)) {
      r.push({
        value: 'english-enrollment-days',
        label: t('english-enrollment-days'),
      });
    }

    if (checkPermission('R202', currentUser)) {
      r.push({
        value: 'leads-conversion',
        label: t('leads-conversion'),
      });
    }

    if (checkPermission('R203', currentUser)) {
      r.push({
        value: 'commitment-meetings',
        label: t('commitment-meetings'),
      });
    }

    if (checkPermission('R204', currentUser)) {
      r.push({
        value: 'english-level-learning-span',
        label: t('english-level-learning-span'),
      });
    }

    return r;
  }, [currentUser, t]);

  const handleSaveParameters = () => {
    setOpenParameters(false);
    setParameters(preRunParams);
  };

  const handleSetParameters = (params) => {
    setPreRunParams(params);
  };

  const handleSelectReport = (rep) => {
    if (reports.length > 0) {
      setSelected(rep);
    }
  };

  return (
    <BaseLayout>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item md={3}>
          <FormSelect
            label={t('report')}
            value={selected}
            options={reports}
            onChange={handleSelectReport}
          />
        </Grid>
        <Grid item md={9}>
          {showParameters && (
            <Accordion
              expanded={openParameters}
              onChange={(e, expanded) => setOpenParameters(expanded)}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="parameters"
                id="parameters"
              >
                <SuiTypography>{t('parameters')}</SuiTypography>
              </AccordionSummary>
              <AccordionDetails>
                {selected.value === 'english-enrollment-days' && (
                  <EnglishEnrollmentDaysParameters
                    defaultParameters={preRunParams}
                    setParametersFunc={handleSetParameters}
                  />
                )}
                {selected.value === 'leads-conversion' && (
                  <LeadConversionParameters
                    defaultParameters={preRunParams}
                    setParametersFunc={handleSetParameters}
                  />
                )}
                {selected.value === 'commitment-meetings' && (
                  <CommitmentMeetingsParameters
                    defaultParameters={preRunParams}
                    setParametersFunc={handleSetParameters}
                  />
                )}
                {selected.value === 'english-level-learning-span' && (
                  <EnglishLearningSpanParameters
                    defaultParameters={preRunParams}
                    setParametersFunc={handleSetParameters}
                  />
                )}
              </AccordionDetails>
              <AccordionActions>
                <SuiButton
                  variant="text"
                  color="info"
                  onClick={handleSaveParameters}
                >
                  {t('save', { keyPrefix: 'common' })}
                </SuiButton>
              </AccordionActions>
            </Accordion>
          )}
        </Grid>
        <Grid item md={6} />
        <Grid item md={12} mt={3}>
          {selected.value === 'english-enrollment-days' && <EnglishEnrollmentDays parameters={parameters} />}
          {selected.value === 'leads-conversion' && <LeadConversion parameters={parameters} />}
          {selected.value === 'commitment-meetings' && <CommitmentMeetings parameters={parameters} />}
          {selected.value === 'english-level-learning-span' && <EnglishLearningSpan parameters={parameters} />}
        </Grid>
      </Grid>
    </BaseLayout>
  );
}

export default AnalyticsReports;
