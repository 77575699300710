import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';

// Components
import FormField from 'layouts/components/FormField';

function DueInvoicesParameters({ defaultParameters, setParametersFunc }) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = useState([]);

  useEffect(() => {
    if (defaultParameters !== undefined) {
      const { date } = defaultParameters;
      if (date !== undefined) {
        setSelectedDate(`${date.substring(0, 4)}-${date.substring(5, 7)}-${date.substring(8, 10)}`);
      }
    }
  }, [dispatch, defaultParameters]);

  const handleDate = (e) => {
    setSelectedDate(e.target.value);
    setParametersFunc({ date: e.target.value });
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      spacing={3}
    >
      <Grid item md={3}>
        <FormField
          label={t('date')}
          value={selectedDate}
          type="date"
          onChange={handleDate}
        />
      </Grid>
      <Grid item md={9} />
    </Grid>
  );
}

DueInvoicesParameters.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  defaultParameters: PropTypes.any.isRequired,
  setParametersFunc: PropTypes.func.isRequired,
};

export default DueInvoicesParameters;
