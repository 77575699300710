import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Reducer functions
import { fetchCandidateStatuses } from 'reducers/commonSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

// Functions
import { findSelectValue } from 'Util';

const selector = (state) => ({
  candidateStatuses: state.common.candidateStatuses,
});

function ProgressParameters({ defaultParameters, setParametersFunc }) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { candidateStatuses } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [selectedCandidateStatuses, setSelectedCandidateStatuses] = useState([]);

  useEffect(() => {
    dispatch(fetchCandidateStatuses());
  }, [dispatch]);

  useEffect(() => {
    const sel = [];

    defaultParameters.statuses
      .forEach((id) => sel.push(findSelectValue(candidateStatuses, id)));

    setSelectedCandidateStatuses(sel);
  }, [dispatch, candidateStatuses, defaultParameters]);

  const handleCandidateStatuses = (newItems) => {
    setSelectedCandidateStatuses(newItems);
    setParametersFunc({
      statuses: newItems.map((s) => s.id),
    });
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      spacing={3}
    >
      <Grid item md={5}>
        {candidateStatuses.length > 0 && (
          <Autocomplete
            multiple
            id="candidate-statuses"
            options={[]}
            getOptionLabel={(option) => (option !== undefined ? option.label : '')}
            value={selectedCandidateStatuses}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('candidate-statuses')}
              />
            )}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                handleCandidateStatuses(newValue);
              } else if (newValue && newValue.inputValue) {
                handleCandidateStatuses(newValue.inputValue);
              } else {
                handleCandidateStatuses(newValue);
              }
            }}
          />
        )}
      </Grid>
      <Grid item md={7} />
    </Grid>
  );
}

ProgressParameters.propTypes = {
  defaultParameters: PropTypes.arrayOf(PropTypes.number).isRequired,
  setParametersFunc: PropTypes.func.isRequired,
};

export default ProgressParameters;
