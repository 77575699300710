import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Reducer functions
import { fetchEmailList, setEditing, setSucceeded } from 'reducers/emailListsSlice';

// Functions
import { setTitle } from 'Util';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import Base from '../components/Base';

function EditEmailList() {
  const dispatch = useDispatch();

  setTitle('Edit Email List');

  const { id } = useParams();
  useEffect(() => {
    dispatch(
      setSucceeded(false),
    );
    dispatch(
      setEditing(true),
    );
    dispatch(
      fetchEmailList({
        id,
      }),
    );
  }, [dispatch, id]);

  return (
    <BaseLayout>
      <Base />
    </BaseLayout>
  );
}

export default EditEmailList;
