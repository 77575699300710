import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducers functions
import { fetchUser, fetchUserCourses, fetchUserProgress } from 'reducers/bsrSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SuiTypography from 'components/SuiTypography';
import SuiBadge from 'components/SuiBadge';

// Functions
import { timestampToDate } from 'Util';

const selector = (state) => ({
  user: state.bsr.user,
  userCourses: state.bsr.userCourses,
  userProgress: state.bsr.userProgress,
});

function UserInfo({
  id,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.bsr' });
  const {
    user,
    userCourses,
    userProgress,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [bsrUser, setBsrUser] = useState(null);
  const [coursesPage, setCoursesPage] = useState(1);
  const [bsrUserCourses, setBsrUserCourses] = useState([]);
  const [progressPage, setProgressPage] = useState(1);
  const [bsrUserProgress, setBsrUserProgress] = useState([]);
  const [progressLevel, setProgressLevel] = useState('');

  useEffect(() => {
    if (userCourses !== undefined && userCourses.meta !== undefined) {
      const currPage = coursesPage;

      if (userCourses.meta.page === currPage) {
        setBsrUserCourses(bsrUserCourses.concat(userCourses.data));
        setCoursesPage((currPage + 1));
      }

      if (currPage < userCourses.meta.totalPages) {
        dispatch(fetchUserCourses({
          id,
          page: currPage + 1,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userCourses]);

  useEffect(() => {
    if (userProgress !== undefined && userProgress.meta !== undefined) {
      const currPage = progressPage;

      if (userProgress.meta.page === currPage) {
        setBsrUserProgress(bsrUserProgress.concat(userProgress.data));
        setProgressPage((currPage + 1));
      }

      if (currPage < userProgress.meta.totalPages) {
        dispatch(fetchUserProgress({
          id,
          page: currPage + 1,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userProgress]);

  useEffect(() => {
    dispatch(fetchUser({ id }));
    dispatch(fetchUserProgress({
      id,
      page: 1,
    }));
    dispatch(fetchUserCourses({
      id,
      page: 1,
    }));
  }, [dispatch, id]);

  useEffect(() => {
    if (user !== undefined) {
      setBsrUser(user);
    }
  }, [user]);

  useEffect(() => {
    const orderedCourse = bsrUserCourses.sort(
      // eslint-disable-next-line no-nested-ternary
      (a, b) => ((a.created < b.created) ? 1 : ((b.created < a.created) ? -1 : 0)),
    );

    // let lastEnrolled = null;

    if (orderedCourse !== null) {
      let i = 0;
      while (i < orderedCourse.length) {
        const regexPattern = /[ABC]\d\.?\d?|\bTOEFLicious\b|\bToeflicious\b/;
        const matches = orderedCourse[i].course.title.match(regexPattern);

        if (matches !== null) {
          // lastEnrolled = orderedCourse[i];
          setProgressLevel(matches[0]);
          break;
        }
        i += 1;
      }
    }
  }, [bsrUserCourses, bsrUserProgress]);

  const convertSeconds = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  const renderCourseDetail = (courseId, created) => {
    if (bsrUserProgress.length > 0) {
      const c = bsrUserProgress.find((p) => p.course_id === courseId);

      return (
        <Grid container>
          <Grid item md={3}>
            {`${t('enrolled')}: ${timestampToDate(created).toLocaleString()}`}
          </Grid>
          <Grid item md={3}>
            {`${t('status')}: ${t(c.status.replace('_', '-'))}`}
          </Grid>
          <Grid item md={3}>
            {`${t('progress')}: ${`${c.progress_rate} % `}`}
          </Grid>
          <Grid item md={3}>
            {`${t('time')}: ${convertSeconds(c.time_on_course)}`}
          </Grid>
        </Grid>
      );
    }
    return (<div />);
  };

  return (
    <Grid container>
      {bsrUser !== null && (
        <>
          <Grid item md={12}>
            <Card id="bsr-user-info" sx={{ overflow: 'visible' }}>
              <CardHeader
                title={t('user-info')}
              />
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item md={2}>
                    <SuiTypography variant="body2" fontWeight="bold">{t('username')}</SuiTypography>
                  </Grid>
                  <Grid item md={4}>
                    <SuiTypography variant="body2">{bsrUser.username}</SuiTypography>
                  </Grid>
                  <Grid item md={2}>
                    <SuiTypography
                      variant="body2"
                      fontWeight="bold"
                    >
                      {t('e-mail', { keyPrefix: 'candidates.contact-info' })}
                    </SuiTypography>
                  </Grid>
                  <Grid item md={4}>
                    <SuiTypography variant="body2">{bsrUser.email}</SuiTypography>
                  </Grid>
                  <Grid item md={2}>
                    <SuiTypography variant="body2" fontWeight="bold">{t('created')}</SuiTypography>
                  </Grid>
                  <Grid item md={4}>
                    <SuiTypography
                      variant="body2"
                    >
                      {new Date(bsrUser.created * 1000).toLocaleString()}
                    </SuiTypography>
                  </Grid>
                  <Grid item md={2}>
                    <SuiTypography variant="body2" fontWeight="bold">{t('last-login')}</SuiTypography>
                  </Grid>
                  <Grid item md={4}>
                    <SuiTypography
                      variant="body2"
                    >
                      {new Date(bsrUser.last_login * 1000).toLocaleString()}
                    </SuiTypography>
                    {' '}
                  </Grid>
                  <Grid item md={12}>
                    {bsrUser.tags !== undefined
                      && bsrUser.tags.map((tag, i) => (
                        <SuiBadge badgeContent={tag} circular size="md" key={`tag-${i}`} />
                      ))}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Divider />
          </Grid>
          <Grid item md={12}>
            <Card id="update-progress" sx={{ overflow: 'visible' }}>
              <CardHeader
                title={t('current-level')}
              />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={2}>
                    <SuiTypography variant="body2" fontWeight="bold">{t('estimated-level')}</SuiTypography>
                  </Grid>
                  <Grid item md={4}>
                    <SuiTypography variant="body2">{progressLevel}</SuiTypography>
                  </Grid>
                  <Grid item md={6} />
                </Grid>
              </CardContent>
            </Card>
            <Divider />
          </Grid>
          <Grid item md={12}>
            <Card id="bsr-user-courses" sx={{ overflow: 'visible' }}>
              <CardHeader
                title={t('courses')}
              />
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item md={12}>
                    {bsrUserCourses.length > 0
                      && bsrUserCourses.map((c, i) => (
                        <Accordion key={`course-${i}`}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                          >
                            {c.course.title}
                          </AccordionSummary>
                          <AccordionDetails>
                            {renderCourseDetail(c.course.id, c.created)}
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </>
      )}
    </Grid>
  );
}

UserInfo.propTypes = {
  id: PropTypes.string.isRequired,
};
export default UserInfo;
