import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Reducer functions
import {
  clearService,
  createService,
  deleteService,
  setCreated,
  setDeleted,
  setFailed,
  setSaved,
  updateService,
} from 'reducers/servicesSlice';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';

// Functions
import Swal from 'sweetalert2';
import { errorsToString } from 'Util';

const selector = (state) => ({
  serviceInfo: state.service.serviceInfo,
  editing: state.service.editing,
  deleted: state.service.deleted,
  created: state.service.created,
  saved: state.service.saved,
  failed: state.service.failed,
  errors: state.service.errors,
});

function SavePanel() {
  const { t } = useTranslation('translation', { keyPrefix: 'services' });

  const {
    serviceInfo,
    editing,
    deleted,
    created,
    saved,
    failed,
    errors,
  } = useSelector(
    selector,
    shallowEqual,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  if (created === true) {
    dispatch(setCreated(false));
    dispatch(clearService());

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('service-created-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      navigate('/billing/services', { replace: true });
    });
  }
  if (saved === true) {
    dispatch(setSaved(false));
    dispatch(clearService());

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('service-saved-successfully'),
      icon: 'success',
    }).then(() => {
      navigate('/billing/services', { replace: true });
    });
  }
  if (deleted === true) {
    dispatch(setDeleted(false));
    dispatch(clearService());

    Swal.fire({
      title: `${t('deleted', { keyPrefix: 'common' })}!`,
      text: t('service-deleted-successfully'),
      icon: 'success',
    }).then(() => {
      navigate('/billing/services', { replace: true });
    });
  }
  if (failed) {
    dispatch(setFailed(false));

    Swal.fire({
      title: t('error', { keyPrefix: 'common' }),
      text: errorsToString(errors),
      icon: 'error',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    });
  }

  const callDeleteService = async (sId) => {
    await dispatch(
      deleteService({
        id: sId,
      }),
    );
  };

  const handleDelete = () => {
    const alert = Swal.mixin({
      customClass: {
        confirmButton: 'button button-success',
        cancelButton: 'button button-error',
      },
      buttonsStyling: false,
    });

    alert
      .fire({
        title: 'Are you sure?',
        text: `Are you sure you want to delete "${serviceInfo.name}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
      })
      .then((result) => {
        if (result.isConfirmed) {
          callDeleteService(id).then(() => {
            Swal.fire('Deleted!', '', 'success').then(() => {
              navigate('/billing/services', { replace: true });
            });
          });
        }
      });
  };

  const handleSave = () => {
    if (serviceInfo.changed === true) {
      const data = {
        name: serviceInfo.name,
        description: serviceInfo.description,
      };

      if (editing === true) {
        data.id = id;

        dispatch(updateService(data));
      } else {
        dispatch(createService(data));
      }
    }
  };

  return (
    <SuiBox mt={5} mb={3}>
      <SuiBox display="flex" justifyContent="flex-end" mt={6}>
        {editing && (
          <SuiBox mr={1}>
            <SuiButton
              variant="gradient"
              color="error"
              size="small"
              onClick={handleDelete}
            >
              delete
            </SuiButton>
          </SuiBox>
        )}
        <SuiBox mr={1}>
          <SuiButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleSave}
          >
            save
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default SavePanel;
