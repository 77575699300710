import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Soft UI Dashboard PRO React example components
import DefaultItem from 'layouts/components/Items/DefaultItem';

function UpcomingEvents({ events }) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.widgets' });

  return (
    <Card sx={{ height: '100%' }}>
      <SuiBox pt={2} px={2} lineHeight={1}>
        <SuiTypography variant="h6" fontWeight="medium">
          {t('upcoming-events')}
        </SuiTypography>
      </SuiBox>
      <SuiBox p={2}>
        {Object.keys(events).length > 0
          && events.map((e, i) => (
            <DefaultItem key={`event${i}`} icon="paid" title={e.title} description={`${e.description} on ${e.date}`} />
          ))}
      </SuiBox>
    </Card>
  );
}

UpcomingEvents.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape(
      {
        title: PropTypes.string,
        description: PropTypes.string,
        date: PropTypes.string,
      },
    ),
  ).isRequired,
};
export default UpcomingEvents;
