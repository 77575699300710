import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// @material-ui core components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';

// Reducer functions
import {
  fetchCandidateLicenses,
  setCredentials,
  setCurrentSubView,
  setRemoveData,
  updateCandidateLicenseDocument,
} from 'reducers/candidatesSlice';
import { fetchCountries, fetchLicenseTypes } from 'reducers/commonSlice';

// Components
import DefaultCredentialCard from 'layouts/components/Cards/CredentialCards/DefaultCredentialCard';

// Functions
import { getCandidateUUID, isValidValue, setTitle } from 'Util';

const selector = (state) => ({
  editing: state.candidate.editing,
  credentials: state.candidate.credentials,
  removeData: state.candidate.removeData,
  countries: state.common.countries,
});

function Credentials() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'candidates.credentials',
  });
  const {
    editing,
    credentials,
    removeData,
    countries,
  } = useSelector(
    selector,
    shallowEqual,
  );
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    dispatch(setCurrentSubView('credentials'));
    dispatch(fetchCountries());
    dispatch(fetchLicenseTypes());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateLicenses({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  setTitle('Candidate Credentials');

  const isValid = (key, value, c) => {
    const objToValidate = {
      ...c,
      [key]: value,
    };

    return isValidValue(objToValidate.license_number)
      && isValidValue(objToValidate.country_id)
      && isValidValue(objToValidate.state_id)
      && isValidValue(objToValidate.issue)
      && (objToValidate.no_expiration ? true : isValidValue(objToValidate.expiration));
  };

  const setValue = (key, value, i) => {
    dispatch(
      setCredentials(
        credentials.map((obj, index) => {
          if (index === i) {
            return {
              ...obj,
              [key]: value,
              changed: true,
              valid: isValid(key, value, credentials[i]),
            };
          }
          return obj;
        }),
      ),
    );
  };

  const handleAddCredential = () => {
    dispatch(
      setCredentials(
        credentials.concat({
          id: undefined,
          country_id: undefined,
          expiration: '',
          no_expiration: false,
          license_number: '',
          license_type_id: undefined,
          issue: '',
          changed: true,
          valid: false,
        }),
      ),
    );
  };

  const handleRemoveCredential = (i) => {
    if (credentials[i].id !== undefined) {
      dispatch(
        setRemoveData({
          ...removeData,
          credentials: removeData.credentials.concat(credentials[i].id),
        }),
      );
    }

    dispatch(
      setCredentials([...credentials.slice(0, i), ...credentials.slice(i + 1)]),
    );
  };

  const handleUploadDocument = (files, id) => {
    dispatch(
      updateCandidateLicenseDocument({
        id,
        candidateId: uuid,
        files: files[0],
      }),
    );
  };

  return (
    <SuiBox component="form">
      <SuiBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <SuiTypography variant="h4" fontWeight="medium">
              {t('licenses')}
            </SuiTypography>
            <SuiBox mt={1} mb={2}>
              <SuiTypography variant="body2" color="text">
                {t('licenses-description')}
              </SuiTypography>
            </SuiBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <SuiBox display="flex" justifyContent="flex-end">
              <SuiButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={handleAddCredential}
              >
                {t('new-license')}
              </SuiButton>
            </SuiBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={12}>
            <SuiBox pb={1} px={1}>
              {Object.keys(credentials).length > 0
                && credentials.map((c, i) => (
                  <div
                    key={
                      // eslint-disable-next-line react/no-array-index-key
                      `credentials${i}`
                    }
                  >
                    <DefaultCredentialCard
                      i={i}
                      c={c}
                      ops={{
                        countries,
                      }}
                      setValue={setValue}
                      removeFunc={handleRemoveCredential}
                      uploadFunc={handleUploadDocument}
                    />
                    <Divider />
                  </div>
                ))}
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default Credentials;
