import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { fetchPositionListings } from 'reducers/positionListingsSlice';
import {
  createCandidateRecruitment,
  fetchCandidateRecruitment, setFailed,
  setSaved,
} from 'reducers/candidatesSlice';

// @mui material components
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import RecruitmentPositionCard
  from 'layouts/components/Cards/PositionCards/RecruitmentPositionCard';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';
import OutlineTable from 'layouts/components/Cards/PositionCards/components/OutlineTable';
import DefaultModal from 'layouts/components/Modal/DefaultModal';

// Functions
import parse from 'html-react-parser';
import { failedCandidateMessage, getCandidateUUID, savedCandidateMessage } from 'Util';

require('datejs');

const selector = (state) => ({
  saved: state.candidate.saved,
  failed: state.candidate.failed,
  errors: state.candidate.errors,
  positionListings: state.positionListing.positionListings,
  recruitments: state.candidate.recruitments,
});

function JobOpportunities() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'candidates.job-opportunities',
  });
  const {
    saved,
    failed,
    errors,
    positionListings,
    recruitments,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  const [openModal, setOpenModal] = useState(false);
  const [position, setPosition] = useState({});
  const [availPositions, setAvailPositions] = useState([]);

  useEffect(() => {
    dispatch(fetchPositionListings());
    dispatch(fetchCandidateRecruitment({ uuid }));
  }, [dispatch, uuid]);

  useEffect(() => {
    if (recruitments !== undefined) {
      setAvailPositions(positionListings
        .filter((ap) => !recruitments
          .some((cp) => ap.id === cp.position_listing_id)));
    }
  }, [positionListings, recruitments]);

  const handleOpenModal = (e, id) => {
    e.preventDefault();
    const pos = positionListings.find((p) => p.id === id);
    setPosition(pos);
    setOpenModal(true);
  };

  const handleApply = (id) => {
    dispatch(createCandidateRecruitment({
      candidateId: uuid,
      positionListingId: id,
      recruitmentStageId: 1,
      startDate: Date().toString('M/d/yyyy'),
    }));

    setOpenModal(false);
  };

  if (saved) {
    dispatch(setSaved(false));
    savedCandidateMessage(t);
  }
  if (failed) {
    dispatch(setFailed(false));
    failedCandidateMessage(t, errors);
  }

  return (
    <SuiBox p={2}>
      {Object.keys(availPositions).length > 0
          && availPositions.map((p, i) => (
            <div key={`position${i}`}>
              <RecruitmentPositionCard
                key={`position${i}`}
                position={p.name}
                client={p.client_name}
                location={p.state !== '' ? `${p.city}, ${p.state}` : ''}
                date={p.date_opened}
                tags={p.tags}
                openFunction={(e) => handleOpenModal(e, p.id)}
              />
              <Divider />
            </div>
          ))}
      <DefaultModal openModal={openModal} closeFunc={() => setOpenModal(false)}>
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
          spacing={1}
        >
          <Grid item md={10}>
            <SuiTypography variant="h2">{position.name}</SuiTypography>
          </Grid>
          <Grid item md={2}>
            <Grid
              container
              direction="row"
              sx={{
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Grid item>
                <SuiButton
                  variant="outlined"
                  size="small"
                  color="info"
                  onClick={() => handleApply(position.id)}
                >
                  {t('apply')}
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Divider />
          </Grid>
          <Grid item md={9}>
            <div style={{ maxHeight: '500px', overflow: 'scroll' }}>
              {position.description !== undefined ? parse(position.description) : ''}
            </div>
          </Grid>
          <Grid item md={3}>
            <OutlineTable
              dateOpened={position.date_opened}
              state={position.state}
              city={position.city}
              tags={position.tags}
            />
          </Grid>
        </Grid>
      </DefaultModal>
    </SuiBox>
  );
}

export default JobOpportunities;
