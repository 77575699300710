function educationColumns(t) {
  return (
    [
      {
        caption: t('candidate-name'),
        field: 'candidate_name',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('education-level', { keyPrefix: 'candidates.education' }),
        field: 'education_level',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('degree-obtained', { keyPrefix: 'candidates.education' }),
        field: 'degree',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('university-institution', { keyPrefix: 'candidates.education' }),
        field: 'university_name',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('name-on-diploma-if-different', { keyPrefix: 'candidates.education' }),
        field: 'name_under',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('attended-from', { keyPrefix: 'candidates.education' }),
        field: 'from',
        dataType: 'date',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('attended-to', { keyPrefix: 'candidates.education' }),
        field: 'to',
        dataType: 'date',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('graduation-date', { keyPrefix: 'candidates.education' }),
        field: 'graduation',
        dataType: 'date',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('is-school-still-active', { keyPrefix: 'candidates.education' }),
        field: 'still_active',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('address', { keyPrefix: 'candidates.education' }),
        field: 'address',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('country', { keyPrefix: 'candidates.education' }),
        field: 'country',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('state-province', { keyPrefix: 'candidates.education' }),
        field: 'state',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('city', { keyPrefix: 'candidates.education' }),
        field: 'city',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('postal-code', { keyPrefix: 'candidates.education' }),
        field: 'postal_code',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('status'),
        field: 'candidate_status',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('advisor'),
        field: 'advisor_name',
        visible: false,
        allowFixing: true,
      },
    ]);
}

export default educationColumns;
