import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducers
import { fetchContactMethods } from 'reducers/commonSlice';
import {
  createCandidateContact,
  deleteCandidateContact,
  fetchCandidateContacts,
  setCreated,
  setSaved,
  updateCandidateContact,
} from 'reducers/candidatesSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import DialogActions from '@mui/material/DialogActions';
import { renderDigitalClockTimeView } from '@mui/x-date-pickers/timeViewRenderers';
import { Backdrop, CircularProgress } from '@mui/material';

// Soft UI Dashboard PRO React components
import SuiButton from 'components/SuiButton';
import SuiEditor from 'components/SuiEditor';
import SuiBox from 'components/SuiBox';

// Functions
import {
  defaultValue, findSelectValue, getCandidateUUID, setTitle,
} from 'Util';
import dayjs from 'dayjs';
import useWindowDimensions from 'layouts/components/Hooks/WindowDimensions';
import Swal from 'sweetalert2';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';
import FormDateTimePicker from 'layouts/components/FormDateTimePicker';
import CommunicationNoteCard from './components/CommunicationNoteCard';

const selector = (state) => ({
  editing: state.candidate.editing,
  contactMethods: state.common.contactMethods,
  contacts: state.candidate.contacts,
  created: state.candidate.created,
  saved: state.candidate.saved,
});

function CommunicationNotes() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.communications' });
  const {
    editing,
    contactMethods,
    contacts,
    created,
    saved,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();
  const { height } = useWindowDimensions();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [showCommunicationForm, setShowCommunicationForm] = useState(false);
  const [communication, setCommunication] = useState({});
  const [communicationNotes, setCommunicationNotes] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedCommunication, setSelectedCommunication] = useState(null);
  const [parentHeight, setParentHeight] = useState(500);

  const loadData = () => {
    dispatch(fetchContactMethods());
    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateContacts({ uuid }));
    }
  };
  const setCommunicationData = () => {
    if (contacts !== null && contacts !== undefined && contacts.length > 0) {
      setSelectedCommunication(contacts[0]);
      setCommunicationNotes(contacts);
    } else {
      setSelectedCommunication(null);
    }
  };

  useEffect(loadData, [dispatch, editing, uuid]);
  useEffect(setCommunicationData, [contacts]);
  useEffect(() => {
    setParentHeight(height - 80);
  }, [height]);

  setTitle('Candidate Communication Notes');

  const setValue = (key, value) => {
    setCommunication({
      ...communication,
      [key]: value,
    });
  };
  const handleSaveCommunication = () => {
    if (communication.contact_method_id !== null
      && communication.contact_method_id !== undefined
      && communication.contact_method_id !== ''
      && communication.contact_method_id !== 0
      && communication.title !== null
      && communication.title !== undefined
      && communication.title !== '') {
      setOpenBackdrop(true);
      const data = {
        candidateId: uuid,
        title: communication.title,
        note: communication.note,
        date: communication.date,
        contactMethodId: communication.contact_method_id,
        meetingOutcomeId: communication.meeting_outcome_id,
      };

      if (communication.id !== undefined) {
        data.id = communication.id;
        dispatch(updateCandidateContact(data));
      } else {
        dispatch(createCandidateContact(data));
      }
    }
  };
  const handleDeleteCommunication = (id) => {
    const alert = Swal.mixin({
      customClass: {
        confirmButton: 'button button-success',
        cancelButton: 'button button-error',
      },
      buttonsStyling: false,
    });

    alert
      .fire({
        title: t('are-you-sure', { keyPrefix: 'common' }),
        text: t('are-you-sure-you-want-to-delete-it', { keyPrefix: 'common' }),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('yes-delete-it', { keyPrefix: 'common' }),
      })
      .then((result) => {
        if (result.isConfirmed) {
          setOpenBackdrop(true);
          dispatch(deleteCandidateContact({
            id,
            candidateId: uuid,
          }));
        }
      });
  };
  const handleNewCommunication = () => {
    setCommunication({
      title: '',
      note: '',
      date: dayjs(new Date(Math.round(dayjs() / (1000 * 60 * 5)) * (1000 * 60 * 5))),
      contact_method_id: undefined,
    });
    setShowCommunicationForm(true);
  };
  const handleEditCommunication = (id) => {
    const contact = contacts.find((c) => c.id === id);

    setCommunication({
      id: contact.id,
      title: contact.title,
      note: contact.note,
      date: contact.date,
      contact_method_id: contact.contact_method_id,
    });
    setShowCommunicationForm(true);
  };
  const handleCloseCommunicationForm = () => setShowCommunicationForm(false);
  const handleSelect = (e, i) => {
    e.preventDefault();
    setSelectedIndex(i);
    setSelectedCommunication(contacts[i]);
  };
  const getContactMethod = (id) => {
    const method = findSelectValue(contactMethods, id);

    if (method !== undefined) {
      return method.name;
    }
    return '';
  };

  if (created) {
    setOpenBackdrop(false);
    dispatch(setCreated(false));

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('communication-created-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      handleCloseCommunicationForm();
    });
  }
  if (saved) {
    setOpenBackdrop(false);
    dispatch(setSaved(false));

    Swal.fire({
      title: `${t('success', { keyPrefix: 'common' })}!`,
      text: t('communication-saved-successfully'),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      handleCloseCommunicationForm();
    });
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={3}
      mt={3}
      mb={3}
    >
      <Grid item md={9} />
      <Grid item md={3}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item>
            <SuiButton
              variant="gradient"
              color="info"
              size="small"
              onClick={handleNewCommunication}
            >
              <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
              &nbsp;
              {t('add-new-communication')}
            </SuiButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4}>
        <div
          style={{
            minHeight: '300',
            height: parentHeight,
            overflowY: 'scroll',
          }}
        >
          {communicationNotes.map((c, i) => (
            <SuiBox onClick={(e) => handleSelect(e, i)}>
              <CommunicationNoteCard
                title={c.title}
                date={c.date}
                note={c.note}
                contactMethod={getContactMethod(c.contact_method_id)}
                selected={selectedIndex === i}
                fade
                editFunc={() => handleEditCommunication(c.id)}
                removeFunc={() => handleDeleteCommunication(c.id)}
              />
              <Divider />
            </SuiBox>
          ))}
        </div>
      </Grid>
      <Grid item md={8}>
        {selectedCommunication !== null && (
          <Grid container>
            <Grid md={12}>
              <CommunicationNoteCard
                title={selectedCommunication.title}
                date={selectedCommunication.date}
                note={selectedCommunication.note}
                contactMethod={getContactMethod(selectedCommunication.contact_method_id)}
                editFunc={() => handleEditCommunication(selectedCommunication.id)}
                removeFunc={() => handleDeleteCommunication(selectedCommunication.id)}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item md={12}>
        <Dialog
          open={showCommunicationForm}
          onClose={handleCloseCommunicationForm}
          maxWidth="xl"
          fullWidth
        >
          <DialogTitle>{t('communication')}</DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item md={2}>
                <FormSelect
                  label={t('communication-method')}
                  options={contactMethods}
                  value={findSelectValue(contactMethods, communication.contact_method_id)}
                  onChange={(e) => {
                    setValue('contact_method_id', e.value);
                  }}
                />
              </Grid>
              <Grid item md={3}>
                <FormDateTimePicker
                  label={t('date')}
                  views={['year', 'month', 'day', 'hours', 'minutes']}
                  value={dayjs(communication.date)}
                  viewRenderers={{ hours: renderDigitalClockTimeView }}
                  onChange={(e) => {
                    setValue('date', e);
                  }}
                />
              </Grid>
              <Grid item md={7}>
                <FormField
                  label={t('title')}
                  value={defaultValue(communication.title)}
                  onChange={(e) => {
                    setValue('title', e.target.value);
                  }}
                />
              </Grid>
              <Grid item md={12} mb={5} sx={{ height: '250px' }}>
                <SuiEditor
                  value={defaultValue(communication.note)}
                  onChange={(e) => {
                    setValue('note', e);
                  }}
                  className="editorSize"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={3}>
              <Grid item md={10}>
                {(communication.id !== undefined) && (
                  <SuiButton
                    variant="contained"
                    color="error"
                    onClick={() => handleDeleteCommunication(communication.id)}
                  >
                    {t('delete', { keyPrefix: 'common' })}
                  </SuiButton>
                )}
              </Grid>
              <Grid item md={1}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    <SuiButton onClick={handleCloseCommunicationForm}>
                      {t('close', { keyPrefix: 'common' })}
                    </SuiButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={1}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    <SuiButton
                      variant="contained"
                      color="info"
                      onClick={handleSaveCommunication}
                    >
                      {t('save', { keyPrefix: 'common' })}
                    </SuiButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}

export default CommunicationNotes;
