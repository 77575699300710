import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// Reducers
import { fetchInvoice, setInvoiceEditing } from 'reducers/invoicesSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import InvoiceInfo from '../components/InvoiceInfo';
import InvoiceDetails from '../components/InvoiceDetails';
import SavePanel from '../components/SavePanel';

function EditInvoice() {
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchInvoice({ id }));
    dispatch(setInvoiceEditing(true));
  }, [dispatch, id]);

  return (
    <BaseLayout>
      <SuiBox mt={4}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <InvoiceInfo />
                  <Divider />
                  <InvoiceDetails />
                  <SavePanel />
                </Grid>
              </Grid>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </BaseLayout>
  );
}

export default EditInvoice;
