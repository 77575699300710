import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  checkSimilarCandidates,
  fetchCandidateProfile,
  setCurrentSubView,
  setProfile,
  setSimilarCandidates,
} from 'reducers/candidatesSlice';
import { fetchCitizenships, fetchCountries, fetchLanguages } from 'reducers/commonSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiInput from 'components/SuiInput';

// Components
import FormField from 'layouts/components/FormField';
import FormMaskInput from 'layouts/components/FormMaskInput';
import FormSelect from 'layouts/components/FormSelect';

// Functions
import { findSelectValue, getCandidateUUID } from 'Util';

const selector = (state) => ({
  editing: state.candidate.editing,
  profile: state.candidate.profile,
  languages: state.common.languages,
  countries: state.common.countries,
  citizenships: state.common.citizenships,
});

function BasicInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.basic-info' });
  const {
    editing,
    profile,
    languages,
    countries,
    citizenships,
  } = useSelector(
    selector,
    shallowEqual,
  );
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    dispatch(setCurrentSubView('basic-info'));
    dispatch(fetchLanguages());
    dispatch(fetchCountries());
    dispatch(fetchCitizenships());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateProfile({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  const setValue = (key, value) => {
    dispatch(
      setProfile(
        {
          ...profile,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  useEffect(() => {
    if (!editing && uuid === undefined) {
      let check = false;

      if (profile.first_name !== undefined) {
        if (profile.first_name.length >= 2) {
          check = true;
        }
      }

      if (profile.last_name !== undefined) {
        if (profile.last_name.length >= 2) {
          check = true;
        }
      }
      if (check) {
        dispatch(
          checkSimilarCandidates({
            firstName: profile.first_name,
            lastName: profile.last_name,
          }),
        );
      } else {
        dispatch(setSimilarCandidates([]));
      }
    }
  }, [profile, dispatch, editing, uuid]);

  const preferredMethod = [
    {
      value: 1,
      label: t('email'),
    },
    {
      value: 2,
      label: t('phone'),
    },
    {
      value: 3,
      label: t('whatsapp'),
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card id="basic-info" sx={{ overflow: 'visible' }}>
          <SuiBox p={3}>
            <SuiTypography variant="h5">{t('basic-info')}</SuiTypography>
          </SuiBox>
          <SuiBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  label={t('first-name', { keyPrefix: 'common' })}
                  value={profile.first_name ? profile.first_name : ''}
                  error={!profile.first_name || profile.first_name === ''}
                  onChange={(e) => {
                    setValue('first_name', e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  label={t('middle-name', { keyPrefix: 'common' })}
                  value={profile.middle_name ? profile.middle_name : ''}
                  onChange={(e) => {
                    setValue('middle_name', e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  label={t('last-name', { keyPrefix: 'common' })}
                  value={profile.last_name ? profile.last_name : ''}
                  error={!profile.last_name || profile.last_name === ''}
                  onChange={(e) => {
                    setValue('last_name', e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  label={t('preferred-name')}
                  value={
                    profile.preferred_name
                      ? profile.preferred_name
                      : ''
                  }
                  onChange={(e) => {
                    setValue('preferred_name', e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      {t('birthdate')}
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput
                    type="date"
                    placeholder={t('select-date')}
                    value={profile.dob ? profile.dob : ''}
                    onChange={(e) => {
                      setValue('dob', e.target.value);
                    }}
                  />
                </SuiBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormMaskInput
                  label={t('ssn')}
                  mask="999-99-9999"
                  maskPlaceholder="*"
                  value={
                    profile.ssn
                      ? profile.ssn
                      : ''
                  }
                  onChange={(e) => {
                    setValue('ssn', e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormSelect
                  label={t('native-language')}
                  options={languages}
                  value={findSelectValue(languages, profile.language_id)}
                  onChange={(e) => {
                    setValue('language_id', e.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormSelect
                  label={t('country-of-origin')}
                  options={countries}
                  value={findSelectValue(countries, profile.country_id)}
                  error={!profile.country_id || profile.country_id === ''}
                  onChange={(e) => {
                    setValue('country_id', e.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormSelect
                  label={t('country-of-residence')}
                  options={countries}
                  value={findSelectValue(countries, profile.residence_country_id)}
                  onChange={(e) => {
                    setValue('residence_country_id', e.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormSelect
                  label={t('citizenship')}
                  options={citizenships}
                  value={findSelectValue(citizenships, profile.citizenship_id)}
                  onChange={(e) => {
                    setValue('citizenship_id', e.value);
                  }}
                />
              </Grid>
            </Grid>
          </SuiBox>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <Card id="contact-method" sx={{ overflow: 'visible' }}>
          <SuiBox p={3}>
            <SuiTypography variant="h5">{t('contact-method')}</SuiTypography>
          </SuiBox>
          <SuiBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <FormSelect
                  label={t('preferred-contact-method')}
                  options={preferredMethod}
                  value={findSelectValue(preferredMethod, profile.contact_method)}
                  onChange={(e) => {
                    setValue('contact_method', e.value);
                  }}
                />
              </Grid>
            </Grid>
          </SuiBox>
        </Card>
      </Grid>
    </Grid>
  );
}

export default BasicInfo;
