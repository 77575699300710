import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Reducers
import { fetchPaypalFee } from 'reducers/paymentsSlice';
import { PayPalButtons, PayPalScriptProvider, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import PropTypes from 'prop-types';

// Functions
import { currencyFormatter } from 'Util';

const initialOptions = {
  'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: 'USD',
  components: 'buttons',
};

const style = { layout: 'vertical' };

function ButtonWrapper({
  currency, showSpinner, amount, isExternal, invoiceInfo,
}) {
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

  useEffect(() => {
    dispatch({
      type: 'resetOptions',
      value: {
        ...options,
        currency,
      },
    });
    /* eslint-disable-next-line */
  }, [currency, showSpinner]);

  const navigate = useNavigate();

  return (
    <>
      {showSpinner && isPending && <div className="spinner" />}
      <PayPalButtons
        style={style}
        disabled={false}
        forceReRender={[amount, currency, style]}
        fundingSource={undefined}
        createOrder={
          (data, actions) => actions.order
            .create({
              purchase_units: [
                {
                  reference_id: invoiceInfo.id,
                  amount: {
                    currency_code: currency,
                    value: amount,
                  },
                },
              ],
            })
            .then(
              (orderId) => orderId,
            )
        }
        onApprove={
          (
            (data, actions) => actions.order.capture()
              .then(
                (details) => {
                  navigate(`/${
                    isExternal ? 'payment_confirmation' : 'invoice'
                  }/${
                    invoiceInfo.uuid
                  }?provider=paypal&payment=${
                    details.id
                  }`);
                },
              )
          )
        }
      />
    </>
  );
}

ButtonWrapper.propTypes = {
  currency: PropTypes.string,
  showSpinner: PropTypes.bool,
  amount: PropTypes.number,
  isExternal: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  invoiceInfo: PropTypes.object,
};

ButtonWrapper.defaultProps = {
  currency: 'USD',
  showSpinner: false,
  amount: 0,
  isExternal: false,
  invoiceInfo: {},
};

const selector = (state) => ({
  invoiceInfo: state.invoice.invoiceInfo,
  amount: state.payment.amount,
  fee: state.payment.fee,
});

function PayPalPaymentForm({ isExternal }) {
  const { invoiceInfo, amount, fee } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (invoiceInfo.total !== undefined) {
      dispatch(
        fetchPaypalFee({
          total: invoiceInfo.total,
        }),
      );
    }
  }, [dispatch, invoiceInfo]);

  return (
    <div>
      {invoiceInfo && (
        <div>
          Total Payment
          {' '}
          {currencyFormatter.format(amount)}
          {' '}
          (
          {currencyFormatter.format(invoiceInfo.total)}
          {' '}
          +
          {' '}
          {currencyFormatter.format(fee)}
          {' '}
          fee)
        </div>
      )}
      <PayPalScriptProvider options={initialOptions}>
        <ButtonWrapper
          currency="USD"
          showSpinner={false}
          amount={amount === undefined ? 0 : amount.toFixed(2)}
          isExternal={isExternal}
          invoiceInfo={invoiceInfo}
        />
      </PayPalScriptProvider>
    </div>
  );
}

// Setting values for the props of PayPalPaymentForm
PayPalPaymentForm.defaultProps = {
  isExternal: false,
};

// Typechecking props for PayPalPaymentForm
PayPalPaymentForm.propTypes = {
  isExternal: PropTypes.bool,
};

export default PayPalPaymentForm;
