import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { setImportData } from 'reducers/candidatesSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';

// Soft UI Dashboard PRO React components
import SuiTypography from 'components/SuiTypography';
import SuiBox from 'components/SuiBox';

const selector = (state) => ({
  importData: state.candidate.importData,
});

function Prepare() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.import' });
  const { importData } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [prepared, setPrepared] = useState(false);

  useEffect(() => {
    const data = [];
    if (importData.prepared === false) {
      importData.data.forEach((e) => {
        data.push({
          ...e,
          import_json: JSON.stringify(e),
        });
      });

      dispatch(
        setImportData(
          {
            ...importData,
            dataPrepared: data,
            prepared: true,
          },
        ),
      );
      setPrepared(true);
    }
  }, [dispatch, importData]);

  return (
    <Grid
      container
    >
      <Grid item md={12}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          mt={10}
          mb={10}
        >
          <Grid item>
            <SuiTypography variant="h5" fontWeight="regular" color="secondary">
              {prepared ? t('information-ready-import') : t('preparing-data')}
            </SuiTypography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={2} />
      <Grid item md={8} mt={5} mb={5}>
        <SuiBox sx={{ width: '100%' }}>
          <LinearProgress color="secondary" />
        </SuiBox>
      </Grid>
      <Grid item md={2} />
    </Grid>
  );
}

export default Prepare;
