import { useState } from 'react';
// ImportFile { shallowEqual, useSelector } from 'react-redux'
// @mui material components
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// @fontawesome components
import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard';
import { faPaypal } from '@fortawesome/free-brands-svg-icons/faPaypal';
import { faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons/faMoneyBillTransfer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Invoice page components
import StripePaymentForm from 'layouts/payments/StripePaymentForm';
import PayPalPaymentForm from 'layouts/payments/PayPalPaymentForm';
import SuiTypography from 'components/SuiTypography';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const selector = (state) => ({
  isAdmin: state.auth.isAdmin,
});

function InvoicePayment({ isExternal }) {
  const { isAdmin } = useSelector(selector, shallowEqual);

  const [tabValue, setTabValue] = useState(0);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <Grid container>
      <Grid item mb={2} xs={12} sm={8} lg={8}>
        <SuiTypography variant="h3">Select Payment Method</SuiTypography>
      </Grid>
      <Grid item xs={12} sm={8} lg={8}>
        <AppBar position="static">
          <Tabs value={tabValue} onChange={handleSetTabValue}>
            <Tab
              icon={<FontAwesomeIcon icon={faCreditCard} />}
              iconPosition="top"
              label="Credit Card"
            />
            <Tab
              icon={<FontAwesomeIcon icon={faPaypal} />}
              iconPosition="top"
              label="PayPal"
            />
            {isAdmin && (
              <Tab
                icon={<FontAwesomeIcon icon={faMoneyBillTransfer} />}
                iconPosition="top"
                label="Wired Transfer"
              />
            )}
          </Tabs>
        </AppBar>
      </Grid>
      <Grid item mt={3} xs={12} sm={8} lg={8}>
        {tabValue === 0 && (
          <Grid item>
            <StripePaymentForm isExternal={isExternal} />
          </Grid>
        )}
        {tabValue === 1 && (
          <Grid item xs={12}>
            <PayPalPaymentForm isExternal={isExternal} />
          </Grid>
        )}
        {tabValue === 2 && <div>WiredT</div>}
      </Grid>
    </Grid>
  );
}

// Typechecking props for InvoicePayment
InvoicePayment.propTypes = {
  isExternal: PropTypes.bool.isRequired,
};

export default InvoicePayment;
