import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { setPaymentMethodInfo } from 'reducers/paymentMethodSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Components
import FormField from 'layouts/components/FormField';

const selector = (state) => ({
  paymentMethodInfo: state.paymentMethod.paymentMethodInfo,
});

function PaymentMethodInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'payment-methods' });
  const { paymentMethodInfo } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const setValue = (key, value) => {
    dispatch(
      setPaymentMethodInfo(
        {
          ...paymentMethodInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  return (
    <Card id="payment-method-info" sx={{ overflow: 'visible' }}>
      <SuiBox p={3}>
        <SuiTypography variant="h5">{t('payment-method-info')}</SuiTypography>
      </SuiBox>
      <SuiBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              label={t('name')}
              value={paymentMethodInfo.name !== undefined ? paymentMethodInfo.name : ''}
              onChange={(e) => {
                setValue('name', e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default PaymentMethodInfo;
