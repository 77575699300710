import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Reducers
import { clearFingerprint, setFingerprintEditing, setSucceeded } from 'reducers/fingerprintsSlice';
import { fetchTemplates } from 'reducers/templatesSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import FingerprintInfo from '../components/FingerprintInfo';
import SavePanel from '../components/SavePanel';

function NewFingerprint() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTemplates());
    dispatch(
      setSucceeded(false),
    );
    dispatch(
      clearFingerprint(),
    );
    dispatch(
      setFingerprintEditing(false),
    );
  }, [dispatch]);

  return (
    <BaseLayout>
      <SuiBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SuiBox mb={3}>
              <FingerprintInfo />
              <SavePanel />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </BaseLayout>
  );
}

export default NewFingerprint;
