import Service from './service';

class ServiceService extends Service {
  // eslint-disable-next-line class-methods-use-this
  async getServices() {
    const response = await this.api
      .get(
        'services',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getService(id) {
    const response = await this.api
      .get(
        `services/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createService(name, description) {
    const data = {
      name,
      description,
    };

    const response = await this.api
      .post(
        'services',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateService(id, name, description) {
    const data = {
      name,
      description,
    };

    const response = await this.api
      .put(
        `services/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteService(id) {
    const response = await this.api
      .delete(
        `services/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new ServiceService();
