// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @material-ui core components
import { Divider } from '@mui/material';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiAvatar from 'components/SuiAvatar';
import SuiButton from 'components/SuiButton';

import imgAvatar from 'assets/images/default-avatar.svg';

function AccountNavbarCard({
  image, name, email, buttonText, handleClick,
}) {
  const buttonHandler = (e) => handleClick(e);
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <SuiBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
    >
      <SuiAvatar
        src={image}
        alt={name}
        size="xxl"
        shadow="md"
        variant="circular"
      />
      <SuiBox p={3}>
        <SuiTypography variant="h4">{name}</SuiTypography>
        <SuiTypography variant="h6" color="text">
          {email}
        </SuiTypography>
      </SuiBox>
      <Divider variant="middle" />
      <SuiBox p={3}>
        <SuiButton variant="outlined" color="info" onClick={buttonHandler}>
          {buttonText}
        </SuiButton>
      </SuiBox>
    </SuiBox>
  );
}

// Setting admin props for the AccountNavbarCard
AccountNavbarCard.defaultProps = {
  image: imgAvatar,
  buttonText: 'Sign out',
};

// Typechecking props for the AccountNavbarCard
AccountNavbarCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
};

export default AccountNavbarCard;
