import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { fetchCourses } from 'reducers/bsrSlice';

import Grid from '@mui/material/Grid';

import FormSelect from 'layouts/components/FormSelect';
import PropTypes from 'prop-types';

const selector = (state) => ({
  courses: state.bsr.courses,
});

function BsrCoursesParameters({ setParametersFunc }) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const {
    courses,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [bsrCourse, setBsrCourse] = useState([]);
  const [coursesPage, setCoursesPage] = useState(1);

  useEffect(() => {
    if (courses !== undefined && courses.meta !== undefined) {
      if (courses.meta.page >= coursesPage) {
        setBsrCourse(bsrCourse.concat(courses.data));
        setCoursesPage(courses.meta.page);
      }

      if (courses.meta.page < courses.meta.totalPages) {
        dispatch(fetchCourses({ page: courses.meta.page + 1 }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, courses]);

  useEffect(() => {
    setBsrCourse([]);
    setCoursesPage(1);
    dispatch(fetchCourses({ page: 1 }));
  }, [dispatch]);

  const handleSelect = (e) => {
    setParametersFunc({ course: e.value });
  };

  const courseSelection = bsrCourse.map((c) => ({ value: c.id, label: c.title }));

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      spacing={3}
      sx={{ height: '100px' }}
    >
      <Grid item md={5}>
        <FormSelect
          label={t('course')}
          // value={selected}
          options={courseSelection}
          onChange={handleSelect}
          sx={{ overflow: 'visible' }}
        />
      </Grid>
      <Grid item md={7} />
    </Grid>
  );
}

BsrCoursesParameters.propTypes = {
  setParametersFunc: PropTypes.func.isRequired,
};

export default BsrCoursesParameters;
