import { Link } from 'react-router-dom';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import IconButton from '@mui/material/IconButton';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

function Invoice({
  date, id, price, status, uuid, noGutter,
}) {
  return (
    <SuiBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      pr={1}
      mb={noGutter ? 0 : 1}
    >
      <SuiBox lineHeight={1.125}>
        <SuiTypography display="block" variant="button" fontWeight="medium">
          {date}
        </SuiTypography>
        <SuiTypography variant="caption" fontWeight="regular" color="text">
          {`# ${id}`}
        </SuiTypography>
      </SuiBox>
      <SuiBox display="flex" alignItems="center">
        <SuiTypography variant="button" fontWeight="regular" color="text">
          {status}
        </SuiTypography>
      </SuiBox>
      <SuiBox display="flex" alignItems="center">
        <SuiTypography variant="button" fontWeight="regular" color="text">
          {price}
        </SuiTypography>
        <SuiBox
          display="flex"
          alignItems="center"
          lineHeight={1}
          ml={3}
          sx={{ cursor: 'pointer' }}
        >
          <Link to={`../invoice/${uuid}`}>
            <IconButton>
              <DescriptionTwoToneIcon fontSize="small">
                picture_as_pdf
              </DescriptionTwoToneIcon>
            </IconButton>
          </Link>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

// Setting admin values for the props of Invoice
Invoice.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Invoice
Invoice.propTypes = {
  date: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default Invoice;
