import PropTypes from 'prop-types';

// @material-ui core components
import Modal from '@mui/material/Modal';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function DefaultModal({ children, openModal, closeFunc }) {
  return (
    <Modal
      open={openModal}
      onClose={closeFunc}
    >
      <SuiBox
        sx={modalStyle}
        borderRadius="xl"
      >
        {children}
      </SuiBox>
    </Modal>
  );
}

DefaultModal.propTypes = {
  children: PropTypes.node.isRequired,
  openModal: PropTypes.bool.isRequired,
  closeFunc: PropTypes.func.isRequired,
};

export default DefaultModal;
