import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducer Functions
import { fetchCandidatesOverview } from 'reducers/reportsSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// DevExtreme components
import PivotGrid, { FieldChooser, Scrolling } from 'devextreme-react/pivot-grid';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
// eslint-disable-next-line object-curly-newline
import Chart, { AdaptiveLayout, CommonSeriesSettings, Export, Size, Tooltip } from 'devextreme-react/chart';
import { exportPivotGrid } from 'devextreme/excel_exporter';

// Other components
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';

// Functions
import { setTitle } from 'Util';
import useWindowDimensions from 'layouts/components/Hooks/WindowDimensions';

const selector = (state) => ({
  candidatesOverview: state.reports.candidatesOverview,
});

function Overview() {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const {
    candidatesOverview,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  setTitle('Candidates Overview Report');

  const chart = useRef(null);
  const pivotGrid = useRef(null);

  const [parentHeight, setParentHeight] = useState(500);
  const [reportData, setReportData] = useState([]);

  const { height } = useWindowDimensions();

  useEffect(() => {
    setParentHeight(height - 150);
  }, [height]);

  useEffect(() => {
    const dataSource = new PivotGridDataSource({
      fields: [
        {
          caption: t('advisor'),
          dataField: 'advisor_name',
          width: 200,
          area: 'row',
          sortBySummaryField: 'Total',
        },
        {
          caption: t('country'),
          dataField: 'country_name',
          width: 150,
          area: 'row',
        },
        {
          caption: t('candidate'),
          dataField: 'candidate_name',
          width: 150,
          area: 'row',
        },
        {
          caption: t('status'),
          dataField: 'status_name',
          area: 'column',
        },
        {
          dataField: 'country_id',
          area: 'column',
          visible: false,
        },
        {
          dataField: 'status_id',
          area: 'column',
          visible: false,
        },
        {
          dataField: 'language_id',
          area: 'column',
          visible: false,
        },
        {
          dataField: 'first_name',
          area: 'column',
          visible: false,
        },
        {
          dataField: 'last_name',
          area: 'column',
          visible: false,
        },
        {
          dataField: 'language_level',
          area: 'column',
          visible: false,
        },
        {
          dataField: 'language_name',
          area: 'column',
          visible: false,
        },
        {
          dataField: 'school_name',
          area: 'column',
          visible: false,
        },
        {
          caption: t('total'),
          dataField: 'uuid',
          summaryType: 'count',
          dataType: 'number',
          area: 'data',
        },
      ],
      store: candidatesOverview,
    });

    setReportData(dataSource);

    pivotGrid.current.instance.bindChart(chart.current.instance, {
      dataFieldsDisplayMode: 'splitPanes',
      alternateDataFields: false,
    });
  }, [candidatesOverview, t]);

  useEffect(() => {
    dispatch(fetchCandidatesOverview());
  }, [dispatch]);

  const customizeTooltip = (args) => {
    const valueText = args.originalValue;
    return {
      html: `${args.seriesName} | Total ${valueText}`,
    };
  };

  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Overview');

    exportPivotGrid({
      component: e.component,
      worksheet,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Overview.xlsx');
      });
    });
  };

  return (
    <Grid
      container
      direction="row"
      spacing={3}
    >
      <Grid item xs={12} md={1} />
      <Grid item xs={12} md={10}>
        <Card>
          <Grid containe m={3}>
            <Grid item md={12}>
              <Chart ref={chart}>
                <Size height={200} />
                <CommonSeriesSettings type="stackedbar" />
                <AdaptiveLayout width={450} />
                <Tooltip enabled customizeTooltip={customizeTooltip} />
              </Chart>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} md={1} />
      <Grid item xs={12} md={12}>
        <Card>
          <Grid containe m={3}>
            <Grid item md={12}>
              <PivotGrid
                id="candidates-overview"
                dataSource={reportData}
                allowSortingBySummary
                allowSorting
                allowFiltering
                showBorders={false}
                showColumnTotals
                showColumnGrandTotals
                showRowTotals
                showRowGrandTotals
                ref={pivotGrid}
                onExporting={onExporting}
                height={parentHeight}
              >
                <FieldChooser enabled height={400} />
                <Export enabled />
                <Scrolling mode="virtual" />
              </PivotGrid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Overview;
