import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import PermissionService from 'services/permission.service';

// Functions
import {
  fulfilledCreatedReducer,
  fulfilledReducer,
  fulfilledSavedReducer,
  generateDropboxProperties,
  pendingReducer,
  rejectionReducer,
} from 'Util';

export const fetchPermissions = createAsyncThunk(
  'permission/fetchPermissions',
  async (payload, { rejectWithValue }) => {
    try {
      return await PermissionService.getPermissions();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchPermission = createAsyncThunk(
  'permission/fetchPermission',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await PermissionService.getPermission(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createPermission = createAsyncThunk(
  'permission/createPermission',
  async (payload, { rejectWithValue }) => {
    const { name, content } = payload;
    try {
      return await PermissionService.createPermission(name, content);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updatePermission = createAsyncThunk(
  'permission/updatePermission',
  async (payload, { rejectWithValue }) => {
    const { id, name, content } = payload;
    try {
      return await PermissionService.updatePermission(id, name, content);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deletePermission = createAsyncThunk(
  'permission/deletePermission',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await PermissionService.deletePermission(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

/* eslint-disable no-param-reassign */
export const permissionSlice = createSlice({
  name: 'permission',
  initialState: {
    permissionInfo: {},
    permissions: [],
    message: '',
    errors: [],
    editing: false,
    created: false,
    saved: false,
    deleted: false,
    failed: false,
    succeeded: false,
  },
  reducers: {
    clearPermission: (state) => {
      state.message = '';
      state.errors = [];
      state.editing = false;
      state.succeeded = false;
      state.created = false;
      state.saved = false;
      state.deleted = false;
      state.failed = false;
      state.permissionInfo = {
        name: '',
      };
    },
    setPermissionEditing: (state, action) => {
      state.editing = action.payload;
    },
    setCreated: (state, action) => {
      state.created = action.payload;
    },
    setSaved: (state, action) => {
      state.saved = action.payload;
    },
    setDeleted: (state, action) => {
      state.deleted = action.payload;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setPermissionInfo: (state, action) => {
      state.permissionInfo = action.payload;
    },
  },
  extraReducers: (builder) => builder
    .addMatcher(
      (action) => action.type.endsWith('/rejected'),
      rejectionReducer,
    )
    .addMatcher((action) => action.type.endsWith('/pending'), pendingReducer)
    .addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, action) => {
        const performedAction = action.type.split('/');
        if (performedAction[0] === 'permission') {
          switch (performedAction[1]) {
            case 'fetchPermissions':
              fulfilledReducer(state, action);
              state.permissions = generateDropboxProperties(action);
              break;
            case 'fetchPermission':
              fulfilledReducer(state, action);
              state.permissionInfo = action.payload;
              break;
            case 'createPermission':
              fulfilledCreatedReducer(state, action);
              state.succeeded = true;
              break;
            case 'updatePermission':
              fulfilledSavedReducer(state, action);
              state.succeeded = true;
              break;
            default:
              fulfilledReducer(state, action);
              state.message = action.payload;
              break;
          }
        }
      },
    ),
});

/* eslint-disable no-param-reassign */
export const {
  clearPermission,
  setPermissionEditing,
  setCreated,
  setSaved,
  setFailed,
  setDeleted,
  setSucceeded,
  setPermissionInfo,
} = permissionSlice.actions;

export default permissionSlice.reducer;
