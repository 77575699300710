import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Functions
import { clearAgreement, fetchAgreements, setSucceeded } from 'reducers/agreementsSlice';

// @mui material components
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';

// Soft UI Dashboard PRO React components
import SuiButton from 'components/SuiButton';

// DevExpress components
import DataGrid, {
  Column,
  Editing,
  HeaderFilter,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Sorting,
  StateStoring,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.material.blue.light.css';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import { useTranslation } from 'react-i18next';

// Functions
import { setTitle } from 'Util';
import Grid from '@mui/material/Grid';

const selector = (state) => ({
  agreements: state.agreement.agreements,
});

function Agreements() {
  const { t } = useTranslation('translation', { keyPrefix: 'agreements' });

  const { agreements } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  setTitle('Agreements');

  useEffect(() => {
    dispatch(fetchAgreements());
    dispatch(setSucceeded(false));
    dispatch(clearAgreement());
  }, [dispatch]);

  const allowedPageSizes = [10, 30, 50, 'all'];

  const handleNew = () => {
    navigate('new', { replace: true });
  };

  const handleEditing = (e) => {
    navigate(`/agreements/edit/${e.key}`);
  };

  return (
    <BaseLayout>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        mb={2}
      >
        <Grid item>
          <SuiButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleNew}
          >
            <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
            &nbsp;
            {t('add-new-agreement')}
          </SuiButton>
        </Grid>
      </Grid>
      <Paper>
        <DataGrid
          id="agreements"
          dataSource={agreements}
          keyExpr="id"
          allowColumnReordering
          allowColumnResizing
          columnAutoWidth
          onEditingStart={handleEditing}
          width={800}
        >
          <Editing
            mode="row"
            allowUpdating
            allowDeleting={false}
            allowAdding={false}
          />
          <HeaderFilter visible />
          <Scrolling rowRenderingMode="virtual" />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={30} />
          <Pager
            visible
            allowedPageSizes={allowedPageSizes}
            displayMode="full"
            showPageSizeSelector
            showInfo
            showNavigationButtons
          />
          <SearchPanel
            visible
            width={240}
            placeholder={t('search', { keyPrefix: 'common' })}
          />
          <StateStoring
            enabled
            type="localStorage"
            storageKey="agreements-grid"
          />
          <Column
            caption={t('name', { keyPrefix: 'common' })}
            dataField="name"
          />
          <Column
            caption={t('language', { keyPrefix: 'common' })}
            dataField="language"
          />
        </DataGrid>
      </Paper>
    </BaseLayout>
  );
}

export default Agreements;
