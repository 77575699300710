import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Reducers
import { fetchInvoiceLimited } from 'reducers/invoicesSlice';
import { createPaypalPayment, createStripePayment } from 'reducers/paymentsSlice';

// @mui material components
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';

// Images
import check from 'assets/images/check-mark.png';

// @fontawesome components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';

// Components
import BaseLayoutNoBars from 'layouts/components/BaseLayoutNoBars';

const cardStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const boxStyle = {
  display: 'flex',
  justifyContent: 'center',
  p: 1,
  m: 1,
  borderRadius: 1,
};

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const selector = (state) => ({
  invoiceInfo: state.invoice.invoiceInfo,
});

function PaymentConfirmation() {
  const { invoiceInfo } = useSelector(selector, shallowEqual);

  const query = useQuery();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(
      fetchInvoiceLimited({
        id,
      }),
    );
  }, [dispatch, id]);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleOpenConfirmation = () => setShowConfirmation(true);
  // eslint-disable-next-line no-undef,no-return-assign
  const goHome = () => window.location = '/';

  if (invoiceInfo.uuid !== undefined && query.get('provider') !== undefined && !showConfirmation) {
    if (query.get('provider') === 'stripe') {
      handleOpenConfirmation();

      dispatch(
        createStripePayment({
          paymentIntent: query.get('payment_intent'),
        }),
      );
    }

    if (query.get('provider') === 'paypal') {
      handleOpenConfirmation();

      dispatch(
        createPaypalPayment({
          id: query.get('payment'),
        }),
      );
    }
  }

  return (
    <BaseLayoutNoBars>
      <Modal
        open={showConfirmation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={cardStyle}>
          <SuiBox mt={2} mx={2} sx={boxStyle}>
            <SuiBox component="img" src={check} width="50%" borderRadius="lg" />
          </SuiBox>
          <SuiBox pt={2} pb={3} px={3} sx={boxStyle}>
            <SuiTypography
              id="modal-modal-description"
              display="inline"
              variant="h5"
              textTransform="capitalize"
              fontWeight="bold"
            >
              Invoice Paid!
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={2} mb={3} sx={boxStyle}>
            <SuiTypography variant="body2" component="p" color="text">
              Thank you for your payment
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={2} mb={3} sx={boxStyle}>
            <SuiButton
              color="secondary"
              size="large"
              sx={{
                width: '30%',
              }}
              onClick={goHome}
            >
              <FontAwesomeIcon icon={faAngleRight} />
            </SuiButton>
          </SuiBox>
        </Card>
      </Modal>
    </BaseLayoutNoBars>
  );
}

export default PaymentConfirmation;
