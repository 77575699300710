// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Soft UI Dashboard PRO React base styles
import colors from 'assets/theme/base/colors';

function Profile(
  {
    color,
    size,
  },
) {
  return (
    <svg
      width={size}
      height={size}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
      viewBox="0 0 750 750"
    >
      <title>profile</title>
      <g id="Basic-Elements" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          fill={colors[color] ? colors[color].main : colors.dark.main}
        >
          <path d="M375,229.1c0-85.9-69.4-157.1-153.1-157.1S68.8,143.1,68.8,229.1s69.4,157.1,153.1,157.1C305.6,386,375,315,375,229.1z
               M130,229.1c0-52.4,40.8-94.2,91.9-94.2s91.9,41.9,91.9,94.2c0,52.4-40.8,94.2-91.9,94.2S130,281.3,130,229.1z"
          />
          <path d="M375,641.5l59.2-8.4l-32.7-207.3l-110.3-37.7l-18.3,58.7l75.5,25.1L375,641.5z" />
          <path d="M7.5,631.1l61.2,10.5l24.5-171.8l75.5-25.1l-18.4-58.6L40,423.9L7.5,631.1z" />
          <path d="M497.5,143.1H620v71.1H497.5V143.1z" />
          <path d="M497.5,285.2h245v71.1h-245V285.2L497.5,285.2z" />
          <path d="M497.5,427.3h245v71.1h-245V427.3z" />
          <path d="M497.5,569.4h245v71.1h-245V569.4z" />
        </g>
      </g>
    </svg>
  );
}

// Setting admin values for the props of Profile
Profile.defaultProps = {
  color: 'dark',
  size: '16px',
};

// Typechecking props for the Profile
Profile.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
    'light',
    'white',
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Profile;
