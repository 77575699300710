import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

// Reducer functions
import { fetchCandidateProfile } from 'reducers/candidatesSlice';

import HandshakeTwoToneIcon from '@mui/icons-material/HandshakeTwoTone';

// Layout components
import LoadingSpinner from 'layouts/components/LoadingSpinner';
import SimpleInfoCard from 'layouts/components/Cards/InfoCards/SimpleInfoCard';

// Other components
import PropTypes from 'prop-types';

// Functions

const selector = (state) => ({
  profile: state.candidate.profile,
  status: state.account.status,
});

function Recruiter(
  {
    uuid,
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'widgets' });
  const {
    profile,
    status,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [recruiter, setRecruiter] = useState('');

  useEffect(() => {
    if (uuid !== undefined) {
      dispatch(fetchCandidateProfile({ uuid }));
    }
  }, [dispatch, uuid]);

  useEffect(() => {
    if (profile.recruiter !== undefined && profile.recruiter !== null) {
      setRecruiter(profile.recruiter.name);
    } else {
      setRecruiter(t('none'));
    }

    if (status === 'loading') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [profile, status, t]);

  return (
    <div>
      {isLoading && (<LoadingSpinner />)}
      {!isLoading && (
        <SimpleInfoCard
          title={t('recruiter')}
          value={recruiter}
          icon={<HandshakeTwoToneIcon fontSize="large" />}
        />
      )}
    </div>
  );
}

Recruiter.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default Recruiter;
