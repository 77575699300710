import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// @material-ui core components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';

// Reducer functions
import {
  fetchCandidateEvaluations,
  setCredentialEvaluations,
  setCurrentSubView,
  setRemoveData,
} from 'reducers/candidatesSlice';
import { fetchCredentialEvaluators } from 'reducers/commonSlice';
import { fetchDocuments } from 'reducers/documentsSlice';

// Components
import DefaultCredentialEvaluationCard
  from 'layouts/components/Cards/CredentialEvaluationCards/DefaultCredentialEvaluationCard';

// Functions
import {
  checkPermission, getCandidateUUID, isValid, setTitle,
} from 'Util';

const selector = (state) => ({
  isAdmin: state.auth.isAdmin,
  currentUser: state.auth.user,
  editing: state.candidate.editing,
  credentialEvaluations: state.candidate.credentialEvaluations,
  removeData: state.candidate.removeData,
  credentialEvaluators: state.common.credentialEvaluators,
  documents: state.document.documents,
  credentialDocuments: state.candidate.documents,
});

function CredentialEvaluations() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'candidates.credentials-evaluation',
  });
  const {
    isAdmin,
    currentUser,
    editing,
    credentialEvaluations,
    removeData,
    credentialEvaluators,
    documents,
    credentialDocuments,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    dispatch(setCurrentSubView('credentials-evaluation'));
    dispatch(fetchCredentialEvaluators());
    dispatch(fetchDocuments());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateEvaluations({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  useEffect(() => {
    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateEvaluations({ uuid }));
    }
  }, [dispatch, editing, uuid, credentialDocuments]);

  setTitle('Candidate Credential Evaluation');

  const processTypes = [
    {
      id: 1,
      value: 1,
      name: 'ADN',
      label: 'ADN',
    },
    {
      id: 2,
      value: 2,
      name: 'BSN',
      label: 'BSN',
    },
  ];

  const setValue = (key, value, i) => {
    dispatch(
      setCredentialEvaluations(
        credentialEvaluations.map((obj, index) => {
          if (index === i) {
            return {
              ...obj,
              [key]: value,
              changed: true,
              valid: isValid(key, value, credentialEvaluations[i]),
            };
          }
          return obj;
        }),
      ),
    );
  };

  const handleAddCredentialEvaluation = () => {
    dispatch(
      setCredentialEvaluations(
        credentialEvaluations.concat({
          id: undefined,
          credential_evaluator_id: undefined,
          expiration_date: '',
          license_docs_submitted: false,
          license_docs_submission_method_id: undefined,
          license_docs_submission_date: '',
          license_docs_submission_tracking: '',
          license_docs_submission_carrier: '',
          license_verified: false,
          license_verification_date: '',
          process_type_id: undefined,
          started_date: '',
          translation_submitted: false,
          translation_submission_date: '',
          university_docs_verified: false,
          university_docs_verification_date: '',
          university_docs: [],
          evaluation_documents: [],
          changed: true,
          valid: false,
        }),
      ),
    );
  };

  const handleRemoveCredentialEvaluation = (i) => {
    if (credentialEvaluations[i].id !== undefined) {
      dispatch(
        setRemoveData({
          ...removeData,
          credentialEvaluations: removeData.credentialEvaluations.concat(
            credentialEvaluations[i].id,
          ),
        }),
      );
    }

    dispatch(
      setCredentialEvaluations([
        ...credentialEvaluations.slice(0, i),
        ...credentialEvaluations.slice(i + 1),
      ]),
    );
  };

  return (
    <SuiBox component="form">
      <SuiBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <SuiTypography variant="h4" fontWeight="medium">
              {t('credentials-evaluation')}
            </SuiTypography>
            {isAdmin && editing && (
              <SuiBox mt={1} mb={2}>
                <SuiTypography variant="body2" color="text">
                  {t('credentials-evaluation-description')}
                </SuiTypography>
              </SuiBox>
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <SuiBox display="flex" justifyContent="flex-end">
              <SuiButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={handleAddCredentialEvaluation}
              >
                {t('new-credential-evaluation')}
              </SuiButton>
            </SuiBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={12}>
            <SuiBox pb={1} px={1}>
              {Object.keys(credentialEvaluations).length > 0
                && credentialEvaluations.map((e, i) => (
                  <div key={`credentialEvaluation${i}`}>
                    <DefaultCredentialEvaluationCard
                      i={i}
                      e={e}
                      ops={{
                        evaluators: credentialEvaluators,
                        processTypes,
                        documents,
                      }}
                      setValue={setValue}
                      removeFunc={handleRemoveCredentialEvaluation}
                      evalDocs={checkPermission('CEED', currentUser)}
                      uniDocs={checkPermission('CEUD', currentUser)}
                      licDocs={checkPermission('CELD', currentUser)}
                      veriSubm={checkPermission('CEVS', currentUser)}
                      trasnDocs={checkPermission('CETD', currentUser)}
                    />
                    <Divider />
                  </div>
                ))}
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default CredentialEvaluations;
