import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiInput from 'components/SuiInput';

function FormPasswordInput(
  {
    label,
    textTransform,
    value,
    showPassword,
    showCopy,
    ...rest
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'components' });

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const copyClipboard = () => {
    navigator.clipboard.writeText(value);
  };

  let columnSize = 12;

  if (showPassword) {
    columnSize -= 1;
  }

  if (showCopy) {
    columnSize -= 1;
  }

  return (
    <Grid
      container
      direction="row"
    >
      <Grid item md={columnSize}>
        <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
          <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform={textTransform}
            >
              {label}
            </SuiTypography>
          </SuiBox>
          <SuiInput
            type={passwordShown ? 'text' : 'password'}
            value={value}
            {...rest}
          />
        </SuiBox>
      </Grid>
      {showPassword && (
        <Grid item md={1} pt={3}>
          <Tooltip title={t('show-password')} placement="left">
            <IconButton onClick={togglePassword}>
              {(passwordShown) && (
                <VisibilityIcon color="info" />
              )}
              {(!passwordShown) && (
                <VisibilityOffIcon />
              )}
            </IconButton>
          </Tooltip>
        </Grid>
      )}
      {showCopy && (
        <Grid item xs={10} md={1} pt={3}>
          <Tooltip title={t('copy-clipboard')} placement="left">
            <IconButton onClick={copyClipboard}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
}

// Setting values for the props of FormPasswordInput
FormPasswordInput.defaultProps = {
  label: ' ',
  textTransform: 'capitalize',
  value: '',
  showPassword: true,
  showCopy: true,
};

// Typechecking props for FormPasswordInput
FormPasswordInput.propTypes = {
  label: PropTypes.string,
  textTransform: PropTypes.string,
  value: PropTypes.string,
  showPassword: PropTypes.bool,
  showCopy: PropTypes.bool,
};

export default FormPasswordInput;
