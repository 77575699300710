import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Reducer functions
import { fetchVendors } from 'reducers/vendorSlice';
import { fetchCandidatesOutlook } from 'reducers/candidatesSlice';
import { fetchPaymentMethods } from 'reducers/paymentMethodSlice';
import { fetchExpenseCategories, setExpenseInfo } from 'reducers/expenseSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Icon from '@mui/material/Icon';

// Soft UI Dashboard PRO React components
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';
import SuiEditor from 'components/SuiEditor';
import SuiBox from 'components/SuiBox';

// Functions
import { currencyFormatter, defaultValue, findSelectValue } from 'Util';

// Components
import FormSelect from 'layouts/components/FormSelect';
import FormField from 'layouts/components/FormField';
import SavePanel from '../SavePanel';
import FormSwitch from '../../../components/FormSwitch';

const selector = (state) => ({
  expenseInfo: state.expense.expenseInfo,
  paymentMethods: state.paymentMethod.paymentMethods,
  expenseCategories: state.expense.expenseCategories,
  candidates: state.candidate.candidatesOutlook,
  vendors: state.vendor.vendors,
});

function ExpenseInfo({ candidateUuid }) {
  const { t } = useTranslation('translation', { keyPrefix: 'expenses' });
  const {
    expenseInfo,
    paymentMethods,
    expenseCategories,
    candidates,
    vendors,
  } = useSelector(
    selector,
    shallowEqual,
  );
  const dispatch = useDispatch();

  const [candidateInfo, setCandidateInfo] = useState({ id: null });
  const [expenseItems, setExpenseItems] = useState([]);
  const [expenseTotal, setExpenseTotal] = useState(0);

  useEffect(() => {
    dispatch(fetchVendors());
    dispatch(fetchCandidatesOutlook({ statuses: '*' }));
    dispatch(fetchPaymentMethods());
    dispatch(fetchExpenseCategories());
  }, [dispatch]);

  useEffect(() => {
    if (candidates !== undefined && candidateUuid !== undefined) {
      setCandidateInfo(candidates.filter((c) => c.uuid === candidateUuid)[0]);
    }
  }, [candidateUuid, candidates]);

  useEffect(() => {
    if (expenseInfo.expense_items !== undefined) {
      // eslint-disable-next-line no-underscore-dangle
      setExpenseItems(expenseInfo.expense_items.filter((i) => i._destroy !== true));
    }
  }, [expenseInfo]);

  useEffect(() => {
    let oTotal = 0;

    expenseItems.forEach((i) => {
      oTotal += Number(i.amount * i.quantity);
    });

    setExpenseTotal(oTotal);
  }, [expenseItems]);

  useEffect(() => {
    dispatch(
      setExpenseInfo(
        {
          ...expenseInfo,
          total: expenseTotal,
          changed: true,
        },
      ),
    );
    // eslint-disable-next-line
  }, [expenseTotal]);

  const payableBy = [
    {
      value: 1,
      label: t('nas'),
    },
    {
      value: 2,
      label: t('customer'),
    },
    {
      value: 3,
      label: t('candidate'),
    },
  ];

  const setValue = (key, value) => {
    dispatch(
      setExpenseInfo(
        {
          ...expenseInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  const setItems = (value) => {
    dispatch(
      setExpenseInfo(
        {
          ...expenseInfo,
          expense_items: value,
          changed: true,
        },
      ),
    );
  };

  const setItemValue = (key, value, i) => {
    setItems(
      expenseInfo.expense_items.map((obj, index) => {
        if (index === i) {
          return {
            ...obj,
            [key]: value,
          };
        }
        return obj;
      }),
    );
  };

  const handleAddItem = () => {
    const items = expenseInfo.expense_items.concat({
      id: null,
      description: '',
      quantity: 0,
      amount: 0,
    });

    setItems(items);
  };

  const handleRemoveItem = (e, i) => {
    e.preventDefault();

    if (expenseInfo.expense_items[i].id !== undefined) {
      setItems(
        expenseInfo.expense_items.map((obj, index) => {
          if (index === i) {
            return {
              ...obj,
              _destroy: true,
            };
          }
          return obj;
        }),
      );
    } else {
      setItems([
        ...expenseInfo.expense_items.slice(0, i),
        ...expenseInfo.expense_items.slice(i + 1),
      ]);
    }
  };

  const handleReimbursable = () => {
    dispatch(
      setExpenseInfo(
        {
          ...expenseInfo,
          reimbursable: !expenseInfo.reimbursable,
          changed: true,
        },
      ),
    );
  };

  const handleBilled = () => {
    dispatch(
      setExpenseInfo(
        {
          ...expenseInfo,
          billed: !expenseInfo.billed,
          changed: true,
        },
      ),
    );
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={3}
    >
      <Grid item md={6}>
        <FormSelect
          label={t('vendor')}
          options={vendors}
          value={findSelectValue(vendors, expenseInfo.vendor_id)}
          onChange={(e) => { setValue('vendor_id', e.value); }}
        />
      </Grid>
      <Grid item md={6}>
        {candidateInfo.id === null && (
          <FormSelect
            label={t('candidate-name')}
            options={candidates}
            value={findSelectValue(candidates, expenseInfo.candidate_id)}
            onChange={(e) => { setValue('candidate_id', e.value); }}
            style={{ overflow: 'visible' }}
          />
        )}
      </Grid>
      <Grid item md={4}>
        <FormField
          label={t('date')}
          type="date"
          placeholder={t('select-date')}
          value={defaultValue(expenseInfo.date)}
          onChange={(e) => { setValue('date', e.target.value); }}
        />
      </Grid>
      <Grid item md={4}>
        <FormSelect
          label={t('payment-method')}
          options={paymentMethods}
          value={findSelectValue(paymentMethods, expenseInfo.payment_method_id)}
          onChange={(e) => { setValue('payment_method_id', e.value); }}
        />
      </Grid>
      <Grid item md={4}>
        <FormSelect
          label={t('expense-category')}
          options={expenseCategories}
          value={findSelectValue(expenseCategories, expenseInfo.expense_category_id)}
          onChange={(e) => { setValue('expense_category_id', e.value); }}
        />
      </Grid>
      <Grid item md={4}>
        <FormSelect
          label={t('payable-by')}
          options={payableBy}
          value={findSelectValue(payableBy, expenseInfo.pay_by)}
          onChange={(e) => { setValue('pay_by', e.value); }}
        />
      </Grid>
      <Grid item md={4} mt={3}>
        <FormSwitch
          label={`${t('reimbursable')}: ${expenseInfo.reimbursable
            ? t('yes', { keyPrefix: 'common' }) : t('no', { keyPrefix: 'common' })}`}
          checked={!!expenseInfo.reimbursable}
          onChange={handleReimbursable}
        />
      </Grid>
      <Grid item md={4} mt={3}>
        <FormSwitch
          label={`${t('billed')}: ${expenseInfo.billed
            ? t('yes', { keyPrefix: 'common' }) : t('no', { keyPrefix: 'common' })}`}
          checked={!!expenseInfo.billed}
          onChange={handleBilled}
        />
      </Grid>
      <Grid item md={12}>
        <Divider />
      </Grid>
      <Grid item md={12}>
        <Card>
          <CardHeader
            title={t('expense-details')}
          />
          <CardContent>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={3}
              margin={2}
            >
              <Grid item md={8}>
                <SuiTypography variant="h6" fontWeight="medium">
                  {t('description')}
                </SuiTypography>
              </Grid>
              <Grid item md={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  {t('qty')}
                </SuiTypography>
              </Grid>
              <Grid item md={2}>
                <SuiTypography variant="h6" fontWeight="medium">
                  {t('amount')}
                </SuiTypography>
              </Grid>
              <Grid item md={1} />
              {Object.keys(expenseItems).length > 0
                && expenseItems.map((oi, i) => (
                  <>
                    <Grid item md={8}>
                      <FormField
                        value={oi.description}
                        onFocus={(e) => { e.target.select(); }}
                        onChange={(e) => { setItemValue('description', e.target.value, i); }}
                      />
                    </Grid>
                    <Grid item md={1}>
                      <FormField
                        value={oi.quantity}
                        onFocus={(e) => { e.target.select(); }}
                        onChange={(e) => { setItemValue('quantity', e.target.value, i); }}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <FormField
                        value={oi.amount}
                        onFocus={(e) => { e.target.select(); }}
                        onChange={(e) => { setItemValue('amount', e.target.value, i); }}
                      />
                    </Grid>
                    <Grid item md={1} mt={2}>
                      <CancelOutlinedIcon
                        color="error"
                        onClick={(e) => {
                          handleRemoveItem(e, i);
                        }}
                      />
                    </Grid>
                  </>
                ))}
              <Grid item md={12}>
                <Grid display="flex" justifyContent="flex-end">
                  <Grid item md={10} />
                  <Grid item md={1}>
                    <SuiButton
                      variant="text"
                      color="info"
                      size="small"
                      onClick={handleAddItem}
                    >
                      <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
                      {` ${t('add-new-item')}`}
                    </SuiButton>
                  </Grid>
                  <Grid item md={1} />
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Grid display="flex" justifyContent="flex-end">
                  <Grid item md={8} />
                  <Grid item md={2}>
                    <SuiTypography variant="h3" fontWeight="medium">
                      {t('total')}
                    </SuiTypography>
                  </Grid>
                  <Grid item md={2}>
                    <SuiTypography variant="h3" fontWeight="medium">
                      {currencyFormatter.format(Math.round(expenseTotal))}
                    </SuiTypography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={11}>
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    {t('memo')}
                  </SuiTypography>
                </SuiBox>
                <SuiEditor
                  value={expenseInfo.memo}
                  onChange={(e) => setValue('memo', e)}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={12}>
        <SavePanel />
      </Grid>
    </Grid>
  );
}

// Setting admin values for the props of ExpenseInfo
ExpenseInfo.defaultProps = {
  candidateUuid: undefined,
};

// Typechecking props for the ExpenseInfo
ExpenseInfo.propTypes = {
  candidateUuid: PropTypes.string,
};
export default ExpenseInfo;
