import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

// Reducer functions
import { setCurrentSubView } from 'reducers/candidatesSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Functions
import { checkPermission } from 'Util';

// Components
import Preferences from '../Preferences';
import Advisors from '../Advisors';
import Recruiters from '../Recruiters';
import DeleteCandidate from '../DeleteCandidate';

const selector = (state) => ({
  currentUser: state.auth.user,
});

function AdminGeneral() {
  const {
    currentUser,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentSubView('general'));
  }, [dispatch]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        {checkPermission('CAAP', currentUser) && <Preferences />}
        <Divider />
        {checkPermission('CAAA', currentUser) && <Advisors />}
        <Divider />
        {checkPermission('CAAR', currentUser) && <Recruiters />}
        <Divider />
        {checkPermission('CAAD', currentUser) && <DeleteCandidate />}
      </Grid>
    </Grid>
  );
}

export default AdminGeneral;
