import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { fetchCandidateSummaries } from 'reducers/reportsSlice';

// Components
import BaseTable from 'layouts/reports/components/BaseTable';

// Functions
import { setTitle } from 'Util';

const selector = (state) => ({
  candidateSummaries: state.reports.candidateSummaries,
});

function Summaries() {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { candidateSummaries } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  setTitle('Candidates Summary Report');

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(fetchCandidateSummaries());
  }, [dispatch]);

  useEffect(() => {
    if (candidateSummaries !== undefined) {
      setData(candidateSummaries);
    }
  }, [candidateSummaries]);

  const handleDateFormat = (d) => {
    if (d !== null) {
      return Date.parse(d);
    }
    return null;
  };

  const columns = [
    {
      caption: t('first-name', { keyPrefix: 'common' }),
      field: 'first_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('last-name', { keyPrefix: 'common' }),
      field: 'last_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('country-of-origin', { keyPrefix: 'candidates.basic-info' }),
      field: 'country',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('status'),
      field: 'status_name',
      visible: false,
      allowFixing: true,
    },
    {
      caption: t('last-status-change'),
      field: 'last_status_change',
      calculateCellValue: (d) => handleDateFormat(d.last_status_change),
      visible: false,
      allowFixing: true,
      dataType: 'date',
    },
    {
      caption: t('english-academy'),
      field: 'school_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('english-level'),
      field: 'level',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('english-level-date'),
      field: 'english_evaluation_date',
      visible: true,
      allowFixing: true,
      dataType: 'date',
    },
    {
      caption: t('certification-evaluator'),
      field: 'credential_evaluator_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('certification-application-date'),
      field: 'credential_evaluation_started_date',
      visible: true,
      allowFixing: true,
      dataType: 'date',
    },
    {
      caption: t('ces-issue-date'),
      field: 'ces_issue_date',
      visible: true,
      allowFixing: true,
      dataType: 'date',
    },
    {
      caption: t('bon-state'),
      field: 'bon_state',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('bon-date'),
      field: 'bon_date',
      visible: true,
      allowFixing: true,
      dataType: 'date',
    },
    {
      caption: t('fingerprints-state'),
      field: 'fp_state',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('fingerprints-date'),
      field: 'fp_date',
      visible: true,
      allowFixing: true,
      dataType: 'date',
    },
    {
      caption: t('att-received'),
      field: 'att_received',
      visible: true,
      allowFixing: true,
      dataType: 'date',
    },
    {
      caption: t('nclex-scheduled'),
      field: 'nclex_scheduled',
      visible: true,
      allowFixing: true,
      dataType: 'date',
    },
    {
      caption: t('nclex-result'),
      field: 'nclex_result',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('archer-registration-date'),
      field: 'archer_registration_date',
      visible: true,
      allowFixing: true,
      dataType: 'date',
    },
    {
      caption: t('saunders-registration-date'),
      field: 'saunders_registration_date',
      visible: true,
      allowFixing: true,
      dataType: 'date',
    },
    {
      caption: t('last-meeting-date'),
      field: 'meeting_starts',
      visible: true,
      allowFixing: true,
      dataType: 'date',
    },
    {
      caption: t('last-meeting-subject'),
      field: 'meeting_subject',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('last-note-date'),
      field: 'note_date',
      visible: true,
      allowFixing: true,
      dataType: 'date',
    },
    {
      caption: t('last-note-title'),
      field: 'note_title',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('advisor'),
      field: 'advisor_name',
      visible: false,
      allowFixing: true,
    },
    {
      caption: t('middle-name', { keyPrefix: 'common' }),
      field: 'middle_name',
      visible: false,
      allowFixing: true,
    },
    {
      caption: t('payment-plan'),
      field: 'payment_plan',
      visible: false,
      allowFixing: true,
    },
    {
      caption: t('first-payment-date'),
      field: 'first_payment_date',
      visible: false,
      allowFixing: true,
      dataType: 'date',
    },
    {
      caption: t('last-payment-date'),
      field: 'last_payment_date',
      visible: false,
      allowFixing: true,
      dataType: 'date',
    },
    {
      caption: t('balance'),
      field: 'balance',
      visible: false,
      allowFixing: true,
      dataType: 'number',
      format: 'currency',
    },
    {
      caption: t('account-status'),
      field: 'account_status',
      visible: false,
      allowFixing: true,
    },
    {
      caption: t('recruiter-name'),
      field: 'recruiter_name',
      visible: false,
      allowFixing: true,
    },
    {
      caption: t('nash-contract-signed-on'),
      field: 'nash_contract_signed_on',
      visible: false,
      allowFixing: true,
      dataType: 'date',
    },
    {
      caption: t('nas-contract-signed-on'),
      field: 'nas_contract_signed_on',
      visible: false,
      allowFixing: true,
      dataType: 'date',
    },
  ];

  return (
    <BaseTable name="CandidatesSummaries" data={data} keyExpr="uuid" columns={columns} />
  );
}

export default Summaries;
