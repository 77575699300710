import { useState } from 'react';

// Functions
import dayjs from 'dayjs';

// @mui material components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';

// Components
import FormField from 'layouts/components/FormField';

// PropTypes
import PropTypes from 'prop-types';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';

const cardStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Overrides(
  {
    overrides,
    addFunc,
    removeFunc,
  },
) {
  // eslint-disable-next-line global-require
  const utc = require('dayjs/plugin/utc');
  // eslint-disable-next-line global-require
  const isBetween = require('dayjs/plugin/isBetween');
  dayjs.extend(utc);
  dayjs.extend(isBetween);

  const minDate = dayjs().startOf('day');

  const [loadHours, setLoadHours] = useState(true);
  const [showCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState(dayjs().local().startOf('day'));
  const [dateOverrides, setDateOverrides] = useState([]);

  const handleOpenCalendar = () => {
    setShowCalendar(true);
    setLoadHours(true);
  };
  const handleCloseCalendar = () => setShowCalendar(false);
  const handleDateSelection = (selectedDate) => {
    setDate(selectedDate);

    if (overrides !== undefined) {
      setDateOverrides(
        overrides.filter(
          (o) => dayjs(o.date)
            .local()
            .isBetween(
              dayjs(selectedDate).local().startOf('day'),
              dayjs(selectedDate).local().endOf('day'),
            ),
        ),
      );
    }
  };
  const handleAddOverride = (e, addedDate) => {
    addFunc(e, addedDate.add(1, 's').format('YYYY-MM-DD HH:mm:ss ZZ'));
    setLoadHours(true);
  };
  const handleRemoveOverride = (e, i) => {
    removeFunc(e, i);
    setLoadHours(true);
  };

  if (loadHours) {
    handleDateSelection(date);
    setLoadHours(false);
  }

  return (
    <div>
      <Grid container mt={2}>
        <SuiBox>
          <SuiTypography variant="h4" fontWeight="bold">
            Add date overrides
          </SuiTypography>
          <SuiTypography variant="body2" fontWeight="regular">
            Add dates when your availability changes from your weekly hours
          </SuiTypography>
        </SuiBox>
      </Grid>
      <Grid container direction="row" justifyContent="center" mt={2}>
        <Grid item>
          <SuiButton
            variant="outlined"
            color="secondary"
            onClick={handleOpenCalendar}
          >
            Add a date override
          </SuiButton>
        </Grid>
      </Grid>
      <Divider />
      <Grid container direction="row" justifyContent="center" mt={2}>
        {overrides !== undefined
          && Object.keys(overrides).length > 0
          && overrides.map((o, i) => (
            <Grid
              key={i}
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="baseline"
            >
              <Grid item xs={4} md={4}>
                {dayjs(o.date).format('MMM D, YYYY')}
              </Grid>
              <Grid item xs={7} md={7}>
                {(o.from === null || o.from === '') && (
                  <SuiTypography variant="h5" color="secondary" fontWeight="light">
                    Unavailable
                  </SuiTypography>
                )}
                {(o.from !== undefined && o.from !== null) && (
                  `${dayjs(o.from).local().format('h:mm A')} - ${dayjs(o.to).local().format('h:mm A')}`
                )}
              </Grid>
              <Grid item xs={1} md={1}>
                <SuiButton
                  variant="outlined"
                  color="secondary"
                  size="small"
                  iconOnly
                  sx={({ functions: { pxToRem } }) => ({
                    width: pxToRem(34),
                    minWidth: pxToRem(34),
                    height: pxToRem(34),
                    minHeight: pxToRem(34),
                  })}
                  onClick={(e) => removeFunc(e, i)}
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </SuiButton>
              </Grid>
              <Grid item xs={12} md={12}>
                <Divider />
              </Grid>
            </Grid>
          ))}
      </Grid>
      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
        <Grid item>
          <SuiButton
            variant="contained"
            color="info"
          >
            save
          </SuiButton>
        </Grid>
      </Grid>
      <Modal
        open={showCalendar}
        onClose={handleCloseCalendar}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={cardStyle}>
          <CardHeader
            title="Select the date(s) you want to assign specific hours"
          />
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} md={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  date={date}
                  minDate={minDate}
                  onChange={(newDate) => handleDateSelection(newDate)}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} mb={1}>
              <SuiBox xs={12} md={12}>
                <SuiTypography variant="h5" fontWeight="bold">
                  What hours are you available?
                </SuiTypography>
              </SuiBox>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={10} md={10}>
                  {Object.keys(dateOverrides).length > 0
                    && dateOverrides.map((o, i) => (
                      <Grid key={`override-${i}`} container direction="row" alignItems="center">
                        <Grid item xs={4} md={3}>
                          <Grid container justifyContent="center" alignItems="center">
                            <FormField
                              type="time"
                              value={o.from !== undefined ? dayjs(o.from).local().format('hh:mm') : ''}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={0} md={1}>
                          <Grid container justifyContent="center" alignItems="center">
                            -
                          </Grid>
                        </Grid>
                        <Grid item xs={4} md={3}>
                          <Grid container justifyContent="center" alignItems="center">
                            <FormField
                              type="time"
                              value={o.to !== undefined ? dayjs(o.to).local().format('hh:mm') : ''}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={2} md={2}>
                          <Grid container justifyContent="center" alignItems="center">
                            <SuiButton
                              variant="outlined"
                              color="secondary"
                              size="small"
                              iconOnly
                              sx={({ functions: { pxToRem } }) => ({
                                width: pxToRem(34),
                                minWidth: pxToRem(34),
                                height: pxToRem(34),
                                minHeight: pxToRem(34),
                              })}
                              onClick={(e) => handleRemoveOverride(e, i)}
                            >
                              <FontAwesomeIcon icon={faTrashCan} />
                            </SuiButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
                <Grid item xs={2} md={2}>
                  <Grid container justifyContent="center" alignItems="center">
                    <SuiButton
                      variant="outlined"
                      color="secondary"
                      size="small"
                      iconOnly
                      sx={({ functions: { pxToRem } }) => ({
                        width: pxToRem(34),
                        minWidth: pxToRem(34),
                        height: pxToRem(34),
                        minHeight: pxToRem(34),
                      })}
                      onClick={(e) => handleAddOverride(e, date)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </SuiButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Modal>
    </div>
  );
}

// Typechecking props for the Overrides
Overrides.propTypes = {
  overrides: PropTypes
    .arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        date: PropTypes.string,
        from: PropTypes.string,
        to: PropTypes.string,
      }),
    ).isRequired,
  addFunc: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
};

export default Overrides;
