import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Reducers functions
import { setServiceInfo } from 'reducers/servicesSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Components
import FormField from 'layouts/components/FormField';
import FormTextarea from 'layouts/components/FormTextarea';

const selector = (state) => ({
  serviceInfo: state.service.serviceInfo,
});

function ServiceInfo() {
  const { serviceInfo } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const setValue = (key, value) => {
    dispatch(
      setServiceInfo(
        {
          ...serviceInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  return (
    <Card id="service-info" sx={{ overflow: 'visible' }}>
      <SuiBox p={3}>
        <SuiTypography variant="h5">Service Info</SuiTypography>
      </SuiBox>
      <SuiBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              label="name"
              value={serviceInfo.name !== undefined ? serviceInfo.name : ''}
              onChange={(e) => {
                setValue('name', e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormTextarea
              label="description"
              value={serviceInfo.description !== undefined ? serviceInfo.description : ''}
              onChange={(e) => {
                setValue('description', e.target.value);
              }}
              minRows={10}
            />
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default ServiceInfo;
