import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// Reducers
import { fetchPaymentPlan, setPaymentPlanEditing } from 'reducers/paymentPlansSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import PlanInfo from '../components/PlanInfo';
import SavePanel from '../components/SavePanel';

function EditPlan() {
  const dispatch = useDispatch();

  const { id } = useParams();
  useEffect(() => {
    dispatch(setPaymentPlanEditing(true));
    dispatch(
      fetchPaymentPlan({
        id,
      }),
    );
  }, [dispatch, id]);

  return (
    <BaseLayout>
      <SuiBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SuiBox mb={3}>
              <PlanInfo />
              <SavePanel />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </BaseLayout>
  );
}

export default EditPlan;
