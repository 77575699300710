import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';

function FormCheckbox({ label, ...rest }) {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <SuiBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      height="100%"
    >
      <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </SuiTypography>
      </SuiBox>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Checkbox {...rest} />
    </SuiBox>
  );
}

// Setting admin values for the props of FormCheckbox
FormCheckbox.defaultProps = {
  label: ' ',
};

// Typechecking props for the FormCheckbox
FormCheckbox.propTypes = {
  label: PropTypes.string,
};

export default FormCheckbox;
