import { useTranslation } from 'react-i18next';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Soft UI Dashboard PRO React base styles
import typography from 'assets/theme/base/typography';

// Images
import error500 from 'assets/images/illustrations/error-500.png';

function Error() {
  const { t } = useTranslation('translation', { keyPrefix: 'links' });
  const {
    d1, d3, d4, d5,
  } = typography;

  return (
    <Grid
      container
      spacing={3}
      sx={{
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: '100%',
      }}
    >
      <Grid item xs={11} sm={9} container alignItems="center">
        <Grid item md={5}>
          <SuiBox
            fontSize={{
              xs: d5.fontSize, sm: d4.fontSize, md: d3.fontSize, lg: d1.fontSize,
            }}
            lineHeight={1.2}
          >
            <SuiTypography variant="inherit" color="warning" textGradient fontWeight="bold">
              {t('invalid-url')}
            </SuiTypography>
          </SuiBox>
          <SuiTypography variant="h2" color="text" fontWeight="bold">
            {t('something-went-wrong')}
          </SuiTypography>
          <SuiBox mt={1} mb={2}>
            <SuiTypography variant="body1" color="text" opacity={0.6}>
              {t('invalid-url-description')}
            </SuiTypography>
          </SuiBox>
        </Grid>
        <Grid item md={7}>
          <SuiBox component="img" src={error500} alt="error" width="100%" />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Error;
