// Components
import BaseLayout from 'layouts/components/BaseLayout';
import AccountSummary from '../components/Summary';

function AccountDetails() {
  return (
    <BaseLayout>
      <AccountSummary />
    </BaseLayout>
  );
}

export default AccountDetails;
