import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

// Reducer functions
import { fetchAccountBalance } from 'reducers/accountsSlice';

// @mui material components
import PaymentsTwoToneIcon from '@mui/icons-material/PaymentsTwoTone';

// Layout components
import SimpleInfoCard from 'layouts/components/Cards/InfoCards/SimpleInfoCard';
import LoadingSpinner from 'layouts/components/LoadingSpinner';

// Other components
import PropTypes from 'prop-types';

// Functions

const selector = (state) => ({
  balance: state.account.balance,
  status: state.account.status,
});

function PaymentPlans(
  {
    uuid,
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'widgets' });
  const {
    balance,
    status,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [plans, setPlans] = useState(0);

  useEffect(() => {
    if (uuid !== undefined) {
      dispatch(fetchAccountBalance({ id: uuid }));
    }
  }, [dispatch, uuid]);

  useEffect(() => {
    if (balance.payment_plans !== undefined) {
      setPlans(balance.payment_plans.join(', '));
    } else {
      setPlans(t('none'));
    }

    if (status === 'loading') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [balance, status, t]);

  return (
    <div>
      {isLoading && (<LoadingSpinner />)}
      {!isLoading && (
        <SimpleInfoCard
          title={t('payment-plans')}
          value={plans}
          icon={<PaymentsTwoToneIcon fontSize="large" />}
        />
      )}
    </div>
  );
}

PaymentPlans.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default PaymentPlans;
