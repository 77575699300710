import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { fetchCandidateProfile, preValidateCandidateInfo, validateCandidateInfo } from 'reducers/candidatesSlice';

// @material-ui core components
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import VerifiedIcon from '@mui/icons-material/Verified';

// Soft UI Dashboard PRO React components
import SuiButton from 'components/SuiButton';
import SuiTypography from 'components/SuiTypography';
import SuiAlert from 'components/SuiAlert';

// Util functions
import { getCandidateUUID } from 'Util';

const selector = (state) => ({
  editing: state.candidate.editing,
  profile: state.candidate.profile,
  preValidate: state.candidate.preValidate,
});

function InfoValidation() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'candidates.info-validation',
  });
  const {
    editing,
    profile,
    preValidate,
  } = useSelector(
    selector,
    shallowEqual,
  );
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  const [isValidated, setIsValidated] = useState(false);
  const [isPreValidated, setIsPreValidated] = useState(false);
  const [validating, setValidating] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (preValidate.validated !== undefined && preValidate.validated) {
      setValidating(false);
      setErrors(preValidate.messages);
      if (preValidate.isValid) {
        setIsPreValidated(true);
      }
    }
  }, [preValidate]);

  useEffect(() => {
    if (profile !== undefined) {
      if (profile.info_verified) {
        setIsValidated(true);
      }
    }
  }, [profile]);

  useEffect(() => {
    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateProfile({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  const handlePreValidate = () => {
    setValidating(true);

    dispatch(preValidateCandidateInfo({ uuid }));
  };

  const handleValidate = () => {
    dispatch(validateCandidateInfo({ uuid }));
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      pb={2}
    >
      {(editing && isValidated) && (
        <Grid item xs={12} md={12}>
          <SuiAlert color="success">
            <IconButton size="large">
              <VerifiedIcon color="light" />
            </IconButton>
            <SuiTypography variant="body3" color="light">
              {t('information-validated')}
            </SuiTypography>
          </SuiAlert>
        </Grid>
      )}
      {(editing && !isValidated && Object.keys(errors).length > 0) && (
        <Grid item xs={12} md={3}>
          {Object.keys(errors).length > 0
            && errors.map((e) => (
              <div>
                <SuiTypography variant="caption" color="error">{e}</SuiTypography>
              </div>
            ))}
        </Grid>
      )}
      {(editing && !isValidated) && (
        <Grid item xs={12} md={4}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            {(editing && isPreValidated) && (
              <SuiButton
                variant="gradient"
                color="info"
                size="small"
                onClick={handleValidate}
              >
                {t(validating ? 'validating' : 'validate-candidate-info')}
              </SuiButton>
            )}
            {(editing && !isPreValidated) && (
              <SuiButton
                variant="gradient"
                color="secondary"
                size="small"
                onClick={handlePreValidate}
              >
                {t(validating ? 'pre-validating' : 'pre-validate-candidate-info')}
              </SuiButton>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default InfoValidation;
