function englishColumns(t) {
  return (
    [
      {
        caption: t('candidate-name'),
        field: 'candidate_name',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('email'),
        field: 'email',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('advisor'),
        field: 'advisor_name',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('status'),
        field: 'candidate_status',
        visible: false,
        allowFixing: true,
      },
      {
        caption: t('school-name'),
        field: 'school_name',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('started-date'),
        field: 'started',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('completion-expected'),
        field: 'completion_expected',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('last-evaluation'),
        field: 'last_evaluation',
        visible: true,
        allowFixing: true,
      },
      {
        caption: t('level'),
        field: 'level',
        visible: true,
        allowFixing: true,
      },
    ]
  );
}

export default englishColumns;
