import {
  createCandidateDegree,
  deleteCandidateDegree,
  setErrors,
  setFailed,
  updateCandidateDegree,
} from 'reducers/candidatesSlice';
import {
  invalidRecords, modifiedRecords,
} from 'Util';

// eslint-disable-next-line import/prefer-default-export
export const saveEducation = (t, dispatch, education, candidateId) => {
  if (!invalidRecords(education).length > 0) {
    const degrees = education.filter((item) => item.changed === true);
    if (degrees.length > 0) {
      modifiedRecords(education).forEach((e) => {
        const data = {
          candidateId,
          degree: e.degree,
          educationLevelId: e.education_level_id,
          graduation: e.graduation,
          nameUnder: e.name_under,
          educationInstitutions: modifiedRecords(e.education_institutions),
        };

        // eslint-disable-next-line no-underscore-dangle
        if (e._destroy === true) {
          dispatch(deleteCandidateDegree({ id: e.id, candidateId }));
        } else if (typeof e.id !== 'undefined') {
          data.id = e.id;
          dispatch(updateCandidateDegree(data));
        } else {
          dispatch(createCandidateDegree(data));
        }
      });
    } else {
      dispatch(setErrors(t('no-changes-to-save', { keyPrefix: 'common' })));
      dispatch(setFailed(true));
    }
  } else {
    dispatch(setErrors(t('entry-is-required-or-has-an-invalid-value', { keyPrefix: 'common' })));
    dispatch(setFailed(true));
  }
};
