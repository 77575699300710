import Service from './service';

class AuthService extends Service {
  // eslint-disable-next-line class-methods-use-this
  login(email, password) {
    const data = {
      user: {
        email,
        password,
      },
    };

    return this.api
      .post(
        this.localizeURL('login'),
        data,
        {
          headers: {
            'content-type': 'application/json',
            accept: '*/*',
          },
        },
      )
      .then((response) => response.data);
  }

  // eslint-disable-next-line class-methods-use-this
  logout() {
    this.api
      .delete(
        'logout',
        { headers: this.getHeaders() },
      );
  }

  // eslint-disable-next-line class-methods-use-this
  register(email, firstName, lastName, roleId, password, preferredLanguage) {
    const data = {
      user: {
        email,
        password,
        role_id: roleId,
        first_name: firstName,
        last_name: lastName,
        preferred_language: preferredLanguage,
      },
    };
    return this.api
      .post(
        this.localizeURL('register'),
        data,
        { headers: this.getHeaders() },
      );
  }

  // eslint-disable-next-line class-methods-use-this
  confirmation(confirmationToken) {
    const data = {
      confirmation: {
        confirmation_token: confirmationToken,
      },
    };

    return this.api
      .post(
        'confirmation',
        data,
        { headers: this.getHeaders() },
      );
  }

  // eslint-disable-next-line class-methods-use-this
  unlock(unlockToken) {
    const data = {
      unlock: {
        unlock_token: unlockToken,
      },
    };

    return this.api
      .post(
        'unlock',
        data,
        { headers: this.getHeaders() },
      );
  }

  // eslint-disable-next-line class-methods-use-this
  ping() {
    return this.api
      .get(
        'ping',
        { headers: this.getHeaders() },
      );
  }
}

export default new AuthService();
