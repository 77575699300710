import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthService from 'services/auth.service';
import { fulfilledReducer, pendingReducer, rejectionReducer } from 'Util';

export const login = createAsyncThunk(
  'auth/login',
  async (payload, { rejectWithValue }) => {
    const {
      email,
      password,
    } = payload;
    try {
      const response = await AuthService.login(email, password);

      if (response.token != null) {
        // eslint-disable-next-line no-undef
        localStorage.setItem('user', JSON.stringify(response));
      }

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const logoutApi = createAsyncThunk(
  'auth/logout',
  async (payload, { rejectWithValue }) => {
    try {
      await AuthService.logout();
      localStorage.removeItem('user');
      return true;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const register = createAsyncThunk(
  'auth/register',
  async (payload, { rejectWithValue }) => {
    const {
      email,
      firstName,
      lastName,
      password,
      preferredLanguage,
    } = payload;
    try {
      return await AuthService.register(
        email,
        firstName,
        lastName,
        2,
        password,
        preferredLanguage,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const confirmation = createAsyncThunk(
  'auth/confirmation',
  async (payload, { rejectWithValue }) => {
    const { confirmationToken } = payload;
    try {
      return await AuthService.confirmation(confirmationToken);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const unlock = createAsyncThunk(
  'auth/unlock',
  async (payload, { rejectWithValue }) => {
    const { unlockToken } = payload;
    try {
      return await AuthService.unlock(unlockToken);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const ping = createAsyncThunk(
  'auth/ping',
  async (payload, { rejectWithValue }) => {
    try {
      return await AuthService.ping();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

const rejectAuth = (state, action) => {
  if (action.type === 'auth/ping/rejected') {
    // eslint-disable-next-line no-param-reassign
    state.status = 'failed';
    // eslint-disable-next-line no-param-reassign
    state.endSession = true;
    // eslint-disable-next-line no-param-reassign
    state.errors = ['Session Expired'];
  } else {
    rejectionReducer(state, action);
  }
};

// eslint-disable-next-line no-undef
const user = JSON.parse(localStorage.getItem('user'));

const initialState = user
  ? {
    isLoggedIn: true,
    isAdmin: user.role.id === 1,
    isAdviser: user.role.id === 3,
    user,
    confirmed: true,
    endSession: false,
    message: '',
    errors: '',
  }
  : {
    isAdmin: false,
    isAdviser: false,
    user: null,
    confirmed: false,
    endSession: false,
    registered: false,
    message: '',
    errors: '',
    signInError: '',
    signUpError: '',
  };

/* eslint-disable no-param-reassign */
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => {
      logoutApi();
      localStorage.removeItem('user');
    },
    setRegistered: (state, action) => {
      state.registered = action.payload;
    },
    setEndSession: (state, action) => {
      state.endSession = action.payload;
    },
  },
  extraReducers: (builder) => builder
    .addMatcher(
      (action) => action.type.endsWith('/rejected'),
      rejectAuth,
    )
    .addMatcher((action) => action.type.endsWith('/pending'), pendingReducer)
    .addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, action) => {
        const performedAction = action.type.split('/');

        if (performedAction[0] === 'auth') {
          switch (performedAction[1]) {
            case 'login':
              fulfilledReducer(state, action);
              state.user = action.payload;
              state.isAdmin = action.payload.role.id === 1;
              state.isAdviser = action.payload.role.id === 3;
              state.isLoggedIn = true;
              break;
            case 'logoutApi':
              fulfilledReducer(state, action);
              state.user = null;
              state.isLoggedIn = false;
              break;
            case 'register':
              fulfilledReducer(state, action);
              state.user = action.payload;
              state.isLoggedIn = false;
              state.registered = true;
              break;
            case 'confirmation':
              fulfilledReducer(state, action);
              state.confirmed = true;
              break;
            case 'unlock':
              fulfilledReducer(state, action);
              state.unlocked = true;
              break;
            case 'ping':
              fulfilledReducer(state, action);
              localStorage.removeItem('checkSession');
              break;
            default:
              fulfilledReducer(state, action);
              state.message = action.payload;
              break;
          }
        }
      },
    ),
});
/* eslint-disable no-param-reassign */

export const {
  logout,
  setRegistered,
  setEndSession,
} = authSlice.actions;

export default authSlice.reducer;
