import axios from 'axios';

export default class Service {
  api;

  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
    });

    this.api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401
          && error.response.config.url !== 'login'
          && /.+\/login/.test(error.response.config.url)
          && error.response.config.url !== 'register'
          && /.+\/register/.test(error.response.config.url)) {
          // eslint-disable-next-line no-undef
          localStorage.removeItem('user');
          // eslint-disable-next-line no-undef
          window.location = '/';
        }
        return Promise.reject(error);
      },
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getLocale() {
    // eslint-disable-next-line no-undef
    let locale = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en';
    // eslint-disable-next-line prefer-destructuring
    locale = `${locale.split('-')[0]}/`;
    return locale;
  }

  localizeURL(url) {
    const locale = this.getLocale();
    return locale + url;
  }

  // eslint-disable-next-line class-methods-use-this
  getHeaders() {
    // eslint-disable-next-line no-undef
    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
      return {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
        Accept: '*/*',
      };
    }
    return {};
  }

  // eslint-disable-next-line class-methods-use-this
  getFormHeaders() {
    // eslint-disable-next-line no-undef
    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
      return {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'multipart/form-data',
        Accept: '*/*',
      };
    }
    return {};
  }
}
