import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import ReactGA from 'react-ga4';

// Reducer functions
import { register, setRegistered } from 'reducers/authSlice';

// @mui material components
import Checkbox from '@mui/material/Checkbox';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';
import SuiAlert from 'components/SuiAlert';

// Authentication layout components
import CoverLayout from 'layouts/authentication/components/CoverLayout';

// Components
import FormField from 'layouts/components/FormField';

// Images
import curved11 from 'assets/images/curved-images/curved11.jpg';
import Swal from 'sweetalert2';

const selector = (state) => ({
  message: state.auth.message,
  error: state.auth.signUpError,
  registered: state.auth.registered,
});

function SignUp() {
  const {
    message,
    error,
    registered,
  } = useSelector(selector, shallowEqual);
  const { t } = useTranslation('translation', { keyPrefix: 'sign-up' });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
      title: 'Sign Up',
    });
  }, []);

  const [agreement, setAgreement] = useState(true);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validations, setValidations] = useState('');

  const currentLanguage = i18next.language;

  const handleSetAgreement = () => setAgreement(!agreement);

  function handleSignUp(e) {
    e.preventDefault();

    setValidations([]);
    // eslint-disable-next-line prefer-const
    let errors = [];

    if (email === '' || email === undefined) {
      errors.push(t('email-cant-be-blank'));
    }

    if (password === '' || password === undefined) {
      errors.push(t('password-cant-be-blank'));
    }

    if (firstName === '' || firstName === undefined) {
      errors.push(t('first-name-cant-be-blank'));
    }

    if (lastName === '' || lastName === undefined) {
      errors.push(t('last-name-cant-be-blank'));
    }

    setValidations(errors);

    if (errors.length < 1) {
      dispatch(
        register({
          email,
          firstName,
          lastName,
          password,
          preferredLanguage: currentLanguage,
        }),
      );
    }
  }

  function onChangeFirstName(e) {
    setFirstName(e.target.value);
  }

  function onChangeLastName(e) {
    setLastName(e.target.value);
  }

  function onChangeEmail(e) {
    setEmail(e.target.value);
  }

  function onChangePassword(e) {
    setPassword(e.target.value);
  }

  if (registered) {
    dispatch(setRegistered(false));

    Swal.fire({
      title: t('registration'),
      text: message,
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'common' }),
    }).then(() => {
      navigate('/', { replace: true });
    });
  }

  return (
    <CoverLayout
      title={t('join-us-today')}
      description={t('enter-your-email-and-password-to-register')}
      image={curved11}
      top={12}
    >
      {error
        && (Object.keys(error).map((e) => Object.keys(error[e])
          .map((i) => <SuiAlert key={`error${i}`} color="error">{`${e}: ${error[e][i]}`}</SuiAlert>)))}
      {validations
        && (Object.keys(validations)
          .map((v, i) => <SuiAlert key={`error${i}`} color="error">{validations[v]}</SuiAlert>))}
      <SuiBox component="form" role="form">
        <div style={{ marginBottom: 5 }}>
          <FormField
            label={t('first-name', { keyPrefix: 'common' })}
            placeholder={t('first-name', { keyPrefix: 'common' })}
            onChange={onChangeFirstName}
          />
        </div>
        <div style={{ marginBottom: 5 }}>
          <FormField
            label={t('last-name', { keyPrefix: 'common' })}
            placeholder={t('last-name', { keyPrefix: 'common' })}
            onChange={onChangeLastName}
          />
        </div>
        <div style={{ marginBottom: 5 }}>
          <FormField
            label={t('email', { keyPrefix: 'common' })}
            type="email"
            placeholder={t('email', { keyPrefix: 'common' })}
            onChange={onChangeEmail}
          />
        </div>
        <div style={{ marginBottom: 5 }}>
          <FormField
            label={t('password', { keyPrefix: 'common' })}
            type="password"
            placeholder={t('password', { keyPrefix: 'common' })}
            onChange={onChangePassword}
          />
        </div>
        <SuiBox display="flex" alignItems="center">
          <Checkbox checked={agreement} onChange={handleSetAgreement} />
          <SuiTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetAgreement}
            sx={{
              cursor: 'pointer',
              userSelect: 'none',
            }}
          >
            &nbsp;&nbsp;
            {t('i-agree-the')}
            &nbsp;
          </SuiTypography>
          <SuiTypography
            component="a"
            href="/terms-conditions"
            variant="button"
            fontWeight="bold"
            textGradient
          >
            {t('terms-and-conditions')}
          </SuiTypography>
        </SuiBox>
        <SuiBox mt={4} mb={1}>
          <SuiButton
            variant="gradient"
            color="info"
            fullWidth
            onClick={handleSignUp}
          >
            {t('sign-up', { keyPrefix: 'common' })}
          </SuiButton>
        </SuiBox>
        <SuiBox mt={3} textAlign="center">
          <SuiTypography variant="button" color="text" fontWeight="regular">
            {`${t('already-have-an-account')} `}
            <SuiTypography
              component={Link}
              to="/sign-in"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              {t('sign-in', { keyPrefix: 'common' })}
            </SuiTypography>
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </CoverLayout>
  );
}

export default SignUp;
