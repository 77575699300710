import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';

// Components
import PageLayout from 'layouts/components/LayoutContainers/PageLayout';
import Footer from 'layouts/authentication/components/Footer';

// Soft UI Dashboard PRO React base styles
import typography from 'assets/theme/base/typography';

// Images
import error404 from 'assets/images/illustrations/error-404.png';

// Soft UI Dashboard PRO React context
import { setLayout, useSoftUIController } from 'context';

function Error404() {
  const { t } = useTranslation('translation', { keyPrefix: 'error.404' });
  // eslint-disable-next-line no-unused-vars
  const [controller, uiDispatch] = useSoftUIController();
  const { pathname } = useLocation();
  useEffect(() => {
    setLayout(uiDispatch, 'error404');
  }, [pathname, uiDispatch]);

  const {
    d1,
    d3,
    d4,
    d5,
  } = typography;

  return (
    <PageLayout white>
      <SuiBox my={24} height="calc(100vh - 24rem)">
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
          sx={{ height: '100%' }}
        >
          <Grid item xs={11} sm={9} container alignItems="center">
            <Grid item xs={12} lg={6}>
              <SuiBox
                fontSize={{
                  xs: d5.fontSize,
                  sm: d4.fontSize,
                  md: d3.fontSize,
                  lg: d1.fontSize,
                }}
                lineHeight={1.2}
              >
                <SuiTypography
                  variant="inherit"
                  color="error"
                  textGradient
                  fontWeight="bold"
                >
                  Error 404
                </SuiTypography>
              </SuiBox>
              <SuiTypography
                variant="h2"
                color="dark"
                textGradient
                fontWeight="bold"
              >
                {t('title')}
              </SuiTypography>
              <SuiBox mt={1} mb={2}>
                <SuiTypography variant="body1" color="text">
                  {t('description')}
                </SuiTypography>
              </SuiBox>
              <SuiBox mt={4} mb={2}>
                <SuiButton
                  component={Link}
                  to="/"
                  variant="gradient"
                  color="dark"
                >
                  {t('homepage')}
                </SuiButton>
              </SuiBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <SuiBox
                component="img"
                src={error404}
                alt="error-404"
                width="100%"
              />
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </PageLayout>
  );
}

export default Error404;
