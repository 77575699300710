import Service from './service';

class PermissionService extends Service {
  // eslint-disable-next-line class-methods-use-this
  async getPermissions() {
    const response = await this.api
      .get(
        'permissions',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getPermission(id) {
    const response = await this.api
      .get(
        `permissions/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createPermission(name, content) {
    const data = {
      agreement: {
        name,
        content,
      },
    };

    const response = await this.api
      .post(
        'permissions',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updatePermission(id, name, content) {
    const data = {
      name,
      content,
    };

    const response = await this.api
      .put(
        `permissions/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deletePermission(id) {
    const response = await this.api
      .delete(
        `permissions/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new PermissionService();
