import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import ExpenseService from 'services/expenses.service';

// Functions
import {
  fulfilledCreatedReducer,
  fulfilledReducer,
  fulfilledSavedReducer,
  pendingReducer,
  rejectionReducer,
} from 'Util';

export const fetchExpenses = createAsyncThunk(
  'expense/fetchExpenses',
  async (payload, { rejectWithValue }) => {
    try {
      return await ExpenseService.getExpenses();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchExpense = createAsyncThunk(
  'expense/fetchExpense',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await ExpenseService.getExpense(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createExpense = createAsyncThunk(
  'expense/createExpense',
  async (payload, { rejectWithValue }) => {
    const {
      date,
      candidateId,
      vendorId,
      paymentMethodId,
      expenseCategoryId,
      total,
      memo,
      expenseItems,
      payBy,
      reimbursable,
      billed,
    } = payload;
    try {
      return await ExpenseService.createExpense(
        date,
        candidateId,
        vendorId,
        paymentMethodId,
        expenseCategoryId,
        total,
        memo,
        expenseItems,
        payBy,
        reimbursable,
        billed,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateExpense = createAsyncThunk(
  'expense/updateExpense',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      date,
      candidateId,
      vendorId,
      paymentMethodId,
      expenseCategoryId,
      total,
      memo,
      expenseItems,
      payBy,
      reimbursable,
      billed,
    } = payload;
    try {
      return await ExpenseService.updateExpense(
        id,
        date,
        candidateId,
        vendorId,
        paymentMethodId,
        expenseCategoryId,
        total,
        memo,
        expenseItems,
        payBy,
        reimbursable,
        billed,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const cancelExpense = createAsyncThunk(
  'expense/cancelExpense',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await ExpenseService.cancelExpense(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchExpenseCategories = createAsyncThunk(
  'expense/fetchExpenseCategories',
  async (payload, { rejectWithValue }) => {
    try {
      return await ExpenseService.getExpenseCategories();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

const generateDropboxProperties = ({ payload }) => payload.map((item) => ({
  ...item,
  value: item.id,
  label: item.name,
}));

/* eslint-disable no-param-reassign */
export const expensesSlice = createSlice({
  name: 'expense',
  initialState: {
    expenseInfo: {},
    expenses: [],
    expenseCategories: [],
    message: '',
    created: false,
    saved: false,
    deleted: false,
    failed: false,
    removeData: {
      items: [],
    },
  },
  reducers: {
    clearExpense: (state) => {
      state.expenseInfo = {};
      state.message = '';
      state.created = false;
      state.saved = false;
      state.deleted = false;
      state.failed = false;
      state.removeData = {
        items: [],
      };
    },
    setExpenseEditing: (state, action) => {
      state.editing = action.payload;
    },
    setCreated: (state, action) => {
      state.created = action.payload;
    },
    setSaved: (state, action) => {
      state.saved = action.payload;
    },
    setDeleted: (state, action) => {
      state.deleted = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setExpenseInfo: (state, action) => {
      state.expenseInfo = action.payload;
    },
    setRemoveData: (state, action) => {
      state.removeData = action.payload;
    },
  },
  extraReducers: (builder) => builder
    .addMatcher(
      (action) => action.type.endsWith('/rejected'),
      rejectionReducer,
    )
    .addMatcher((action) => action.type.endsWith('/pending'), pendingReducer)
    .addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, action) => {
        const performedAction = action.type.split('/');
        if (performedAction[0] === 'expense') {
          switch (performedAction[1]) {
            case 'fetchExpenses':
              fulfilledReducer(state, action);
              state.expenses = action.payload;
              break;
            case 'fetchExpense':
              fulfilledReducer(state, action);
              state.expenseInfo = action.payload;
              break;
            case 'createExpense':
              fulfilledCreatedReducer(state, action);
              state.expenses = action.payload.data;
              state.expenseInfo = {};
              break;
            case 'updateExpense':
            case 'cancelExpense':
              fulfilledSavedReducer(state, action);
              state.expenses = action.payload.data;
              state.expenseInfo = {};
              break;
            case 'fetchExpenseCategories':
              fulfilledReducer(state, action);
              state.expenseCategories = generateDropboxProperties(action);
              break;
            default:
              fulfilledReducer(state, action);
              state.message = action.payload;
              break;
          }
        }
      },
    ),
});
/* eslint-disable no-param-reassign */

export const {
  clearExpense,
  setExpenseEditing,
  setCreated,
  setSaved,
  setDeleted,
  setFailed,
  setExpenseInfo,
  setRemoveData,
} = expensesSlice.actions;

export default expensesSlice.reducer;
