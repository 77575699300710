// @mui material components
import Card from '@mui/material/Card';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Billing page components
import { currencyFormatter } from 'Util';
import PropTypes from 'prop-types';
import Transaction from '../Transaction';

function Transactions({ transactions }) {
  return (
    <Card sx={{ height: '100%' }}>
      <SuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={3}
        px={2}
      >
        <SuiTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          Transactions
        </SuiTypography>
        {/* <SuiButton variant="outlined" color="info" size="small"> */}
        {/*  view all */}
        {/* </SuiButton> */}
      </SuiBox>
      <SuiBox pt={3} pb={2} px={2}>
        <SuiBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: 'none' }}
        >
          {Object.keys(transactions).length > 0
            && transactions.map((t, i) => (
              <Transaction
                key={
                  // eslint-disable-next-line react/no-array-index-key
                  `transaction${i}`
                }
                color={t.doc_type === 'payment' ? 'error' : 'success'}
                icon={
                  t.doc_type === 'payment' ? 'arrow_downward' : 'arrow_upward'
                }
                name={t.doc_type}
                description={t.doc_date}
                value={currencyFormatter.format(t.total)}
              />
            ))}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

Transactions.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape(
      {
        doc_type: PropTypes.string,
        doc_number: PropTypes.number,
        status: PropTypes.string,
        doc_date: PropTypes.string,
        candidate_id: PropTypes.string,
        last_name: PropTypes.string,
        first_name: PropTypes.string,
        total: PropTypes.string,
        uuid: PropTypes.string,
      },
    ),
  ).isRequired,
};

export default Transactions;
