// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Components
import EmailListInfo from '../EmailListInfo';
import SavePanel from '../SavePanel';

function Base() {
  return (
    <SuiBox mt={4}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <SuiBox mb={3}>
            <EmailListInfo />
            <SavePanel />
          </SuiBox>
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default Base;
