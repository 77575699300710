import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
// eslint-disable-next-line object-curly-newline
import {
  checkSimilarCandidateEmail,
  checkSimilarCandidatePhone,
  fetchCandidateAddresses,
  fetchCandidateEmails,
  fetchCandidatePhones,
  setAddresses,
  setCurrentSubView,
  setEmails,
  setPhones,
  setRemoveData,
  setSimilarCandidatesEmails,
  setSimilarCandidatesPhones,
} from 'reducers/candidatesSlice';
// eslint-disable-next-line object-curly-newline
import { fetchAddressTypes, fetchCitiesIndex, fetchCountries, fetchStatesIndex } from 'reducers/commonSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';

// Components
import FormField from 'layouts/components/FormField';
import FormCheckbox from 'layouts/components/FormCheckbox';
import FormPhoneInput from 'layouts/components/FormPhoneInput';
import DefaultAddressCard from 'layouts/components/Cards/AddressCards/DefaultAddressCard';

// Functions
import validator from 'validator';
import { getCandidateUUID, setTitle } from 'Util';

const selector = (state) => ({
  editing: state.candidate.editing,
  phones: state.candidate.phones,
  emails: state.candidate.emails,
  addresses: state.candidate.addresses,
  removeData: state.candidate.removeData,
  countries: state.common.countries,
  statesIndex: state.common.statesIndex,
  citiesIndex: state.common.citiesIndex,
  addressTypes: state.common.addressTypes,
  similarEmails: state.candidate.similarEmails,
});

function ContactInfo() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'candidates.contact-info',
  });
  const {
    editing,
    phones,
    emails,
    addresses,
    removeData,
    countries,
    statesIndex,
    citiesIndex,
    addressTypes,
    similarEmails,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    dispatch(setCurrentSubView('basic-info'));
    dispatch(fetchCountries());
    dispatch(fetchAddressTypes());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidatePhones({ uuid }));
      dispatch(fetchCandidateAddresses({ uuid }));
      dispatch(fetchCandidateEmails({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  setTitle('Candidate Contact Info');

  const [firstLoad, setFirstLoad] = useState(true);
  const [emailErrors, setEmailErrors] = useState([]);
  const [addressStates, setAddressStates] = useState([]);
  const [addressCities, setAddressCities] = useState([]);

  useEffect(() => {
    if (similarEmails !== undefined) {
      similarEmails.forEach((s) => {
        const i = emails.findIndex((e) => e.email === s.email);
        if (i > -1) {
          emailErrors[i] = true;
          setEmailErrors(emailErrors);
        }
      });
    }
  }, [similarEmails, emails, emailErrors]);

  const isValid = (key, value, r) => {
    switch (key) {
      case 'address':
        return value !== '' && value !== undefined;
      default:
        return r.address !== '' && r.address !== undefined;
    }
  };

  const setValue = (key, value, i) => {
    dispatch(
      setAddresses(
        addresses.map((obj, index) => {
          if (index === i) {
            switch (key) {
              case 'country_id':
                return {
                  ...obj,
                  [key]: value,
                  state_id: undefined,
                  city_id: undefined,
                  changed: true,
                  valid: isValid(key, value, addresses[i]),
                };
              case 'state_id':
                return {
                  ...obj,
                  [key]: value,
                  city_id: undefined,
                  changed: true,
                  valid: isValid(key, value, addresses[i]),
                };
              default:
                return {
                  ...obj,
                  [key]: value,
                  changed: true,
                  valid: isValid(key, value, addresses[i]),
                };
            }
          }
          return obj;
        }),
      ),
    );
  };

  const handleEmail = (e, i) => {
    e.preventDefault();

    if (validator.isEmail(e.target.value)) {
      emailErrors[i] = false;
      setEmailErrors(emailErrors);
      dispatch(checkSimilarCandidateEmail({ email: e.target.value }));
    } else {
      emailErrors[i] = true;
      setEmailErrors(emailErrors);
      dispatch(setSimilarCandidatesEmails([]));
    }

    dispatch(
      setEmails(
        emails.map((obj, index) => {
          if (index === i) {
            return {
              ...obj,
              email: e.target.value,
              changed: true,
              valid: !emailErrors[i],
            };
          }
          return obj;
        }),
      ),
    );
  };

  const handleEmailDefault = (e, i) => {
    e.preventDefault();

    dispatch(
      setEmails(
        emails.map((obj, index) => {
          if (index === i) {
            return {
              ...obj,
              default: true,
              changed: true,
            };
          }
          return {
            ...obj,
            default: false,
            changed: true,
          };
        }),
      ),
    );
  };

  const handleAddEmail = () => {
    let isDefault = false;
    if (emails.length < 1) {
      isDefault = true;
    }
    dispatch(
      setEmails(
        emails.concat({
          email: '',
          default: isDefault,
          changed: true,
          valid: false,
        }),
      ),
    );
  };

  const handleRemoveEmail = (e, i) => {
    e.preventDefault();

    if (emails[i].id !== undefined) {
      dispatch(
        setRemoveData({
          ...removeData,
          emails: removeData.emails.concat(emails[i].id),
        }),
      );
    }

    dispatch(setEmails([...emails.slice(0, i), ...emails.slice(i + 1)]));
  };

  const handlePhone = (value, i) => {
    dispatch(
      setPhones(
        phones.map((obj, index) => {
          if (index === i) {
            return {
              ...obj,
              phone: value,
              changed: true,
              valid: value !== '' && value !== undefined,
            };
          }
          return obj;
        }),
      ),
    );

    if (value.length >= 5) {
      dispatch(checkSimilarCandidatePhone({ phone: value }));
    } else {
      dispatch(setSimilarCandidatesPhones([]));
    }
  };

  const handlePhoneDefault = (e, i) => {
    e.preventDefault();

    dispatch(
      setPhones(
        phones.map((obj, index) => {
          if (index === i) {
            return {
              ...obj,
              default: true,
              changed: true,
            };
          }
          return {
            ...obj,
            default: false,
            changed: true,
          };
        }),
      ),
    );
  };

  const handleAddPhone = () => {
    let isDefault = false;
    if (phones.length < 1) {
      isDefault = true;
    }
    dispatch(
      setPhones(
        phones.concat({
          phone: '',
          default: isDefault,
          changed: true,
          valid: false,
        }),
      ),
    );
  };

  const handleRemovePhone = (e, i) => {
    e.preventDefault();

    if (phones[i].id !== undefined) {
      dispatch(
        setRemoveData({
          ...removeData,
          phones: removeData.phones.concat(phones[i].id),
        }),
      );
    }

    dispatch(setPhones([...phones.slice(0, i), ...phones.slice(i + 1)]));
  };

  useEffect(() => {
    const aCities = [...addressCities];
    aCities[citiesIndex.index] = citiesIndex.cities;
    setAddressCities(aCities);
    // eslint-disable-next-line
  }, [citiesIndex]);

  useEffect(() => {
    const aStates = [...addressStates];
    aStates[statesIndex.index] = statesIndex.states;
    setAddressStates(aStates);

    const aCities = [...addressCities];
    aCities[statesIndex.index] = [];
    setAddressCities(aCities);
    // eslint-disable-next-line
  }, [statesIndex]);

  const handleAddressDefault = (e, i) => {
    e.preventDefault();

    dispatch(
      setAddresses(
        addresses.map((obj, index) => {
          if (index === i) {
            return {
              ...obj,
              default: true,
              changed: true,
            };
          }
          return {
            ...obj,
            default: false,
            changed: true,
          };
        }),
      ),
    );
  };

  const handleAddAddress = () => {
    let isDefault = false;
    if (addresses.length < 1) {
      isDefault = true;
    }
    dispatch(
      setAddresses(
        addresses.concat({
          address: '',
          address_type_id: undefined,
          city_id: undefined,
          state_id: undefined,
          country_id: undefined,
          postal_code: '',
          default: isDefault,
          changed: true,
          valid: false,
        }),
      ),
    );
  };

  const handleRemoveAddress = (i) => {
    if (addresses[i].id !== undefined) {
      dispatch(
        setRemoveData({
          ...removeData,
          addresses: removeData.addresses.concat(addresses[i].id),
        }),
      );
    }

    dispatch(
      setAddresses([...addresses.slice(0, i), ...addresses.slice(i + 1)]),
    );
  };

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);

      addresses.forEach((a, i) => {
        if (a.country_id !== undefined && a.country_id !== null) {
          dispatch(
            fetchStatesIndex({
              id: a.country_id,
              index: i,
            }),
          ).then(() => {
            if (a.state_id !== undefined && a.state_id !== null) {
              dispatch(
                fetchCitiesIndex({
                  countryId: a.country_id,
                  stateId: a.state_id,
                  index: i,
                }),
              );
            }
          });
        }
      });
    }
  }, [dispatch, firstLoad, addresses]);

  return (
    <SuiBox component="form">
      <SuiBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card id="email-info" sx={{ overflow: 'visible' }}>
              <SuiBox p={3}>
                <SuiTypography variant="h5">{t('e-mails')}</SuiTypography>
              </SuiBox>
              <SuiBox pb={3} px={3}>
                {Object.keys(emails).length > 0
                  && emails.map((e, i) => (
                    <SuiBox
                      height="100%"
                      pt={1}
                      key={`emailBox${i}`}
                    >
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item xs={12} md={8}>
                          <FormField
                            id={`email[${i}]`}
                            label={`${t('e-mail')} ${i + 1}`}
                            onChange={(ev) => {
                              handleEmail(ev, i);
                            }}
                            value={e.email}
                            type="email"
                            error={
                              emailErrors[i] || !e.email || e.email === ''
                            }
                          />
                        </Grid>
                        <Grid item xs={2} md={1} pt={2}>
                          <SuiTypography
                            variant="body1"
                            color="secondary"
                            sx={{
                              cursor: 'pointer',
                              lineHeight: 0,
                            }}
                            onClick={(ev) => {
                              handleRemoveEmail(ev, i);
                            }}
                          >
                            <Tooltip title={t('delete-phone')} placement="left">
                              <Icon>delete</Icon>
                            </Tooltip>
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={2} md={1} pt={1}>
                          <FormCheckbox
                            key={`emailCheckbox${i}`}
                            icon={<FavoriteBorder />}
                            checkedIcon={<Favorite />}
                            checked={e.default ? e.default : false}
                            onChange={(ev) => {
                              handleEmailDefault(ev, i);
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                            label=""
                          />
                        </Grid>
                        <Grid item xs={8} md={2} pt={1}>
                          <SuiTypography variant="caption">
                            {e.default ? t('default') : ''}
                          </SuiTypography>
                        </Grid>
                      </Grid>
                    </SuiBox>
                  ))}
                <SuiBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  flexWrap="wrap"
                  pt={2}
                >
                  <SuiBox ml="auto">
                    <SuiButton
                      variant="gradient"
                      color="dark"
                      size="small"
                      onClick={handleAddEmail}
                    >
                      {t('new-e-mail')}
                    </SuiButton>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card id="phones-info" sx={{ overflow: 'visible' }}>
              <SuiBox p={3}>
                <SuiTypography variant="h5">{t('phones')}</SuiTypography>
              </SuiBox>
              <SuiBox pb={3} px={3}>
                {Object.keys(phones).length > 0
                  && phones.map((p, i) => (
                    <SuiBox
                      height="100%"
                      pt={1}
                      key={`phoneBox${i}`}
                    >
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item xs={12} md={8}>
                          <FormPhoneInput
                            label={`${t('phone')} ${i + 1}`}
                            id={`phone[${i}]`}
                            country="us"
                            placeholder=""
                            specialLabel=""
                            isValid={(value) => !(value === '1' || value === '')}
                            enableSearch
                            value={p.phone}
                            onChange={(value) => {
                              handlePhone(
                                value,
                                i,
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} md={1} pt={2}>
                          <SuiTypography
                            variant="body1"
                            color="secondary"
                            sx={{
                              cursor: 'pointer',
                              lineHeight: 0,
                            }}
                            onClick={(e) => {
                              handleRemovePhone(e, i);
                            }}
                          >
                            <Tooltip title={t('delete-phone')} placement="left">
                              <Icon>delete</Icon>
                            </Tooltip>
                          </SuiTypography>
                        </Grid>
                        <Grid item xs={2} md={1} pt={1}>
                          <FormCheckbox
                            key={
                              // eslint-disable-next-line react/no-array-index-key
                              `phoneCheckbox${i}`
                            }
                            icon={<FavoriteBorder />}
                            checkedIcon={<Favorite />}
                            checked={p.default ? p.default : false}
                            onChange={(e) => {
                              handlePhoneDefault(e, i);
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                            label=""
                          />
                        </Grid>
                        <Grid item xs={8} md={2} pt={1}>
                          <SuiTypography variant="caption">
                            {p.default ? t('default') : ''}
                          </SuiTypography>
                        </Grid>
                      </Grid>
                    </SuiBox>
                  ))}
                <SuiBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  flexWrap="wrap"
                  pt={2}
                >
                  <SuiBox ml="auto">
                    <SuiButton
                      variant="gradient"
                      color="dark"
                      size="small"
                      onClick={handleAddPhone}
                    >
                      {t('new-phone')}
                    </SuiButton>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <SuiTypography variant="h4" fontWeight="medium">
              {t('addresses')}
            </SuiTypography>
            <SuiBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              flexWrap="wrap"
              pt={2}
            >
              <SuiBox ml="auto">
                <SuiButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  onClick={handleAddAddress}
                >
                  {t('new-address')}
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </Grid>
          <Grid item xs={12} md={12}>
            {Object.keys(addresses).length > 0
              && addresses.map((a, i) => (
                <div
                  key={
                    // eslint-disable-next-line react/no-array-index-key
                    `address${i}`
                  }
                >
                  <DefaultAddressCard
                    a={a}
                    ops={{
                      addressTypes,
                      countries,
                    }}
                    setValue={setValue}
                    setDefault={handleAddressDefault}
                    i={i}
                    removeFunc={handleRemoveAddress}
                  />
                  <Divider />
                </div>
              ))}
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default ContactInfo;
