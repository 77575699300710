import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Reducer functions
import { setInvoiceInfo } from 'reducers/invoicesSlice';

// Components
import DefaultInvoiceDetails from 'layouts/components/InvoiceDetails/DefaultInvoiceDetails';

const selector = (state) => ({
  invoiceInfo: state.invoice.invoiceInfo,
});

function InvoiceDetails() {
  const {
    invoiceInfo,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const setItems = (value) => {
    dispatch(
      setInvoiceInfo(
        {
          ...invoiceInfo,
          invoice_items: value,
          changed: true,
        },
      ),
    );
  };

  const handleRemoveItem = (i) => {
    if (invoiceInfo.invoice_items[i].id !== null) {
      setItems(invoiceInfo.invoice_items.map((obj, index) => {
        if (index === i) {
          return {
            ...obj,
            _destroy: true,
          };
        }
        return obj;
      }));
    } else {
      setItems([
        ...invoiceInfo.invoice_items.slice(0, i),
        ...invoiceInfo.invoice_items.slice(i + 1),
      ]);
    }
  };

  return (
    <DefaultInvoiceDetails
      invoiceDetails={invoiceInfo.invoice_items}
      setValue={setItems}
      removeFunc={handleRemoveItem}
    />
  );
}

export default InvoiceDetails;
