import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Service functions
import CandidateService from 'services/candidate.service';

// @mui material components
import GridMui from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// DevExtreme components
// eslint-disable-next-line object-curly-newline
import DataGrid, {
  Column,
  Editing,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Scrolling,
  Sorting,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.material.blue.light.css';
import CustomStore from 'devextreme/data/custom_store';

// Functions
import { englishLevels } from 'Util';

function LanguageCourseProgress(
  {
    languageProficiency,
    candidateId,
    courseId,
    p,
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.language-course-progress' });

  const dataGrid = useRef(null);

  const getItems = () => CandidateService
    .getCandidateLanguageCoursesProgress(candidateId, languageProficiency, courseId);

  const addItem = (values) => CandidateService
    .createCandidateLanguageCourseProgress(
      candidateId,
      languageProficiency,
      courseId,
      values.level,
      values.date,
      values.progress,
      values.enrollment_date,
      values.last_access,
    );

  const modifyItem = (key, values) => {
    const item = dataGrid.current.instance.getDataSource().items().find((cp) => cp.id === key);
    const changes = {
      ...item,
      [Object.keys(values)[0]]: values[Object.keys(values)[0]],
    };
    return CandidateService.updateCandidateLanguageCourseProgress(
      key,
      candidateId,
      languageProficiency,
      courseId,
      changes.level,
      changes.date,
      changes.progress,
      changes.enrollment_date,
      changes.last_access,
    );
  };

  const removeItem = (key) => CandidateService.deleteCandidateLanguageCourseProgress(
    key,
    candidateId,
    languageProficiency,
    courseId,
  );

  const [progressData] = useState(new CustomStore({
    key: 'id',
    load: () => getItems(),
    insert: (values) => addItem(values),
    update: (key, values) => modifyItem(key, values),
    remove: (key) => removeItem(key),
  }));

  const allowedPageSizes = [10, 30, 50, 'all'];

  return (
    <SuiBox>
      <SuiBox display="flex" justifyContent="space-between" alignItems="center" mb={2} mt={2}>
        <SuiTypography
          variant="h6"
          fontWeight="medium"
        >
          {t('course-progress')}
        </SuiTypography>
      </SuiBox>
      <SuiBox mb={2}>
        <GridMui container spacing={3}>
          <GridMui item md={12}>
            <Paper>
              {(p !== undefined && p !== null)
                && (
                  <DataGrid
                    id="candidate-language-progress"
                    dataSource={progressData}
                    ref={dataGrid}
                    keyExpr="id"
                    allowColumnReordering
                    allowColumnResizing
                    columnAutoWidth
                    // repaintChangesOnly
                  >
                    <Editing
                      // refreshMode="repaint"
                      mode="cell"
                      allowAdding
                      allowDeleting
                      allowUpdating
                    />
                    <HeaderFilter visible />
                    <Scrolling rowRenderingMode="virtual" />
                    <Sorting mode="multiple" />
                    <Paging defaultPageSize={30} />
                    <Pager
                      visible
                      allowedPageSizes={allowedPageSizes}
                      displayMode="full"
                      showPageSizeSelector
                      showInfo
                      showNavigationButtons
                    />
                    <Column
                      caption={t('date')}
                      dataType="date"
                      dataField="date"
                      defaultSortOrder="desc"
                    />
                    <Column
                      caption={t('level')}
                      dataField="level"
                    >
                      <Lookup dataSource={englishLevels} valueExpr="value" displayExpr="label" />
                    </Column>
                    <Column
                      caption={t('progress')}
                      dataType="number"
                      dataField="progress"
                    />
                    <Column
                      caption={t('enrollment-date')}
                      dataType="date"
                      dataField="enrollment_date"
                    />
                    <Column
                      caption={t('last-access')}
                      dataType="date"
                      dataField="last_access"
                    />
                  </DataGrid>
                )}
            </Paper>
          </GridMui>
        </GridMui>
      </SuiBox>
    </SuiBox>
  );
}

LanguageCourseProgress.propTypes = {
  languageProficiency: PropTypes.number.isRequired,
  candidateId: PropTypes.string.isRequired,
  courseId: PropTypes.number.isRequired,
  p: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      level: PropTypes.string,
      date: PropTypes.string,
      completion_expected: PropTypes.string,
    }),
  ).isRequired,
};

export default LanguageCourseProgress;
