// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';
import colors from '../../../assets/theme/base/colors';

// Soft UI Dashboard PRO React base styles

function PaidIcon({ color, size }) {
  return (
    <svg
      width={size * 2}
      height={size}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
      viewBox="0 0 600 300"
    >
      <title>paid</title>
      <g
        fill={colors[color] ? colors[color].main : colors.dark.main}
        fillRule="nonzero"
      >
        <path
          d="M18.63,250.88c-0.15,2.52-0.29,5.05-0.44,7.57c-0.14,0.01-0.28,0.01-0.42,0.02c-2.92-54.14-5.83-108.27-8.75-162.41
          c-0.59,0.01-1.18,0.02-1.77,0.03c0,9.1,0,18.21,0,27.31c-0.11,0-0.22,0-0.33,0.01C4.63,95.25,2.34,67.08,0,38.34
          c4.13-0.26,7.69-0.48,11.26-0.71c0.02,0.16,0.04,0.31,0.06,0.47c-3.01,0.44-6.01,0.89-9.73,1.44c0.84,5.71,1.63,11.09,2.42,16.48
          C4.47,56,4.94,55.98,5.4,55.96c-0.1-2.18-0.1-4.37-0.32-6.54c-0.6-5.67,0.57-7.36,6.41-7.79c17.45-1.3,34.91-2.45,52.36-3.63
          c32.26-2.17,64.51-4.35,96.77-6.46c21.79-1.43,43.58-2.71,65.37-4.12c9.41-0.61,18.81-1.36,28.21-2.04
          c-0.04-0.57-0.08-1.14-0.11-1.71c-36.01,1.68-72.03,3.37-108.04,5.05c-0.03-0.2-0.06-0.4-0.08-0.6c8.33-0.85,16.65-1.92,25-2.5
          c32.58-2.26,65.17-4.63,97.78-6.45c35.73-2,71.5-3.41,107.24-5.14c8.98-0.43,17.94-1.37,26.92-1.58c6.96-0.16,13.97,0.95,20.92,0.64
          c32.12-1.44,64.22-3.09,96.32-4.84c19.1-1.04,38.19-2.25,57.25-3.74c5.14-0.4,6.29,1.68,6.48,5.79c0.2,4.28,0.72,8.55,1.22,12.81
          c0.17,1.48,0.72,2.91,2.26,4.16c-0.44-7.72-0.87-15.45-1.34-23.17c-0.19-3.1-2.59-2.76-4.79-2.65c-6.15,0.32-12.31,0.65-18.5,0.39
          c7.88-0.6,15.76-1.2,24.23-1.84c0.65,6.51,1.4,12.62,1.85,18.75c2.1,28.86,4.64,57.71,6,86.6c1.14,24.28,0.54,48.63,1.32,72.93
          c0.79,24.62,2.12,49.22,3.64,73.81c0.76,12.22,0.58,12.75-12.42,13.6c-61.9,4.02-123.79,8.35-185.71,12.1
          c-59.92,3.64-119.89,6.6-179.82,10.07c-43.28,2.5-86.54,5.32-129.8,8.05c-20.47,1.29-40.93,2.73-61.4,4.03
          c-6.05,0.38-7.79-1.1-8.24-6.87c-1.1-14.1-2.12-28.21-3.17-42.31C19.01,250.8,18.82,250.84,18.63,250.88z M37.37,285.4
          c59.42-5.78,118.81-10.72,178.51-11.28c-10.78,1.09-21.56,2.18-32.34,3.27c0.06,0.56,0.11,1.12,0.17,1.68
          c48.15-3.61,96.3-7.23,144.45-10.84c0.1-0.43,0.19-0.87,0.29-1.3c-0.68-0.22-1.35-0.45-2.03-0.67c8.88-1.32,17.34-2,25.81-2.28
          c36.82-1.18,73.71-0.8,110.45-3.99c7.17-0.62,14.3-1.72,21.44-2.6c-0.04-0.46-0.08-0.92-0.13-1.38c-4.09,0-8.18,0-12.27,0
          c0-0.28,0-0.56,0.01-0.84c12.42-0.57,24.83-1.14,37.25-1.71c0.01,0.16,0.02,0.33,0.03,0.49c-4.96,0.57-9.93,1.14-16.02,1.83
          c9.46,2.67,86.95,0.77,95.35-2.07c-0.34-4.81-0.68-9.71-1.03-14.61c-2.22-30.94-4.32-61.88-6.7-92.81
          c-1.96-25.49-4.48-50.94-6.32-76.44c-1.12-15.54-1.52-31.12-2.05-46.69c-0.15-4.54-1.56-6.23-6.82-5.84
          c-29.92,2.23-59.88,3.98-89.81,6.09c-13.94,0.98-27.82,2.67-41.76,3.57c-11.42,0.73-22.94,0.25-34.32,1.23
          c-18.06,1.55-36.06,3.82-54.07,5.88c-11.49,1.32-22.95,2.81-34.43,4.22c-0.05-0.38-0.1-0.77-0.15-1.15
          c8.12-1.09,16.24-2.17,24.37-3.26c-0.08-0.54-0.15-1.09-0.23-1.63c-12.57,1.02-25.31,1.11-37.65,3.3
          c-15.34,2.73-30.64,4.32-46.19,3.99c-14.81-0.31-29.62-1.1-44.43-0.94c-8.25,0.09-16.45,2.02-24.72,2.58
          c-25.11,1.7-50.24,3.09-75.35,4.73c-2.56,0.17-5.03,1.6-7.59,1.8c-5.9,0.46-11.86,0.25-17.74,0.8c-17.89,1.66-35.75,3.6-53.64,5.32
          c-3.05,0.29-6.16,0.04-9.69,0.04C24.46,130.92,30.87,207.59,37.37,285.4z"
        />
        <path
          d="M11.01,195.29c-0.88-17.17-1.76-34.35-2.64-51.52c0.29-0.02,0.57-0.03,0.86-0.05c1.17,17.16,2.34,34.32,3.51,51.48
          C12.17,195.23,11.59,195.26,11.01,195.29z"
        />
        <path
          d="M410.47,9.82c12.42-0.64,24.85-1.27,37.27-1.91c0.02,0.29,0.04,0.57,0.05,0.86c-12.41,0.69-24.83,1.37-37.24,2.06
          C410.53,10.49,410.5,10.16,410.47,9.82z"
        />
        <path
          d="M416.49,215.92c-3.81-47.63-7.58-94.79-11.38-142.41c8.07-0.62,15.66-1.26,23.26-1.78c12.13-0.83,24.26-2.16,36.41-2.27
          c38.94-0.36,64.73,22.44,68.2,59.52c1.33,14.23,1.05,28.3-3.99,41.91c-9.36,25.25-29.27,37.91-56.18,41.07
          c-17.52,2.06-35.21,2.81-52.83,4.12C419,216.15,418,216,416.49,215.92z M445.21,191.45c7.96-0.63,15.26-1.03,22.51-1.82
          c14.71-1.62,25.41-8.9,30.4-22.19c6.95-18.53,6.17-37.21-2.17-55.25c-4.69-10.15-13.05-17.53-24.68-18.88
          c-11.06-1.28-22.4-0.28-34.28-0.28C439.78,126.54,442.46,158.6,445.21,191.45z"
        />
        <path
          d="M198.88,232.5c8.66-36.91,17.13-73.02,25.59-109.12c2.54-10.84,5.19-21.67,7.54-32.55c0.76-3.53,2.39-5,6.26-5.18
          c10.15-0.47,20.28-1.46,30.43-1.9c1.61-0.07,4.24,1.25,4.81,2.56c19.45,44.56,38.72,89.2,57.99,133.83c0.24,0.56,0.27,1.2,0.54,2.45
          c-9.95,0.72-19.65,1.54-29.38,1.98c-1.11,0.05-2.82-1.85-3.42-3.18c-3.85-8.62-7.57-17.3-11.11-26.03c-1.07-2.62-2.43-3.65-5.5-3.39
          c-14.28,1.17-28.59,2.02-42.86,3.3c-1.48,0.13-3.59,2.15-3.97,3.63c-2.29,8.95-4.35,17.96-6.08,27.01
          c-0.67,3.47-2.17,4.64-5.82,4.79C215.77,231.03,207.66,231.84,198.88,232.5z M253.82,106.47c-4.6,22.31-8.92,43.2-13.36,64.7
          c12.85-0.95,25.05-1.85,38.04-2.81C270.29,147.76,262.29,127.71,253.82,106.47z"
        />
        <path
          d="M119.31,182.72c1.51,18.56,2.98,36.69,4.49,55.33c-10.27,0.76-19.98,1.47-30.33,2.23c-3.82-47.83-7.6-95.1-11.42-142.81
          c8.49-0.65,16.41-1.33,24.34-1.86c12.8-0.86,25.59-2,38.41-2.28c5.89-0.13,12.02,0.84,17.71,2.43
          c19.39,5.42,30.54,19.44,31.03,38.13c0.56,21-9.67,36.18-30.44,42.6c-9.53,2.95-19.87,3.59-29.88,5.06
          C128.83,182.21,124.35,182.32,119.31,182.72z M117.43,160.81c11.49-1.63,22.21-2.28,32.4-4.85c10.13-2.55,14.05-10.49,13.03-21.94
          c-0.82-9.29-6.56-16.37-16.32-17.19c-10.63-0.89-21.42,0.03-32.75,0.17C115.04,132.06,116.18,145.77,117.43,160.81z"
        />
        <path
          d="M382.03,218.9c-10.41,0.76-20.15,1.47-30.39,2.22c-3.82-47.87-7.6-95.17-11.41-142.83c10.07-0.75,19.49-1.46,29.82-2.23
          C374.04,123.62,378.01,170.91,382.03,218.9z"
        />
        <path
          d="M525.07,251.87c9.1-0.83,18.19-1.66,27.29-2.5c0.04,0.51,0.09,1.02,0.13,1.52c-9.11,0.68-18.22,1.36-27.33,2.03
          C525.13,252.58,525.1,252.22,525.07,251.87z"
        />
      </g>
    </svg>
  );
}

// Setting admin values for the props of PaidIcon
PaidIcon.defaultProps = {
  color: 'dark',
  size: '16px',
};

// Typechecking props for the PaidIcon
PaidIcon.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
    'light',
    'white',
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default PaidIcon;
