import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

// @mui material components
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Soft UI Dashboard PRO React base styles
import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';

// Functions
import { currencyFormatter } from 'Util';

const selector = (state) => ({
  invoiceInfo: state.invoice.invoiceInfo,
});

function InvoiceDetail() {
  const { invoiceInfo } = useSelector(selector, shallowEqual);

  const [invoiceItems, setInvoiceItems] = useState([]);

  useEffect(() => {
    if (invoiceInfo.invoice_items !== undefined) {
      setInvoiceItems(invoiceInfo.invoice_items);
    }
  }, [invoiceInfo]);

  const { borderWidth } = borders;
  const { light } = colors;
  const borderBottom = `${borderWidth[1]} solid ${light.main}`;

  return (
    <SuiBox p={3}>
      <SuiBox width="100%" overflow="auto">
        <Table sx={{ minWidth: '32rem' }}>
          <SuiBox component="thead">
            <TableRow>
              <SuiBox
                component="th"
                py={1.5}
                pl={3}
                pr={1}
                textAlign="left"
                borderBottom={borderBottom}
              >
                <SuiTypography variant="h6" color="text" fontWeight="medium">
                  Qty
                </SuiTypography>
              </SuiBox>
              <SuiBox
                component="th"
                width={{ xs: '45%', md: '50%' }}
                py={1.5}
                px={1}
                textAlign="left"
                borderBottom={borderBottom}
              >
                <SuiTypography variant="h6" color="text" fontWeight="medium">
                  Description
                </SuiTypography>
              </SuiBox>
              <SuiBox
                component="th"
                py={1.5}
                pl={3}
                pr={1}
                textAlign="left"
                borderBottom={borderBottom}
              >
                <SuiTypography variant="h6" color="text" fontWeight="medium">
                  Unit Price
                </SuiTypography>
              </SuiBox>
              <SuiBox
                component="th"
                py={1.5}
                pl={3}
                pr={1}
                textAlign="left"
                borderBottom={borderBottom}
              >
                <SuiTypography variant="h6" color="text" fontWeight="medium">
                  Total
                </SuiTypography>
              </SuiBox>
            </TableRow>
          </SuiBox>
          <TableBody>
            {Object.keys(invoiceItems).length > 0
              && invoiceItems.map((i, n) => (
                <TableRow
                  key={
                    // eslint-disable-next-line react/no-array-index-key
                    `item${n}`
                  }
                >
                  <SuiBox
                    component="td"
                    textAlign="left"
                    py={1}
                    pr={1}
                    pl={3}
                    borderBottom={borderBottom}
                  >
                    <SuiTypography variant="body2" color="text">
                      {i.quantity}
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox
                    component="td"
                    textAlign="left"
                    p={1}
                    borderBottom={borderBottom}
                  >
                    <SuiTypography variant="body2" color="text">
                      {i.description}
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox
                    component="td"
                    textAlign="left"
                    py={1}
                    pr={1}
                    pl={3}
                    borderBottom={borderBottom}
                  >
                    <SuiTypography variant="body2" color="text">
                      {currencyFormatter.format(i.unit_price)}
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox
                    component="td"
                    textAlign="left"
                    py={1}
                    pr={1}
                    pl={3}
                    borderBottom={borderBottom}
                  >
                    <SuiTypography variant="body2" color="text">
                      {currencyFormatter.format(i.quantity * i.unit_price)}
                    </SuiTypography>
                  </SuiBox>
                </TableRow>
              ))}
            <TableRow>
              <SuiBox
                component="td"
                textAlign="left"
                p={1}
                borderBottom={borderBottom}
              />
              <SuiBox
                component="td"
                textAlign="left"
                py={1}
                pr={1}
                pl={3}
                borderBottom={borderBottom}
              />
              <SuiBox
                component="td"
                textAlign="left"
                py={1}
                pr={1}
                pl={3}
                borderBottom={borderBottom}
              >
                <SuiTypography variant="h5">Total</SuiTypography>
              </SuiBox>
              <SuiBox
                component="td"
                textAlign="left"
                py={1}
                pr={1}
                pl={3}
                borderBottom={borderBottom}
              >
                <SuiTypography variant="h5">
                  {currencyFormatter.format(invoiceInfo.total)}
                </SuiTypography>
              </SuiBox>
            </TableRow>
          </TableBody>
        </Table>
      </SuiBox>
    </SuiBox>
  );
}

export default InvoiceDetail;
