import ReactDOM from 'react-dom';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'index.css';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import { Provider } from 'react-redux';
import { store } from 'store';
import { history } from 'helpers/history';

// Soft UI Context Provider
import { SoftUIControllerProvider } from 'context';
import ReactLoading from 'react-loading';

ReactDOM.render(
  <Suspense fallback={<ReactLoading type="spin" />}>
    <Provider store={store}>
      <BrowserRouter history={history}>
        <SoftUIControllerProvider>
          <App />
        </SoftUIControllerProvider>
      </BrowserRouter>
    </Provider>
  </Suspense>,
  // eslint-disable-next-line no-undef
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
