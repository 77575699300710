import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';

// Components
import FormDateRangePicker from 'layouts/components/FormDateRangePicker';

// Functions
import dayjs from 'dayjs';

function LeadConversionParameters({ defaultParameters, setParametersFunc }) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = useState([
    dayjs().subtract(1, 'month'),
    dayjs(),
  ]);

  useEffect(() => {
    if (defaultParameters !== undefined) {
      let fd = dayjs().subtract(1, 'month');
      let td = dayjs();

      const { from, to } = defaultParameters;
      if (from !== undefined && from !== null) {
        fd = dayjs(from);
      }
      if (to !== undefined && to !== null) {
        td = dayjs(to);
      }

      setSelectedDate([fd, td]);
    }
  }, [dispatch, defaultParameters]);

  const handleDate = (e) => {
    setSelectedDate(e);
    setParametersFunc({
      from: e[0] !== null ? e[0].format('YYYY-MM-DD') : null,
      to: e[1] !== null ? e[1].format('YYYY-MM-DD') : null,
    });
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      spacing={3}
    >
      <Grid item md={4}>
        <FormDateRangePicker
          label={t('date')}
          localeText={{
            start: '',
            end: '',
          }}
          value={selectedDate}
          onChange={handleDate}
        />
      </Grid>
      <Grid item md={8} />
    </Grid>
  );
}

LeadConversionParameters.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  defaultParameters: PropTypes.any.isRequired,
  setParametersFunc: PropTypes.func.isRequired,
};

export default LeadConversionParameters;
