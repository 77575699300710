import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// @mui material components
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';

// Reducers
import { clearPositionListing, fetchPositionListings } from 'reducers/positionListingsSlice';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';

// DevExtreme components
import DataGrid, {
  Column,
  Editing,
  HeaderFilter,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Sorting,
  StateStoring,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.material.blue.light.css';

// Layout components
import BaseLayout from 'layouts/components/BaseLayout';
import TabBar from 'layouts/components/TabBar';

const selector = (state) => ({
  positionListings: state.positionListing.positionListings,
});

function Customers() {
  const { t } = useTranslation('translation', { keyPrefix: 'position-listings' });

  const { positionListings } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [filteredPositions, setFilteredPositions] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    dispatch(clearPositionListing());
    dispatch(fetchPositionListings());
  }, [dispatch]);

  useEffect(() => {
    if (selectedTab === 0) {
      setFilteredPositions(positionListings.filter((p) => p.status_id === 1));
    } else {
      setFilteredPositions(positionListings.filter((p) => p.status_id === 2));
    }
  }, [positionListings, selectedTab]);

  const allowedPageSizes = [10, 30, 50, 'all'];

  const handleSetTabValue = (event, newValue) => {
    switch (newValue) {
      case 0:
        setSelectedTab(0);
        break;
      case 1:
        setSelectedTab(1);
        break;
      default:
        setSelectedTab(0);
        break;
    }
  };

  const handleNew = () => {
    navigate('new', { replace: true });
  };

  const handleEditing = (e) => {
    navigate(`/position-listings/edit/${e.key}`);
  };

  return (
    <BaseLayout>
      <TabBar
        tabs={[t('active'), t('archived')]}
        setTabValueFunction={handleSetTabValue}
      />
      <SuiBox display="flex" justifyContent="flex-end" mt={6} p={1}>
        <SuiBox mr={1}>
          <SuiButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleNew}
          >
            <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
            {` ${t('add-new-position-listing')}`}
          </SuiButton>
        </SuiBox>
      </SuiBox>
      <SuiBox>
        <Paper>
          <DataGrid
            id="positios-listings"
            dataSource={filteredPositions}
            keyExpr="id"
            allowColumnReordering
            allowColumnResizing
            columnAutoWidth
            onEditingStart={handleEditing}
          >
            <Editing
              mode="row"
              allowUpdating
              allowDeleting={false}
              allowAdding={false}
            />
            <HeaderFilter visible />
            <Scrolling rowRenderingMode="virtual" />
            <Sorting mode="multiple" />
            <Paging defaultPageSize={30} />
            <Pager
              visible
              allowedPageSizes={allowedPageSizes}
              displayMode="full"
              showPageSizeSelector
              showInfo
              showNavigationButtons
            />
            <SearchPanel
              visible
              width={240}
              placeholder={t('search', { keyPrefix: 'common' })}
            />
            <StateStoring
              enabled
              type="localStorage"
              storageKey="position-listing-grid"
            />
            <Column
              caption={t('client')}
              dataField="client_name"
            />
            <Column
              caption={t('name')}
              dataField="name"
            />
            <Column
              caption={t('date-opened')}
              dataField="date_opened"
            />
            <Column
              caption={t('openings')}
              dataField="openings"
            />
          </DataGrid>
        </Paper>
      </SuiBox>
    </BaseLayout>
  );
}

export default Customers;
