import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Reducer functions
import { clearRole, setRoleEditing } from 'reducers/roleSlice';
import { fetchPermission, fetchPermissions } from 'reducers/permisionsSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import BasicInfo from '../components/BasicInfo';
import SavePanel from '../components/SavePanel';
import { fetchRoleTypes } from '../../../reducers/commonSlice';

function NewRole() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setRoleEditing(false));
    dispatch(clearRole());
    dispatch(fetchPermissions());
    dispatch(fetchPermission({ id: 1 }));
    dispatch(fetchRoleTypes());
  }, [dispatch]);

  return (
    <BaseLayout>
      <SuiBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <BasicInfo />
                  <SavePanel />
                </Grid>
              </Grid>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </BaseLayout>
  );
}

export default NewRole;
